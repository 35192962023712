import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '../pro-components/Page';
import SectionComponent from '../pro-components/Section';
import Dropdown from '../pro-components/CDropDown';
import { fetchTreatmentData, fetchEnableSubTreatmentsData, updateUser, updateActiveTreatmentStatusAPI, updatePatientStatusAPI, sendEmail, createCustomer, updatePatient } from '../../../api';

const SelectTreatment = () => {
  const [treatments, setTreatments] = useState([]);
  const [subTreatments, setSubTreatments] = useState([]);
  const [subSubTreatments, setSubSubTreatments] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState("");
  const [selectedSubTreatment, setSelectedSubTreatment] = useState("");
  const [selectedSubSubTreatment, setSelectedSubSubTreatment] = useState("");
  const patientId = localStorage.getItem("patientId");
  console.log('patient id from local storage', patientId);
  console.log('selected sub treatment from sidebar', selectedSubTreatment);
  //console.log('selected sub sub treatment from sidebar', selectedSubSubTreatment);
  const patientStatusId = localStorage.getItem("patientStatusId");
  console.log('patient status id from local storage', patientStatusId);
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const PatientPhone = localStorage.getItem("Patientphone");
  const storedSectionData = localStorage.getItem('patientsecondPageData');
  console.log("printing patient phone from local storage", storedSectionData);

  let PatientPhoneLocal = '';

  if (storedSectionData) {
    const parsedData = JSON.parse(storedSectionData);
    if (parsedData.phone) {
      PatientPhoneLocal = parsedData.phone;
    }
  }

  console.log("printing patient phone from local storage", PatientPhoneLocal);

  useEffect(() => {
    fetchTreatmentData()
      .then((data) => {
        setTreatments(data);
        console.log("Getting data from FindAllTreatment API");
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (selectedTreatment !== "") {
      fetchEnableSubTreatmentsData(selectedTreatment)
        .then((data) => {
          setSubTreatments(data);
          console.log("Getting data from FindAllSubTreatment API");
          console.log(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedTreatment]);

  const updatePatientTreatmentDetails = async () => {
    try {
      const customerData = {
        email: userData.email,
        name: userData.name,
        phone: PatientPhoneLocal || PatientPhone,
      };

      // Create a customer and get the customer_id
      const customerResponse = await createCustomer(customerData);

      if (customerResponse) {
        console.log("Customer created successfully:", customerResponse);

        const sectionData = {
          subtreatment_id: selectedSubTreatment,
          customer_id: customerResponse.id,
        };
        localStorage.setItem("selectedsubtreatment_id", selectedSubTreatment)
        console.log("-->> printing selected treatment id", selectedSubTreatment)
        const patientResponse = await updatePatient(patientId, { subtreatment_id: selectedSubTreatment, customer_id: customerResponse.id,});
        console.log("'''''before if condition Patient treatment details updated successfully");
        if (patientResponse) {
          console.log(",,,,,Patient treatment details updated successfully");
          console.log(patientResponse)
          const requests = [
            updatePatientStatus(sectionData.customer_id), // Update patient status
            updateActiveTreatment(sectionData.customer_id), // Update active treatment
            updateCustomerID(sectionData.customer_id), // Update user's customer_id
            localStorage.setItem("customer_id", sectionData.customer_id), // Store customer_id in local storage
          ];

          const responses = await Promise.all(requests);

          if (responses.every(response => response)) {
            console.log("All updates completed successfully");
            localStorage.setItem("patientSidebar", 'treatment-selected');
          } else {
            console.log("Failed to update some details");
          }
        } else {
          console.log("Failed to update patient treatment details");
        }
      } else {
        console.log("Failed to create a customer");
      }
    } catch (error) {
      console.log("Error updating patient treatment details or creating customer:", error);
    }
  };

  const updatePatientStatus = async (customer_id) => {
    try {

      // Call the correct API function for updating patient status
      const response = await updatePatientStatusAPI(patientStatusId, {
        subtreatment_id: selectedSubTreatment,
        treatmentselect_status: "selected", // Ensure that this property is defined
        customer_id: customer_id,
      });

      if (response) {
        console.log("Patient status updated successfully");
        localStorage.setItem("patientSidebar", 'treatment-selected');

        const sendMailResponse = await sendEmail(userData.email, 'Thank You for Selecting Treatment', emailLayout);

        console.log('Email sent successfully:', sendMailResponse.data);
      } else {
        console.log("Failed to update patient status");
      }
    } catch (error) {
      console.log("Error updating patient status:", error);
    }
  };

  const updateActiveTreatment = async (customer_id) => {
    try {
      const userId = userData.id; // Assuming userData contains the user's ID

      // Use the updateActiveTreatmentStatus API function to update active treatment status
      const updateResponse = await updateActiveTreatmentStatusAPI(userId, {
        subtreatment_id: selectedSubTreatment,
        // subsub_treatment_id: selectedSubSubTreatment,
        status: 'treatment_selected',
        customer_id: customer_id,
      });
      console.log("selectedSubTreatment", selectedSubTreatment);

      if (updateResponse) {
        console.log("Active Treatment updated successfully");
        localStorage.setItem("patientSidebar", 'treatment-selected');
      } else {
        console.log("Failed to update Active Treatment");
      }
    } catch (error) {
      console.log("Error updating Active Treatment:", error);
    }
  };

  const updateCustomerID = async (customer_id) => {
    try {
      const userId = userData.id; // Assuming userData contains the user's ID

      // Use the updateUser API function to update customer_id
      const updateResponse = await updateUser(userId, { customer_id });

      if (updateResponse) {
        console.log("User's customer_id updated successfully");
      } else {
        console.log("Failed to update user's customer_id. Status:", updateResponse.status);
        console.log("Response data:", updateResponse.data);
      }
    } catch (error) {
      console.log("Error updating user's customer_id:", error);
    }
  };

  const emailLayout = `
  <div style="max-width: 100%; margin: 0 auto; font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6;">
    <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; text-align: center;">
      <img src="https://meditravels.s3.amazonaws.com/Logo/952d4079-478e-44ce-92e8-f8e03e84852d-sticky-logo.png" alt="MediTravels Logo" style="max-width: 20%; height: auto;" />
      <p style="text-align: left; margin: 5px 0;">Dear ${userData.name},</p>
    <p style="text-align: left;">Thank you for choosing MediTravels for your treatment. Your health is our top priority, and we are committed to providing you with the best care possible.</p>
    <p style="text-align: left;">To proceed with your treatment, we kindly request you to fill out our consultation form. This form will help us better understand your needs and ensure that you receive the most appropriate care.</p>
    <p style="text-align: left;">Once again, thank you for choosing MediTravels. We look forward to providing you with exceptional healthcare.</p>
    <p style="text-align: left; margin: 5px 0 2px;">Best regards,</p>
    <p style="text-align: left; margin: 0px;">MeditravelS Team</p>
  </div>
</div>`;

  return (
    <Page>
      <SectionComponent>
        <label className="sec-label">Select Treatment</label>
        <br />
        {/* Treatment Dropdown */}
        <div style={{ paddingTop: '10px' }}>
          <Dropdown
            className="dropdown-compo"
            label="Treatment"
            value={selectedTreatment}
            options={treatments.map((treatment) => ({
              value: treatment._id,
              label: treatment.name,
            }))}
            onChange={(selectedValue) => setSelectedTreatment(selectedValue)}
          />
        </div>
        {/* <br /> */}
        {/* Sub-treatment Dropdown */}
        <div style={{ paddingTop: '20px' }}>
          <Dropdown
            className="dropdown-compo select-treatment-dropdown"
            label="Sub-treatment"
            value={selectedSubTreatment}
            options={subTreatments.map((subTreatment) => ({
              value: subTreatment._id,
              label: subTreatment.name,
            }))}
            onChange={(selectedValue) => setSelectedSubTreatment(selectedValue)}
            isDisabled={!selectedTreatment}
          />
        </div>
        {/* <br /> */}
        {/* Sub-sub-treatment Dropdown */}
        {/* <div style={{ paddingTop: '10px' }}>
          <Dropdown
          className="dropdown-compo"
            label="Sub-sub-treatment"
            value={selectedSubSubTreatment}
            options={subSubTreatments.map((subSubTreatment) => ({
              value: subSubTreatment._id,
              label: subSubTreatment.name,
            }))}
            onChange={(selectedValue) => setSelectedSubSubTreatment(selectedValue)}
            isDisabled={!selectedSubTreatment}
          />
        </div> */}
        <div className='select-treatment-btn'>
          <Link className="btn btn-yellow" to="/patient/patientdashboard" style={{ marginRight: '20px' }} onClick={updatePatientTreatmentDetails}>
            Save
          </Link>
        </div>
      </SectionComponent>
    </Page>
  );
};

export default SelectTreatment;