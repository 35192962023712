import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Intro extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="intro-area home-intro pd-top-90">
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<div className="single-intro wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">
							<h4 className="intro-title">
								<span className="intro-count">01</span>
								<Link className="intro-cat" to="/about">Treatments</Link>
							</h4>
							<p className="intro-title-content" style={{ textAlign: 'justify' }}>India's Hospitals Have Earned The Reputation as World - Class Institutions With State of the - Art Technology. The state of the art technologies is available to treat even the most difficult medical cases such as organ transplants, cardiovascular surgery, orthopedic surgery, etc.</p>
						</div>
					</div>
					<div className="col-md-4">
						<div className="single-intro wow animated fadeInUp" data-wow-duration="1.0s" data-wow-delay="0.2s">
							<h4 className="intro-title">
								<span className="intro-count">02</span>
								<Link className="intro-cat" to="/about">Tourism</Link>
							</h4>
							<p className="intro-title-content" style={{ textAlign: 'justify' }}>Indian Tourism Offers a Different Cultures, Traditions,Festivals, and Places of Interest. There are a lot of options for the Tourists. India is a Country with Rich Cultural and United Diversity. This aspect is even reflected in its tourism.</p>
						</div>
					</div>
					<div className="col-md-4">
						<div className="single-intro wow animated fadeInUp" data-wow-duration="1.6s" data-wow-delay="0.3s">
							<h4 className="intro-title">
								<span className="intro-count">03</span>
								<Link className="intro-cat" to="/about">Training</Link>
							</h4>
							<p className="intro-title-content" style={{ textAlign: 'justify' }}> Indian Medical Education and the Training approach to be modified and ensure enhancing Practical Clinical skills, than just sticking with Predominantly Theoretical or Classroom training for various Medical and ParaMedical Students and Also for Doctors.</p>
						</div>
					</div>
				</div>
			</div>
		</div>

	}
}

export default Intro