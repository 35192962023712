// import React from "react";


// const BlogSkeleton = () => {
//   return (
//     <div
//       style={{
//         marginTop: "250px",
//         display: "flex", // Use flex to create a row layout
//         justifyContent: "space-evenly", // Space between columns
//       }}
//     >
//       {/* First Column */}
//       <div
//         style={{
//           width: "400px", // Width of the box
//           height: "280px", // Height of the box
//           backgroundColor: "#f2f2f2",
//           borderRadius: "10px",
//           overflow: "hidden",
//           background:
//             "linear-gradient(to right, #f2f2f2 0%, #e6e6e6 20%, #f2f2f2 40%)",
//           animation: "loading-line 1.5s linear infinite",
         
//         }}
//       >
//         {/* Skeleton content */}
//       </div>

//       {/* Second Column */}
//       <div
//         style={{
//           width: "400px", // Width of the box
//           height: "280px", // Height of the box
//           backgroundColor: "#f2f2f2",
//           borderRadius: "10px",
//           overflow: "hidden",
//           background:
//             "linear-gradient(to right, #f2f2f2 0%, #e6e6e6 20%, #f2f2f2 40%)",
//           animation: "loading-line 1.5s linear infinite",
//         }}
//       >
//         {/* Skeleton content */}
//       </div>

//       {/* Third Column */}
//       <div
//         style={{
//           width: "400px", // Width of the box
//           height: "280px", // Height of the box
//           backgroundColor: "#f2f2f2",
//           borderRadius: "10px",
//           overflow: "hidden",
//           background:
//             "linear-gradient(to right, #f2f2f2 0%, #e6e6e6 20%, #f2f2f2 40%)",
//           animation: "loading-line 1.5s linear infinite",
//         }}
//       >
//         {/* Skeleton content */}
//       </div>
//     </div>
//   );
// };

// export default BlogSkeleton;


import React from "react";


const BlogSkeleton = () => {
  return (
    <div className="skeleton-container">
      {/* First Column */}
      <div className="skeleton-box">
        {/* Skeleton content */}
      </div>

      {/* Second Column */}
      <div className="skeleton-box">
        {/* Skeleton content */}
      </div>

      {/* Third Column */}
      <div className="skeleton-box">
        {/* Skeleton content */}
      </div>
    </div>
  );
};

export default BlogSkeleton;
