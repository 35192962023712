import React from 'react';


const DateTitleSkeleton = () => {
  return (
    <div className="skeleton-loader2">
      <div className="skeleton-date"></div>
      <div className="skeleton-title2"></div>
      <div className="skeleton-line2"></div>
      <div className="skeleton-line2"></div>
      <div className="skeleton-line2"></div>
    </div>
  );
};

export default DateTitleSkeleton;
