import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from './CButton';
// import LanguageProficiency from '../pages/LanguageProficiency'

const FirstAction = ({onClearForm,  onClickNext}) => {
  const handleClearForm = () => {
    // Add your logic to clear the form here
    onClearForm();
  };
  return (
    <div className='action-component' style={{ display: 'flex', overflow: 'unset', width: '50%', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex'}}>
      <div style={{marginRight: '20px'}}>
      <Link
            onClick={(event) => {
              event.preventDefault();
              window.scrollTo({ top: 0, behavior: 'smooth' });
              if (onClickNext) {
                onClickNext(event); // Pass the event object to the onClickNext function
              }
            }}
          >
            <CustomButton className='action-btn' variant="contained" color="primary" label="Next">
              Next
            </CustomButton>
          </Link>
        </div>
      </div>
      <div>
        <CustomButton className='action-btn' variant="contained" color="secondary" label="Clear Form" onClick={handleClearForm}>
          Clear Form
        </CustomButton>
      </div>
    </div>
  );
};

export default FirstAction;