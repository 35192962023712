import React, { useState, useEffect, useRef } from 'react';
import Page from '../pro-components/Page'
import SectionComponent from '../pro-components/Section'
import FirstSection from '../pro-components/FirstSection'
import Action from '../pro-components/Action'
import CheckboxComponent from '../pro-components/CCheckBox'
import { useHistory } from "react-router-dom";
import CustomButton from '../pro-components/CButton';
import { Modal, Button } from 'react-bootstrap';
import Ticket from '../pro-components/ticket'
import CustomModal from "../../global-components/modal";
import { updatePro2 } from '../../../api';

const TermsandConditions = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckeds, setIsCheckeds] = useState(false);
  const [sectionData, setSectionData] = useState("");
  // const [showModal, setShowModal] = useState(false);
  const [isAgreeButtonClicked1, setIsAgreeButtonClicked1] = useState(false);
  const [isAgreeButtonClicked2, setIsAgreeButtonClicked2] = useState(false);
  const [isModalScrolled, setIsModalScrolled] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [isModalScrolled1, setIsModalScrolled1] = useState(false);
  const [isModalScrolled2, setIsModalScrolled2] = useState(false);
  const [isAgreeEnabled1, setIsAgreeEnabled1] = useState(false);
  const [isAgreeEnabled2, setIsAgreeEnabled2] = useState(false);
  const [isScrollbarAtEnd1, setIsScrollbarAtEnd1] = useState(false);
  const [isScrollbarAtEnd2, setIsScrollbarAtEnd2] = useState(false);
  const [checkboxError1, setCheckboxError1] = useState(false);
  const [showCheckboxError1, setShowCheckboxError1] = useState(false);
  const [checkboxError2, setCheckboxError2] = useState("");
  const [showCheckboxError2, setShowCheckboxError2] = useState("");
  const pageRef = useRef(null);
  const bodyRef1 = useRef(null);
  const bodyRef2 = useRef(null);
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const previousPageURL = '/pro/section6-2';
  let history = useHistory();
  // const nextPageURL = '/submit-page';
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const target = bodyRef1.current;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      const scrollTop = target.scrollTop;
      const maxScrollTop = scrollHeight - clientHeight;
      setIsModalScrolled1(scrollTop === maxScrollTop);

      if (scrollTop === maxScrollTop) {
        setIsAgreeEnabled1(true);
      } else {
        setIsAgreeEnabled1(false);
      }
    };

    if (bodyRef1.current) {
      bodyRef1.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (bodyRef1.current) {
        bodyRef1.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isScrollbarAtEnd1) {
      setIsAgreeEnabled1(true);
    } else {
      setIsAgreeEnabled1(false);
    }
  }, [isScrollbarAtEnd1]);

  useEffect(() => {
    const handleScroll2 = () => {
      const target = bodyRef2.current;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      const scrollTop = target.scrollTop;
      const maxScrollTop = scrollHeight - clientHeight;
      setIsModalScrolled2(scrollTop === maxScrollTop);

      if (scrollTop === maxScrollTop) {
        setIsAgreeEnabled2(true);
      } else {
        setIsAgreeEnabled2(false);
      }
    };

    if (bodyRef2.current) {
      bodyRef2.current.addEventListener('scroll', handleScroll2);
    }

    return () => {
      if (bodyRef2.current) {
        bodyRef2.current.removeEventListener('scroll', handleScroll2);
      }
    };
  }, []);

  useEffect(() => {
    if (isScrollbarAtEnd2) {
      setIsAgreeEnabled2(true);
    } else {
      setIsAgreeEnabled2(false);
    }
  }, [isScrollbarAtEnd2]);

  const updateSectionData = async (sectionData) => {
    console.log("user id from local storage", userData.id);
    const userId = userData.id;
    console.log("-------userId from local storage", userId);

    if (!userId) {
      console.log("User ID not found in local storage");
      return;
    }

    try {
      const existingSectionData = JSON.parse(localStorage.getItem("firstPageData2"));
      const section_data = [
        {
          0: existingSectionData, // Include the first page data at index 0
        },
        {
          1: JSON.parse(localStorage.getItem("secondPageData2")), // Include the second page data at index 1
        },
        {
          2: JSON.parse(localStorage.getItem("thirdPageData2")), // Include the second page data at index 1
        },
        {
          3: JSON.parse(localStorage.getItem("fourthPageData2")), // Include the second page data at index 1
        },
        {
          4: JSON.parse(localStorage.getItem("fifthPageData2")),
        },
        {
          5: JSON.parse(localStorage.getItem("sixthPageData2")),
        },
        {
          6: sectionData,
        },
      ];

      console.log("Updated data of pro2");
      console.log(section_data);

      const updatedSectionData = await updatePro2(userId, section_data);

      if (updatedSectionData) {
        console.log("------Updated data of pro");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updatePro2.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckboxChange = (checked) => {
    let error = '';
    if (!isAgreeButtonClicked1) {
      // Display error message for terms and conditions
      error = 'Please click on the "View company\'s terms & conditions" button and then click "Agree" before checking the checkbox.';
      return;
    } setCheckboxError1(error);
    setIsChecked(checked);
    setSectionData((prevData) => ({
      ...prevData,
      isChecked: checked,
    }));

  };

  const handleCheckboxChangeBlur = () => {
    setShowCheckboxError1(!isChecked);
  };

  const handleCheckboxChanges = (checked) => {
    let error = '';
    if (!isAgreeButtonClicked2) {
      // Display error message for privacy policy
      error = 'Please click on the "View Privacy policy consent" button and then click "Agree" before checking the checkbox.';
      return;
    } setCheckboxError2(error);
    setIsCheckeds(checked);
    setSectionData((prevData) => ({
      ...prevData,
      isCheckeds: checked,
    }));
  };

  const handleCheckboxChangesBlur = () => {
    setShowCheckboxError2(!isCheckeds);
  };

  useEffect(() => {
    const storedSectionData = JSON.parse(localStorage.getItem('seventhPageData2'));

    if (storedSectionData) {
      setIsChecked(storedSectionData.isChecked || '');
      setIsCheckeds(storedSectionData.isCheckeds || '');
    }
  }, []);

  const handleViewClick1 = () => {
    setShowModal1(true);
  };

  const handleViewClick2 = () => {
    setShowModal2(true);
  };

  const handleAgreeClick = () => {
    setIsAgreeButtonClicked1(true);
    setShowCheckboxError1(false);
    setIsChecked(true);
    setIsAgreeEnabled1(false);
    setShowModal1(false);
    const target = bodyRef1.current;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    const scrollTop = target.scrollTop;
    const maxScrollTop = scrollHeight - clientHeight;
    setIsScrollbarAtEnd1(scrollTop === maxScrollTop);
  };

  const handleAgreeClick2 = () => {
    setIsAgreeButtonClicked2(true);
    setIsCheckeds(true);
    setIsAgreeEnabled2(false);
    setIsScrollbarAtEnd2(false);
    setShowCheckboxError2(false);
    setShowModal2(false);
    const target = bodyRef2.current;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    const scrollTop = target.scrollTop;
    const maxScrollTop = scrollHeight - clientHeight;
  };

  const handleModalClose1 = () => {
    setShowModal1(false);
  };

  const handleModalClose2 = () => {
    setShowModal2(false);
  };

  const handleNextClick = async (event) => {
    event.preventDefault();

    if (!isChecked) {
      showErrorModal("Alert", "Please fill the required checkbox for Agreement");
      setCheckboxError1("Please fill the required checkbox for Agreement");
      setShowCheckboxError1(true);
      return;
    }

    if (!isCheckeds) {
      showErrorModal("Alert", "Please fill the required checkbox for Privacy Policy");
      setCheckboxError2("Please fill the required checkbox for Privacy Policy");
      setShowCheckboxError2(true);
      return;
    }else {
      // Store all the data in local storage
      const seventhPageData = {
        isChecked: isChecked,
        isCheckeds: isCheckeds,
      };


      localStorage.setItem("seventhPageData2", JSON.stringify(seventhPageData));

      // Update the section data in the backend
      await updateSectionData(seventhPageData);

      history.push("/pro/submit-phase2");
    }
  };

  const handleClearForm = () => {
    setIsChecked('');
    setIsCheckeds('');
    setCheckboxError1("");
    setCheckboxError2("");
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <Page ref={pageRef}>
      <FirstSection>
        <h4>Terms and Conditions</h4>
      </FirstSection>
      <SectionComponent>
        AGREEMENT TO COMPANY'S TERMS AND CONDITIONS*
        <br />
        <CheckboxComponent
          label="I Agree"
          checked={isChecked}
          // onChange={handleCheckboxChange}
          onChange={() => handleViewClick1()}
          onBlur={handleCheckboxChangeBlur}
        />
        {showCheckboxError1 && checkboxError1 && (
          <div className="error-msg">{checkboxError1}</div>
        )}
        <div style={{ paddingTop: '10px' }}>
          <CustomButton type="button" onClick={handleViewClick1} >
            View
          </CustomButton>
        </div>
      </SectionComponent>
      <SectionComponent>
        PRIVACY POLICY CONSENT*
        <br />
        <CheckboxComponent
          label="I Agree"
          checked={isCheckeds}
          // onChange={handleCheckboxChanges}
          onChange={() => handleViewClick2()}
          onBlur={handleCheckboxChangesBlur}
        />
        {showCheckboxError2 && checkboxError2 && (
          <div className="error-msg">{checkboxError2}</div>
        )}
        <div style={{ paddingTop: '10px' }}>
          <CustomButton type="button" onClick={handleViewClick2} >
            View
          </CustomButton>
        </div>
      </SectionComponent>
      <Action
        backLink={previousPageURL}
        onClickNext={handleNextClick}
        onClearForm={handleClearForm} />
      <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
        <Ticket variant="contained" color="secondary" section="PRO: Verification Form - Terms and Conditions" />
      </div>
      <Modal show={showModal1} onHide={handleModalClose1} className="newsletter-popup view-popup" backdrop="static">
        <Modal.Header className="modal-close-button" style={{ width: '100%' }}>
          <Modal.Title >Terms & Conditions for a Patient Relation Officer (P.R.O.) working with MITS Hospitality LLP 1:</Modal.Title>
        </Modal.Header>
        <Modal.Body
          ref={bodyRef1}
           style={{
            maxHeight: '400px', // Adjust this value as needed
            overflowY: 'scroll',
          }}
          onScroll={(e) => {
            const target = e.target;
            const scrollHeight = target.scrollHeight;
            const clientHeight = target.clientHeight;
            const scrollTop = target.scrollTop;

            // Calculate a threshold for determining if the modal is scrolled to the end
            const scrollThreshold = 5; // Adjust this value as needed

            const isAtEnd = scrollHeight - scrollTop - clientHeight <= scrollThreshold;

            setIsModalScrolled1(isAtEnd);

            if (isAtEnd) {
              setIsAgreeEnabled1(true);
            } else {
              setIsAgreeEnabled1(false);
            }
          }}
        >
          {/* Add your terms and conditions text here */}
          <span style={{ display: 'inline-block' }}>1. The P.R.O. is responsible for promoting MedITravelS's website and services to potential clients in their respective location. They will build relationships with potential clients, answer their questions about medical tourism in India and MedITravelS's services, and refer them to appropriate healthcare providers.</span>
          <span style={{ display: 'inline-block' }}>2. The P.R.O. must adhere to MITS Hospitality LLP's branding and marketing guidelines when promoting MedITravelS. This includes using approved messaging, logos, and other marketing materials provided by MITS Hospitality LLP.</span>
          <span style={{ display: 'inline-block' }}>3. The P.R.O. is only authorized to provide general information about medical tourism in India and to refer clients to appropriate healthcare providers. If the P.R.O. is unsure about a particular medical question or inquiry, they must refer the client to a healthcare professional or a MedITravelS team member.</span>
          <span style={{ display: 'inline-block' }}>4. The P.R.O. must act with professionalism and integrity at all times when representing MITS Hospitality LLP. This includes treating clients and healthcare providers courteously and respectfully, avoiding false or misleading claims, and steering clear of any conflicts of interest.</span>
          <span style={{ display: 'inline-block' }}>5. The P.R.O. is considered an independent contractor and is not an employee of MITS Hospitality LLP. They are responsible for their own taxes, insurance, and other business-related expenses. MITS Hospitality LLP will provide the P.R.O. with a commission structure based on their performance.</span>
          <span style={{ display: 'inline-block' }}>6. The P.R.O. will be compensated for their services based on an agreed-upon commission structure. The specific commission structure will be outlined in a separate agreement between the P.R.O. and MITS Hospitality LLP.</span>
          <span style={{ display: 'inline-block' }}>7. MITS Hospitality LLP reserves the right to terminate the P.R.O.'s services at any time for justified reasons.</span>
          <span style={{ display: 'inline-block' }}>8. The P.R.O. is responsible for complying with all local laws and regulations in their respective location when promoting MedITravelS.</span>
          <span style={{ display: 'inline-block' }}>9. The P.R.O. is not authorized to make any representations or warranties on behalf of MITS Hospitality LLP unless specifically authorized in writing by the company.</span>
          <span style={{ display: 'inline-block' }}>10. These terms and conditions represent the whole agreement between the P.R.O. and MITS Hospitality LLP, overriding any previous agreements or understandings, whether they were written or verbal.</span>
          <span style={{ display: 'inline-block' }}>11. The P.R.O. is responsible for ensuring that all promotional materials and communications comply with local laws and regulations, including those related to advertising and healthcare.</span>
          <span style={{ display: 'inline-block' }}>12. The P.R.O. must not engage in any conduct that could damage the reputation or credibility of MITS Hospitality LLP or its services.</span>
          <span style={{ display: 'inline-block' }}>13. The P.R.O. must maintain accurate records of all referrals and communications with potential clients and provide regular reports to MITS Hospitality LLP.</span>
          <span style={{ display: 'inline-block' }}>14. The P.R.O. must promptly notify MITS Hospitality LLP of any potential legal or regulatory issues related to their promotional activities or referrals.</span>
          <span style={{ display: 'inline-block' }}>15. MITS Hospitality LLP may provide training and support to the P.R.O. to ensure that they are able to effectively promote the company's services and adhere to all applicable laws and regulations.</span>
          <span style={{ display: 'inline-block' }}>16. The P.R.O. must maintain the confidentiality of all client information and not disclose any personal or medical information without the client's express consent.</span>
          <span style={{ display: 'inline-block' }}>17. The P.R.O. must promptly notify MITS Hospitality LLP of any conflicts of interest or potential conflicts of interest that could impact their ability to effectively promote the company's services.</span>
          <span style={{ display: 'inline-block' }}>18. MITS Hospitality LLP has the right to terminate the P.R.O.'s services with immediate effect if the P.R.O. violates any of these terms and conditions, or if their actions could potentially harm the reputation or credibility of the company.</span>
          <span style={{ display: 'inline-block' }}>19. The P.R.O. must comply with all applicable anti-corruption laws and regulations when promoting MedITravelS services and must not engage in any conduct that could be considered a violation of such laws.</span>
          <span style={{ display: 'inline-block' }}>20. These terms and conditions are governed by the laws of the jurisdiction in which MITS Hospitality LLP is incorporated and any disputes arising from or related to these terms and conditions will be resolved in accordance with the laws of such jurisdiction.</span>
          <span style={{ display: 'inline-block' }}>21. The P.R.O. must comply with all applicable data protection laws and regulations when handling any personal data or sensitive information related to clients or potential clients.</span>
          <span style={{ display: 'inline-block' }}>22. The P.R.O. is not authorized to enter into any agreements or contracts on behalf of MITS Hospitality LLP without obtaining prior written consent from the company.</span>
          <span style={{ display: 'inline-block' }}>23. The P.R.O. must promptly notify MITS Hospitality LLP of any changes to their contact information or other relevant details.</span>
          <span style={{ display: 'inline-block' }}>24. The P.R.O. is responsible for obtaining all necessary licenses, permits, and authorizations required to legally conduct their promotional activities in the relevant jurisdictions.</span>
          <span style={{ display: 'inline-block' }}>25. The P.R.O. must provide accurate and truthful information to clients and potential clients about MedITravelS services, capabilities, and limitations.</span>
          <span style={{ display: 'inline-block' }}>26. The P.R.O. must not offer any discounts, rebates, or other incentives to clients or potential clients without obtaining prior written approval from MITS Hospitality LLP.</span>
          <span style={{ display: 'inline-block' }}>27. The P.R.O. must comply with all applicable anti-money laundering and counter-terrorism financing laws and regulations when promoting MedITravelS services.</span>
          <span style={{ display: 'inline-block' }}>28. The P.R.O. must avoid engaging in any activities that could be perceived as misleading, deceptive, or unfair during their promotions.</span>
          <span style={{ display: 'inline-block' }}>29. The P.R.O. must comply with all applicable trade and export control laws and regulations when promoting MedITravelS services.</span>
          <span style={{ display: 'inline-block' }}>30. MITS Hospitality LLP may amend these terms and conditions from time to time. The P.R.O. will be notified of any such changes. Their continued promotion of MedITravelS services will constitute acceptance of any revised terms and conditions.</span>
          <span style={{ display: 'inline-block' }}>31. The P.R.O. must understand and clarify to clients that MedITravelS is not a direct service provider but a platform where service providers and clients meet.</span>
          {/* </div> */}
        </Modal.Body>
        <Modal.Footer>
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
          <Button
              className={`agree-btn ${isModalScrolled1 ? 'scrolled' : ''} ${isAgreeEnabled1 ? '' : 'disabled-btn'}`}
              onClick={isAgreeEnabled1 ? handleAgreeClick : undefined}
              style={{
                backgroundColor: isModalScrolled1 ? '#291211' : '#f4a460',
                pointerEvents: isModalScrolled1 && isAgreeEnabled1 ? 'auto' : 'none',
                filter: isModalScrolled1 ? 'none' : 'grayscale(100%)',
              }}
            >
              Agree
            </Button>
            <Button className="btn btn-yellow" variant="secondary" onClick={() => setShowModal1(false)} label='Close'>
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal2} onHide={handleModalClose2} className="newsletter-popup view-popup" backdrop="static">
        <Modal.Header className="modal-close-button" style={{ width: '100%' }}>
          <Modal.Title >Privacy Policy for MedITravelS</Modal.Title>

        </Modal.Header>
        <Modal.Body
          ref={bodyRef2}
          style={{
            maxHeight: '400px', // Adjust this value as needed
            overflowY: 'scroll',
          }}
          onScroll={(e) => {
            const target = e.target;
            const scrollHeight = target.scrollHeight;
            const clientHeight = target.clientHeight;
            const scrollTop = target.scrollTop;

            // Calculate a threshold for determining if the modal is scrolled to the end
            const scrollThreshold = 5; // Adjust this value as needed

            const isAtEnd = scrollHeight - scrollTop - clientHeight <= scrollThreshold;

            setIsModalScrolled2(isAtEnd);

            if (isAtEnd) {
              setIsAgreeEnabled2(true);
            } else {
              setIsAgreeEnabled2(false);
            }
          }}
        >
          {/* Add your privacy policy text here */}
          <span style={{ display: 'inline-block' }}>At MedITravelS, we prioritize the privacy of our visitors and are committed to protecting their personal information. This Privacy Policy explains how we collect, use, and safeguard the information you provide when using our website.</span>
          <span style={{ display: 'inline-block' }}>If you have any questions or concerns about this Privacy Policy, please feel free to contact us.</span>
          <span style={{ display: 'inline-block' }}>Information We Collect</span>
          <span style={{ display: 'inline-block' }}>When you visit our website or interact with our services, we may collect the following types of personal information:</span>
          <span style={{ display: 'inline-block' }}>1. Personal Identifiable Information: This may include your full name, email address, postal address, telephone number, date of birth, and other contact details. We collect this information when you register for an account, subscribe to our newsletters, submit inquiries or feedback, or engage in transactions on our website.</span>
          <span style={{ display: 'inline-block' }}>2. Financial Information: If you engage in financial transactions, such as making payments for services or products, we may collect financial information, including credit card details, bank account information, or other payment-related information. Please note that we utilize secure payment processors to handle these transactions, and we do not store your financial information on our servers.</span>
          <span style={{ display: 'inline-block' }}>3. Medical Information: In order to provide personalized medical services or assistance, we may collect relevant medical information such as your medical history, current medical conditions, prescription details, and other health-related data. This information is collected with your explicit consent and is treated with the utmost confidentiality.</span>
          <span style={{ display: 'inline-block' }}>4. Usage Information: We may collect information about how you interact with our website and services, including your browsing activities, pages viewed, links clicked, and other actions taken on our site. This data is collected using cookies, log files, and similar technologies to analyze trends, improve our website functionality, and enhance your overall user experience. For more details on the use of cookies, please refer to the "Cookies and Web Beacons" section of this Privacy Policy.</span>
          <span style={{ display: 'inline-block' }}>5. Device and Technical Information: When you access our website, we may automatically collect certain technical information about your device, such as your IP address, browser type, operating system, device identifiers, and referring URLs. This information helps us diagnose technical issues, prevent fraudulent activities, and optimize our website performance.</span>
          <span style={{ display: 'inline-block' }}>6. Geolocation Information: With your explicit consent, we may collect and process information about your approximate location based on your IP address or other Geolocation technologies. This information may be used to provide location-specific services or tailor content to your region.</span>
          <span style={{ display: 'inline-block' }}>7. Log Files: Like many other websites, we gather certain information automatically and store it in log files. This information may include your IP address, browser type, Internet Service Provider (ISP), referring/exit pages, date and time stamps, and other browsing-related data. We use this information to analyze trends, administer the site, track user movement, and gather demographic information.</span>
          <span style={{ display: 'inline-block' }}>8. Cookies and Web Beacons: We use cookies and web beacons to enhance your browsing experience. Cookies are small files stored on your device that allow us to recognize your preferences and provide customized content. Web beacons are small graphic images embedded in web pages or emails that help us analyze user behaviour and improve our services. You can manage your cookie preferences through your browser settings.</span>
          <span style={{ display: 'inline-block' }}>Please note that the types of information collected may vary depending on the nature of your interaction with our website and the services you utilize. We only collect personal information that is necessary for the purposes outlined in this Privacy Policy, and we strive to minimize the data collected to what is essential for providing our services effectively.</span>
          <span style={{ display: 'inline-block' }}>If you have any concerns about the specific information being collected or would like further clarification, please contact us using the information provided in the "Contact Us" section of this Privacy Policy.</span>
          <span style={{ display: 'inline-block' }}>How We Use Your Information</span>
          <span style={{ display: 'inline-block' }}>We use the information we collect for various purposes, including:</span>
          <span style={{ display: 'inline-block' }}>1. Providing and improving our Services: We use your information to operate, maintain, and enhance our website, ensuring a smooth user experience and optimizing our services.</span>
          <span style={{ display: 'inline-block' }}>2. Communication: We may contact you directly or through our partners for customer service, to provide updates and information related to our services, and for marketing and promotional purposes. You have the option to unsubscribe from these communications at any time.</span>
          <span style={{ display: 'inline-block' }}>3. Personalization: We may use the information we collect to personalize your experience on our website, such as tailoring content and recommendations based on your preferences.</span>
          <span style={{ display: 'inline-block' }}>4. Fraud Prevention: We may use the information to detect and prevent fraudulent activities, ensuring the security and integrity of our services.</span>
          <span style={{ display: 'inline-block' }}>Data Sharing and Third Parties</span>
          <span style={{ display: 'inline-block' }}>We respect your privacy and do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except in the following circumstances:</span>
          <span style={{ display: 'inline-block' }}>1. Service Providers: We may engage trusted third-party service providers to assist us in operating our website and providing services. These service providers are bound by confidentiality agreements and are prohibited from using your personal information for any other purpose.</span>
          <span style={{ display: 'inline-block' }}>2. Legal Requirements: We may disclose your personal information if required by law, regulation, legal process, or governmental request, or in cases where we believe it is necessary to protect our rights, safety, or the rights of others.</span>
          <span style={{ display: 'inline-block' }}>Data Security</span>
          <span style={{ display: 'inline-block' }}>We take appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</span>
          <span style={{ display: 'inline-block' }}>Advertising Partners Privacy Policies</span>
          <span style={{ display: 'inline-block' }}>You may consult this list to find the Privacy Policy for each of the advertising partners of www. meditravels.in.
            Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on www. meditravels.in, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
            Note that www.meditravels.in has no access to or control over these cookies that are used by third-party advertisers</span>
          <span style={{ display: 'inline-block' }}>GDPR Data Protection Rights</span>
          <span style={{ display: 'inline-block' }}>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
            The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
            The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
            The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
            The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
            The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
            The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
            If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</span>
          <span style={{ display: 'inline-block' }}>Children's Privacy</span>
          <span style={{ display: 'inline-block' }}>Protecting the privacy of children is important to us. Our services are not directed at individuals under the age of 18, and we do not knowingly collect personal information from children. If you believe that your child has provided us with personal information, please contact us immediately, and we will take steps to remove that information from our records.</span>
          <span style={{ display: 'inline-block' }}>Changes to the Privacy Policy</span>
          <span style={{ display: 'inline-block' }}>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date will be revised accordingly. We encourage you to review this Privacy Policy periodically for any updates.</span>

          {/* </div> */}
        </Modal.Body>
        <Modal.Footer>
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
          <Button
              className={`agree-btn ${isModalScrolled2 ? 'scrolled' : ''} ${isAgreeEnabled2 ? '' : 'disabled-btn'}`}
              onClick={isAgreeEnabled2 ? handleAgreeClick2 : undefined}
              style={{
                backgroundColor: isModalScrolled2 ? '#291211' : '#f4a460',
                pointerEvents: isModalScrolled2 && isAgreeEnabled2 ? 'auto' : 'none',
                filter: isModalScrolled2 ? 'none' : 'grayscale(100%)',
              }}
            >
              Agree
            </Button>

            <Button className="btn btn-yellow" variant="secondary" onClick={() => setShowModal2(false)} label='Close'>
              Close
            </Button>

          </div>
        </Modal.Footer>
      </Modal>
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  )
}

export default TermsandConditions;