import React, { useEffect } from 'react';
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import Page from '../../PRO/pro-components/Page';

const PrivacyPolicy = () => {
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page on component mount
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0); // Scroll to the top of the page on route change
        });
        return () => unlisten();
    }, [history]);

    useEffect(() => {
        const minscript = document.createElement("script");
        minscript.async = true;
        document.body.appendChild(minscript);
    }, []);

    return (
        <Page>
            <h4>Privacy Policy for MedITravelS</h4>
            <div style={{ padding: '30px', textAlign: 'justify' }}>
                <p>
                    At MedITravelS, we prioritize the privacy of our visitors and are committed to protecting their personal information. This Privacy Policy explains how we collect, use, and safeguard the information you provide when using our website.

                    If you have any questions or concerns about this Privacy Policy, please feel free to contact us.
                    Information We Collect
                    When you visit our website or interact with our services, we may collect the following types of personal information:

                    1. Personal Identifiable Information: This may include your full name, email address, postal address, telephone number, date of birth, and other contact details. We collect this information when you register for an account, subscribe to our newsletters, submit inquiries or feedback, or engage in transactions on our website.

                    2. Financial Information: If you engage in financial transactions, such as making payments for services or products, we may collect financial information, including credit card details, bank account information, or other payment-related information. Please note that we utilize secure payment processors to handle these transactions, and we do not store your financial information on our servers.

                    3. Medical Information: In order to provide personalized medical services or assistance, we may collect relevant medical information such as your medical history, current medical conditions, prescription details, and other health-related data. This information is collected with your explicit consent and is treated with the utmost confidentiality.

                    4. Usage Information: We may collect information about how you interact with our website and services, including your browsing activities, pages viewed, links clicked, and other actions taken on our site. This data is collected using cookies, log files, and similar technologies to analyze trends, improve our website functionality, and enhance your overall user experience. For more details on the use of cookies, please refer to the "Cookies and Web Beacons" section of this Privacy Policy.

                    5. Device and Technical Information: When you access our website, we may automatically collect certain technical information about your device, such as your IP address, browser type, operating system, device identifiers, and referring URLs. This information helps us diagnose technical issues, prevent fraudulent activities, and optimize our website performance.

                    6. Geolocation Information: With your explicit consent, we may collect and process information about your approximate location based on your IP address or other Geolocation technologies. This information may be used to provide location-specific services or tailor content to your region.

                    7. Log Files: Like many other websites, we gather certain information automatically and store it in log files. This information may include your IP address, browser type, Internet Service Provider (ISP), referring/exit pages, date and time stamps, and other browsing-related data. We use this information to analyze trends, administer the site, track user movement, and gather demographic information.

                    8. Cookies and Web Beacons: We use cookies and web beacons to enhance your browsing experience. Cookies are small files stored on your device that allow us to recognize your preferences and provide customized content. Web beacons are small graphic images embedded in web pages or emails that help us analyze user behaviour and improve our services. You can manage your cookie preferences through your browser settings.

                    Please note that the types of information collected may vary depending on the nature of your interaction with our website and the services you utilize. We only collect personal information that is necessary for the purposes outlined in this Privacy Policy, and we strive to minimize the data collected to what is essential for providing our services effectively.

                    If you have any concerns about the specific information being collected or would like further clarification, please contact us using the information provided in the "Contact Us" section of this Privacy Policy.

                    How We Use Your Information

                    We use the information we collect for various purposes, including:

                    1. Providing and improving our Services: We use your information to operate, maintain, and enhance our website, ensuring a smooth user experience and optimizing our services.

                    2. Communication: We may contact you directly or through our partners for customer service, to provide updates and information related to our services, and for marketing and promotional purposes. You have the option to unsubscribe from these communications at any time.

                    3. Personalization: We may use the information we collect to personalize your experience on our website, such as tailoring content and recommendations based on your preferences.

                    4. Fraud Prevention: We may use the information to detect and prevent fraudulent activities, ensuring the security and integrity of our services.

                    Data Sharing and Third Parties

                    We respect your privacy and do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except in the following circumstances:

                    1. Service Providers: We may engage trusted third-party service providers to assist us in operating our website and providing services. These service providers are bound by confidentiality agreements and are prohibited from using your personal information for any other purpose.

                    2. Legal Requirements: We may disclose your personal information if required by law, regulation, legal process, or governmental request, or in cases where we believe it is necessary to protect our rights, safety, or the rights of others.

                    Data Security

                    We take appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                    Advertising Partners Privacy Policies
                    You may consult this list to find the Privacy Policy for each of the advertising partners of www. meditravels.in.
                    Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on www. meditravels.in, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                    Note that www.meditravels.in has no access to or control over these cookies that are used by third-party advertisers
                    GDPR Data Protection Rights
                    We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                    The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
                    The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
                    The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
                    The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
                    The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
                    The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                    If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.

                    Children's Privacy

                    Protecting the privacy of children is important to us. Our services are not directed at individuals under the age of 18, and we do not knowingly collect personal information from children. If you believe that your child has provided us with personal information, please contact us immediately, and we will take steps to remove that information from our records.

                    Changes to the Privacy Policy

                    We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date will be revised accordingly. We encourage you to review this Privacy Policy periodically for any updates.

                    Privacy Policy | MedITravelS
                </p>
            </div>
        </Page>
    );
};

export default PrivacyPolicy;
