import React, { useState, useEffect } from 'react';
import Page from '../pro-components/Page';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import CustomTextField from '../pro-components/CTextField';
import Dropdown from '../pro-components/CDropDown';
import FileUpload from '../pro-components/CFileUpload';
import Action from '../pro-components/Action';
import CustomTextArea from '../pro-components/CTextArea';
import { useHistory } from 'react-router-dom';
import CustomButton from '../pro-components/CButton';
import Ticket from '../pro-components/ticket'
import CustomModal from "../../global-components/modal";
import { updatePro1, uploadMedia } from '../../../api';

const RelevantExprience = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [degreeInput, setDegreeInput] = useState('');
  const [degreeInputError, setDegreeInputError] = useState('');
  const [showdegreeInputError, setshowDegreeInputError] = useState(false);
  const [summary, setSummary] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [profexperienceError, setProfexperienceError] = useState('');
  const [showprofexperienceError, setshowProfexprienceError] = useState(false);
  const [summaryError, setSummaryError] = useState('');
  const [showsummaryError, setshowSummaryError] = useState(false);
  const [expicertificate, setExpicertificate] = useState('');
  const [showImageError, setShowImageError] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [sectionData, setSectionData] = useState('');
  const [expiInputError, setExpiInputError] = useState('');
  const [showExpiInputError, setshowExpiInputError] = useState(false);
  const [expiInput, setExpiInput] = useState('');
  let history = useHistory();
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const previousPageURL = '/pro/section3';
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const options = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  const updateSectionData = async (sectionData) => {
    const proId = localStorage.getItem("proId");
    console.log("pro id from local storage", proId);
    if (!proId) {
        console.log("Pro ID not found in local storage");
        return;
    }

    try {
        const existingSectionData = JSON.parse(localStorage.getItem("firstPageData"));

        const section_data = [
          {
            0: existingSectionData, // Include the first page data at index 0
          },
          {
            1: JSON.parse(localStorage.getItem('secondPageData')), // Include the second page data at index 1
          },
          {
            2: JSON.parse(localStorage.getItem('thirdPageData')), // Include the third page data at index 2
          },
          {
            3: sectionData, // Include the fourth page data at index 3
          },
        ]

        console.log("updated data of pro");
        console.log(section_data);
        const updatedSectionData = await updatePro1(proId, section_data);
        if (updatedSectionData) {
            console.log("updated data of pro");
            console.log(updatedSectionData);
        } else {
            console.error("No data returned from updateDoctor1.");
        }
    } catch (error) {
        console.error(error);
    }
};

  const handleNextClick = async (event) => {
    event.preventDefault();

    let hasError = false;

    if (!validateDegreeInput(degreeInput)) {
      showErrorModal("Alert", "Highest Level of Education Completed is required.");
      setshowDegreeInputError(true);
      hasError = true;
    }

    if (expiInput.trim() !== '') {
      // Validation for experience and summary when total years of experience are entered
      if (!validateMasterDegreeInput(expiInput)) {
        setshowExpiInputError(true);
        hasError = true;
      } else {
        // If master degree input is entered, require experience certificate and summary
        if (!expicertificate) {
          setShowImageError(true);
          hasError = true;
        }
        if (!validateSummary(summary)) {
          setshowSummaryError(true);
          hasError = true;
        }
      }
    }

    if (hasError) {
      return;
    }

    // Store all the data in local storage
    const fourthPageData = {
      degreeInput: degreeInput,
      selectedOption: selectedOption,
      expiInput: expiInput,
      expicertificate: expicertificate,
      summary: summary,
    };
    localStorage.setItem("fourthPageData", JSON.stringify(fourthPageData));

    // Update the section data in the backend
    await updateSectionData(fourthPageData);

    history.push("/pro/section5");
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("fourthPageData"));

    if (storedData) {
      setDegreeInput(storedData.degreeInput || "");
      setSelectedOption(storedData.selectedOption || "");
      setExpiInput(storedData.expiInput || '');
      setSummary(storedData.summary || "");
      setExpicertificate(storedData.expicertificate || "");
      setPreviewUrl(storedData.previewUrl || null);
      setSelectedFile(storedData.selectedFile || null);
      setImageUrl(storedData.imageUrl || "");
    }
  }, []);

  useEffect(() => {
    const dataToStore = {
      degreeInput,
      selectedOption,
      summary,
      expiInput,
      expicertificate,
      previewUrl,
      selectedFile,
      imageUrl,
    };

    localStorage.setItem("fourthPageData", JSON.stringify(dataToStore));
  }, [selectedOption, summary, expicertificate, previewUrl, selectedFile, imageUrl, expiInput]);

  const validateDegreeInput = (value) => {
    setDegreeInput(value);

    let error = '';

    if (!value) {
      error = 'Highest Level of Education Completed is required';
    } else if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }
    setDegreeInputError(error);

    return !error;
  };

  const handleDegreeChange = (event) => {
    const degreeInput = event.target.value;
    setDegreeInput(degreeInput);
    validateDegreeInput(degreeInput)
    setDegreeInputError(validateDegreeInput(degreeInput) ? '' : 'Highest Level of Education Completed is required');

    setSectionData((prevData) => ({
      ...prevData,
      degreeinput: degreeInput,
    }));
  };

  const handleDegreeBlur = () => {
    setshowDegreeInputError(true);
  };

  const validateProfexperienceLanguage = (value) => {
    setSelectedOption(value);

    let error = '';

    if (!value) {
      error = 'Professional experience is required';
    } else if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }

    setProfexperienceError(error);

    return !error; // Return true if there is no error
  };

  const handleProfexprienceChange = (value) => {
    setSelectedOption(value);
    validateProfexperienceLanguage(value)
    const isValid = validateProfexperienceLanguage(value);
    setProfexperienceError(isValid ? '' : 'Professional experience is required');

    setSectionData((prevData) => ({
      ...prevData,
      selectedOption: value,
    }));
  };

  const handleProfexprienceBlur = () => {
    setshowProfexprienceError(true);
  };

  const validateSummary = (value) => {
    setSummary(value);

    let error = '';

    if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }
    setSummaryError(error);

    return !error; // Return true if there is no error
  };

  const handleSummaryChange = (event) => {
    const summaryValue = event.target.value;
    setSummary(summaryValue);
    validateSummary(summaryValue)
    setSummaryError(validateSummary(summaryValue) ? '' : 'Summary of relevant work experience is required');
    if (expiInput.trim() !== '') {
      validateSummary(summaryValue);
    } else {
      setSummaryError('');
      setshowSummaryError(false);
    }

    setSectionData((prevData) => ({
      ...prevData,
      summary: summaryValue,
    }));
  };

  const handleSummaryBlur = () => {
    setshowSummaryError(true);
  };

  const validateMasterDegreeInput = (value) => {
    setExpiInput(value);
    let error = '';
    if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }
    setExpiInputError(error);

    return !error; // Return true if there is no error
  };

  const handleMasterDegreeChange = (event) => {
    const expiInput = event.target.value;
    setExpiInput(expiInput);

    if (expiInput.trim() !== '') {
      validateMasterDegreeInput(expiInput);
      validateSummary(summary); // Validate summary when expiInput is entered
    } else {
      setExpiInputError('');
      setshowExpiInputError(false);
      setSummaryError('');
      setshowSummaryError(false);
    }
  };

  const handleMasterDegreeBlur = () => {
    setshowExpiInputError(true);
  };

  const handleFileUpload = async (file) => {
    setSelectedFile(file);
    setPreviewUrl(URL.createObjectURL(file));

    try {
      const mediaType = "PRO";

      const uploadedImagePath = await uploadMedia(file, mediaType);

      setExpicertificate(uploadedImagePath);
      setImageUrl(uploadedImagePath); // Set the image URL in the state
      console.log('image url from pro', uploadedImagePath);

      // Save the image URL in the database
      await saveImageUrlToDatabase(uploadedImagePath); // Make sure to implement this function
    } catch (error) {
      console.error(error);
    }
  };

  const saveImageUrlToDatabase = async (imageUrl) => {
    const proId = localStorage.getItem('proId');
    const sectionData = { expicertificate: imageUrl };
  
    try {
      const response = await updatePro1(proId, sectionData);  

      console.log('Image URL saved in the database:', response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearForm = () => {
    setDegreeInput('');
    setSelectedOption('');
    setSelectedFile('');
    setExpiInput('');
    setExpiInputError('');
    setshowExpiInputError('');
    setSummary('');
    setProfexperienceError('');
    setSummaryError('');
    setshowProfexprienceError('');
    setshowSummaryError('');
  };

  const handleCloseErrorModal = () => {

    // Hide the error modal
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <Page>
      <FirstSection>
        <h4>Education & Relevant Experience</h4>
      </FirstSection>
      <SectionComponent>
        <label className="sec-label">HIGHEST LEVEL OF EDUCATION COMPLETED*</label>
        <CustomTextField
          id="outlined-basic"
          placeholder="Highest Level of Education Completed"
          variant="outlined"
          value={degreeInput}
          onChange={handleDegreeChange}
          onBlur={handleDegreeBlur}
        />
        <span className="error-msg" >
          {showdegreeInputError && degreeInputError && (
            <p className="error-msg">{degreeInputError}</p>
          )} </span>
      </SectionComponent>
      <SectionComponent>
        <label className="sec-label">TOTAL YEARS OF EXPERIENCE IN THE FIELD</label>
        <br />
        <CustomTextField
          id="outlined-basic"
          placeholder="Total Years Of Experience Relative Field"
          variant="outlined"
          value={expiInput}
          onChange={handleMasterDegreeChange}
          onBlur={handleMasterDegreeBlur}
        />
        <span className="error-msg">
          {showExpiInputError && expiInputError && (
            <p className="error-msg">{expiInputError}</p>
          )}
        </span>
        {showprofexperienceError && profexperienceError && (
          <p className="error-msg">{profexperienceError}</p>
        )}
        <br></br>
        <label className="sec-label">Add Experience Certificate</label>
        <br></br>
        <FileUpload onChange={handleFileUpload} value={expicertificate} />
        {previewUrl && (
          <>
            <div style={{ paddingRight: '10px', borderRadius: '50%' }} className='close-btn'>
              <CustomButton type="button" onClick={() => {
                setSelectedFile(null);
                setPreviewUrl(null);
              }}>
                X
              </CustomButton>
            </div>
            <img src={previewUrl} alt="Selected" style={{ width: '70%' }} />
          </>)}
        {showImageError && !selectedFile && (
          <p className="error-msg">{showImageError}</p>
        )}
      </SectionComponent>
      <SectionComponent>
        <label className="sec-label">BRIEF SUMMARY OF RELEVANT WORK EXPERIENCE</label>
        <CustomTextArea
          id="outlined-basic"
          variant="outlined"
          onChange={handleSummaryChange}
          onBlur={handleSummaryBlur}
          placeholder="Brief summary of relevant work experience"
          rows={4}
          rowsMax={8}
          value={summary}
        />
        {showsummaryError && summaryError && (
          <p className="error-msg">{summaryError}</p>
        )}
      </SectionComponent>
      <Action backLink={previousPageURL} onClickNext={handleNextClick} onClearForm={handleClearForm} />
      <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
        <Ticket variant="contained" color="secondary" section="PRO: Application Form - Education & Relevant Experience" />
      </div>
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
};

export default RelevantExprience;