import React, { useState, useEffect, useRef } from 'react';
import Page from '../pro-components/Page';
import CustomTextField from '../pro-components/CTextField';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import RadioButtonGroup from '../pro-components/CRadioButton';
import Dropdown from '../pro-components/CDropDown';
import Action from '../pro-components/Action';
import CustomButton from '../pro-components/CButton';
import countries from '../../../countries.json';
import { useHistory } from "react-router-dom";
import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { set } from 'date-fns';
import Ticket from '../pro-components/ticket';
import CustomModal from "../../global-components/modal";
import { updateDoctor1 } from '../../../api';

const ContactInformation = () => {
    const userDataJSON = localStorage.getItem("user");
    const userData = userDataJSON ? JSON.parse(userDataJSON) : {};
    let loginEmail = userData.email;
    const [email, setEmail] = useState(loginEmail || '');
    const [emailError, setEmailError] = useState(false);
    const [showEmailError, setshowEmailError] = useState(false);
    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);
    const [showaddressError, setshowAddressError] = useState(false);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const is_whatsappRef = useRef(null);
    const otherphoneRef = useRef(null);
    const addressRef = useRef(null);
    const stateRef = useRef(null);
    const cityRef = useRef(null);
    const zipcodeRef = useRef(null);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [showPhoneError, setshowPhoneError] = useState(false);
    const [is_whatsapp, setIs_whatsapp] = useState('');
    const [is_whatsappError, setIs_whatsappError] = useState(false);
    const [showis_whatsappError, setshowIs_whatsappError] = useState(false);
    const [otherphone, setOtherphone] = useState('');
    const [otherphoneError, setOtherphoneError] = useState(false);
    // const [country, setCountry] = useState('IN');
    const [country, setCountry] = useState(countries.find(country => country.value === 'IN'));
    const [countryError, setCountryError] = useState(false);
    const [showcountryError, setshowCountryError] = useState(false);
    const [state, setState] = useState('');
    const [stateError, setStateError] = useState(false);
    const [showstateError, setshowStateError] = useState(false);
    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState(false);
    const [showcityError, setshowCityError] = useState(false);
    const [zipcode, setZipcode] = useState('');
    const [zipcodeError, setZipcodeError] = useState(false);
    const [showzipcodeError, setshowZipcodeError] = useState(false);
    const [sectionData, setSectionData] = useState("");
    const indiaCountry = { name: 'India', dialCode: '91', countryCode: 'IN' };
    const minscript = document.createElement("script");
    const [scrollToTop, setScrollToTop] = useState(false);
    const [shouldScrollToTop, setShouldScrollToTop] = useState(false);

    const [errorModalData, setErrorModalData] = useState({
        title: "",
        message: "",
        show: false,
    });

    const showErrorModal = (title, message) => {
        setErrorModalData({
            title: title,
            message: message,
            show: true,
        });
    };
    const filterCountries = (value, country) => {
        return country.countryCode === 'IN';
    };
    minscript.async = true;
    document.body.appendChild(minscript);
    const defaultCountry = 'IN';
    let history = useHistory();
    const firstErrorFieldRef = useRef(null);

    const previousPageURL = '/doc/section1';

    useEffect(() => {
        if (scrollToTop) {
            window.scrollTo(0, 0);
            setScrollToTop(false);
        }
    }, [scrollToTop]);

    useEffect(() => {
        if (shouldScrollToTop) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setShouldScrollToTop(false);
        }
    }, [shouldScrollToTop]);

    useEffect(() => {
        const storedSectionData = localStorage.getItem('docsecondPageData');
        if (storedSectionData) {
            const parsedSectionData = JSON.parse(storedSectionData);
            setSectionData(parsedSectionData);
            setPhone(parsedSectionData.phone);
            setIs_whatsapp(parsedSectionData.same_wp);
            setOtherphone(parsedSectionData.otherphone);
            setAddress(parsedSectionData.address || '');
            setCountry(parsedSectionData.country);
            setState(parsedSectionData.state);
            setCity(parsedSectionData.city);
            setZipcode(parsedSectionData.zipcode);
        }
        const parsedSectionData = JSON.parse(storedSectionData);
        // Set the email state to the stored email or the loginEmail value
        const storedEmail = storedSectionData ? parsedSectionData.email : loginEmail;
        setEmail(storedEmail);
    }, [loginEmail]);

    const updateSectionData = async (sectionData) => {
        const docId = localStorage.getItem("docId");
        if (!docId) {
            console.log("doc ID not found in local storage");
            return;
        }
        try {
            const existingSectionData = JSON.parse(localStorage.getItem("docfirstPageData"));
            const sectionDataObject = [
                {
                    0: existingSectionData,
                },
                {
                    1: sectionData,
                }
            ]
            const updatedSectionData = await updateDoctor1(docId, sectionDataObject);
        } catch (error) {
            console.error(error);
        }
    };

    const scrollToFirstErrorField = () => {
        // Check if any error is present, and if so, set the ref for the first error field
        const errorFields = [
            { ref: emailRef, error: !email },
            // { ref: phoneRef, error: !phone || phone.length !== 12 },
            // { ref: is_whatsappRef, error: !is_whatsapp },
            // { ref: otherphoneRef, error: is_whatsapp === 'no' && !otherphone },
            { ref: addressRef, error: !address },
            { ref: stateRef, error: !state },
            { ref: cityRef, error: !city },
            { ref: zipcodeRef, error: !zipcode },
            // Add other fields with errors here if needed
        ];

        let firstErrorField = null;

        for (const field of errorFields) {
            if (field.error && field.ref.current) {
                firstErrorField = firstErrorField || field.ref.current;
            }
        }

        // If a field with an error is found, focus on it and set a flag to scroll to the top on next render
        if (firstErrorField) {
            firstErrorField.focus();
            setScrollToTop(true);
        }
    };

    const handleNextClick = async (event) => {
        event.preventDefault();

        setEmailError(false);
        setPhoneError(false);
        setIs_whatsappError(false);
        setAddressError(false);
        setCountryError(false);
        setStateError(false);
        setCityError(false);
        setZipcodeError(false);
        setOtherphoneError(false);
        if (!email) {
            setEmailError(true);
            setshowEmailError(true);
            emailRef.current.focus();
            showErrorModal("Alert", "Email ID is required.");
            return;
        }

        if (!phone) {
            setPhoneError(true);
            setshowPhoneError(true);
            showErrorModal("Alert", "Phone is required.");
            return;
        } else if (phone.length < 12) {
            setPhoneError(true);
            setshowPhoneError(true);
            showErrorModal("Alert", "Phone number should be exactly 10 digits.");
            return;
        }

        if (!is_whatsapp) {
            setIs_whatsappError(true);
            setshowIs_whatsappError(true);
            showErrorModal("Alert", "Same Whatsapp or not is required.");
            return;
        }
        if (is_whatsapp === 'no') {
            if (!otherphone) {
                setOtherphoneError(true);
                showErrorModal("Alert", "WhatsApp number is required.");
                return;
            } else if (otherphone.length < 12) {
                setOtherphoneError(true);
                showErrorModal("Alert", "WhatsApp number should be exactly 10 digits.");
                return;
            }
        }

        if (!address) {
            setAddressError(true);
            setshowAddressError(true);
            showErrorModal("Alert", " Address is required.");
            return;
        }

        // if (!country) {
        //     setCountryError(true);
        //     setshowCountryError(true);
        //     alert('Country is required');
        //     return;
        // }

        if (!city) {
            setCityError(true);
            setshowCityError(true);
            showErrorModal("Alert", "City is required.");
            return;
        }

        if (!zipcode) {
            setZipcodeError(true);
            setshowZipcodeError(true);
            showErrorModal("Alert", "Zipcode is required.");
            return;
        }

        if (!state) {
            setStateError(true);
            setshowStateError(true);
            showErrorModal("Alert", "State is required");
            return;
        }

        // Store all the data in local storage
        const docsecondPageData = {
            email: email,
            phone: phone,
            address: address,
            state: state,
            city: city,
            zipcode: zipcode,
            same_wp: is_whatsapp,
            otherphone: otherphone,
            country: country,
        };

        localStorage.setItem('docsecondPageData', JSON.stringify(docsecondPageData));
        await updateSectionData(docsecondPageData);

        scrollToFirstErrorField();

        // If there's no error, proceed to the next page
        if (!emailError && !addressError && !stateError && !cityError && !zipcodeError) {
            setShouldScrollToTop(true);
            history.push('/doc/section3');
        }
        history.push('/doc/section3');
    };

    const validateEmail = (email) => {
        let isValid = true;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

        // Test the email against the regex pattern
        isValid = emailRegex.test(email);

        // Additional validation for dot after the @ symbol
        if (isValid) {
            const atIndex = email.indexOf('@');
            const dotIndex = email.indexOf('.', atIndex);
            isValid = dotIndex !== -1 && dotIndex > atIndex + 1;
        }

        return isValid;
    };

    const handleEmailBlur = () => {
        setshowEmailError(true);
    };

    const validateAddress = (value) => {
        if (!value) {
            return 'Address is required';
        } else if (/\s{2,}/.test(value)) {
            return 'Multiple consecutive spaces are not allowed';
        }
        return '';
    };

    const handleAddressBlur = () => {
        const error = validateAddress(address);
        setAddressError(error);
    };

    const handlePhoneChange = (value) => {
        setPhone(value);
        setPhoneError(false);
    };

    const handleOtherPhoneChange = (value) => {
        setOtherphone(value);
        setOtherphoneError(false);
        // setPhoneError(!validatePhone(value));
        console.log("phone number", value, phone)
    };

    const handleEmailChange = (event) => {
        const emailValue = event.target.value;
        setEmail(emailValue);
        setEmailError(!validateEmail(emailValue));
        setSectionData((prevData) => ({
            ...prevData,
            email: emailValue,
        }));
    };

    const handleAddressChange = (event) => {
        const addressValue = event.target.value;
        setAddress(addressValue);
        validateAddress(addressValue);
        setSectionData((prevData) => ({
            ...prevData,
            address: addressValue,
        }));
        setAddressError(false);
        setshowAddressError(false);
    };

    const validateisWhatsapp = (options) => {
        if (!options) {
            setIs_whatsappError("Phone number is Whatsapp or not is required");
        } else {
            setIs_whatsappError("");
        }
    };

    const handleIsWhatsapp = (options) => {
        setIs_whatsapp(options);
        setIs_whatsappError(!validateisWhatsapp(options))
        setSectionData((prevData) => ({
            ...prevData,
            same_wp: options,
        }));
    };

    const handleIsWhatsappBlur = () => {
        setshowIs_whatsappError(true);
    };

    const validateCountry = (event) => {
        const country = event.target.value;
        if (!country) {
            setCountryError("Country is required");
        } else {
            setCountryError("");
        }
    };
    const validateState = (value) => {
        let error = '';
        if (!value) {
            error = 'State is required';
        } else if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        }
        setStateError(error);

    };

    const handleStateChange = (event) => {
        const stateValue = event.target.value;
        setState(stateValue);
        validateState(stateValue);
        setSectionData((prevData) => ({
            ...prevData,
            state: stateValue,
        }));
    };

    const handleStateBlur = () => {
        setshowStateError(true);
    };

    const validateCity = (value) => {
        let error = '';
        if (!value) {
            error = 'City is required';
        } else if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        }
        setCityError(error);
    };

    const handleCityChange = (event) => {
        const cityValue = event.target.value;
        setCity(cityValue);
        validateCity(cityValue);
        setSectionData((prevData) => ({
            ...prevData,
            city: cityValue,
        }));
    };

    const handleCityBlur = () => {
        setshowCityError(true);
    };

    const validateZipcode = (value) => {
        let error = '';
        if (!value) {
            error = 'Zipcode is required';
        } else if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        }
        setZipcodeError(error);
    };

    const handleZipcodeChange = (event) => {
        const zipcodeValue = event.target.value;
        setZipcode(zipcodeValue);
        validateZipcode(zipcodeValue);
        setSectionData((prevData) => ({
            ...prevData,
            zipcode: zipcodeValue,
        }));
    };

    const handleZipcodeBlur = () => {
        setshowZipcodeError(true);
    };

    const handleClearForm = () => {
        setIs_whatsapp('');

        setEmail('');
        setPhone('');
        setState('');
        setCity('');
        setZipcode('');
        setAddress('');
        setAddressError('');
        setshowAddressError('');
        setEmailError('');
        setshowEmailError('');
        setPhoneError('');
        setshowPhoneError('');
        setStateError('');
        setshowStateError('');
        setCityError('');
        setshowCityError('');
        setZipcodeError('');
        setshowZipcodeError('');
        setIs_whatsappError('')
        setshowIs_whatsappError('');
    };

    const WhatsApp = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
    ];

    // const platforms = [
    //     { value: 'Twitter', label: 'Twitter' },
    //     { value: 'Telegram', label: 'Telegram' },
    //     { value: 'Instagram', label: 'Instagram' },
    //     { value: 'Snapchat', label: 'Snapchat' },
    //     { value: 'Discord', label: 'Discord' },
    //     { value: 'Other', label: 'Other' },
    // ];

    const handleCloseErrorModal = () => {
        setErrorModalData({
            title: "",
            message: "",
            show: false,
        });
    };

    return (
        <Page>
            <FirstSection >
                <h4>Contact Information</h4>
            </FirstSection >
            <SectionComponent>
                <label className='sec-label'>EMAIL ADDRESS*</label>
                <CustomTextField id="outlined-basic" placeholder="Email Address" variant="outlined" value={email} onBlur={handleEmailBlur}
                    onChange={handleEmailChange} inputRef={emailRef} />
                {emailError && showEmailError && (<p className="error-msg">Please enter a valid email</p>)}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>PHONE NUMBER*</label>
                <PhoneInput
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={handlePhoneChange}
                    inputProps={{
                        required: true,
                        autoFocus: true,
                    }}
                    readOnly={true}
                    country="in"
                    specialLabel="India"
                    specialTabIndex={0}
                    disableAreaCodes
                    disableDropdown={true}
                    countryOptions={{ filterCountries }}
                    enableAreaCodes={false}
                    enableSearch={false}
                    ref={phoneRef}
                />
                {phoneError && <p className="error-msg">Please enter a valid phone number</p>}
            </SectionComponent>

            <SectionComponent>
                <label className='sec-label'> ARE YOU USING WHATSAPP ON THE SAME NUMBER?* </label>
                <div className='wp-radio'>
                    <RadioButtonGroup
                        name="radioOptions"
                        value={is_whatsapp}
                        options={WhatsApp}
                        onChange={handleIsWhatsapp}
                        onBlur={handleIsWhatsappBlur}
                        ref={is_whatsappRef}
                    >
                        {is_whatsappError && showis_whatsappError && (<p className="error-msg">Using Whastapp on same number?</p>)}
                    </RadioButtonGroup>
                </div>
                {is_whatsapp === 'no' && (
                    <div className='other-wpnumber'>
                        <label className='sec-label' style={{ marginTop: '20px' }}>ADD YOUR WHATSAPP NUMBER HERE</label>
                        <PhoneInput
                            placeholder="Enter phone number"
                            value={otherphone}
                            onChange={handleOtherPhoneChange}
                            inputProps={{
                                required: true,
                                autoFocus: true,
                            }}
                            country="in"
                            specialLabel="India"
                            specialTabIndex={0}
                            disableAreaCodes
                            disableDropdown={true}
                            countryOptions={{ filterCountries }}
                            enableAreaCodes={false}
                            enableSearch={false}
                            ref={otherphoneRef}
                        />
                        {otherphoneError && <p className="error-msg">Please enter a valid phone number</p>}
                    </div>
                )}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'> ADDRESS* </label>
                <CustomTextField id="outlined-basic" placeholder=" Address" variant="outlined" value={address} onBlur={handleAddressBlur}
                    onChange={handleAddressChange} inputRef={addressRef} />
                {addressError && showaddressError && <p className="error-msg">{addressError}</p>}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>CITY*</label>
                <CustomTextField id="outlined-basic" placeholder="City" variant="outlined" inputRef={cityRef} value={city} onChange={handleCityChange}
                // onBlur={handleCityBlur} 
                />
                {cityError && showcityError && (<p className="error-msg">Please enter City</p>)}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>POSTAL/ZIP CODE*</label>
                <CustomTextField id="outlined-basic" placeholder="Postal/Zip Code" variant="outlined" value={zipcode} inputRef={zipcodeRef} onChange={handleZipcodeChange}
                // onBlur={handleZipcodeBlur} 
                />
                {zipcodeError && showzipcodeError && (<p className="error-msg">Please enter zipcode</p>)}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>STATE/PROVINCE*</label>
                <CustomTextField id="outlined-basic" placeholder="State/Province" variant="outlined" value={state} inputRef={stateRef}
                    onChange={handleStateChange}
                // onBlur={handleStateBlur} 
                />
                {stateError && showstateError && (<p className="error-msg">Please enter State</p>)}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>COUNTRY*</label>
                <br />
                <CustomTextField
                    id="outlined-basic"
                    placeholder="Country"
                    variant="outlined"
                    value="India"
                // onChange={handleSelectCountry}
                // onBlur={handleStateBlur}
                />
                {/* <Dropdown
                    label={country ? '' : 'Select Country'}
                    value={country} // Set the value prop to the selected country
                    disabled={true}
                    options={countries.map((country) => ({
                        value: country.name,
                        label: country.name,
                    }))}
                    onChange={handleSelectCountry}
                    // onBlur={handleCountryBlur}
                // ref={countryRef}
                />  */}
                {/* {countryError && showcountryError && (<p className="error-msg">Please enter Country</p>)} */}
            </SectionComponent>
            <Action
                backLink={previousPageURL}
                onClickNext={handleNextClick}
                onClearForm={handleClearForm}
            />
            <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
                <Ticket variant="contained" color="secondary" section="Doctor: Application Form - Contact Information" />
            </div>
            <CustomModal
                show={errorModalData.show}
                onHide={handleCloseErrorModal}
                titleStyle={{ fontWeight: "bold" }}
                title={errorModalData.title}
                message={errorModalData.message}
            />
        </Page>
    );
};

export default ContactInformation;