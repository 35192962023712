import React, { useState, useEffect } from 'react';
import Page from '../pro-components/Page';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import CheckboxComponent from '../pro-components/CCheckBox';
import CustomTextField from '../pro-components/CTextField';
import TimePickerComponent from '../pro-components/CTimePicker';
import Action from '../pro-components/Action';
import CustomTextArea from '../pro-components/CTextArea';
import { useHistory } from "react-router-dom";
import Ticket from '../pro-components/ticket';
import CustomModal from "../../global-components/modal";
import { updatePro2 } from '../../../api';

const Availability = () => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const communicationMethod = ['Phone', 'E-mail', 'Chat', 'Metaverse', 'Other'];
  const [daysCheckboxes, setDaysCheckboxes] = useState(Array(7).fill(false));
  const [methodCheckboxes, setMethodCheckboxes] = useState(Array(communicationMethod.length).fill(false));
  const [otherText, setOtherText] = useState('');
  const [timeError, setTimeError] = useState('');
  const [showTimeError, setShowTimeError] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startTimeError, setStartTimeError] = useState('');
  const [endTimeError, setEndTimeError] = useState('');
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);

  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  let history = useHistory();
  const previousPageURL = '/pro/section2-2';
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const updateSectionData = async (sectionData) => {
    console.log("user id from local storage", userData.id);
    const userId = userData.id;
    console.log("-------userId from local storage", userId);

    if (!userId) {
      console.log("User ID not found in local storage");
      return;
    }

    try {
      const existingSectionData = JSON.parse(localStorage.getItem("firstPageData2"));
      const section_data = [
        {
          0: existingSectionData, // Include the first page data at index 0
        },
        {
          1: JSON.parse(localStorage.getItem("secondPageData2")), // Include the second page data at index 1
        },
        {
          2: {
            ...sectionData,
            selectedTime: selectedTime // Include the selectedTime in the third page data
          },
        }
      ];

      console.log("Updated data of pro");
      console.log(section_data);

      const updatedSectionData = await updatePro2(userId, section_data);

      if (updatedSectionData) {
        console.log("------Updated data of doc");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updatePro2.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDaysChange = (index, isChecked) => {
    const updatedCheckboxes = [...daysCheckboxes];
    updatedCheckboxes[index] = isChecked ? daysOfWeek[index] : '';
    setDaysCheckboxes(updatedCheckboxes);
  };

  const handleCommunicationMethodChange = (index, isChecked) => {
    const updatedCheckboxes = [...methodCheckboxes];
    updatedCheckboxes[index] = isChecked ? communicationMethod[index] : '';
    setMethodCheckboxes(updatedCheckboxes);
  };

  const handleOtherTextChange = (event) => {
    setOtherText(event.target.value);
  };

  const validateTime = (value) => {
    let error = '';

    if (value === '') {
      error = 'Please select a time.';
    }

    setTimeError(error);

    return !error; // Return true if there is no error
  };

  const handleTimeChange = (value) => {
    setSelectedTime(value);
    setTimeError(validateTime(value) ? '' : 'Please select a time.');
    console.log("selectedTime", value);
  };

  const handleTimeSelect = (time, timeType) => {
    if (timeType === 'start') {
      setStartTime(time);
      setStartTimeError('');
      validateTime(time, 'start');
    } else if (timeType === 'end') {
      setEndTime(time);
      setEndTimeError('');
      validateTime(time, 'end');
    }
  };

  const handleTimeBlur = () => {
    if (!endTime && !startTime) {
      setShowTimeError(true);
      setStartTimeError('Please select a Start Time.');
      setEndTimeError('Please select an End Time.');
    } else {
      setShowTimeError(false);
      validateTime(startTime, 'start');
      validateTime(endTime, 'end');
    }
  };

  const timeStringToHours = (timeString) => {
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    if (hours === 12) {
      hours = 0;
    }

    if (modifier === 'PM') {
      hours += 12;
    }

    return hours + minutes / 60;
  };

  useEffect(() => {
    if (startTime && endTime && startTime !== endTime) {
      const startHours = timeStringToHours(startTime);
      const endHours = timeStringToHours(endTime);

      // Calculate the total working hours only if start and end times are not equal
      let totalHours = endHours - startHours;

      if (totalHours < 0) {
        totalHours += 24; // Add 24 hours to handle overnight shifts
      }

      setTotalWorkingHours(totalHours);
    } else {
      setTotalWorkingHours(0); // Reset the total working hours to 0 if start and end times are the same or not set
    }
  }, [startTime, endTime]);

  useEffect(() => {
    if (!startTime || !endTime) {
      setTotalWorkingHours(0);
    }
  }, [startTime, endTime]);

  const handleNextClick = async (event) => {
    event.preventDefault();
    let hasError = false;

    if (!daysCheckboxes.some((isChecked) => isChecked)) {
      showErrorModal("Alert", "Please select at least one day of the week.");
      // setShowTimeError(true);
      hasError = true;
      return;
    }

    if (!startTime) {
      showErrorModal("Alert", "Please select a Start Time.");
      hasError = true;
      return;
    }

    if (!endTime) {
      showErrorModal("Alert", "Please select a End Time.");
      hasError = true;
      return;
    }

    if (!methodCheckboxes.some((isChecked) => isChecked)) {
      showErrorModal("Alert", "Please select at least one preferred method of communication.");
      // setShowTimeError(true);
      hasError = true;
      return;
    }

    if (methodCheckboxes[communicationMethod.length - 1] && !otherText.trim()) {
      showErrorModal("Alert", "Please write something in the Other mode of communication textarea.");
      hasError = true;
      return;
    }

    if (hasError) {
      return;
    }

    const ThirdPageData = {
      daysCheckboxes: daysCheckboxes, // Store the image URL
      methodCheckboxes: methodCheckboxes,
      otherText: otherText,
      // selectedTime: selectedTime,
      startTime: startTime,
      endTime: endTime,
      totalWorkingHours: totalWorkingHours,
    };
    localStorage.setItem('thirdPageData2', JSON.stringify(ThirdPageData));

    // Update the section data in the backend
    await updateSectionData(ThirdPageData);

    history.push("/pro/section4-2");
  };

  // Load data from localStorage when the component mounts
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('thirdPageData2'));

    if (storedData) {
      setDaysCheckboxes(storedData.daysCheckboxes || Array(7).fill(false));
      setMethodCheckboxes(storedData.methodCheckboxes || Array(communicationMethod.length).fill(false));
      setOtherText(storedData.otherText || '');
      // setSelectedTime(storedData.selectedTime || '');
      // setTimeError('');
      // setShowTimeError(false);
      setSelectedTime(storedData.selectedTime || '');
      setStartTime(storedData.startTime);
      setEndTime(storedData.endTime);
      setTotalWorkingHours(storedData.totalWorkingHours)
      setTimeError('');
      setShowTimeError(false);
    } else {
      // Use default start and end time if no stored data found
      setSelectedTime('');
    }
  }, []);

  const handleClearForm = () => {
    setDaysCheckboxes(Array(7).fill(false));
    setMethodCheckboxes(Array(communicationMethod.length).fill(false));
    setOtherText('');
    setSelectedTime('');
    setStartTime('');
    setEndTime('');
    setTotalWorkingHours('');

  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <Page>
      <FirstSection>
        <h4>Availability</h4>
      </FirstSection>
      <SectionComponent className='avaibility'>
        <label className="sec-label">DAYS OF THE WEEK AVAILABLE*</label>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {daysOfWeek.map((day, index) => (
              <div key={index}>
                <p className='days-week' style={{ textAlign: 'center', marginBottom: '5px' }}>{day}</p>
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#f8f9fa', borderRadius: '5px' }}>
            {daysOfWeek.map((day, index) => (
              <CheckboxComponent
                key={index}
                label=""
                checked={daysCheckboxes[index]}
                onChange={(isChecked) => handleDaysChange(index, isChecked)}
              />
            ))}
          </div>
        </div>
      </SectionComponent>
      <SectionComponent>
        <label className="sec-label">HOURS OF AVAILABILITY*</label>
        <br></br>
        <label className="sec-label">*Bound to timely changes in company's Terms & Conditions</label>
        <br></br>
        <div className='time-div'>

          <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '20px' }}>
            <div className='start-time'>

              <label className="sec-label">Start Time*</label>

              <br></br>
              <TimePickerComponent
                value={startTime}
                onChange={(value) => setStartTime(value)}
                error={startTimeError}
                onBlur={handleTimeBlur}
                onTimeSelect={(time) => handleTimeSelect(time, 'start')}
                identifier="start"
              />
            </div>
            <div>
              <label className="sec-label">End Time*</label>
              <br></br>
              <TimePickerComponent
                value={endTime}
                onChange={(value) => setEndTime(value)}
                error={endTimeError}
                onBlur={handleTimeBlur}
                onTimeSelect={(time) => handleTimeSelect(time, 'end')}
                identifier="end" 
              />
            </div>
          </div>
          <label className="sec-label">Total working hours: </label>
          {typeof totalWorkingHours === 'number' ? (
            <label className="sec-label" style={{ paddingLeft: '10px' }}>{totalWorkingHours.toFixed(2)}</label>
          ) : (
            <label className="sec-label" style={{ paddingLeft: '10px' }}>0.00</label>
          )}
          {startTimeError && showTimeError && <p className="error-msg">{startTimeError}</p>}
          {endTimeError && showTimeError && <p className="error-msg">{endTimeError}</p>}
        </div>
      </SectionComponent>
      <SectionComponent>

        <label className="sec-label">PREFERRED METHOD OF COMMUNICATION WITH PATIENTS*</label>

        <div className='communication-methods' style={{ display: 'flex', justifyContent: 'space-around' }}>
          {communicationMethod.map((method, index) => (
            <CheckboxComponent
              key={index}
              label={method}
              checked={methodCheckboxes[index]}
              onChange={(isChecked) => handleCommunicationMethodChange(index, isChecked)}
              style={{ display: 'flex', flexDirection: 'column-reverse' }}
            />
          ))}
        </div>
        <div style={{ paddingTop: '15px' }}>
          {methodCheckboxes[communicationMethod.length - 1] && (
            <CustomTextArea
              id="outlined-basic"
              variant="outlined"
              value={otherText}
              onChange={handleOtherTextChange}
              placeholder="Other mode of communication"
              rows={4}
              rowsMax={8}
            />
          )}
        </div>
      </SectionComponent>
      <Action backLink={previousPageURL} onClickNext={handleNextClick} onClearForm={handleClearForm} />
      <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
        <Ticket variant="contained" color="secondary" section="PRO: Verification Form - Availability" />
      </div>
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
};

export default Availability;