import React, { useState, useEffect, useRef } from 'react';
import Page from '../pro-components/Page';
import CustomTextField from '../pro-components/CTextField';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import RadioButtonGroup from '../pro-components/CRadioButton';
import DatePickerDialog from '../pro-components/CDatePicker';
import Dropdown from '../pro-components/CDropDown';
import FirstAction from '../pro-components/FirstPageAction';
import countries from '../../../countries.json';
import { useHistory } from "react-router-dom";
import Ticket from '../pro-components/ticket';
import CustomModal from "../../global-components/modal";
import { updatePro1 } from '../../../api';

const PersonalInformation = () => {
    const userDataJSON = localStorage.getItem("user");
    const userData = userDataJSON ? JSON.parse(userDataJSON) : {};
    console.log("printing user data on loign", userData.name);
    const fullNameParts = userData.name ? userData.name.split(' ') : [];
    const fname = fullNameParts[0] || '';
    const lname = fullNameParts.slice(1).join(' ') || '';
    const [selectedOption, setSelectedOption] = useState('');
    const [selectCountry, setSelectCountry] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [age, setAge] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [showFirstNameError, setShowFirstNameError] = useState(false);
    const [showLastNameError, setShowLastNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [dobError, setDobError] = useState(false);
    const [showDobError, setShowDobError] = useState(false);
    const [sexError, setSexError] = useState(false);
    const [nationalityError, setNationalityError] = useState('');
    const [sectionData, setSectionData] = useState("");
    const [isGenderFilled, setIsGenderFilled] = useState(false);
    const [errorModalData, setErrorModalData] = useState({
        title: "",
        message: "",
        show: false,
    });

    const showErrorModal = (title, message) => {
        setErrorModalData({
            title: title,
            message: message,
            show: true,
        });
    };

    let history = useHistory();
    const minscript = document.createElement("script");
    minscript.async = true;
    document.body.appendChild(minscript);

    useEffect(() => {
        const storedData = localStorage.getItem("firstPageData");
        console.log("get firstPageData in section 1", storedData);

        if (storedData) {
            const parsedData = JSON.parse(storedData);
            // Retrieve the stored first name and last name from local storage
            const storedFirstName = parsedData.fname || '';
            const storedLastName = parsedData.lname || '';
            setFirstName(storedFirstName || '');
            setLastName(storedLastName || '');
            setSelectedDate(parsedData.dob || '');
            setAge(parsedData.age || '')
            setSelectedOption(parsedData.gender || '');
            setSelectCountry(parsedData.nationality || '');
            setAge(parsedData.age || ''); // Add this line to set the age
        }
        else {
            // Use the full name from user data if no local storage data is available
            setFirstName(fname);
            setLastName(lname);
        }
    }, [fname, lname]);

    const options = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'non-binary', label: 'Non-Binary' },
        { value: 'prefer not to say', label: 'Prefer Not to Say' },
    ];

    const updateSectionData = async (sectionData) => {
        const proId = localStorage.getItem("proId");
        console.log("pro id from local storage", proId);
        if (!proId) {
            console.log("Pro ID not found in local storage");
            return;
        }

        try {
            const section_data = [
                {
                    0: sectionData,
                },
            ]

            console.log("updated data of pro");
            console.log(section_data);
            const updatedSectionData = await updatePro1(proId, section_data);
            if (updatedSectionData) {
                console.log("updated data of pro");
                console.log(updatedSectionData);
            } else {
                console.error("No data returned from updateDoctor1.");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleNextClick = async (event) => {
        event.preventDefault();

        validateFirstName(firstName);
        validateLastName(lastName);
        validateDateOfBirth(selectedDate);
        validateGender(selectedOption);
        validateNationality(selectCountry);

        if (!firstName || firstNameError) {
            showErrorModal("Alert", "First Name is required");
            scrollErrorFieldIntoView("firstName");
            return;
        }
        if (!lastName || lastNameError) {
            showErrorModal("Alert", "Last Name is required");
            scrollErrorFieldIntoView("lastName");
            return;
        }
        if (!selectedDate || dobError) {
            showErrorModal("Alert", "Date of Birth is required");
            scrollErrorFieldIntoView("dob");
            return;
        }
        if (!selectedOption || sexError) {
            showErrorModal("Alert", "Gender is required");
            scrollErrorFieldIntoView("gender");
            return;
        }
        if (!selectCountry || nationalityError) {
            showErrorModal("Alert", "Nationality is required");
            scrollErrorFieldIntoView("nationality");
            return;
        }
        const firstPageData = {
            fname: firstName,
            lname: lastName,
            dob: selectedDate,
            age: age,
            gender: selectedOption,
            nationality: selectCountry,
        };
        localStorage.setItem("firstPageData", JSON.stringify(firstPageData));
        // console.log(JSON.parse(localStorage.getItem("firstPageData")));

        setFirstNameError(false);
        setLastNameError(false);
        setDobError(false);
        setSexError(false);
        setNationalityError(false);

        await updateSectionData(firstPageData);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        history.push("/pro/section2");
    };

    const validateFirstName = (value) => {
        let error = '';

        if (!value) {
            error = 'First Name is required';
        } else if (/\s/.test(value)) {
            error = 'Spaces are not allowed';
        } else if (/[!@#$%^&*(),.?":{}|<>0-9]/.test(value)) {
            error = 'Special characters and numbers are not allowed';
        } else if (value.length < 2) {
            error = 'First Name should be at least 2 characters long';
        } else if (/\d/.test(value)) {
            error = 'Numbers are not allowed';
        }

        setFirstNameError(error);
    };

    const handlefnameBlur = () => {
        setShowFirstNameError(true);
    };

    const validateLastName = (value) => {
        let error = '';

        if (!value) {
            error = 'Last Name is required';
        } else if (/\s/.test(value)) {
            error = 'Spaces are not allowed';
        } else if (/[!@#$%^&*(),.?":{}|<>0-9]/.test(value)) {
            error = 'Special characters and numbers are not allowed';
        } else if (value.length < 2) {
            error = 'Last Name should be at least 2 characters long';
        } else if (/\d/.test(value)) {
            error = 'Numbers are not allowed';
        }

        setLastNameError(error);
    };

    const handlelnamelBlur = () => {
        setShowLastNameError(true);
    };

    const capitalizeFirstLetter = (value) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    };

    const handleFirstNameChange = (event) => {
        const value = event.target.value;
        const capitalizedValue = capitalizeFirstLetter(value);
        setFirstName(capitalizedValue);
        validateFirstName(capitalizedValue);

        // Store the updated first name in local storage
        const docfirstPageData = {
            ...JSON.parse(localStorage.getItem("docfirstPageData")),
            fname: capitalizedValue,
        };
        localStorage.setItem("docfirstPageData", JSON.stringify(docfirstPageData));
    };

    const handleLastNameChange = (event) => {
        const value = event.target.value;
        const capitalizedValue = capitalizeFirstLetter(value);
        setLastName(capitalizedValue);
        validateLastName(capitalizedValue);

        // Store the updated last name in local storage
        const docfirstPageData = {
            ...JSON.parse(localStorage.getItem("docfirstPageData")),
            lname: capitalizedValue,
        };
        localStorage.setItem("docfirstPageData", JSON.stringify(docfirstPageData));
    };

    const validateDateOfBirth = (value) => {
        if (!value) {
            setDobError("Date of Birth is required");
            return;
        }

        const calculatedAge = calculateAge(value);

        if (calculatedAge < 18) {
            setDobError("Your age must be at least 18 years");
        } else {
            setDobError("");
        }
    };

    const handledobBlur = () => {
        setShowDobError(true);
    };

    const calculateAge = (birthdate) => {
        const today = new Date();

        const birthDateParts = birthdate.split('/');
        const formattedBirthDate = `${birthDateParts[1]}/${birthDateParts[0]}/${birthDateParts[2]}`;
        const birthDate = new Date(formattedBirthDate);
        console.log("dob", birthDate);

        let calculatedAge = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            calculatedAge--;
        }
        return calculatedAge;
    };

    const handleDateChange = (value) => {
        if (value) {
            setSelectedDate(value);
            validateDateOfBirth(value);
            const calculatedAge = calculateAge(value);
            setAge(calculatedAge);

            const updatedSectionData = {
                ...sectionData,
                dob: value,
                age: calculatedAge,
            };
            setSectionData(updatedSectionData);

            localStorage.setItem('firstPageData', JSON.stringify(updatedSectionData));
        } else {
            setSelectedDate(null);
            setDobError('Date of Birth is required');
            setAge('');
        }
    };

    const validateGender = (option) => {
        if (!option) {
            setSexError("Gender is required");
        } else {
            setSexError("");
        }
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        validateGender(option);

        setSectionData((prevData) => ({
            ...prevData,
            gender: option,
        }));

        localStorage.setItem("firstPageData", JSON.stringify({ ...sectionData, gender: option, }));
    };

    const validateNationality = (option) => {
        if (!option) {
            setNationalityError("Nationality is required");
        } else {
            setNationalityError("");
        }
    };

    const handleSelectCountry = (option) => {
        validateNationality(option);
        setSelectCountry(option);

        const updatedSectionData = {
            ...sectionData,
            nationality: option,
        };
        setSectionData(updatedSectionData);

        localStorage.setItem('firstPageData', JSON.stringify(updatedSectionData));
    };

    const handleClearForm = () => {
        setFirstName('');
        setLastName('');
        setSelectedOption('');
        setSelectCountry('');
        setAge('');
        setSelectedDate('');
        setDobError('');
        setFirstNameError('');
        setLastNameError('');
        setSexError('');
        setNationalityError('');
    };

    const scrollErrorFieldIntoView = (errorField) => {
        const element = document.getElementById(errorField);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
            console.log("Prinitng clicked filed", errorField)
        }
    };

    const handleCloseErrorModal = () => {
        setErrorModalData({
            title: "",
            message: "",
            show: false,
        });

        validateFirstName(firstName);
        validateLastName(lastName);
        validateDateOfBirth(selectedDate);
        validateGender(selectedOption);
        validateNationality(selectCountry);

        // Scroll to the error field if an error occurred
        if (!firstName || firstNameError) {
            console.log("Scrolling to firstName");
            scrollErrorFieldIntoView("firstName");
        } else if (!lastName || lastNameError) {
            console.log("Scrolling to lastName");
            scrollErrorFieldIntoView("lastName");
        } else if (!selectedDate || dobError) {
            console.log("Scrolling to dob");
            scrollErrorFieldIntoView("dob");
        } else if (sexError) {
            scrollErrorFieldIntoView("gender");
        } else if (nationalityError) {
            scrollErrorFieldIntoView("nationality");
        } else {
            // Scroll to the top of the page
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    return (
        <Page>
            <FirstSection title="PRO FORM FOR MedITravelS">
                <h4 style={{ margin: '0px' }}>Personal Information</h4>
            </FirstSection>
            <SectionComponent>
                <label className="sec-label">FIRST NAME*</label>
                <div id="firstName">
                    <CustomTextField
                        id="outlined-basic"
                        placeholder="First Name"
                        variant="outlined"
                        value={firstName}
                        onChange={handleFirstNameChange}
                        onBlur={handlefnameBlur}
                        error={!!firstNameError}
                        helperText={firstNameError}
                    />
                </div>
            </SectionComponent>
            <SectionComponent>
                <label className="sec-label">LAST NAME*</label>
                <div id="lastName">
                    <CustomTextField
                        id="outlined-basic"
                        placeholder="Last Name"
                        variant="outlined"
                        value={lastName}
                        onChange={handleLastNameChange}
                        onBlur={handlelnamelBlur}
                        error={!!lastNameError}
                        helperText={lastNameError}
                    />
                </div>
            </SectionComponent>
            <SectionComponent>
                <label className="sec-label">DATE OF BIRTH*</label>
                <div className="date-pick" id="dob">
                    <DatePickerDialog
                        value={selectedDate !== null ? selectedDate : ''}
                        onChange={handleDateChange}
                        onBlur={handledobBlur}
                    />
                    {dobError && <span className="error-msg" style={{ color: 'red', marginBottom: '20px' }}>{dobError}</span>}
                </div>
                <br></br>
                <CustomTextField
                    label="Age according to Date of Birth"
                    variant="outlined"
                    value={age}
                    readOnly
                />
            </SectionComponent>
            <SectionComponent>
                <label className="sec-label">SEX*</label>
                <div id="gender">
                    <RadioButtonGroup
                        name="radioOptions"
                        value={selectedOption}
                        options={options}
                        onChange={handleOptionChange}

                    />
                </div>
                {sexError && sexError && (
                    <p className="error-msg">{sexError}</p>
                )}
            </SectionComponent>
            <SectionComponent>
                <label className="sec-label">NATIONALITY*</label>
                <br></br>
                <div id="nationality">
                    <Dropdown
                        className="dropdown-compo"
                        label={selectCountry ? '' : 'Select Country'}
                        options={countries.map((country) => ({
                            value: country.name,
                            label: country.name,
                        }))}
                        onChange={handleSelectCountry}
                        value={selectCountry}
                        error={!!nationalityError}
                        helperText={nationalityError}

                    />
                </div>
                {nationalityError && nationalityError && (
                    <p className="error-msg">{nationalityError}</p>
                )}
            </SectionComponent>
            <FirstAction
                onClickNext={handleNextClick}
                onClearForm={handleClearForm}
            />
            <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
                <Ticket variant="contained" color="secondary" section="PRO: Application Form - Personal Information" />
            </div>
            <CustomModal
                show={errorModalData.show}
                onHide={handleCloseErrorModal}
                titleStyle={{ fontWeight: "bold" }}
                title={errorModalData.title}
                message={errorModalData.message}
            />
        </Page>
    );
};

export default PersonalInformation;