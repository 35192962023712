import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from './CButton';

const SubmitAction = ({ backLink, nextLink, onSubmit }) => {
  // const handleClearForm = () => {
  //   onClearForm();
  // };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    onSubmit(event); // Pass the event object to the onSubmit function
  };

  return (
    <div className='submit-component' style={{ display: 'flex', overflow: 'unset', width: '50%', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '20px' }}>
          {backLink && (
            <Link to={backLink} onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>
              <CustomButton
                variant="contained"
                color="primary"
                label="Back"
              >
                Back
              </CustomButton>
            </Link>
          )}
        </div>
        <div>
          <CustomButton variant="contained" color="primary" label="Submit" onClick={handleSubmitForm}>
            Submit
          </CustomButton>
        </div>
      </div>
      {/* <div>
        <CustomButton variant="contained" color="secondary" label="Clear Form" onClick={handleClearForm}>
          Clear Form
        </CustomButton>
      </div> */}
    </div>
  );
};
export default SubmitAction;