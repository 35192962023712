import React, { useEffect, useState } from 'react';
import Page from '../pro-components/Page';
import SectionComponent from '../pro-components/Section';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { Modal, Button } from 'react-bootstrap';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { fetchTicketData } from '../../../api';

const TicketPage = () => {
    const [ticketData, setTicketData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const userDataJSON = localStorage.getItem('user');
    const userData = userDataJSON ? JSON.parse(userDataJSON) : {};
    const userId = userData.id;
    let loginEmail = userData.email;

    useEffect(() => {
        console.log("-----printing data of user id for ticket------")
        console.log(userId)
        fetchTicketData(userId)
          .then((data) => {
            setTicketData(data);
          })
          .catch((error) => {
            console.error('Error fetching ticket data:', error);
          });
      }, []);

    const handleStatusClick = (ticket) => {
        setSelectedTicket(ticket);
        setShowModal(true);
    };

    const formatDateTime = dateTimeString => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true, // Add this option to display time in AM/PM format
        };
        return new Date(dateTimeString).toLocaleString(undefined, options);
    };

    const truncateText = (text, numWords) => {
        const words = text.split(' ');
        if (words.length > numWords) {
            return words.slice(0, numWords).join(' ') + '...';
        }
        return text;
    };

    return (
        <Page>
            {/* <SectionComponent> */}
            <div style={{
                backgroundColor: '#fff',
                border: '1px solid #dadce0',
                borderRadius: '8px',
                width: '85%',
                marginTop: '20px',
                padding: '20px'
            }}>
                {ticketData.length > 0 ? (
                    <>
                        <h5 style={{ color: '#000000', fontWeight: 'bold', paddingBottom: '20px', textAlign: 'center' }}>Your Tickets</h5>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No.</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Issue</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>Status</TableCell>
                                        <TableCell>Details</TableCell>
                                        {/* <TableCell>Problem</TableCell>
                                        <TableCell>Message</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ticketData.map((ticket, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <strong>{index + 1}</strong>
                                            </TableCell>
                                            <TableCell>{ticket.email}</TableCell>
                                            <TableCell>
                                                {truncateText(ticket.problem, 15)}
                                            </TableCell>
                                            <TableCell>{formatDateTime(ticket.createdAt)}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                {/* Conditional rendering of status button */}
                                                {ticket.status === 'open' ? (
                                                    <Button variant="contained" style={{
                                                        backgroundColor: '#e35c5d', color: '#fff', fontSize: '15px', height: '45px',
                                                        fontWeight: 600, lineHeight: '30px', padding: '0 16px'
                                                    }}>
                                                        Open
                                                    </Button>
                                                ) : (
                                                    <Button variant="contained" style={{
                                                        backgroundColor: '#54ad57', color: '#fff', fontSize: '15px', height: '45px',
                                                        fontWeight: 600, lineHeight: '30px', padding: '0 16px'
                                                    }}>
                                                        Closed
                                                    </Button>
                                                )}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}><Button
                                                variant="contained"
                                                style={{ backgroundColor: '#06b6d600', color: '#000', padding: '0px' }}
                                                onClick={() => handleStatusClick(ticket)}
                                            >
                                                <VisibilityIcon />
                                            </Button></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : (
                    <h5 style={{ color: '#000000' }}>You haven't created any tickets yet.</h5>
                )}
                <Modal show={showModal} className="newsletter-popup" onHide={() => setShowModal(false)} backdrop="static">
                    <Modal.Header>
                        <Modal.Title>Ticket Details: </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ /* ... Add your styles ... */ }}>
                        {/* Display ticket details inside the modal */}
                        {selectedTicket && (
                            <>
                                <p><strong>Email:</strong> {selectedTicket.email}</p>
                                <p><strong>Section:</strong> {selectedTicket.section}</p>
                                <p><strong>Problem:</strong> {selectedTicket.problem}</p>
                                {selectedTicket.msg ? (
                                    <p>
                                        <strong>Message:</strong> {selectedTicket.msg}
                                    </p>
                                ) : (
                                    <p>
                                        <strong>Message:</strong> Your ticket is under processing. We will reply to you soon. Thank you.
                                    </p>
                                )}

                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-yellow" variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            {/* </SectionComponent> */}
        </Page>
    );
};

export default TicketPage;