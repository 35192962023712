import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

// const IntroV3 = (props) => {
// 	const { aboutData } = props;
// 	useEffect(() => {
// 		console.log("about data from intro props");
// 		console.log(aboutData)
// 	})

const IntroV3 = ({ aboutData }) => {
	const [parsedData, setparsedData] = useState([])
	// let publicUrl = process.env.PUBLIC_URL+'/'
	// let imagealt = 'image'

	useEffect(() => {

		if (typeof aboutData === "string") {
			const parsedData = JSON.parse(aboutData);
			setparsedData(parsedData)
		}
	}, [aboutData]);

	return (
		<>
			{parsedData.length > 0 && (
				<div className="intro-area pd-top-90">
					<div className="container">
						<div className="section-title text-lg-center text-left">

							<h2 className={`title ${parsedData[0].Data.css}`}>{parsedData[0].content}</h2>
						</div>
						<div className="row">
							<div className="col-lg-4 col-md-6">

								<div className="single-intro style-two">
									<div className="thumb">
										<img src={parsedData[1].Data[0].Icon} alt="img" />
									</div>

									<h4 className="intro-title">{parsedData[1].Data[0].Title}</h4>
									<p>{parsedData[1].Data[0].Description}</p>

								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="single-intro style-two">
									<div className="thumb">
										<img src={parsedData[1].Data[1].Icon} alt="img" />
									</div>

									<h4 className="intro-title">{parsedData[1].Data[1].Title}</h4>
									<p>{parsedData[1].Data[1].Description}</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="single-intro style-two">

									<div className="thumb">
										<img src={parsedData[1].Data[2].Icon} alt="img" />
									</div>

									<h4 className="intro-title">{parsedData[1].Data[2].Title}</h4>
									<p>{parsedData[1].Data[2].Description}</p>
								</div>

							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default IntroV3