import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

	const Team = () => {
		// const [parsedData, setparsedData] = useState([])
		 let publicUrl = process.env.PUBLIC_URL+'/'

		// useEffect(() => {
		  
		// 	if (typeof aboutData === "string") {
		// 	const parsedData = JSON.parse(aboutData);
		// 	console.log("about data from intro team json");
		// 	console.log(parsedData);
		// 	setparsedData(parsedData)
		// 	console.log("header")
		// console.log(parsedData[0].content)
		// 	}
		//   }, [aboutData]);

	  return(
			
	 <div className="team-newslater-bg" style={{backgroundImage: 'url('+publicUrl+'assets/img/bg/4.png)'}}>
			  {/* team area end */}
			  {/* {parsedData.length > 0 && ( */}
			  <div className="team-area pd-top-70">
			    <div className="container">
			      <div className="row justify-content-center">
			        <div className="col-lg-6">
			          <div className="section-title text-center">
			            <h2 className="title">Our Team</h2>
						<p>At our MeditravelS, we have a dedicated team of professionals who are passionate about revolutionizing the healthcare and travel industry. Led by our visionary Founder and supported by two innovative Co-Founders, we are committed to delivering exceptional experiences and cutting-edge solutions.</p>
			          </div>
			        </div>
			      </div>
			      <div className="rowteam">
			        <div className="col-lg-4 col-sm-6">
			          <div className="single-team text-center">
			            <div className="thumb">
			              <img src={publicUrl+"assets/img/team/DrRavi.jpg"} alt="team" />
			            </div>
			            {/* <h3 className="name"><a href="#">{parsedData[10].Data[0].Name}</a></h3>
			            <span>{parsedData[10].Data[0].Description}</span> */}
						 <h3 className="name"><a href="#">Dr. Ravi Gondaliya</a></h3>
			            <span> Founder</span>
			            <ul className="team-social">
			              {/* <li><a href="#"><i className="fa fa-facebook" /></a></li>
			              <li><a href="#"><i className="fa fa-twitter" /></a></li>
			              <li><a href="#"><i className="fa fa-instagram" /></a></li>
			              <li><a href="#"><i className="fa fa-linkedin" /></a></li> */}
			            </ul>
			          </div>
			        </div>
			        <div className="col-lg-4 col-sm-6">
			          <div className="single-team text-center">
			            <div className="thumb">
			              <img src={publicUrl+"assets/img/team/DrUmang.jpg"} alt="team" />
			            </div>
			            {/* <h3 className="name"><a href="#">{parsedData[10].Data[1].Name}</a></h3>
			            <span>{parsedData[10].Data[1].Description}</span> */}
						 <h3 className="name"><a href="#">Dr. Umang Sihora</a></h3>
			            <span>Co-Founder</span>
			            <ul className="team-social">
			              {/* <li><a href="#"><i className="fa fa-facebook" /></a></li>
			              <li><a href="#"><i className="fa fa-twitter" /></a></li>
			              <li><a href="#"><i className="fa fa-instagram" /></a></li>
			              <li><a href="#"><i className="fa fa-linkedin" /></a></li> */}
			            </ul>
			          </div>
			        </div>
			        <div className="col-lg-4 col-sm-6">
			          <div className="single-team text-center">
			            <div className="thumb">
			              <img src={publicUrl+"assets/img/team/Ravisir.jpg"} alt="team" />
			            </div>
			            {/* <h3 className="name"><a href="#">{parsedData[10].Data[2].Name}</a></h3>
			            <span>{parsedData[10].Data[2].Description}</span> */}
						 <h3 className="name"><a href="#">Ravi Vaishnav</a></h3>
			            <span>Co-Founder</span>
			            <ul className="team-social">
			              {/* <li><a href="#"><i className="fa fa-facebook" /></a></li>
			              <li><a href="#"><i className="fa fa-twitter" /></a></li>
			              <li><a href="#"><i className="fa fa-instagram" /></a></li>
			              <li><a href="#"><i className="fa fa-linkedin" /></a></li> */}
			            </ul>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			  {/* )} */}

			  {/* team area end */}
			  {/* newslatter area Start */}
			  {/* <div className="newslatter-area pd-top-80">
			    <div className="container">
			      <div className="newslatter-area-wrap border-tp-solid">
			        <div className="row">
			          <div className="col-xl-3 col-lg-6 col-md-5 offset-xl-2">
			            <div className="section-title mb-md-0">
			              <h2 className="title">Newsletter</h2>
			              <p>Sign up to receive the best offers</p>
			            </div>
			          </div>
			          <div className="col-xl-4 col-lg-6 col-md-7 align-self-center offset-xl-1">
			            <div className="input-group newslatter-wrap">
			              <div className="input-group-prepend">
			                <span className="input-group-text"><i className="fa fa-envelope" /></span>
			              </div>
			              <input type="text" className="form-control" placeholder="Email" />
			              <div className="input-group-append">
			                <button className="btn btn-yellow" type="button">Subscribe</button>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div> */}
			  {/* newslatter area End */}
			</div>

);
        }


export default Team