import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const Dropdown = ({ label, value, options, onChange, className }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl className={className} style={{
      minWidth: '35%'
    }}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={handleChange} >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;

// const options = [
//     { value: 'option1', label: 'Option 1' },
//     { value: 'option2', label: 'Option 2' },
//     { value: 'option3', label: 'Option 3' },
//   ];
  
//   <Dropdown
//     label="Select Option"
//     value={selectedOption}
//     options={options}
//     onChange={handleOptionChange}
//   />  