import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faFileAlt, faStethoscope, faBars, faTimes, faFile } from '@fortawesome/free-solid-svg-icons';
import { auth } from '../../login-signup/screens/firebase.config';
import CustomModal from "../../global-components/modal";
import { findOnePatientStatus, checkTokenValidity, logoutUser } from '../../../api';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  let anchor = '#'
  let imagealt = 'image'
  let publicUrl = process.env.PUBLIC_URL + '/'
  let history = useHistory();
  const [isAccepted, setIsAccepted] = useState('');
  const [isSubmit, setIsSubmit] = useState('');
  const [showSelectTreatmentLink, setShowSelectTreatmentLink] = useState('');
  const [patientData, setPatientData] = useState(null);
  const [statusConsult, setStatusConsult] = useState('');
  const [patientFormStatus, setPatientFormStatus] = useState('');
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const minscript = document.createElement("script");
  minscript.async = true;
  minscript.src = publicUrl + "assets/js/main.js";
  document.body.appendChild(minscript);
  const patientform = localStorage.getItem('isSubmitpatient');
  const patientStatus = localStorage.getItem('patientSidebar');

  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const updateSidebar = async () => {
    const userId = userData.id;
    try {
      const data = await findOnePatientStatus(userId);
      console.log('API response:', data);
      setTimeout(() => {
        console.log("Printing timeout")
        console.log(localStorage.getItem("patientSidebar"))
      }, 500)
      setIsAccepted(data.is_accepted);
      console.log("sidebar pro isAccepted:", data.is_accepted)
      setIsSubmit(data.is_submit);
      console.log("sidebar pro isSubmit:", data.is_submit);
      setShowSelectTreatmentLink(data.treatmentselect_status);
      console.log("printing select treatment status from patinet status api:", data.treatmentselect_status);
      setStatusConsult(data.consultation_status);
      console.log("printing consultation status from patinet status api:", data.consultation_status)
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  useEffect(() => {
    updateSidebar();

    const intervalId = setInterval(updateSidebar, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleLogout = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwt.decode(token);
        const userId = decodedToken && decodedToken.user_id;

        // Check the token validity before logging out
        const isValidToken = await checkTokenValidity(token);

        if (!isValidToken) {
          console.log('Token is invalid. Logging out without API call.');
          localStorage.removeItem('token');
          // Remove all items from local storage
          localStorage.clear();
          console.log('Logout successful');
          // Redirect to the login page or any other desired page
          history.push('/');
          return;
        }

        console.log('Token is valid. Proceeding with logout API call...');

        // Call the logout API
        const logoutSuccess = await logoutUser(userId);

        if (logoutSuccess) {
          localStorage.removeItem('token');
          localStorage.clear();
          console.log('Logout successful');
          history.push('/');
        } else {
          console.error('Logout failed');
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('Token not found in local storage');
      history.push('/');
    }
  };

  const handlePatientFormClick = async () => {
    const isEmailVerifiedPro = await auth.currentUser.reload().then(() => {
      return auth.currentUser.emailVerified;
    });

    if (!isEmailVerifiedPro) {
      // Show the custom modal for email verification
      showErrorModal("Email Verification Required", "Please verify your email to continue.");
    } else if (isSubmit === "true") {
      console.log('Redirecting to Dashboard');
      history.push('/patient/patientdashboard');
    } else {
      console.log('Redirecting to PRO Form');
      history.push('/patient/sec-1');
    }
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <div className='pro-main-sidebar'>
      <div className="widget_ads">
        <a href="/" style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
          <img src={publicUrl + "assets/img/sticky-logo.png"} alt={imagealt} style={{ width: '55%' }} />
        </a>
      </div>
      <aside className="sidebar-area sidebar-area-4">
        <div className="widget widget_search bg-none pd-none">
        </div>
        <div className="widget_categories">
          <h2 className="widget-title">PATIENT</h2>
          <ul>
            <li>
              {/* <div className="sidebar-icons"> */}
              <Link to="/patient/patientdashboard" >
                <FontAwesomeIcon icon={faHome} /> Dashboard

              </Link>
              {/* </div> */}
            </li>
            {isSubmit !== "true" && (
              <li>
                <Link onClick={handlePatientFormClick}>
                  <FontAwesomeIcon icon={faFileAlt} /> Patient Form

                </Link>
              </li>
            )}
            {/* {isSubmit === "true" && showSelectTreatmentLink === "pending" && (
              <li>
                <Link to="/patient/patienttreatment">Select Treatment</Link>
              </li>
            )} */}
            {isAccepted === "true" && showSelectTreatmentLink === "pending" && (
              <li>
                <Link to="/patient/patienttreatment">
                  <FontAwesomeIcon icon={faStethoscope} /> Select Treatment
                </Link>
              </li>
            )}
            {showSelectTreatmentLink === "selected" && (statusConsult === "pending" || statusConsult === "date_selected" || statusConsult === "form_completed") && (
              <li>
                <Link to="/patient/consultation">
                  <FontAwesomeIcon icon={faFileAlt} /> Consultation Form
                </Link>
              </li>
            )}

            <li>
              <Link to="/patient/patticket">
                <FontAwesomeIcon icon={faFile} /> Ticket
              </Link>
            </li>

          </ul>
        </div>
        <div className='pro-logout'>
          <button className="btn btn-yellow" type="button" onClick={handleLogout}>Logout</button>
        </div>
      </aside>
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </div>
  );
};

export default withRouter(Sidebar);