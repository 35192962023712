import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const  DatePickerValue = ({ value, onChange }) => {
  const maxDate = dayjs().endOf('day'); 

  const handleDateChange = (date) => {
    const selectedDate = dayjs(date).format('DD/MM/YYYY'); // Format the selected date

    onChange(selectedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker', 'DatePicker']}>
        <DatePicker
          // label="Controlled picker"
          value={value !== null ? dayjs(value, 'DD/MM/YYYY') : null}
          onChange={handleDateChange}
          // value={value}
          format='DD/MM/YYYY'
          disablePast 
          // maxDate={maxDate} 
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
export default DatePickerValue;