import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Contact from './section-components/contact';
import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';

const ContactPage = () => {
  const history = useHistory();
  const [contactHeaderImage, setContactHeaderImage] = useState(null);
  const headerImage = localStorage.getItem("contactimage");

  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => unlisten();
  }, [history]);

  return (
    <div>
      <Navbar />
      <PageHeader image={headerImage} headertitle="Contact Us" />
          <Contact />
          <Subscribe />
          <Footer />
    </div>
  );
};

export default ContactPage;