import {
  About_api_url,
  FindAllBlog_api_url,
  FindBlogbyId_api_url,
  FindAllContact_api_url,
  FindAllTreatment_api_url,
  Testimonial_api_url,
  FindAllenableSubTreatment_api_url,
  FindAllSubSubTreatment_api_url,
  Treatmentdetails_api_url,
  PRO_Create_api_url,
  Patient_Create_api_url,
  ActiveTreatment_Create_api_url,
  Doc_Create_api_url,
  Signup_api_url,
  Send_Mail_api_url,
  Findonebyemail_api_url,
  Login_api_url,
  FindOnePRO_api_url,
  FindOnePRO2_api_url,
  Patient_FindById_api_url,
  Doc_FindById_api_url,
  Doc2_FindById_api_url,
  UpdateUser_api_url,
  ForgetPassword_api_url,
  VerifyOtp_api_url,
  ResendOtp_api_url,
  ResetPassword_api_url,
  Doc_Update_api_url,
  Doc1Status_Create_api_url,
  Doc2_Update_api_url,
  Doc2Status_Create_api_url,
  UploadMedia_api_url,
  PRO_Update_api_url,
  PRO1Status_Create_api_url,
  PRO2_Update_api_url,
  PRO2Status_Create_api_url,
  FindOneDoc1Status_api_url,
  FindOneDoc2Status_api_url,
  CheckToken_api_url,
  Logout_api_url,
  Ticket_FindAllByUserId_api_url,
  Ticket_Create_api_url,
  FindOnePro2Status_api_url,
  FindOnePro1Status_api_url,
  FindOnePatientStatus_api_url,
  Patient_Update_api_url,
  PatientStatus_Create_api_url,
  ActiveTreatment_Update_api_url,
  PatientStatus_Update_api_url,
  Customer_Create_api_url,
  FindConsultation_api_url,
  Consultation_Create_api_url,
  Session_Create_api_url,
  Consultation_Update_api_url,
  CreateContactUs_api_url,
  Newsletter_api_url,
  FindAll_PRO2_Status_api_url,
  Findoneuser_api_url, Get_All_ProList_api_url,
  Updatepro1status_api_url,
  Updatepro2status_api_url,
  Updatedoc1status_api_url,
  UpdateDoc2status_api_url,
} from './constant/url';
import axios from 'axios';

export const fetchAboutData = async () => {
  try {
    const response = await axios.get(About_api_url);
    return response.data;
  } catch (error) {
    console.error('Error fetching about data:', error);
    throw error;
  }
};

export const fetchBlogs = async (page, category) => {
  try {
    const data = {
      pageNumber: page,
      category: category,
    };

    const response = await axios.post(FindAllBlog_api_url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Save data to localStorage
    localStorage.setItem('blog_id', response.data.blog_id);
    localStorage.setItem('image', response.data.data.image_url);

    return response.data;
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error;
  }
};

export const fetchBlogById = async (blogId) => {
  try {
    const data = {
      blog_id: blogId,
    };

    const response = await axios.post(FindBlogbyId_api_url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data.data;
  } catch (error) {
    console.error('Error fetching blog by ID:', error);
    throw error;
  }
};

export const fetchContactData = async () => {
  try {
    const response = await axios.get(FindAllContact_api_url);
    return response.data;
  } catch (error) {
    console.error('Error fetching contact data:', error);
    throw error;
  }
};

export const fetchTreatmentData = async () => {
  try {
    const response = await axios.get(FindAllTreatment_api_url);
    return response.data;
  } catch (error) {
    console.error('Error fetching contact data:', error);
    throw error;
  }
};

export const fetchTestimonialData = async () => {
  try {
    const response = await axios.get(Testimonial_api_url);
    return response.data;
  } catch (error) {
    console.error('Error fetching testimonial data:', error);
    throw error;
  }
};

export const fetchEnableSubTreatmentsData = async (treatmentId) => {
  try {
    const requestData = {
      treatment_id: treatmentId,
      enabled: 'true', // Pass "true" as a string in the request body
    };

    const response = await axios.post(FindAllenableSubTreatment_api_url, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data.data;
  } catch (error) {
    console.error('Error fetching enable sub-treatments:', error);
    throw error;
  }
};

export const fetchSubsubTreatmentsData = async (subtreatmentId) => {
  try {
    const response = await axios.post(FindAllSubSubTreatment_api_url, {
      subtreatment_id: subtreatmentId,
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching sub-sub-treatments:', error);
    throw error;
  }
};

export const fetchTreatmentDetailsData = async (subsubTreatment_id) => {
  try {
    const response = await axios.post(Treatmentdetails_api_url, {
      subsubtreatment_id: subsubTreatment_id,
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    const data = response.data;
    let json = JSON.parse(data.data.details);

    return {
      treatmentData: json,
      treatmentDetailsId: data.data._id,
    };
  } catch (error) {
    console.error('Error fetching treatment details:', error);
    throw error;
  }
};

export const signup = async (usersData) => {
  try {
    const response = await axios.post(Signup_api_url, usersData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response;
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const createPro = async (userId) => {
  try {
    const response = await axios.post(PRO_Create_api_url, {
      user_id: userId,
      last_section: "",
      section_data: {},
    });

    if (response.status === 200) {
      const proId = response.data.data._id;
      localStorage.setItem("proId", proId);
    } else {
      console.log("Failed to create Pro");
    }
  } catch (error) {
    console.log("Error creating Pro:", error);
  }
};

export const createPatient = async (userId) => {
  try {
    const response = await axios.post(Patient_Create_api_url, {
      user_id: userId,
      last_section: "",
      section_data: {},
      treatment_details_id: "",
      is_submit: false,
    });

    if (response.status === 200) {
      const patientId = response.data.data._id;
      localStorage.setItem("patientId", patientId);
      localStorage.setItem("patientSidebar", 'form');

      // Create ActiveTreatment for the patient
      const currentDate = new Date().toISOString();
      try {
        const activeTreatmentResponse = await axios.post(ActiveTreatment_Create_api_url, {
          user_id: userId,
          status: "initial",
          date: currentDate,
        });

        if (activeTreatmentResponse.status === 200) {
        } else {
          console.log("Failed to create ActiveTreatment");
        }
      } catch (activeTreatmentError) {
        console.error("Error creating ActiveTreatment:", activeTreatmentError);
      }
    } else {
      console.log("Failed to create Patient");
    }
  } catch (createPatientError) {
    console.log("Error creating Patient:", createPatientError);
  }
};

export const createDoctor = async (userId) => {
  try {
    const response = await axios.post(Doc_Create_api_url, {
      user_id: userId,
      last_section: "",
      section_data: {},
    });

    if (response.status === 200) {
      const docId = response.data.data._id;
      localStorage.setItem("docId", docId);
    } else {
      console.log("Failed to create Doctor");
    }
  } catch (error) {
    console.log("Error creating Doctor:", error);
  }
};

export const sendEmail = async (email, subject, content) => {
  try {
    const response = await axios.post(Send_Mail_api_url, {
      emails: [email],
      subject: subject,
      content: content,
    });

    return response.data;
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};

export const checkEmailExistence = async (email) => {
  try {
    const response = await axios.get(Findonebyemail_api_url.replace(":email", email));

    if (response.status === 200 && response.data.status === 1) {
      // Email already exists
      return true;
    } else {
      // Email doesn't exist
      return false;
    }
  } catch (error) {
    console.error('Error checking email existence:', error);
    // Handle the error as needed
    return false;
  }
};

export const loginUser = async (credentials) => {
  try {
    const response = await axios.post(Login_api_url, credentials);

    return response;

  } catch (error) {
    throw error;
  }
};

export const fetchPROData = async (userId) => {
  try {
    const response = await axios.post(FindOnePRO_api_url, { user_id: userId });
    return response.data.data;
  } catch (error) {
    // Handle 404 error and return null, continue with other errors
    if (error.response && error.response.status === 404) {
      return null;
    } else {
      throw error;
    }
  }
};

export const fetchPRO2Data = async (userId) => {
  try {
    const response = await axios.post(FindOnePRO2_api_url, { user_id: userId });
    return response.data.data;
  } catch (error) {
    // Handle 404 error and return null, continue with other errors
    if (error.response && error.response.status === 404) {
      return null;
    } else {
      throw error;
    }
  }
};

export const fetchPatientData = async (userId) => {
  try {
    const response = await axios.post(Patient_FindById_api_url, { user_id: userId });
    return response.data.data;
  } catch (error) {
    // Handle 404 error and return null, continue with other errors
    if (error.response && error.response.status === 404) {
      return null;
    } else {
      throw error;
    }
  }
};

export const fetchDoctorData = async (userId) => {
  try {
    const response = await axios.post(Doc_FindById_api_url, { user_id: userId });
    return response.data.data;
  } catch (error) {
    // Handle 404 error and return null, continue with other errors
    if (error.response && error.response.status === 404) {
      return null;
    } else {
      throw error;
    }
  }
};

export const fetchDoctor2Data = async (userId) => {
  try {
    const response = await axios.post(Doc2_FindById_api_url, { user_id: userId });
    return response.data.data;
  } catch (error) {
    // Handle 404 error and return null, continue with other errors
    if (error.response && error.response.status === 404) {
      return null;
    } else {
      throw error;
    }
  }
};

export const updateUser = async (userId, dataToUpdate) => {
  try {
    const updateResponse = await axios.put(
      `${UpdateUser_api_url.replace(":id", userId)}`,
      dataToUpdate
    );
    if (updateResponse.status === 200) {
      return true; // Indicate success
    } else {
      return false; // Indicate failure
    }
  } catch (error) {
    return false; // Indicate failure
  }
};

export const sendPasswordResetEmail = async (email) => {
  try {
    const response = await axios.post(ForgetPassword_api_url, { email });
    if (response.status === 200) {
      return true; // Password reset email sent successfully
    } else {
      return false; // Password reset email sending failed
    }
  } catch (error) {
    console.error('Error sending password reset email:', error);
    // Handle the error as needed
    return false;
  }
};

export const verifyOtp = async (email, otp) => {
  try {
    const requestBody = {
      email: email,
      otp: otp,
    };

    const response = await axios.post(VerifyOtp_api_url, requestBody);

    if (response.status === 200) {
      return true; // OTP verification successful
    } else {
      return false; // OTP verification failed
    }
  } catch (error) {
    console.error('Error verifying OTP:', error);
    // Handle the error as needed
    return false;
  }
};

export const resendOtp = async (email) => {
  try {
    const requestBody = {
      email: email,
    };

    const response = await axios.post(ResendOtp_api_url, requestBody);

    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error resending OTP:', error);

    return false;
  }
};

export const resetPassword = async (email, newPassword) => {
  try {
    const requestBody = {
      email: email,
      newPassword: newPassword,
    };

    const response = await axios.post(ResetPassword_api_url, requestBody);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};

export const updateDoctor1 = async (docId, sectionData) => {
  try {
    const url = Doc_Update_api_url.replace(":id", docId);
    const response = await axios.patch(url, {
      section_data: sectionData,
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    console.error('Error updating document section:', error);
    throw error;
  }
};

export const createDoc1Status = async (doc1StatusData) => {
  try {
    const response = await axios.post(Doc1Status_Create_api_url, doc1StatusData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response;
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const updateDoctor2 = async (userId, sectionData) => {
  try {
    const url = Doc2_Update_api_url.replace(":userId", userId);
    const response = await axios.patch(url, {
      section_data: sectionData,
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    console.error('Error updating document section:', error);
    throw error;
  }
};

export const createDoc2Status = async (doc2StatusData) => {
  try {
    const response = await axios.post(Doc2Status_Create_api_url, doc2StatusData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response;
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const uploadMedia = async (file, mediaType) => {
  try {
    const formData = new FormData();
    formData.append("media", file);
    formData.append("type", mediaType);

    const response = await axios.post(UploadMedia_api_url, formData);

    if (response.status !== 200) {
      throw new Error(response.data.message);
    }

    const { filepath } = response.data;
    return filepath;
  } catch (error) {
    throw error;
  }
};

export const updatePro1 = async (proId, sectionData) => {
  try {
    const url = PRO_Update_api_url.replace(":id", proId);
    const response = await axios.patch(url, {
      section_data: sectionData,
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    console.error('Error updating document section:', error);
    throw error;
  }
};

export const createPro1Status = async (pro1StatusData) => {
  try {
    const response = await axios.post(PRO1Status_Create_api_url, pro1StatusData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response;
  } catch (error) {
    console.error('Error create pro 1 status:', error);
    throw error;
  }
};

export const updatePro2 = async (userId, sectionData) => {
  try {
    const url = PRO2_Update_api_url.replace(":userId", userId);
    const response = await axios.patch(url, {
      section_data: sectionData,
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    console.error('Error updating document section:', error);
    throw error;
  }
};

export const createPro2Status = async (pro2StatusData) => {
  try {
    const response = await axios.post(PRO2Status_Create_api_url, pro2StatusData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response;
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const findOneDoc1Status = async (userId) => {
  try {
    const response = await axios.post(FindOneDoc1Status_api_url, { user_id: userId });

    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    throw error;
  }
};

export const findOneDoc2Status = async (userId) => {
  try {
    const response = await axios.post(FindOneDoc2Status_api_url, { user_id: userId });

    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    throw error;
  }
};

export const checkTokenValidity = async (token) => {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: { token }, // Use 'data' property to send JSON data
      url: CheckToken_api_url,
    };

    const checkTokenResponse = await axios(requestOptions);

    if (checkTokenResponse.status !== 200 || checkTokenResponse.data.status !== 1) {
      return false;
    }

    console.log('Token is valid.');
    return true;
  } catch (error) {
    console.error('Error checking token validity:', error);
    throw error;
  }
};

export const logoutUser = async (userId) => {
  try {
    const response = await axios.post(Logout_api_url, { userId });

    if (response.status === 200) {
      return true;
    } else {
      console.error('Logout failed');
      return false;
    }
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  }
};

export const fetchTicketData = async (userId) => {
  try {
    const response = await axios.post(Ticket_FindAllByUserId_api_url, {
      user_id: userId,
    });

    const data = response.data;

    if (data.status === 1 && data.data) {
      return data.data;
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};

export const createTicket = async (ticketData) => {
  try {
    const response = await axios.post(Ticket_Create_api_url, ticketData);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Failed to create a ticket');
    }
  } catch (error) {
    throw error;
  }
};

export const findOnePro1Status = async (userId) => {
  try {
    const response = await axios.post(FindOnePro1Status_api_url, { user_id: userId });

    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    throw error;
  }
};

export const findOnePro2Status = async (userId) => {
  try {
    const response = await axios.post(FindOnePro2Status_api_url, { user_id: userId });

    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    throw error;
  }
};

export const findOnePatientStatus = async (userId) => {
  try {
    const response = await axios.post(FindOnePatientStatus_api_url, { user_id: userId });

    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    throw error;
  }
};

export const updatePatient = async (patientId, updateData) => {
  try {
    const url = Patient_Update_api_url.replace(":id", patientId);
    const response = await axios.patch(url, updateData);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    console.error('Error updating document section:', error);
    throw error;
  }
};

export const createPatientStatus = async (patientStatusData) => {
  try {
    const response = await axios.post(PatientStatus_Create_api_url, patientStatusData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response;
  } catch (error) {
    console.error('Error create pro 1 status:', error);
    throw error;
  }
};

export const updateActiveTreatmentStatusAPI = async (userId, dataToUpdate) => {
  try {
    const updateResponse = await axios.put(
      `${ActiveTreatment_Update_api_url.replace(":userId", userId)}`,
      dataToUpdate
    );
    if (updateResponse.status === 200) {
      return true; // Indicate success
    } else {
      return false; // Indicate failure
    }
  } catch (error) {
    return false; // Indicate failure
  }
};

export const updatePatientStatusAPI = async (patientStatusId, sectionData) => {
  try {
    const url = PatientStatus_Update_api_url.replace(":id", patientStatusId);
    const response = await axios.patch(url, sectionData);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    console.error('Error updating document section:', error);
    throw error;
  }
};

export const createCustomer = async (customerData) => {
  try {
    const response = await axios.post(Customer_Create_api_url, customerData, {
      headers: {
        'Authorization': 'Bearer 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',
        'Content-Type': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response.data; // Return the customer data
  } catch (error) {
    console.error('Error creating customer:', error);
    throw error;
  }
};

export const fetchConsultationData = async (userId) => {
  try {
    const response = await axios.post(FindConsultation_api_url, { user_id: userId });
    return response.data;
  } catch (error) {
    // Handle 404 error and return null, continue with other errors
    if (error.response && error.response.status === 404) {
      return null;
    } else {
      throw error;
    }
  }
};

export const createConsultation = async (consultationData) => {
  try {
    const response = await axios.post(Consultation_Create_api_url, consultationData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createSession = async (sessionData) => {
  try {
    const response = await axios.post(Session_Create_api_url, sessionData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer 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',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateConsultation = async (userId, dataToUpdate) => {
  try {
    const url = Consultation_Update_api_url.replace(':user_id', userId);
    const response = await axios.put(url, dataToUpdate);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error(response.data.error);
    }
  } catch (error) {
    console.error('Error updating Consultation:', error);
    throw error;
  }
};

export const createContactUs = async (data) => {
  try {
    const response = await axios.post(CreateContactUs_api_url, data);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Failed to create Contact US');
    }
  } catch (error) {
    console.error('Error creating Contact US:', error);
    throw error;
  }
};

export const subscribeToNewsletter = async (email) => {
  try {
    const response = await axios.post(Newsletter_api_url, { email });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data.message || 'An error occurred while subscribing to the newsletter');
    }
  } catch (error) {
    throw error;
  }
};


export const findAllPro2Status = async () => {
  try {
    const response = await axios.get(FindAll_PRO2_Status_api_url);
    return response.data;
  } catch (error) {
    console.error('Error fetching all Pro2Status:', error);
    throw error;
  }
};

export const findOneUserById = async (userId) => {
  try {
    const response = await axios.get(`${Findoneuser_api_url}/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user by ID:', error);
    throw error;
  }
};

export const GetAllProList = async () => {
  try {
    const response = await axios.get(Get_All_ProList_api_url);
    return response.data;
  } catch (error) {
    console.error('Error fetching all Pro2Status:', error);
    throw error;
  }
};

export const updatePro1Status = async (pro1StatusId, dataToUpdate) => {
  try {
    const updateResponse = await axios.patch(
      `${Updatepro1status_api_url.replace(":id", pro1StatusId)}`,
      dataToUpdate
    );
    if (updateResponse.status === 200) {
      return true; // Indicate success
    } else {
      return false; // Indicate failure
    }
  } catch (error) {
    return false; // Indicate failure
  }
};

export const updatePro2Status = async (pro2StatusId, dataToUpdate) => {
  try {
    const updateResponse = await axios.patch(
      `${Updatepro2status_api_url.replace(":id", pro2StatusId)}`,
      dataToUpdate
    );
    if (updateResponse.status === 200) {
      return true; // Indicate success
    } else {
      return false; // Indicate failure
    }
  } catch (error) {
    return false; // Indicate failure
  }
};

export const updateDoc1Status = async (doc1StatusId, dataToUpdate) => {
  try {
    const updateResponse = await axios.patch(
      `${Updatedoc1status_api_url.replace(":id", doc1StatusId)}`,
      dataToUpdate
    );
    if (updateResponse.status === 200) {
      return true; // Indicate success
    } else {
      return false; // Indicate failure
    }
  } catch (error) {
    return false; // Indicate failure
  }
};

export const updateDoc2Status = async (doc2StatusId, dataToUpdate) => {
  try {
    const updateResponse = await axios.patch(
      `${UpdateDoc2status_api_url.replace(":id", doc2StatusId)}`,
      dataToUpdate
    );
    if (updateResponse.status === 200) {
      return true; // Indicate success
    } else {
      return false; // Indicate failure
    }
  } catch (error) {
    return false; // Indicate failure

  }
};