import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import SvgIcon from '@mui/material/SvgIcon';
import Button from '@mui/material/Button';

function ClockIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 1C5.935 1 1 5.935 1 12s4.935 11 11 11 11-4.935 11-11S18.065 1 12 1zm0 20.125c-5.324 0-9.625-4.3-9.625-9.625S6.676 2.875 12 2.875s9.625 4.301 9.625 9.625-4.3 9.625-9.625 9.625zm-.5-4.5v-7.95L15.5 7l-.7-.7-4.5 4.5V9.625h-1v7.5z" />
    </SvgIcon>
  );
}

export default function StaticTimePickerWithButton({ onTimeSelect, identifier }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    const savedTime = localStorage.getItem(`selectedTime_${identifier}`);
    if (savedTime) {
      setSelectedTime(new AdapterDayjs().date(savedTime));
    }
  }, [identifier]);

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
  };

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePicker = () => {
    setAnchorEl(null);
  };

  const handleOk = () => {
    setAnchorEl(null);
    if (selectedTime) {
      localStorage.setItem(`selectedTime_${identifier}`, selectedTime.format());
      onTimeSelect(selectedTime.format('hh:mm A'));
    }
  };

  const handleCancel = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'time-picker-popover' : undefined;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ position: 'relative' }}>
        <TextField
          value={selectedTime ? selectedTime.format('hh:mm A') : 'Select a Time'}
          onClick={handleButtonClick}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton edge="end" onClick={handleButtonClick}>
                <ClockIcon />
              </IconButton>
            ),
          }}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePicker}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <StaticTimePicker
            value={selectedTime}
            onChange={handleTimeChange}
            renderInput={(params) => <TextField {...params} label="Select a Time" />}
            orientation="landscape"
          />
          <div className='timepicker-action' style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px' }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleOk}>OK</Button>
          </div>
        </Popover>
      </div>
    </LocalizationProvider>
  );
}
