import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

const About = ({ aboutData }) => {
  const [parsedData, setparsedData] = useState([])
  // let publicUrl = process.env.PUBLIC_URL+'/'
  // let imagealt = 'image'

  useEffect(() => {

    if (typeof aboutData === "string") {
      const parsedData = JSON.parse(aboutData);
      setparsedData(parsedData)
      console.log(parsedData[0].content)
    }
  }, [aboutData]);

  return (
    <>
      {parsedData.length > 0 && (<div className="about-section pd-top-80">
        <div className="container" style={{ paddingBottom: '80px' }}>
          <div className="row">
            <div className="col-lg-5 align-self-center">
              <div className="section-title mb-lg-0">

                <h2 className="title">{parsedData[2].content}</h2>
                <p>{parsedData[3].content}</p>

              </div>
            </div>
            <div className="col-lg-5 offset-lg-2">
              <div className="thumb about-section-right-thumb">
                <img src={parsedData[4].Data[0].video} alt="img" />
                <img className="about-absolute-thumb" src={parsedData[4].Data[1].video} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
}


export default About