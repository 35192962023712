import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Button from "../../components/PRO/pro-components/CButton"
import { Modal } from 'react-bootstrap';
import { createContactUs } from '../../api';

const Contact = () => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [showNameError, setShowNameError] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [showMessageError, setShowMessageError] = useState(false);
  const [sendmessage, setSendMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [contactHeaderImage, setContactHeaderImage] = useState(null);
  const [countryCode, setCountryCode] = useState('');
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page on route change
    });
    return () => unlisten();
  }, [history]);

  const validateName = (value) => {
    let error = '';

    if (!value) {
      error = 'Full Name is required';
    } else if (/[!@#$%^&*(),'`~.?":{}|<>0-9]/.test(value)) {
      error = 'Special characters and numbers are not allowed';
    } else if (value.length < 2) {
      error = 'Full Name should be at least 2 characters long';
    } else if (/\d/.test(value)) {
      error = 'Numbers are not allowed';
    }

    setNameError(error);
  };

  const handleNameBlur = () => {
    setShowNameError(true);
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    setName(value);
    validateName(value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleEmailBlur = () => {
    setShowEmailError(true);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setEmailError(!validateEmail(value));
  };

  const validatePhone = (phone, countryCode) => {
    const phoneDigits = phone.replace(/\D/g, '');
    const validPhoneLengths = {
      AE: 9,     // United Arab Emirates
      AF: 11,    // Afghanistan
      AL: 9,     // Albania
      DZ: 10,    // Algeria
      AD: 9,     // Andorra
      AO: 9,     // Angola
      AG: 10,    // Antigua and Barbuda
      AR: 10,    // Argentina
      AM: 8,     // Armenia
      AW: 7,     // Aruba
      AU: 9,     // Australia
      AT: 10,    // Austria
      AZ: 9,     // Azerbaijan
      BS: 10,    // Bahamas
      BH: 8,     // Bahrain
      BD: 10,    // Bangladesh
      BB: 7,     // Barbados
      BY: 9,     // Belarus
      BE: 9,     // Belgium
      BZ: 7,     // Belize
      BJ: 8,     // Benin
      BT: 8,     // Bhutan
      BO: 8,     // Bolivia
      BA: 8,     // Bosnia and Herzegovina
      BW: 8,     // Botswana
      BR: 11,    // Brazil
      IO: 7,     // British Indian Ocean Territory
      BN: 7,     // Brunei
      BG: 9,     // Bulgaria
      BF: 8,     // Burkina Faso
      BI: 8,     // Burundi
      KH: 9,     // Cambodia
      CM: 9,     // Cameroon
      CA: 10,    // Canada
      CV: 7,     // Cape Verde
      BQ: 7,     // Caribbean Netherlands
      CF: 8,     // Central African Republic
      TD: 8,     // Chad
      CL: 9,     // Chile
      CN: 11,    // China
      CO: 10,    // Colombia
      KM: 8,     // Comoros
      CG: 9,     // Congo
      CD: 9,     // Congo
      CR: 8,     // Costa Rica
      CI: 10,    // Côte d'Ivoire
      HR: 9,     // Croatia
      CU: 8,     // Cuba
      CW: 7,     // Curaçao
      CY: 8,     // Cyprus
      CZ: 9,     // Czech Republic
      DK: 8,     // Denmark
      DJ: 8,     // Djibouti
      DM: 7,     // Dominica
      DO: 10,    // Dominican Republic
      EC: 9,     // Ecuador
      EG: 10,    // Egypt
      SV: 8,     // El Salvador
      GQ: 9,     // Equatorial Guinea
      ER: 7,     // Eritrea
      EE: 8,     // Estonia
      ET: 9,     // Ethiopia
      FJ: 7,     // Fiji
      FI: 10,    // Finland
      FR: 9,     // France
      GF: 9,     // French Guiana
      PF: 8,     // French Polynesia
      GA: 8,     // Gabon
      GM: 7,     // Gambia
      GE: 9,     // Georgia
      DE: 10,    // Germany
      GH: 9,     // Ghana
      GR: 10,    // Greece
      GD: 10,    // Grenada
      GP: 9,     // Guadeloupe
      GU: 7,     // Guam
      GT: 8,     // Guatemala
      GN: 8,     // Guinea
      GW: 8,     // Guinea-Bissau
      GY: 8,     // Guyana
      HT: 8,     // Haiti
      HN: 8,     // Honduras
      HK: 8,     // Hong Kong
      HU: 9,     // Hungary
      IS: 7,     // Iceland
      IN: 10,    // India
      ID: 10,    // Indonesia
      IR: 10,    // Iran
      IQ: 10,    // Iraq
      IE: 9,     // Ireland
      IL: 9,     // Israel
      IT: 10,    // Italy
      JM: 10,    // Jamaica
      JP: 10,    // Japan
      JO: 9,     // Jordan
      KZ: 10,    // Kazakhstan
      KE: 9,     // Kenya
      KI: 7,     // Kiribati
      XK: 8,     // Kosovo
      KW: 8,     // Kuwait
      KG: 9,     // Kyrgyzstan
      LA: 8,     // Laos
      LV: 8,     // Latvia
      LB: 8,     // Lebanon
      LS: 8,     // Lesotho
      LR: 8,     // Liberia
      LY: 8,     // Libya
      LI: 9,     // Liechtenstein
      LT: 8,     // Lithuania
      LU: 9,     // Luxembourg
      MO: 8,     // Macau
      MK: 8,     // Macedonia
      MG: 9,     // Madagascar
      MW: 8,     // Malawi
      MY: 9,     // Malaysia
      MV: 7,     // Maldives
      ML: 8,     // Mali
      MT: 8,     // Malta
      MH: 7,     // Marshall Islands
      MQ: 9,     // Martinique
      MR: 8,     // Mauritania
      MU: 8,     // Mauritius
      MX: 10,    // Mexico
      FM: 7,     // Micronesia
      MD: 8,     // Moldova
      MC: 9,     // Monaco
      MN: 8,     // Mongolia
      ME: 8,     // Montenegro
      MA: 12,    // Morocco
      MZ: 12,    // Mozambique
      MM: 10,    // Myanmar
      NA: 12,    // Namibia
      NR: 9,     // Nauru
      NP: 11,    // Nepal
      NL: 10,    // Netherlands
      NC: 9,     // New Caledonia
      NZ: 11,    // New Zealand
      NI: 8,     // Nicaragua
      NE: 8,     // Niger
      NG: 11,    // Nigeria
      KP: 10,    // North Korea
      NO: 8,     // Norway
      OM: 9,     // Oman
      PK: 10,    // Pakistan
      PW: 7,     // Palau
      PS: 10,    // Palestine
      PA: 8,     // Panama
      PG: 9,     // Papua New Guinea
      PY: 9,     // Paraguay
      PE: 11,    // Peru
      PH: 10,    // Philippines
      PL: 9,     // Poland
      PT: 9,     // Portugal
      PR: 10,    // Puerto Rico
      QA: 9,     // Qatar
      RE: 9,     // Réunion
      RO: 10,    // Romania
      RU: 10,    // Russia
      RW: 9,     // Rwanda
      KN: 10,    // Saint Kitts and Nevis
      LC: 10,    // Saint Lucia
      VC: 10,    // Saint Vincent and the Grenadines
      WS: 7,     // Samoa
      SM: 11,    // San Marino
      ST: 9,     // São Tomé and Príncipe
      SA: 9,     // Saudi Arabia
      SN: 9,     // Senegal
      RS: 10,    // Serbia
      SC: 10,    // Seychelles
      SL: 9,     // Sierra Leone
      SG: 8,     // Singapore
      SK: 9,     // Slovakia
      SI: 8,     // Slovenia
      SB: 8,     // Solomon Islands
      SO: 8,     // Somalia
      ZA: 9,     // South Africa
      KR: 10,    // South Korea
      SS: 9,     // South Sudan
      ES: 9,     // Spain
      LK: 9,     // Sri Lanka
      SD: 9,     // Sudan
      SR: 8,     // Suriname
      SZ: 9,     // Swaziland
      SE: 10,    // Sweden
      CH: 9,     // Switzerland
      SY: 9,     // Syria
      TW: 9,     // Taiwan
      TJ: 9,     // Tajikistan
      TZ: 10,    // Tanzania
      TH: 9,     // Thailand
      TL: 8,     // Timor-Leste
      TG: 8,     // Togo
      TO: 7,     // Tonga
      TT: 10,    // Trinidad and Tobago
      TN: 8,     // Tunisia
      TR: 10,    // Turkey
      TM: 8,     // Turkmenistan
      TV: 5,     // Tuvalu
      UG: 12,    // Uganda
      UA: 12,    // Ukraine
      AE: 12,    // United Arab Emirates
      GB: 10,    // United Kingdom
      US: 10,    // United States
      UY: 10,    // Uruguay
      UZ: 9,     // Uzbekistan
      VU: 7,     // Vanuatu
      VA: 12,    // Vatican City
      VE: 11,    // Venezuela
      VN: 10,    // Vietnam
      YE: 9,     // Yemen
      ZM: 9,     // Zambia
      ZW: 10,    // Zimbabwe
    };

    const validPhoneLength = validPhoneLengths[countryCode];
    return validPhoneLength && phoneDigits.length === validPhoneLength;
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);
    setCountryCode(country.countryCode);

    // Check for valid phone number only if the input is not empty
    if (value.trim() !== '') {
      const isValidPhone = validatePhone(value, country.countryCode);
      setPhoneError(!isValidPhone);
    } else {
      // Reset the phoneError when the input is empty
      setPhoneError(false);
    }
  };

  const validateMessage = (value) => {
    let error = '';

    if (!value) {
      error = 'Message is required';
    } else if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }

    setMessageError(error);
    return !error;
  };

  const handleMessageBlur = () => {
    setShowMessageError(true);
  };

  const handleMessageChange = (event) => {
    const value = event.target.value;
    setMessage(value);
    setMessageError(!validateMessage(value));
  };

  const handleSendMessageClick = async (event) => {
    event.preventDefault();

    if (!name) {
      setNameError('Full Name is required');
      setShowNameError(true);
      alert('Name is required.');
      return;
    }

    if (!email) {
      setEmailError(true);
      setShowEmailError(true);
      alert('Email ID is required.');
      return;
    }

    if (!phone) {
      setPhoneError(true);
      setShowPhoneError(true);
      alert(`Phone is required.`);
      return;
    }

    if (!message) {
      setMessageError('Message is required.');
      setShowMessageError(true);
      return;
    }

    try {
      const createContactResponse = await createContactUs({
        name: name,
        number: phone,
        email: email,
        message: message,
      });

      if (createContactResponse) {
        setShowModal(true);
        const data = createContactResponse.data;
        // Reset form values
        setName('');
        setNameError('');
        setPhone('');
        setPhoneError('');
        setEmail('');
        setMessageError('');
        setMessage('');
        setMessageError('');

        // Show the modal on send message button
        if (sendmessage === true) {
          setShowModal(true);
        }
      } else {
        console.log('Failed to create Contact US');
      }
    } catch (error) {
      console.log('Error creating Contact US:', error);
    }
  };

  return (
    <div>
      <div className="contact-area pd-top-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-lg-center text-left">
                <h2 className="title">Get In Touch!</h2>
                <p>Thank you for visiting Meditravels website. We appreciate your interest in our services.</p>
                <p>
                  If you have any queries or would like to book an appointment, please don't hesitate to get in touch
                  with us. Our team of experts is available to assist you in any way possible.
                </p>
                <p>We aim to respond to all queries within 48 hours, so you can expect a prompt and efficient service from us.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-5 offset-xl-1 col-lg-6">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/meditravels-3b054.appspot.com/o/About%20Us%2FContact%20Us.png?alt=media&token=56cbd4d8-c8b6-4129-a2e2-52bc5a335af3"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <form className="tp-form-wrap">
                <div className="row">
                  <div className="col-md-6">
                    <label className="single-input-wrap style-two">
                      <span className="single-input-title">Full Name</span>
                      <input
                        type="text"
                        name="name"
                        placeholder="Full Name"
                        value={name}
                        onChange={handleNameChange}
                        onBlur={handleNameBlur}
                      />
                      {nameError && showNameError && <p className="error">{nameError}</p>}
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label className="single-input-wrap style-two-phone">
                      <span className="single-input-title">Number</span>
                      <div className="phone-input-container">
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={phone}
                          onChange={handlePhoneChange}
                          country="in"
                          inputProps={{
                            required: true,
                            placeholder: "Enter phone number"
                          }}
                          searchable={false}
                        />
                      </div>
                      {showPhoneError && phoneError && <p className="error">Please enter a valid phone number with the selected country code.</p>}
                    </label>
                  </div>
                  <div className="col-lg-12">
                    <label className="single-input-wrap style-two">
                      <span className="single-input-title">Email</span>
                      <input
                        type="text"
                        name="email"
                        placeholder="Enter your Email ID"
                        value={email}
                        onBlur={handleEmailBlur}
                        onChange={handleEmailChange}
                      />
                      {emailError && showEmailError && <p className="error">Please enter a valid email</p>}
                    </label>
                  </div>
                  <div className="col-lg-12">
                    <label className="single-input-wrap style-two">
                      <span className="single-input-title">Message</span>
                      <textarea
                        defaultValue={""}
                        name="message"
                        placeholder="Enter your Reviews or Questions here"
                        onChange={handleMessageChange}
                        onBlur={handleMessageBlur}
                        value={message}
                      />
                      {showMessageError && messageError && <p className="error-msg">{messageError}</p>}
                    </label>
                  </div>
                  <div className="col-12">
                    <input
                      type="submit"
                      className="btn btn-yellow"
                      value="Send Message"
                      onClick={handleSendMessageClick}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={() => setShowModal(false)} className="newsletter-popup" backdrop="static">
          <Modal.Header>
            <Modal.Title>Thank you for reaching out to us! We appreciate your interest and will respond to your message as soon as possible.</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button className="btn btn-yellow" variant="secondary" onClick={() => setShowModal(false)} label='Ok'>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="contact-info-area pd-top-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 order-lg-12">
              <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d230.66074291859215!2d70.8216658!3d22.3320252!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959b75be4f6f9f3%3A0x61b0f209a9195b9d!2sMEDITRAVELS!5e0!3m2!1sen!2sin!4v1687929770500!5m2!1sen!2sin"></iframe>
              {/* <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55137.3051325513!2d-97.76825118838518!3d30.263256963734733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644b599a0cc032f%3A0x5d9b464bd469d57a!2sAustin%2C%20TX%2C%20USA!5e0!3m2!1sen!2sbd!4v1572085289886!5m2!1sen!2sbd" /> */}
            </div>
            <div className="col-xl-3 col-lg-4 order-lg-1">
              <div className="contact-info bg-gray">
                <p>
                  <i className="fa fa-map-marker" />
                  <span>Pushkardham Avenue - 2, Opp. Nagbai Temple, Morbi Road. Raikot - 360003</span>
                </p>
                {/* <p>
                          <i className="fa fa-clock-o" /> 
                          <span>Office Hour 9:00 to 7:00 Sunday 10:00 to 5:00</span>
                        </p> */}
                <p>
                  <i className="fa fa-envelope" />
                  <span>Email: <a href="mailto:admin@meditravels.in">admin@meditravels.in</a></span>
                </p>
                <p>
                  <i className="fa fa-phone" />
                  <span>
                    {/* sell phone: <span>(+88) 0172 570051</span> <br /> */}
                    telephone: <a href="tel:+9199 135 136 84">+91 99 135 136 84</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact

