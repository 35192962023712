import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
// import PageHeader from '../global-components/page-header';
import ParagraphSkeleton2 from "../skeleton/ParagraphSkeleton2";
import { Modal, Button } from 'react-bootstrap';
import { fetchTreatmentDetailsData, checkTokenValidity } from '../../api';

// class Treatments extends Component{

function Treatments() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalRole, setModalRole] = useState('');
  const [treatmentData, setTreatmentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const isMounted = useRef(true);
  const history = useHistory();
  // const { subsubTreatment_id, image } = location.state
  const subsubTreatment_id = localStorage.getItem("subsubTreatment_id");
  const userDataJSON = localStorage.getItem("user");
  const Role = localStorage.getItem("userrole");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const userRole = userData && userData.role || Role;

  useEffect(() => {
    const token = localStorage.getItem("token");
  
    if (token) {
      const checkToken = async () => {
        try {
          const isValid = await checkTokenValidity(token);
  
          if (isValid) {
            // Token is valid, user is logged in
            setLoggedIn(true);
          } else {
            // Token is invalid, user is not logged in
            setLoggedIn(false);
            localStorage.removeItem("token");
            localStorage.clear();
          }
        } catch (error) {
          console.error("Error checking token:", error);
          setLoggedIn(false);
          localStorage.removeItem("token");
          localStorage.clear();
        }
      };
  
      checkToken();
    } else {
      // Token is missing, user is not logged in
      setLoggedIn(false);
      localStorage.removeItem("token");
    }
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page on route change
    });
    return () => {
      isMounted.current = false;
    };
  }, [history]);

  useEffect(() => {
    if (subsubTreatment_id) {
        fetchTreatmentDetailsData(subsubTreatment_id)
            .then(({ treatmentData, treatmentDetailsId }) => {
                if (isMounted.current) {
                    setTreatmentData(treatmentData);
                    setLoading(false);
                    localStorage.setItem("treatment_details_id", treatmentDetailsId);
                    console.log(
                        "printing treatment_details_id in local storage",
                        treatmentDetailsId
                    );
                }
            })
            .catch((error) => console.log("error", error));
    }
}, [subsubTreatment_id]);

  useEffect(() => {
    var $d_details_main_slider = window.$(".destinations-details-main-slider");
    $d_details_main_slider.slick({
      slidesToShow: 1,
      dots: false,
      slidesToScroll: 1,
      speed: 400,
      loop: true,
      fade: true,
      autoplay: false,
      prevArrow:
        '<span class="slick-prev"><i class="la la-long-arrow-left"></i></span>',
      nextArrow:
        '<span class="slick-next"><i class="la la-long-arrow-right"></i></span>',
      appendArrows: window.$(
        ".destinations-details-main-slider-controls .slider-nav"
      ),
    });
    //active progress
    var $progressBar = window.$(".d-list-progress");
    var $progressBarLabel = window.$(".slider__label");
    $d_details_main_slider.on(
      "beforeChange",
      function (event, slick, currentSlide, nextSlide) {
        var calc = (nextSlide / (slick.slideCount - 1)) * 100;
        $progressBar
          .css("background-size", calc + "% 100%")
          .attr("aria-valuenow", calc);
        $progressBarLabel.text(calc + "% completed");
      }
    );
    //active count list
    window
      .$(".destinations-details-main-slider")
      .on("beforeChange", function (event, slick, currentSlide, nextSlide) {
        var firstNumber = check_number(++nextSlide);
        window
          .$(
            ".destinations-details-main-slider-controls .slider-extra .text .first"
          )
          .text(firstNumber);
      });
    var smSlider = window
      .$(".destinations-details-main-slider")
      .slick("getSlick");
    var smSliderCount = smSlider.slideCount;
    window
      .$(".destinations-details-main-slider-controls .slider-extra .text .last")
      .text(check_number(smSliderCount));
    function check_number(num) {
      var IsInteger = /^[0-9]+$/.test(num);
      return IsInteger ? "0" + num : null;
    }
  }, [treatmentData]);


  let publicUrl = process.env.PUBLIC_URL + "/";
  // let imagealt = 'image';
  const imgsrc = publicUrl + "assets/img/bg/1.png";

  const handleConsultUsClicklogin = (role) => {
    if (role === 'Patient') {
      history.push("/patient/patientdashboard");
    } else if (role === 'Doctor' || role === 'PRO') {
      setModalRole(role);
      setShowModal(true);
    }
  };

  const handleConsultUsClick = (role) => {
    localStorage.setItem("selectedRole", role);
  };

  return (
    <>
      {/* <PageHeader headertitle={""} BImage={imgsrc} /> */}

      {loading ? (
        <ParagraphSkeleton2 />
      ) : (
        <>
          {treatmentData.length > 0 && (
            <div className="container mt-3 treatment-details-main">
              <div className="row destinations-details-location-name mt-3">
                <div className="col-lg-12">
                  <h3>{treatmentData[0].content}</h3>
                  <p>{treatmentData[1].content}</p>
                </div>
                <div className="col-lg-12">
                  <h3>{treatmentData[2].content}</h3>
                  <p>{treatmentData[3].content}</p>
                </div>
              </div>
              <div className="destinations-client-review-slider tp-common-slider-style">
                <div className="tretmentdetails-col-2">
                  <div className="d-client-review-slider-item">
                    <div className="single-destination-grid text-center">
                      <div className="thumb">
                        <img
                          src={
                            "https://meditravels.s3.amazonaws.com/about/5668b3bf-57ce-4f2d-9c7a-8ce417095186-Investigation%20on%20disease-01.jpg"
                          }
                          alt="img"
                        />
                      </div>
                      <div className="details">
                        <div className="details-head-box">
                          <h4 className="title">
                            {treatmentData[4].Data[0].Title}
                          </h4>
                          <p className="content">
                            {treatmentData[4].Data[0].Description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-client-review-slider-item">
                    <div className="single-destination-grid text-center">
                      <div className="thumb">
                        <img
                          src={
                            "https://meditravels.s3.amazonaws.com/about/fc58f175-3670-4060-b6a2-50bc4e581023-Treatment%20on%20Disease%20copy-01.jpg"
                          }
                          alt="img"
                        />
                      </div>
                      <div className="details">
                        <div className="details-head-box">
                          <h4 className="title">
                            {treatmentData[4].Data[1].Title}
                          </h4>
                          <p className="content">
                            {treatmentData[4].Data[1].Description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tretmentdetails-col-2">
                  <div className="d-client-review-slider-item">
                    <div className="single-destination-grid text-center">
                      <div className="thumb">
                        <img
                          src={
                            "https://meditravels.s3.amazonaws.com/about/e6c49280-acfd-4fc9-987d-93a31073fc85-Total%20stay-01.jpg"
                          }
                          alt="img"
                        />
                      </div>
                      <div className="details">
                        <div className="details-head-box">
                          <h4 className="title">
                            {treatmentData[4].Data[2].Title}
                          </h4>
                          <p className="content">
                            {treatmentData[4].Data[2].Description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-client-review-slider-item">
                    <div className="single-destination-grid text-center">
                      <div className="thumb">
                        <img
                          src={
                            "https://meditravels.s3.amazonaws.com/about/3ee8ea45-d88f-47b8-8819-50d61a791e7b-Treatment%20cost-01.jpg"
                          }
                          alt="img"
                        />
                      </div>
                      <div className="details">
                        <div className="details-head-box">
                          <h4 className="title">
                            {treatmentData[4].Data[3].Title}
                          </h4>
                          <p className="content">
                            {treatmentData[4].Data[3].Description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="trip-plan-area">
                <div className="row justify-content-center">
                  <div className="col-lg-5 col-md-6 mb-3">
                    <h4 className="single-page-small-title">
                      {treatmentData[5].Data[0].Title}
                    </h4>

                    <div className="single-trip-plan">
                      <div className="thumb single-trip-plan-left">
                        <img
                          src={
                            "https://meditravels.s3.amazonaws.com/about/8e82e907-5035-4ffa-8ca5-83a4ab575b6f-Success%20rate-01.jpg"
                          }
                          alt="blog"
                        />
                      </div>
                      <div className="single-trip-plan-right">
                        {/* <ul className="tp-list-meta border-bt-dot">
                              <li><i className="fa fa-calendar-o" /> 8oct</li>
                              <li><i className="fa fa-clock-o" /> 4 days</li>
                              <li><i className="fa fa-star" /> 4.3</li>
                            </ul> */}
                        <div className="tp-price-meta tp-price-meta-cl">
                          <p className="content-treatment-details">
                            {treatmentData[5].Data[0].Description}
                          </p>
                          {/* <p>Price</p> */}
                          {/* <h2>high <small>$</small></h2> */}
                          {/* <del>620<span>$</span></del> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 mb-3">
                    <h4 className="single-page-small-title">
                      {treatmentData[5].Data[1].Title}
                    </h4>

                    <div className="single-trip-plan">
                      <div className="thumb single-trip-plan-left">
                        <img
                          src={
                            "https://meditravels.s3.amazonaws.com/about/f0107a7d-da6b-4411-8615-e7710b3a2bd3-Chance%20of%20Recurrence-01.jpg"
                          }
                          alt="blog"
                        />
                      </div>
                      <div className="single-trip-plan-right">
                        {/* <ul className="tp-list-meta border-bt-dot">
                              <li><i className="fa fa-calendar-o" /> 8oct</li>
                              <li><i className="fa fa-clock-o" /> 4 days</li>
                              <li><i className="fa fa-star" /> 4.3</li>
                            </ul> */}
                        <div className="tp-price-meta tp-price-meta-cl">
                          <p className="content-treatment-details">
                            {treatmentData[5].Data[1].Description}
                          </p>
                          {/* <p>Price</p> */}
                          {/* <h2>high <small>$</small></h2> */}
                          {/* <del>620<span>$</span></del> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 mb-3">
                    <h4 className="single-page-small-title">
                      {treatmentData[5].Data[2].Title}
                    </h4>

                    <div className="single-trip-plan">
                      <div className="thumb single-trip-plan-left">
                        <img
                          src={
                            "https://meditravels.s3.amazonaws.com/about/1450e419-941c-4de8-b096-e5914126df63-Total%20Combined%20Experience-01.jpg"
                          }
                          alt="blog"
                        />
                      </div>
                      <div className="single-trip-plan-right">
                        {/* <ul className="tp-list-meta border-bt-dot">
                              <li><i className="fa fa-calendar-o" /> 8oct</li>
                              <li><i className="fa fa-clock-o" /> 4 days</li>
                              <li><i className="fa fa-star" /> 4.3</li>
                            </ul> */}
                        <div className="tp-price-meta tp-price-meta-cl">
                          <p className="content-treatment-details">
                            {treatmentData[5].Data[2].Description}
                          </p>
                          {/* <p>Price</p> */}
                          {/* <h2>high <small>$</small></h2> */}
                          {/* <del>620<span>$</span></del> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5 col-md-6 mb-3">
                    <h4 className="single-page-small-title">
                      {treatmentData[5].Data[3].Title}
                    </h4>

                    <div className="single-trip-plan">
                      <div className="thumb single-trip-plan-left">
                        <img
                          src={
                            "https://meditravels.s3.amazonaws.com/about/844093c9-878e-4f50-af50-175d27652d56-Total%20Surgery%20Performed-01.jpg"
                          }
                          alt="blog"
                        />
                      </div>
                      <div className="single-trip-plan-right">
                        {/* <ul className="tp-list-meta border-bt-dot">
                              <li><i className="fa fa-calendar-o" /> 8oct</li>
                              <li><i className="fa fa-clock-o" /> 4 days</li>
                              <li><i className="fa fa-star" /> 4.3</li>
                            </ul> */}
                        <div className="tp-price-meta tp-price-meta-cl">
                          <p className="content-treatment-details">
                            {treatmentData[5].Data[3].Description}
                          </p>
                          {/* <p>Price</p> */}
                          {/* <h2>high <small>$</small></h2> */}
                          {/* <del>620<span>$</span></del> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row destinations-details-location-name mt-3">
            <div className="col-lg-12">
              <h3>{treatmentData[7].content}</h3>
              <p>{treatmentData[8].content}</p>
            </div>
          </div> */}

              {/* <div className="trip-plan-area price-box" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="center-card" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div className="row justify-content-center">
                    <div >
                      <h4 className="single-page-small-title1">Price of Treatment</h4>
                    </div>
                  </div>
                  <div className="single-trip-plan1">
                    <div className="thumb single-trip-plan-left">
                      <img src={"https://meditravels.s3.amazonaws.com/about/f0107a7d-da6b-4411-8615-e7710b3a2bd3-Chance%20of%20Recurrence-01.jpg"} alt="blog" />
                    </div>
                    <div className="single-trip-plan-right">
                      <div className="tp-price-meta tp-price-meta-cl">
                        <p className='content-treatment-details'>US$ 25.99</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="row justify-content-center mt-5 ">
            <div className="col-xl-10">
              <div className="destinations-details-main-slider-wrap">
                <div className="destinations-details-main-slider">
                  <div className="d-details-main-slider-item">
                    <img src={publicUrl + "assets/img/others/3.png"} alt="img" />
                  </div>
                  <div className="d-details-main-slider-item">
                    <img src={publicUrl + "assets/img/others/2.png"} alt="img" />
                  </div>
                  <div className="d-details-main-slider-item">
                    <img src={publicUrl + "assets/img/others/4.png"} alt="img" />
                  </div>
                </div>
                <div className="destinations-details-main-slider-controls">
                  <div className="slider-nav tp-control-nav" />
                  slider-nav
                  <div className="slider-extra tp-slider-extra">
                    <div className="text">
                      <span className="first">01 </span>
                      <span className="last">05</span>
                    </div>
                    text
                    <div className="d-list-progress" role="progressbar" aria-valuemin={0} aria-valuemax={100}>
                      <span className="slider__label sr-only" />
                    </div>
                  </div>
                  slider-extra
                </div>
              </div>
            </div>
          </div> */}

              <div className='consult-btn consult-btn-treatment'>
                {!loggedIn && (
                  <Link
                    className="btn btn-yellow"
                    to="/signin"
                    onClick={() => handleConsultUsClick("Patient")}
                  >
                    Consult Us <i className="fa fa-paper-plane" />
                  </Link>
                )}
                {loggedIn && userRole === 'Patient' && (
                  <Link className="btn btn-yellow" to="/patient/patientdashboard">
                    Consult Us <i className="fa fa-paper-plane" />
                  </Link>
                )}
                {loggedIn && (userRole === 'Doctor' || userRole === 'PRO') && (
                  <button className="btn btn-yellow" onClick={() => handleConsultUsClicklogin(userRole)}>
                    Consult Us <i className="fa fa-paper-plane" />
                  </button>
                )}
              </div>

              <Modal show={showModal} onHide={() => setShowModal(false)} className='newsletter-popup' backdrop="static">
                <Modal.Header>
                  <Modal.Title>Attention</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {modalRole === 'PRO' || modalRole === 'Doctor' ? (
                    <p>Please sign up or log in as a Patient to access this feature.</p>
                  ) : null}
                </Modal.Body>
                <Modal.Footer>
                  <Button className="btn btn-yellow" variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Treatments;
