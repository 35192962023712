import React, { useState, useEffect } from 'react';
import Page from '../pro-components/Page';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import CustomTextField from '../pro-components/CTextField';
import FileUpload from '../pro-components/CFileUpload';
import Action from '../pro-components/Action';
import { useHistory } from 'react-router-dom';
import CustomButton from '../pro-components/CButton';
import Ticket from '../pro-components/ticket';
import CustomModal from "../../global-components/modal";
import { updateDoctor1, uploadMedia } from '../../../api';

const RelevantExprience = () => {
    const [bachelordegreeInput, setBachelorDegreeInput] = useState('');
    const [bachelordegreeImg, setBachelorDegreeImg] = useState(null);
    const [bachelordegreePreviewUrl, setBachelordegreePreviewUrl] = useState(null);
    const [bachelordegreeInputError, setBachelorDegreeInputError] = useState('');
    const [showBachelordegreeInputError, setshowBachelorDegreeInputError] = useState(false);
    const [masterDegreeInput, setMasterDegreeInput] = useState('');
    const [masterDegreeImg, setMasterDegreeImg] = useState(null);
    const [masterDegreePreviewUrl, setMasterDegreePreviewUrl] = useState(null);
    const [masterdegreeInputError, setMasterDegreeInputError] = useState('');
    const [showMasterdegreeInputError, setshowMasterDegreeInputError] = useState(false);
    const [superDegreeInput, setSuperDegreeInput] = useState('');
    const [superDegreeImg, setSuperDegreeImg] = useState(null);
    const [superDegreePreviewUrl, setSuperDegreePreviewUrl] = useState(null);
    const [superdegreeInputError, setSuperDegreeInputError] = useState('');
    const [showSuperdegreeInputError, setshowSuperDegreeInputError] = useState(false);
    const [sectionData, setSectionData] = useState('');
    const pdfOpenParams = 'toolbar=0&navpanes=0&scrollbar=0';
    const [imagePreviewUrl, setImagePreviewUrl] = useState("");
    const [pdfPreviewUrl, setPdfPreviewUrl] = useState("");
    const [imagePreviewUrl1, setImagePreviewUrl1] = useState("");
    const [pdfPreviewUrl1, setPdfPreviewUrl1] = useState("");
    const [imagePreviewUrl2, setImagePreviewUrl2] = useState("");
    const [pdfPreviewUrl2, setPdfPreviewUrl2] = useState("");
    const [errorModalData, setErrorModalData] = useState({
        title: "",
        message: "",
        show: false,
    });

    const showErrorModal = (title, message) => {
        setErrorModalData({
            title: title,
            message: message,
            show: true,
        });
    };
    let history = useHistory();
    const minscript = document.createElement("script");
    minscript.async = true;
    document.body.appendChild(minscript);
    const previousPageURL = '/doc/section3';

    const updateSectionData = async (sectionData) => {
        const docId = localStorage.getItem("docId");
        console.log("doc id from local storage", docId);
        if (!docId) {
            console.log("doc ID not found in local storage");
            return;
        }
        try {
            const existingSectionData = JSON.parse(localStorage.getItem("docfirstPageData"));
            const sectionDataObject = [
                {
                    0: existingSectionData, // Include the first page data at index 0
                },
                {
                    1: JSON.parse(localStorage.getItem('docsecondPageData')), // Include the second page data at index 1
                },
                {
                    2: JSON.parse(localStorage.getItem('docthirdPageData')), // Include the third page data at index 2
                },
                {
                    3: sectionData, // Include the fourth page data at index 3
                },
            ]
            const updatedSectionData = await updateDoctor1(docId, sectionDataObject);
        } catch (error) {
            console.error(error);
        }
    };

    const handleNextClick = async (event) => {
        event.preventDefault();

        let hasError = false;

        // Validate the Bachelor Degree input
        const isBachelorDegreeInputValid = validateBachelorDegreeInput(bachelordegreeInput);
        console.log("isBachelorDegreeInputValid:", isBachelorDegreeInputValid);

        if (!isBachelorDegreeInputValid) {
            // The Bachelor Degree input is required but not filled
          
            showErrorModal("Alert", "Bachelor Degree of Education is required.");
            hasError = true;
        } if (!bachelordegreeImg) {
            // Check if an image is selected for Bachelor Degree (after input validation)
          
            showErrorModal("Alert", "Please upload an image for the Bachelor Degree.");
            hasError = true;
        }
        if (!validateMasterDegreeInput(masterDegreeInput)) {
            showErrorModal("Alert", "Please upload an image for the Master Degree.");
            setMasterDegreePreviewUrl(true);
            hasError = true;
        }
        if (!validateSuperDegreeInput(superDegreeInput)) {
            showErrorModal("Alert", "Please upload an image for the Super Speciality Degree.");
            setSuperDegreePreviewUrl(true);
            hasError = true;
        }
        if (hasError) {
            return;
        }
        // Store all the data in local storage
        const docfourthPageData = {
            bachelordegreeInput: bachelordegreeInput,
            bachelordegreeImg: bachelordegreeImg,
            masterDegreeInput: masterDegreeInput,
            masterDegreeImg: masterDegreeImg,
            superDegreeInput: superDegreeInput,
            superDegreeImg: superDegreeImg,
        };
        localStorage.setItem('docfourthPageData', JSON.stringify(docfourthPageData));

        // Update the section data in the backend
        await updateSectionData(docfourthPageData);

        history.push('/doc/section5');
    };

    // const validateBachelorDegreeInput = (value) => {
    //     setBachelorDegreeInput(value);
    //     let error = '';
    //     if (!value) {
    //         error = 'Bachelor Degree of Education is required';
    //     } else if (/\s{2,}/.test(value)) {
    //         error = 'Multiple consecutive spaces are not allowed';
    //     } else if (!bachelordegreeImg) {
    //         error = 'Please upload an image for the Bachelor Degree';
    //     }
    //     setBachelorDegreeInputError(error);

    //     return !error; // Return true if there is no error
    // };

    const validateBachelorDegreeInput = (value) => {
        setBachelorDegreeInput(value);
        let error = '';

        if (!value.trim()) {
            error = 'Bachelor Degree of Education is required';
        } else if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        }
        // else if (!bachelordegreeImg) {
        //     error = 'Please upload an image for the Bachelor Degree';
        // }

        setBachelorDegreeInputError(error);

        return !error; // Return true if there is no error
    };


    const handleBachelorDegreeChange = (event) => {
        const bachelorDegreeInput = event.target.value;
        setBachelorDegreeInput(bachelorDegreeInput);
        validateBachelorDegreeInput(bachelorDegreeInput);
    };

    const handleBachelorDegreeBlur = () => {
        setshowBachelorDegreeInputError(true);
    };


    const validateMasterDegreeInput = (value) => {
        setMasterDegreeInput(value);
        let error = '';
        if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        } else if (value && !masterDegreeImg) {
            error = 'Please upload an image for the Master Degree';
        }
        setMasterDegreeInputError(error);

        return !error; // Return true if there is no error
    };

    const handleMasterDegreeChange = (event) => {
        const masterDegreeInput = event.target.value;
        setMasterDegreeInput(masterDegreeInput);
        validateMasterDegreeInput(masterDegreeInput);
    };

    const handleMasterDegreeBlur = () => {
        setshowMasterDegreeInputError(true);
    };

    const validateSuperDegreeInput = (value) => {
        setSuperDegreeInput(value);
        let error = '';
        if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        } else if (value && !superDegreeImg) {
            error = 'Please upload an image for the Super Degree';
        }
        setSuperDegreeInputError(error);

        return !error; // Return true if there is no error
    };

    const handleSuperDegreeChange = (event) => {
        const superDegreeInput = event.target.value;
        setSuperDegreeInput(superDegreeInput);
        validateSuperDegreeInput(superDegreeInput);
    };

    const handleSuperDegreeBlur = () => {
        setshowSuperDegreeInputError(true);
    };

    const handleFileUpload = async (file) => {
        setBachelordegreePreviewUrl(URL.createObjectURL(file));

        try {
            const mediaType = "DOC";
      
            const uploadedImagePath = await uploadMedia(file, mediaType);
      
            console.log("image url from doc2", uploadedImagePath);

            if (file.type.startsWith('image/')) {
                setImagePreviewUrl(URL.createObjectURL(file));
                setPdfPreviewUrl(null);
                setBachelorDegreeImg(uploadedImagePath);
            }
            else if (file.type === 'application/pdf') {
                setPdfPreviewUrl(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
                setImagePreviewUrl(null);
                setBachelorDegreeImg(uploadedImagePath);
            }

            // Save the image URL in the database
            await saveBachelorDegreeImageUrlToDatabase(uploadedImagePath); // Make sure to implement this function
        } catch (error) {
            console.error(error);
        }
    };

    const saveBachelorDegreeImageUrlToDatabase = async (imageUrl) => {
        const docId = localStorage.getItem('docId');
        const sectionData = { bachelordegreeImg: imageUrl };
  
        try {
          const response = await updateDoctor1(docId, sectionData);  
    
          console.log("Image URL saved in the database:", response.data);

        } catch (error) {
            console.error(error);
        }
        setSectionData((prevData) => ({
            ...prevData,
            bachelordegreeImg: imageUrl,
        }));
    };

    const handleFileUploadMaster = async (file) => {
        setMasterDegreePreviewUrl(URL.createObjectURL(file));

        try {
            const mediaType = "DOC";
      
            const uploadedImagePath = await uploadMedia(file, mediaType);
      
            setMasterDegreeImg(uploadedImagePath);
            console.log('image url from doc', uploadedImagePath);

            if (file.type.startsWith('image/')) {
                setImagePreviewUrl1(URL.createObjectURL(file));
                setPdfPreviewUrl1(null);
                setMasterDegreeImg(uploadedImagePath);
            }
            else if (file.type === 'application/pdf') {
                setPdfPreviewUrl1(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
                setImagePreviewUrl1(null);
                setMasterDegreeImg(uploadedImagePath);
            }
            // setImageUrl(uploadedImagePath); // Set the image URL in the state
            await saveMasterDegreeImageUrlToDatabase(uploadedImagePath); // Make sure to implement this function
        } catch (error) {
            console.error(error);
        }
    };

    const saveMasterDegreeImageUrlToDatabase = async (imageUrl) => {
        const docId = localStorage.getItem('docId');

        const sectionData = { masterDegreeImg: imageUrl };
  
        try {
          const response = await updateDoctor1(docId, sectionData);  

            console.log('Image URL saved in the database:', response.data);
        } catch (error) {
            console.error(error);
        }
        setSectionData((prevData) => ({
            ...prevData,
            masterDegreeImg: imageUrl,
        }));
    };

    const handleFileUploadSuper = async (file) => {
        setSuperDegreePreviewUrl(URL.createObjectURL(file));

        try {
            const mediaType = "DOC";
      
            const uploadedImagePath = await uploadMedia(file, mediaType);

            console.log('image url from doc', uploadedImagePath);

            if (file.type.startsWith('image/')) {
                setImagePreviewUrl2(URL.createObjectURL(file));
                setPdfPreviewUrl2(null);
                setSuperDegreeImg(uploadedImagePath);
            }
            else if (file.type === 'application/pdf') {
                setPdfPreviewUrl2(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
                setImagePreviewUrl2(null);
                setSuperDegreeImg(uploadedImagePath);
            }

            // Save the image URL in the database
            await saveSuperDegreeImageUrlToDatabase(uploadedImagePath); // Make sure to implement this function
        } catch (error) {
            console.error(error);
        }
    };

    const saveSuperDegreeImageUrlToDatabase = async (imageUrl) => {
        const docId = localStorage.getItem('docId');

        const sectionData = { superDegreeImg: imageUrl };
  
        try {
          const response = await updateDoctor1(docId, sectionData);  

            console.log('Image URL saved in the database:', response.data);
        } catch (error) {
            console.error(error);
        }
        setSectionData((prevData) => ({
            ...prevData,
            superDegreeImg: imageUrl,
        }));
    };

    useEffect(() => {
        const storedSectionData = JSON.parse(localStorage.getItem('docfourthPageData'));

        if (storedSectionData) {
            setBachelorDegreeInput(storedSectionData.bachelordegreeInput || '');
            setBachelorDegreeImg(storedSectionData.bachelordegreeImg);

            if (storedSectionData.bachelordegreeImg && storedSectionData.bachelordegreeImg.endsWith('.pdf')) {
                setPdfPreviewUrl(`https://docs.google.com/viewer?url=${encodeURIComponent(storedSectionData.bachelordegreeImg)}&embedded=true`);
            } else {
                setImagePreviewUrl(storedSectionData.bachelordegreeImg);
            }

            setMasterDegreeInput(storedSectionData.masterDegreeInput || '');
            setMasterDegreeImg(storedSectionData.masterDegreeImg);

            if (storedSectionData.masterDegreeImg && storedSectionData.masterDegreeImg.endsWith('.pdf')) {
                setPdfPreviewUrl1(`https://docs.google.com/viewer?url=${encodeURIComponent(storedSectionData.bachelordegreeImg)}&embedded=true`);
            } else {
                setImagePreviewUrl1(storedSectionData.masterDegreeImg);
            }

            setSuperDegreeInput(storedSectionData.superDegreeInput || '');
            setSuperDegreeImg(storedSectionData.superDegreeImg);

            if (storedSectionData.superDegreeImg && storedSectionData.superDegreeImg.endsWith('.pdf')) {
                setPdfPreviewUrl2(`https://docs.google.com/viewer?url=${encodeURIComponent(storedSectionData.superDegreeImg)}&embedded=true`);
            } else {
                setImagePreviewUrl2(storedSectionData.superDegreeImg);
            }
        }
    }, []);

    const handleClearForm = () => {
        setBachelorDegreeInput('');
        setBachelorDegreeInputError('');
        setshowBachelorDegreeInputError('');
        setBachelorDegreeImg('');
        setBachelordegreePreviewUrl('');
        setMasterDegreeInput('');
        setMasterDegreeImg('');
        setMasterDegreePreviewUrl('');
        setMasterDegreeInputError('');
        setshowMasterDegreeInputError('');
        setSuperDegreeInput('');
        setSuperDegreeImg('');
        setSuperDegreePreviewUrl('');
        setSuperDegreeInputError('');
        setshowSuperDegreeInputError('');
        setImagePreviewUrl("");
        setImagePreviewUrl1("");
        setImagePreviewUrl2("");
        setPdfPreviewUrl("")
        setPdfPreviewUrl1("")
        setPdfPreviewUrl2("")
    };

    const handleCloseErrorModal = () => {
        setErrorModalData({
            title: "",
            message: "",
            show: false,
        });
    };

    return (
        <Page>
            <FirstSection>
                <h4>Educational Background</h4>
            </FirstSection>
            <SectionComponent>
                <label className="sec-label">BACHELOR DEGREE*</label>
                <CustomTextField
                    id="outlined-basic"
                    placeholder="Bachelor Degree"
                    variant="outlined"
                    value={bachelordegreeInput}
                    onChange={handleBachelorDegreeChange}
                    onBlur={handleBachelorDegreeBlur}
                />
                <span className="error-msg">
                    {showBachelordegreeInputError && bachelordegreeInputError && (
                        <p className="error-msg">{bachelordegreeInputError}</p>
                    )}
                </span>
                <FileUpload onChange={handleFileUpload} value={bachelordegreeImg} />
                {imagePreviewUrl && (
                    <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                        <CustomButton type="button" onClick={() => setImagePreviewUrl(null)} // Reset the image preview URL
                        >X</CustomButton>
                    </div>
                        <div style={{ border: '1px solid black' }}>
                            <img src={imagePreviewUrl} alt="Selected" style={{ width: '100%' }} />
                        </div>
                    </>
                )}
                {pdfPreviewUrl && (
                    <>
                        <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                            <CustomButton
                                type="button"
                                onClick={() => setPdfPreviewUrl(null)} // Reset the PDF preview URL
                            >
                                X
                            </CustomButton>
                        </div>
                        <div style={{ border: '1px solid black' }}>
                            <iframe
                                src={pdfPreviewUrl}
                                title="PDF Preview"
                                style={{ width: '100%', height: '500px' }}
                            />
                        </div>
                    </>
                )}
            </SectionComponent>
            <SectionComponent>
                <label className="sec-label">MASTER DEGREE</label>
                <CustomTextField
                    id="outlined-basic"
                    placeholder="Master Degree"
                    variant="outlined"
                    value={masterDegreeInput}
                    onChange={handleMasterDegreeChange}
                    onBlur={handleMasterDegreeBlur}
                />
                <span className="error-msg">
                    {showMasterdegreeInputError && masterdegreeInputError && (
                        <p className="error-msg">{masterdegreeInputError}</p>
                    )}
                </span>
                <FileUpload onChange={handleFileUploadMaster} value={masterDegreeImg} />
                {imagePreviewUrl1 && (
                    <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                        <CustomButton type="button" onClick={() => setImagePreviewUrl1(null)} // Reset the image preview URL
                        >X</CustomButton>
                    </div>
                        <div style={{ border: '1px solid black' }}>
                            <img src={imagePreviewUrl1} alt="Selected" style={{ width: '100%' }} />
                        </div>
                    </>
                )}

                {pdfPreviewUrl1 && (
                    <>
                        <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                            <CustomButton type="button" onClick={() => setPdfPreviewUrl1(null)} // Reset the PDF preview URL
                            >X</CustomButton>
                        </div>
                        <div style={{ border: '1px solid black' }}>
                            <iframe
                                src={pdfPreviewUrl1}
                                title="PDF Preview"
                                style={{ width: '100%', height: '500px' }}
                            />
                        </div>
                    </>
                )}

            </SectionComponent>
            <SectionComponent>
                <label className="sec-label">SUPER SPECIALITY DEGREE</label>
                <CustomTextField
                    id="outlined-basic"
                    placeholder="Super Speciality Degree"
                    variant="outlined"
                    value={superDegreeInput}
                    onChange={handleSuperDegreeChange}
                    onBlur={handleSuperDegreeBlur}
                />
                <span className="error-msg">
                    {showSuperdegreeInputError && superdegreeInputError && (
                        <p className="error-msg">{superdegreeInputError}</p>
                    )}
                </span>
                <FileUpload onChange={handleFileUploadSuper} value={superDegreeImg} />
                {imagePreviewUrl2 && (
                    <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                        <CustomButton type="button" onClick={() => setImagePreviewUrl2(null)} // Reset the image preview URL
                        >X</CustomButton>
                    </div>
                        <div style={{ border: '1px solid black' }}>
                            <img src={imagePreviewUrl2} alt="Selected" style={{ width: '100%' }} />
                        </div>
                    </>
                )}
                {pdfPreviewUrl2 && (
                    <>
                        <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                            <CustomButton type="button" onClick={() => setPdfPreviewUrl2(null)} // Reset the PDF preview URL
                            >X</CustomButton>
                        </div>
                        <div style={{ border: '1px solid black' }}>
                            <iframe
                                src={pdfPreviewUrl2}
                                title="PDF Preview"
                                style={{ width: '100%', height: '500px' }}
                            />

                        </div>
                    </>
                )}
            </SectionComponent>
            <Action backLink={previousPageURL} onClickNext={handleNextClick} onClearForm={handleClearForm} />
            <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
                <Ticket variant="contained" color="secondary" section="Doctor: Application Form - Educational Background" />
            </div>
            <CustomModal
                show={errorModalData.show}
                onHide={handleCloseErrorModal}
                titleStyle={{ fontWeight: "bold" }}
                title={errorModalData.title}
                message={errorModalData.message}
            />
        </Page>
    );
};

export default RelevantExprience;