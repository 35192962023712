import { useState, useEffect, useRef } from "react";
import Button from "../Button";
import { auth, provider, messaging, sendEmailVerification } from './firebase.config';
import { signInWithPopup } from "firebase/auth";
import { Link, useHistory } from "react-router-dom";
import React from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { Radio, FormControlLabel } from "@mui/material";
import { Height } from "@mui/icons-material";
import CustomModal from "../../global-components/modal";
import { createPro, createPatient, createDoctor, signup, sendEmail, checkEmailExistence, loginUser, fetchPROData, fetchPRO2Data, fetchPatientData, fetchDoctorData, fetchDoctor2Data, updateUser, sendPasswordResetEmail, verifyOtp, resendOtp, resetPassword } from '../../../api';

const Signin = () => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [showEmailError, setshowEmailError] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showMsgModal, setShowMsgModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalAcknowledged, setModalAcknowledged] = useState(false);

  //forget password
  const [forgetEmail, setForgetEmail] = useState("");
  const [emailModal, setEmailModal] = useState(false);
  const [forgetEmailError, setForgetEmailError] = useState("");
  const [otpSentSuccess, setOtpSentSuccess] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const [otpVerificationError, setOtpVerificationError] = useState("");
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  //reset password
  const [showModal2, setShowModal2] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [showPasswordError, setshowPasswordError] = useState(false);
  const [showConfirmPasswordError, setshowConfirmPasswordError] =
    useState(false);
  const [updatedUser, setUpdatedUser] = useState(null);
  // const [showPassword, setShowPassword] = useState(false);
  const [showconPassword, setShowConPassword] = useState(false);
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };
  const handleForgotPassword = (e) => {
    e.preventDefault();
    setEmailModal(true);
  };

  const handleSendOtp = async () => {
    try {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(forgetEmail)) {
        setForgetEmailError("Please enter a valid email address.");
        return;
      }

      const isEmailExists = await checkEmailExistence(forgetEmail);

      if (isEmailExists) {
        const isEmailSent = await sendPasswordResetEmail(forgetEmail);

        if (isEmailSent) {
          console.log("OTP sent successfully");
          setForgetEmailError("");
          setOtpSentSuccess(true);
          setEmailModal(false);
        } else {
          setForgetEmailError("Error sending OTP. Please try again later.");
        }
      } else {
        setForgetEmailError("User with the provided email does not exist.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);

      if (error.response && error.response.status === 404) {
        setForgetEmailError("User with the provided email does not exist.");
      } else {
        setForgetEmailError("Error sending OTP. Please try again later.");
      }
    }
  };

  const handleOkButtonClick = () => {
    setOtpSentSuccess(false);
    setOtpModal(true);
  };

  const handleOkButtonClick2 = () => {
    setVerifySuccess(false);
    setPasswordModal(true);
  };

  const handleOKButtonClick3 = () => {
    setResendSuccess(false);
    setOtpModal(true);
  };

  const handleCloseEmailModal = () => {
    setEmailModal(false);
  };

  const handleCloseModal = () => {
    setOtpModal(false);
  };

  const squareInputStyle = {
    width: "50px",
    height: "50px",
    textAlign: "center",
    fontSize: "16px",
    margin: "5px",
    borderRadius: "0",
    border: "1px solid #ccc",
  };

  const handleInputChange = (currentBoxIndex, value) => {
    const numericValue = value.replace(/\D/g, "").slice(0, 1);
    setOtpValues((prevOtpValues) => {
      const newOtpValues = [...prevOtpValues];
      newOtpValues[currentBoxIndex] = numericValue;
      return newOtpValues;
    });
    if (numericValue.length === 1 && currentBoxIndex < 3) {
      inputRefs[currentBoxIndex + 1].current.focus();
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const requestBody = {
        email: forgetEmail,
        otp: otpValues.join(""),
      };
      console.log(requestBody);

      const isOtpVerified = await verifyOtp(forgetEmail, otpValues.join(""));

      if (isOtpVerified) {
        setVerifySuccess(true);
        setOtpModal(false);
        setOtpValues(["", "", "", ""]);
        setOtpVerificationError("");
      } else {
        console.error("OTP verification failed");
        setOtpVerificationError("Invalid OTP. Please try again.");
        setOtpValues(["", "", "", ""]);
        setPasswordModal(false);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setOtpVerificationError("An error occurred. Please try again.");
      setPasswordModal(false);
    }
  };

  const handleResendOTP = async () => {
    try {
      const isOtpResent = await resendOtp(forgetEmail); // Use the resendOtp function

      if (isOtpResent) {
        setResendSuccess(true);
        setOtpValues(["", "", "", ""]);
        setOtpVerificationError("");
      } else {
        console.error("Failed to resend OTP");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  const userDataJSON = localStorage.getItem("user");
  console.log(userDataJSON);
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  // const [userData, setUserData] = useState({});
  const name = "";
  const isSocialLogin = false;
  const login_type = "Email";
  const socialToken = null;
  let history = useHistory();
  let publicUrl = process.env.PUBLIC_URL + "/";
  const minscript = document.createElement("script");
  minscript.async = true;
  minscript.src = publicUrl + "assets/js/main.js";
  document.body.appendChild(minscript);

  const handleSavePassword = async () => {
    setPasswordError(false);
    setConfirmPasswordError(false);
    try {
      const updatedUser = await resetPassword(forgetEmail, newPassword);

      if (updatedUser) {
        setShowModal2(true);
        setPasswordModal(false);
        setUpdatedUser(updatedUser);
      } else {
        console.error("Failed to reset password");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
    }
  };

  useEffect(() => {
    if (updatedUser) {
      localStorage.setItem("user", JSON.stringify(updatedUser));

    }
  }, [updatedUser]);

  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setemail(emailValue);
    setEmailError(!validateEmail(emailValue));
  };

  const handleEmailBlur = () => {
    setshowEmailError(true);
  };

  const handlePasswordChange = (event) => {
    const passwordValue = event.target.value;
    setNewPassword(passwordValue);
    setPasswordError(passwordValue.length < 8);
    setConfirmPasswordError(
      confirm_password && confirm_password !== passwordValue
    );
  };

  const handleConfirmPasswordChange = (event) => {
    const confirmPasswordValue = event.target.value;
    setconfirm_password(confirmPasswordValue);
    setConfirmPasswordError(
      newPassword && newPassword !== confirmPasswordValue
    );
  };

  const handlePasswordBlur = () => {
    setshowPasswordError(true);
  };

  const handleConfirmPasswordBlur = () => {
    setshowConfirmPasswordError(true);
  };

  const handleCPasswordToggle = () => {
    setShowConPassword((prevState) => !prevState);
  };

  const handleClosePasswordModal = () => {
    setPasswordModal(false);
  };

  useEffect(() => {
    // Get the selected role from localStorage
    const storedRole = localStorage.getItem("selectedRole");
    if (storedRole) {
      setSelectedRole(storedRole);
    }
  }, []);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleRoleSelect = async () => {
    if (!selectedRole) {
      showErrorModal("Alert", "Please select a role.");
      return;
    }
    setShowModal(false);

    const dataToUpdate = {
      role: selectedRole,
    };

    try {
      const updated = await updateUser(userData.id, dataToUpdate);
      if (updated) {
        localStorage.setItem("userrole", selectedRole);

        // Redirect to the appropriate page based on the selected role
        if (selectedRole === "PRO") {
          history.push("/pro/prodashboard");
          const user = JSON.parse(localStorage.getItem("user"));
          const userId = user ? user.id : null;
          if (userId) {
            await createPro(userId);
          }
        } else if (selectedRole === "Patient") {
          history.push("/patient/patientdashboard");
          // window.location.href = "/patient/patientdashboard";
          const user = JSON.parse(localStorage.getItem("user"));
          const userId = user ? user.id : null;
          if (userId) {
            await createPatient(userId);
          }
        } else if (selectedRole === "Doctor") {
          history.push("/doc/docdashboard");
          const user = JSON.parse(localStorage.getItem("user"));
          const userId = user ? user.id : null;
          if (userId) {
            await createDoctor(userId);
          }
        } else {
          // Handle invalid or no role selection
          showErrorModal("Alert", "Invalid role selected. Redirecting to the home page.");
          history.push("/");
        }
      };
    } catch (error) {
      console.log("Error:", error);
      // Handle the error here or show an error message to the user
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      showErrorModal("Alert", "Email and password are required!");
      return;
    }
    const credentials = {
      email: email,
      password: password,
      isSocialLogin: isSocialLogin,
      login_type: login_type,
      socialToken: socialToken,
      name: name,
    };

    try {
      const loginResponse = await loginUser(credentials);
      if (loginResponse.status === 200) {
        const data = loginResponse.data;
        // alert("Login Successful");
        showErrorModal("Alert", "Login Successful.");
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data.userObj));

        // Check the role and redirect accordingly
        const role = data.userObj.role;
        if (role === "PRO") {
          try {
            const proData = await fetchPROData(data.userObj.id);
            const pro2Data = await fetchPRO2Data(data.userObj.id);
            if (proData || pro2Data) {

              // Store the PRO data in local storage
              localStorage.setItem("proData", JSON.stringify(proData));
              localStorage.setItem("proId", proData._id);
              console.log(
                "secondPageData from login",
                proData.section_data[1]
              );
              const firstPageData = proData.section_data[0];
              const secondPageData = proData.section_data[1];
              const thirdPageData = proData.section_data[2];
              const fourthPageData = proData.section_data[3];
              const fifthPageData = proData.section_data[4];
              if (firstPageData) {
                const modifiedFirstPageData = { ...firstPageData[0] };
                localStorage.setItem(
                  "firstPageData",
                  JSON.stringify(modifiedFirstPageData)
                );
              }
              if (secondPageData) {
                const modifiedSecondPageData = { ...secondPageData[1] };
                localStorage.setItem(
                  "secondPageData",
                  JSON.stringify(modifiedSecondPageData)
                );
              }
              if (thirdPageData) {
                const modifiedThirdPageData = { ...thirdPageData[2] };
                localStorage.setItem(
                  "thirdPageData",
                  JSON.stringify(modifiedThirdPageData)
                );
              }
              if (fourthPageData) {
                const modifiedFourthPageData = { ...fourthPageData[3] };
                localStorage.setItem(
                  "fourthPageData",
                  JSON.stringify(modifiedFourthPageData)
                );
              }
              if (fifthPageData) {
                const modifiedFifthPageData = { ...fifthPageData[4] };
                localStorage.setItem(
                  "fifthPageData",
                  JSON.stringify(modifiedFifthPageData)
                );
              }
              // Store the PRO data in local storage
              localStorage.setItem("proData2", JSON.stringify(pro2Data));
              localStorage.setItem("pro2Id", pro2Data._id);
              const firstPageData2 = pro2Data.section_data[0];
              const secondPageData2 = pro2Data.section_data[1];
              const thirdPageData2 = pro2Data.section_data[2];
              const fourthPageData2 = pro2Data.section_data[3];
              const fifthPageData2 = pro2Data.section_data[4];
              const sixthPageData2 = pro2Data.section_data[5];
              const seventhPageData2 = pro2Data.section_data[6];
              if (firstPageData2) {
                const modifiedFirstPageData2 = { ...firstPageData2[0] };
                localStorage.setItem(
                  "firstPageData2",
                  JSON.stringify(modifiedFirstPageData2)
                );
              }
              if (secondPageData2) {
                const modifiedSecondPageData2 = { ...secondPageData2[1] };
                localStorage.setItem(
                  "secondPageData2",
                  JSON.stringify(modifiedSecondPageData2)
                );
              }
              if (thirdPageData2) {
                const modifiedThirdPageData2 = { ...thirdPageData2[2] };
                localStorage.setItem(
                  "thirdPageData2",
                  JSON.stringify(modifiedThirdPageData2)
                );
              }
              if (fourthPageData2) {
                const modifiedFourthPageData2 = { ...fourthPageData2[3] };
                localStorage.setItem(
                  "fourthPageData2",
                  JSON.stringify(modifiedFourthPageData2)
                );
              }
              if (fifthPageData2) {
                const modifiedFifthPageData2 = { ...fifthPageData2[4] };
                localStorage.setItem(
                  "fifthPageData2",
                  JSON.stringify(modifiedFifthPageData2)
                );
              }
              if (sixthPageData2) {
                const modifiedSixthPageData2 = { ...sixthPageData2[5] };
                localStorage.setItem(
                  "sixthPageData2",
                  JSON.stringify(modifiedSixthPageData2)
                );
              }
              if (seventhPageData2) {
                const modifiedSeventhPageData2 = { ...seventhPageData2[6] };
                localStorage.setItem(
                  "seventhPageData2",
                  JSON.stringify(modifiedSeventhPageData2)
                );
              }
            } else {
              throw new Error("Failed to fetch PRO data.");
            }
          } catch (error) {
            console.log(error);
            // alert(error.message);
          }

          history.push("/pro/prodashboard");
        } else if (role === "Patient") {
          try {
            const patientData = await fetchPatientData(data.userObj.id);

            if (patientData) {
              localStorage.setItem("patientData", JSON.stringify(patientData));
              localStorage.setItem("patientId", patientData.data._id);

              // Store the PRO data in local storage
              localStorage.setItem("patientData", JSON.stringify(patientData));
              localStorage.setItem("patientId", patientData._id);
              localStorage.setItem("Patientphone", patientData.section_data[1][1].phone);
              localStorage.setItem("patientSidebar", 'form')
              const patientfirstPageData = patientData.section_data[0];
              const patientsecondPageData = patientData.section_data[1];
              const patientthirdPageData = patientData.section_data[2];
              if (patientfirstPageData) {
                const modifiedPatientFirstPageData = {
                  ...patientfirstPageData[0],
                };
                localStorage.setItem(
                  "patientfirstPageData",
                  JSON.stringify(modifiedPatientFirstPageData)
                );
              }
              if (patientsecondPageData) {
                const modifiedPatientSecondPageData = {
                  ...patientsecondPageData[1],
                };
                localStorage.setItem(
                  "patientsecondPageData",
                  JSON.stringify(modifiedPatientSecondPageData)
                );
              }
              if (patientthirdPageData) {
                const modifiedPatientthirdPageData = {
                  ...patientthirdPageData[1],
                };
                localStorage.setItem(
                  "patientthirdPageData",
                  JSON.stringify(modifiedPatientthirdPageData)
                );
              }
            } else {
              throw new Error("Failed to fetch patient data.");
            }
          } catch (error) {
            console.log(error);
            // alert(error.message);
          }
          history.push("/patient/patientdashboard");
        } else if (role === "Doctor") {
          try {

            const docData = await fetchDoctorData(data.userObj.id);
            const doc2Data = await fetchDoctor2Data(data.userObj.id);
            if (docData || doc2Data) {
             
              // Store the PRO data in local storage
              localStorage.setItem("docData", JSON.stringify(docData));
              localStorage.setItem("docId", docData._id);
              const docfirstPageData = docData.section_data[0];
              const docsecondPageData = docData.section_data[1];
              const docthirdPageData = docData.section_data[2];
              const docfourthPageData = docData.section_data[3];
              const docfifthPageData = docData.section_data[4];
              const docsixthPageData = docData.section_data[5];
              if (docfirstPageData) {
                const modifiedDocFirstPageData = { ...docfirstPageData[0] };
                localStorage.setItem(
                  "docfirstPageData",
                  JSON.stringify(modifiedDocFirstPageData)
                );
              }
              if (docsecondPageData) {
                const modifiedDocSecondPageData = { ...docsecondPageData[1] };
                localStorage.setItem(
                  "docsecondPageData",
                  JSON.stringify(modifiedDocSecondPageData)
                );
              }
              if (docthirdPageData) {
                const modifiedDocThirdPageData = { ...docthirdPageData[2] };
                localStorage.setItem(
                  "docthirdPageData",
                  JSON.stringify(modifiedDocThirdPageData)
                );
              }
              if (docfourthPageData) {
                const modifiedDocFourthPageData = { ...docfourthPageData[3] };
                localStorage.setItem(
                  "docfourthPageData",
                  JSON.stringify(modifiedDocFourthPageData)
                );
              }
              if (docfifthPageData) {
                const modifiedDocFifthPageData = { ...docfifthPageData[4] };
                localStorage.setItem(
                  "docfifthPageData",
                  JSON.stringify(modifiedDocFifthPageData)
                );
              }
              if (docsixthPageData) {
                const modifiedDocSixthPageData = { ...docsixthPageData[4] };
                localStorage.setItem(
                  "docsixthPageData",
                  JSON.stringify(modifiedDocSixthPageData)
                );
              }
              // Store the PRO data in local storage
              localStorage.setItem("docData2", JSON.stringify(doc2Data));
              localStorage.setItem("doc2Id", doc2Data._id);
              const docfirstPageData2 = doc2Data.section_data[0];
              const docsecondPageData2 = doc2Data.section_data[1];
              const docthirdPageData2 = doc2Data.section_data[2];
              const docfourthPageData2 = doc2Data.section_data[3];
              if (docfirstPageData2) {
                const modifiedFirstPageData2 = { ...docfirstPageData2[0] };
                localStorage.setItem(
                  "docfirstPageData2",
                  JSON.stringify(modifiedFirstPageData2)
                );
              }
              if (docsecondPageData2) {
                const modifiedSecondPageData2 = { ...docsecondPageData2[1] };
                localStorage.setItem(
                  "docsecondPageData2",
                  JSON.stringify(modifiedSecondPageData2)
                );
              }
              if (docthirdPageData2) {
                const modifiedThirdPageData2 = { ...docthirdPageData2[2] };
                localStorage.setItem(
                  "docthirdPageData2",
                  JSON.stringify(modifiedThirdPageData2)
                );
              }
              if (docfourthPageData2) {
                const modifiedFourthPageData2 = { ...docfourthPageData2[3] };
                localStorage.setItem(
                  "docfourthPageData2",
                  JSON.stringify(modifiedFourthPageData2)
                );
              }
            } else {
              throw new Error("Failed to fetch PRO data.");
            }
          } catch (error) {
            console.log(error);
            // alert(error.message);
          }
          history.push("/doc/docdashboard");
        } else {
          // Handle invalid role selection
          showErrorModal("Alert", "Invalid role selected. Redirecting to the home page.");
          history.push("/");
        }
      } else if (loginResponse.status === 400) {
        const data = await loginResponse.json();
        showErrorModal("Alert", data.message);
      } else if (loginResponse.status === 401) {
        showErrorModal("Alert", "Invalid email or password.");
      } else {
        showErrorModal("Alert", "Something went wrong!");
      }
    } catch (error) {
      console.log(error);
      showErrorModal("Alert", error.message);
    }
  };

  const GoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const email = result.user.email;
      const isEmailExists = await checkEmailExistence(email);

 
      const emailLayoutSocial = `
      <div style="max-width: 100%; margin: 0 auto; font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6;">
        <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; text-align: center;">
          <img src="https://meditravels.s3.amazonaws.com/Logo/952d4079-478e-44ce-92e8-f8e03e84852d-sticky-logo.png" alt="MediTravels Logo" style="max-width: 20%; height: auto;" />
          <p style="text-align: left; margin: 5px 0;">Dear ${result.user.displayName},</p>
          <p style="text-align: left;">Thank you for signing up with MediTravels. We are delighted to welcome you to our healthcare community. With MediTravels, you'll have access to a world of medical expertise, personalized care, and a wide network of healthcare providers. If you have any questions or need assistance, please don't hesitate to reach out to our dedicated support team.</p>
          <p style="text-align: left; margin: 5px 0 2px;">Best regards,</p>
          <p style="text-align: left; margin: 0px;">MeditravelS Team</p>
        </div>
      </div>`;

      if (!isEmailExists) {
        // User doesn't exist, create a new user and prompt for role selection
        const { displayName, email, phoneNumber, photoURL } = result.user;
        const usersData = {
          name: displayName,
          email: email,
          mobile_number: phoneNumber,
          profile_picture: photoURL,
          isSocialLogin: true,
          role: selectedRole,
          login_type: "google",
          socialToken: result.user.accessToken,
        };

        const response = await signup(usersData);

        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        const sendMailResponse = await sendEmail(email, 'Welcome to MediTravels!', emailLayoutSocial);

        // Show the modal for role selection if no role is selected
        if (!selectedRole) {
          setShowModal(true);
          return;
        }

        switch (selectedRole) {
          case "PRO":
            history.push("/pro/prodashboard");
            const user = JSON.parse(localStorage.getItem("user"));
            const userId = user ? user.id : null;
            if (userId) {
              await createPro(userId);
            }
            setShowModal(false);
            break;

          case "Patient":
            history.push("/patient/patientdashboard");
            const patientUser = JSON.parse(localStorage.getItem("user"));
            const patientUserId = patientUser ? patientUser.id : null;
            if (patientUserId) {
              await createPatient(patientUserId);
            }
            setShowModal(false);
            break;

          case "Doctor":
            history.push("/doc/docdashboard");
            const doctorUser = JSON.parse(localStorage.getItem("user"));
            const doctorUserId = doctorUser ? doctorUser.id : null;
            if (doctorUserId) {
              await createDoctor(doctorUserId);
            }
            setShowModal(false);
            break;

          default:
            // Handle unknown role
            break;
        }
      } else {
        // User exists, proceed with login
        const credentials = {
          email: email,
          password: "",
          isSocialLogin: true,
          login_type: "google",
          socialToken: result.user.accessToken,
        };

        const loginResponse = await loginUser(credentials);

        if (loginResponse.status === 200) {
          const data = loginResponse.data;
          // alert("You're logged in successfully with your existing email account.");
          localStorage.setItem("token", data.token);
          localStorage.setItem("user", JSON.stringify(data.userObj));

          // Check the role and redirect accordingly
          const role = data.userObj.role;
          if (role === "PRO") {
            try {
              const proData = await fetchPROData(data.userObj.id);
              const pro2Data = await fetchPRO2Data(data.userObj.id);
              if (proData || pro2Data) {

                // Store the PRO data in local storage
                localStorage.setItem("proData", JSON.stringify(proData));
                localStorage.setItem("proId", proData._id);
                const firstPageData = proData.section_data[0];
                const secondPageData = proData.section_data[1];
                const thirdPageData = proData.section_data[2];
                const fourthPageData = proData.section_data[3];
                const fifthPageData = proData.section_data[4];
                if (firstPageData) {
                  const modifiedFirstPageData = { ...firstPageData[0] };
                  localStorage.setItem(
                    "firstPageData",
                    JSON.stringify(modifiedFirstPageData)
                  );
                }
                if (secondPageData) {
                  const modifiedSecondPageData = { ...secondPageData[1] };
                  localStorage.setItem(
                    "secondPageData",
                    JSON.stringify(modifiedSecondPageData)
                  );
                }
                if (thirdPageData) {
                  const modifiedThirdPageData = { ...thirdPageData[2] };
                  localStorage.setItem(
                    "thirdPageData",
                    JSON.stringify(modifiedThirdPageData)
                  );
                }
                if (fourthPageData) {
                  const modifiedFourthPageData = { ...fourthPageData[3] };
                  localStorage.setItem(
                    "fourthPageData",
                    JSON.stringify(modifiedFourthPageData)
                  );
                }
                if (fifthPageData) {
                  const modifiedFifthPageData = { ...fifthPageData[4] };
                  localStorage.setItem(
                    "fifthPageData",
                    JSON.stringify(modifiedFifthPageData)
                  );
                }
                // Store the PRO data in local storage
                localStorage.setItem("proData2", JSON.stringify(pro2Data));
                localStorage.setItem("pro2Id", pro2Data._id);
                const firstPageData2 = pro2Data.section_data[0];
                const secondPageData2 = pro2Data.section_data[1];
                const thirdPageData2 = pro2Data.section_data[2];
                const fourthPageData2 = pro2Data.section_data[3];
                const fifthPageData2 = pro2Data.section_data[4];
                const sixthPageData2 = pro2Data.section_data[5];
                const seventhPageData2 = pro2Data.section_data[6];
                if (firstPageData2) {
                  const modifiedFirstPageData2 = { ...firstPageData2[0] };
                  localStorage.setItem(
                    "firstPageData2",
                    JSON.stringify(modifiedFirstPageData2)
                  );
                }
                if (secondPageData2) {
                  const modifiedSecondPageData2 = { ...secondPageData2[1] };
                  localStorage.setItem(
                    "secondPageData2",
                    JSON.stringify(modifiedSecondPageData2)
                  );
                }
                if (thirdPageData2) {
                  const modifiedThirdPageData2 = { ...thirdPageData2[2] };
                  localStorage.setItem(
                    "thirdPageData2",
                    JSON.stringify(modifiedThirdPageData2)
                  );
                }
                if (fourthPageData2) {
                  const modifiedFourthPageData2 = { ...fourthPageData2[3] };
                  localStorage.setItem(
                    "fourthPageData2",
                    JSON.stringify(modifiedFourthPageData2)
                  );
                }
                if (fifthPageData2) {
                  const modifiedFifthPageData2 = { ...fifthPageData2[4] };
                  localStorage.setItem(
                    "fifthPageData2",
                    JSON.stringify(modifiedFifthPageData2)
                  );
                }
                if (sixthPageData2) {
                  const modifiedSixthPageData2 = { ...sixthPageData2[5] };
                  localStorage.setItem(
                    "sixthPageData2",
                    JSON.stringify(modifiedSixthPageData2)
                  );
                }
                if (seventhPageData2) {
                  const modifiedSeventhPageData2 = { ...seventhPageData2[6] };
                  localStorage.setItem(
                    "seventhPageData2",
                    JSON.stringify(modifiedSeventhPageData2)
                  );
                }
              } else {
                throw new Error("Failed to fetch PRO data.");
              }
            } catch (error) {
              console.log(error);
              // alert(error.message);
            }
            history.push("/pro/prodashboard");
          } else if (role === "Patient") {
            try {
              const patientData = await fetchPatientData(data.userObj.id);
              if (patientData) {
                localStorage.setItem(
                  "patientData",
                  JSON.stringify(patientData)
                );
                localStorage.setItem("patientId", patientData._id);
                localStorage.setItem("Patientphone", patientData.section_data[1][1].phone);

                // Store the PRO data in local storage
                localStorage.setItem(
                  "patientData",
                  JSON.stringify(patientData)
                );
                localStorage.setItem("patientId", patientData._id);
                localStorage.setItem("patientSidebar", 'form')
                const patientfirstPageData = patientData.section_data[0];
                const patientsecondPageData = patientData.section_data[1];
                const patientthirdPageData = patientData.section_data[2];
                if (patientfirstPageData) {
                  const modifiedPatientFirstPageData = {
                    ...patientfirstPageData[0],
                  };
                  localStorage.setItem(
                    "patientfirstPageData",
                    JSON.stringify(modifiedPatientFirstPageData)
                  );
                }
                if (patientsecondPageData) {
                  const modifiedPatientSecondPageData = {
                    ...patientsecondPageData[1],
                  };
                  localStorage.setItem(
                    "patientsecondPageData",
                    JSON.stringify(modifiedPatientSecondPageData)
                  );
                }
                if (patientthirdPageData) {
                  const modifiedPatientthirdPageData = {
                    ...patientthirdPageData[1],
                  };
                  localStorage.setItem(
                    "patientthirdPageData",
                    JSON.stringify(modifiedPatientthirdPageData)
                  );
                }
              } else {
                throw new Error("Failed to fetch patient data.");
              }
            } catch (error) {
              console.log(error);
              // alert(error.message);
            }
            history.push("/patient/patientdashboard");
          } else if (role === "Doctor") {
            try {
              const docData = await fetchDoctorData(data.userObj.id);
              const doc2Data = await fetchDoctor2Data(data.userObj.id);
              if (docData || doc2Data) {

                // Store the PRO data in local storage
                localStorage.setItem("docData", JSON.stringify(docData));
                localStorage.setItem("docId", docData._id);
                const docfirstPageData = docData.section_data[0];
                const docsecondPageData = docData.section_data[1];
                const docthirdPageData = docData.section_data[2];
                const docfourthPageData = docData.section_data[3];
                const docfifthPageData = docData.section_data[4];
                const docsixthPageData = docData.section_data[5];
                if (docfirstPageData) {
                  const modifiedDocFirstPageData = { ...docfirstPageData[0] };
                  localStorage.setItem(
                    "docfirstPageData",
                    JSON.stringify(modifiedDocFirstPageData)
                  );
                }
                if (docsecondPageData) {
                  const modifiedDocSecondPageData = { ...docsecondPageData[1] };
                  localStorage.setItem(
                    "docsecondPageData",
                    JSON.stringify(modifiedDocSecondPageData)
                  );
                }
                if (docthirdPageData) {
                  const modifiedDocThirdPageData = { ...docthirdPageData[2] };
                  localStorage.setItem(
                    "docthirdPageData",
                    JSON.stringify(modifiedDocThirdPageData)
                  );
                }
                if (docfourthPageData) {
                  const modifiedDocFourthPageData = { ...docfourthPageData[3] };
                  localStorage.setItem(
                    "docfourthPageData",
                    JSON.stringify(modifiedDocFourthPageData)
                  );
                }
                if (docfifthPageData) {
                  const modifiedDocFifthPageData = { ...docfifthPageData[4] };
                  localStorage.setItem(
                    "docfifthPageData",
                    JSON.stringify(modifiedDocFifthPageData)
                  );
                }
                if (docsixthPageData) {
                  const modifiedDocSixthPageData = { ...docsixthPageData[4] };
                  localStorage.setItem(
                    "docsixthPageData",
                    JSON.stringify(modifiedDocSixthPageData)
                  );
                }
                // Store the PRO data in local storage
                localStorage.setItem("docData2", JSON.stringify(doc2Data));
                localStorage.setItem("doc2Id", doc2Data._id);
                const docfirstPageData2 = doc2Data.section_data[0];
                const docsecondPageData2 = doc2Data.section_data[1];
                const docthirdPageData2 = doc2Data.section_data[2];
                const docfourthPageData2 = doc2Data.section_data[3];
                if (docfirstPageData2) {
                  const modifiedFirstPageData2 = { ...docfirstPageData2[0] };
                  localStorage.setItem(
                    "docfirstPageData2",
                    JSON.stringify(modifiedFirstPageData2)
                  );
                }
                if (docsecondPageData2) {
                  const modifiedSecondPageData2 = { ...docsecondPageData2[1] };
                  localStorage.setItem(
                    "docsecondPageData2",
                    JSON.stringify(modifiedSecondPageData2)
                  );
                }
                if (docthirdPageData2) {
                  const modifiedThirdPageData2 = { ...docthirdPageData2[2] };
                  localStorage.setItem(
                    "docthirdPageData2",
                    JSON.stringify(modifiedThirdPageData2)
                  );
                }
                if (docfourthPageData2) {
                  const modifiedFourthPageData2 = { ...docfourthPageData2[3] };
                  localStorage.setItem(
                    "docfourthPageData2",
                    JSON.stringify(modifiedFourthPageData2)
                  );
                }
              } else {
                throw new Error("Failed to fetch PRO data.");
              }
            } catch (error) {
              console.log(error);
              // alert(error.message);
            }
            history.push("/doc/docdashboard");
          } else {
            // Handle invalid role selection
            setShowModal(true);
          }
        } else if (loginResponse.status === 401) {
          showErrorModal("Alert", "Invalid email or password.");
        } else if (loginResponse.status === 400) {
          throw new Error("User not found. Please sign up.");
        } else {
          throw new Error("Something went wrong!");
        }
      }
    } catch (error) {
      console.error(error);
      showErrorModal("Alert", error.message);
    }
  };

  const handleModalAcknowledgment = () => {
 
    setModalAcknowledged(true);
    setShowMsgModal(false);
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <div className="main-box">
      <div className="split left">
        <div className="splitleft">
          <form>
            <div className="wlc">Welcome back</div>
            <div className="wlcback">
              Welcome back! Please enter your details.
            </div>
            <div className="textborder">
              <div className="email signintext">
                <label>
                  Enter email<span className="required">*</span>
                </label>
                <br></br>
                <div className="inputcontainer">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={email}
                    onBlur={handleEmailBlur}
                    onChange={handleEmailChange}
                    placeholder="Enter your email"
                  ></input>
                </div>
                {emailError && showEmailError && (
                  <p className="error">Please enter a valid email</p>
                )}
              </div>

              <div className="password signintext">
                <label>
                  Password<span className="required">*</span>
                </label>
                <div className="inputcontainer">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    value={password}
                    onChange={(value) => setpassword(value.target.value)}
                    placeholder="Enter your password"
                  />
                  <i
                    className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'} eye-btn`}
                    onClick={handlePasswordToggle}
                    style={{ marginRight: "10px", marginTop: "-27px", position: "absolute", marginLeft: "330px" }}
                  />

                </div>

              </div>
            </div>
            <label className="forgetpassword">
              <div className="check signintext">
                <input type="checkbox" name="Remember for 30 days" />
                &nbsp; &nbsp;
                <label htmlFor="remember-me">Remember Me</label>
              </div>

              <span className="forgot-password">
                <a href="#forgot-password" onClick={handleForgotPassword}>
                  Forgot password?
                </a>
              </span>

              {/* forget password modal */}
              <Modal
                show={emailModal}
                onHide={() => setEmailModal(false)}
                className="newsletter-popup"
                backdrop="static"
              >
                <Modal.Header>
                  <Modal.Title>Forgot Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="textborder">
                    <div className="email">
                      <label>Email:</label>
                      <span className="required">*</span>
                      <input
                        type="email"
                        className="form-control"
                        value={forgetEmail}
                        onChange={(e) => setForgetEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  {forgetEmailError && (
                    <p className="error">{forgetEmailError}</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-yellow" onClick={handleSendOtp}>
                    Send OTP
                  </button>
                  <button
                    className="btn btn-yellow"
                    onClick={handleCloseEmailModal}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>

              {/* Modal for OTP Sent Success */}
              <Modal
                show={otpSentSuccess}
                onHide={() => setOtpSentSuccess(false)}
                className="newsletter-popup"
                backdrop="static"
              >
                <Modal.Header></Modal.Header>
                <Modal.Body>
                  <h3>OTP has been sent successfully!</h3>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-yellow"
                    onClick={handleOkButtonClick}
                  >
                    OK
                  </button>
                </Modal.Footer>
              </Modal>

              {/* Modal for OTP Input */}
              <Modal
                show={otpModal}
                onHide={() => setOtpModal(false)}
                className="newsletter-popup"
                backdrop="static"
              >
                <Modal.Header>
                  <Modal.Title>Enter OTP</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="form-group">
                    <label>Enter OTP:</label>
                    <div>
                      <input
                        type="tel"
                        ref={inputRefs[0]}
                        style={squareInputStyle}
                        maxLength="1"
                        value={otpValues[0]}
                        onChange={(e) => handleInputChange(0, e.target.value)}
                      />
                      <input
                        type="tel"
                        ref={inputRefs[1]}
                        style={squareInputStyle}
                        maxLength="1"
                        value={otpValues[1]}
                        onChange={(e) => handleInputChange(1, e.target.value)}
                      />
                      <input
                        type="tel"
                        ref={inputRefs[2]}
                        style={squareInputStyle}
                        maxLength="1"
                        value={otpValues[2]}
                        onChange={(e) => handleInputChange(2, e.target.value)}
                      />
                      <input
                        type="tel"
                        ref={inputRefs[3]}
                        style={squareInputStyle}
                        maxLength="1"
                        value={otpValues[3]}
                        onChange={(e) => handleInputChange(3, e.target.value)}
                      />
                    </div>
                  </div>
                  {otpVerificationError && (
                    <p className="error">{otpVerificationError}</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-yellow" onClick={handleVerifyOtp}>
                    Verify OTP
                  </button>
                  <button className="btn btn-yellow" onClick={handleResendOTP}>
                    Resend OTP
                  </button>
                  <button className="btn btn-yellow" onClick={handleCloseModal}>
                    Close
                  </button>
                </Modal.Footer>
              </Modal>

              {/* modal for verifysuccess */}
              <Modal
                show={verifySuccess}
                onHide={() => setVerifySuccess(false)}
                className="newsletter-popup"
                backdrop="static"
              >
                <Modal.Header></Modal.Header>
                <Modal.Body>
                  <h3>Verified successfully!</h3>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-yellow"
                    onClick={handleOkButtonClick2}
                  >
                    OK
                  </button>
                </Modal.Footer>
              </Modal>

              {/* modal for resendSuccess */}
              <Modal
                show={resendSuccess}
                onHide={() => setResendSuccess(false)}
                className="newsletter-popup"
                backdrop="static"
              >
                <Modal.Header></Modal.Header>
                <Modal.Body>
                  <h3>Resend successfully!</h3>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-yellow"
                    onClick={handleOKButtonClick3}
                  >
                    OK
                  </button>
                </Modal.Footer>
              </Modal>

              {/* Modal for new & conf. Password */}
              <Modal
                show={passwordModal}
                onHide={() => setPasswordModal(false)}
                className="newsletter-popup"
                backdrop="static"
              >
                <Modal.Header>
                  <Modal.Title>Reset Your Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="textborder">
                    <div className="email">
                      <label>New Password:</label>
                      <span className="required">*</span>
                      <input
                        id="password"
                        name="newPassword"
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        value={newPassword}
                        onBlur={handlePasswordBlur}
                        onChange={handlePasswordChange}
                      />
                      <i
                        className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                        onClick={handlePasswordToggle}
                        style={{
                          marginRight: "10px",
                          marginTop: "-27px",
                          position: "absolute",
                          marginLeft: "330px",
                        }}
                      />
                    </div>
                    {passwordError && showPasswordError && (
                      <p className="error">
                        Password must be 8 characters long
                      </p>
                    )}
                  </div>
                  <br />
                  <div className="textborder">
                    <div className="email">
                      <label>Confirm Password:</label>
                      <span className="required">*</span>
                      <input
                        type={showconPassword ? "text" : "password"}
                        id="con-password"
                        name="confirm_password"
                        value={confirm_password}
                        className="form-control"
                        onBlur={handleConfirmPasswordBlur}
                        onChange={handleConfirmPasswordChange}
                      />
                      <i
                        className={`fa ${showconPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                        onClick={handleCPasswordToggle}
                        style={{
                          marginRight: "10px",
                          marginTop: "-27px",
                          position: "absolute",
                          marginLeft: "330px",
                        }}
                      />
                    </div>
                    {confirmPasswordError && showConfirmPasswordError && (
                      <p className="error">Passwords do not match</p>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-yellow"
                    onClick={handleSavePassword}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-yellow"
                    onClick={handleClosePasswordModal}
                  >
                    Close
                  </button>
                </Modal.Footer>
              </Modal>

              {/* Modal Reset Password successsfull */}
              <Modal
                show={showModal2}
                onHide={() => setShowModal2(false)}
                className="newsletter-popup"
                backdrop="static"
              >
                <Modal.Header>
                  <Modal.Title>Password Reset Successfully</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4>
                    Your password has been successfully reset. You can now log
                    in with your new password.
                  </h4>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="btn btn-yellow"
                    label="OK"
                    onClick={() => setShowModal2(false)}
                  >
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </label>
            <Button
              className="btn-normal"
              type="sign in"
              label="Sign in"
              onClick={handleLogin}
            ></Button>

            <br></br>
            <br></br>
            <button className="google-btn" type="button" onClick={GoogleLogin}>
              <span className="btn-text">Sign in with Google</span>
            </button>
            <br></br>
            <br></br>
            <Link className="center account" to="/signup">
              Don't have an account?
              <span className="signuptext"> &nbsp;Sign up</span>
            </Link>
          </form>
        </div>
      </div>

      <div className="split right">
        <div className="centeredimg">
          <Link to="/" className="main-logo">
            <img src={publicUrl + "assets/img/logo.png"} alt="logo" />
          </Link>
          {/* <div className="card" style={{boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2)" }}>
          <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
          <Link to="/" className="main-logo">
            <img src={publicUrl + "assets/img/logo.png"} alt="logo" />
          </Link>
        </div>
             <div className="card-body">
              <h5 className="card-title">Card title</h5>
             <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

             <div className="socials-iconss">
             <a className="facebook" href="https://www.facebook.com/codingeek.net/" target="_blank"><i className="fa fa-facebook"/></a>
             <a className="twitter" href="https://twitter.com/codingeeknet" target="_blank"><i className="fa fa-twitter"/></a>
             <a className="pinterest" href="https://www.instagram.com/codingeeknet/" target="_blank"><i className="fa fa-instagram" /></a>
            </div>

            </div>
          </div> */}
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}

        className="newsletter-popup"
        backdrop="static"

      >
        <Modal.Header>
          <Modal.Title>Select Your Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControlLabel
            control={
              <Radio
                checked={selectedRole === "Doctor"}
                onChange={handleRoleChange}
                value="Doctor"
              />
            }
            label="Doctor"
          />
          <br />
          <FormControlLabel
            control={
              <Radio
                checked={selectedRole === "PRO"}
                onChange={handleRoleChange}
                value="PRO"
              />
            }
            label="PRO"
          />
          <br />
          <FormControlLabel
            control={
              <Radio
                checked={selectedRole === "Patient"}
                onChange={handleRoleChange}
                value="Patient"
              />
            }
            label="Patient"
          />
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-yellow"
            variant="secondary"
            onClick={handleRoleSelect}
            label="OK"
          >
            Ok
          </Button>
          {/* <Button
            className="btn btn-yellow"
            variant="secondary"
            onClick={() => setShowModal(false)}
            label="Close"
          >
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </div>
  );
};
export default Signin;