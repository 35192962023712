import React, { useEffect, useState } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
// import TourLIst from "./section-components/tour-list";
import Subscribe from "./section-components/subscribe";
import Footer from "./global-components/footer";
import TreatmentBlog from "./section-components/treatment_blog";
import { useHistory } from "react-router-dom";
// import { useLocation } from 'react-router-dom';

// const location = useLocation();
// const { title } = location.state;
// "Allopathic"
const TourListPage = () => {
  const history = useHistory();
  const headerImage = localStorage.getItem("treatmentimage");
  const [isTreatmentBlogLoaded, setIsTreatmentBlogLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); 
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0); 
    });
    return () => unlisten();
  }, [history]);

  // const handleTreatmentBlogLoad = () => {
  //   console.log("Treatment_Blog loaded");
  //   setIsTreatmentBlogLoaded(true);
  // };

  // console.log("isTreatmentBlogLoaded:", isTreatmentBlogLoaded);

  return (
    <div>
      <Navbar />

      <PageHeader image={headerImage} headertitle="" />
      <TreatmentBlog />
      <Subscribe />
      <Footer />
    </div>
  );
};

export default TourListPage;
