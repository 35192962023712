import React, { useState, useEffect } from "react";
import Page from "../pro-components/Page";
import SectionComponent from "../pro-components/Section";
import FirstSection from "../pro-components/FirstSection";
import RadioButtonGroup from "../pro-components/CRadioButton";
import Action from "../pro-components/Action";
import { useHistory } from "react-router-dom";
import CustomTextField from "../pro-components/CTextField";
import CustomButton from "../pro-components/CButton";
import FileUpload from "../pro-components/CFileUpload";
import CustomModal from "../../global-components/modal";
import { updatePro2, uploadMedia } from '../../../api';

const HealthHistory = () => {
  const [allergy, setAllergy] = useState("");
  const [allergyImg, setAllergyImg] = useState(null);
  const [previewAllergyImg, setPreviewAllergyImg] = useState(null);
  const [allergyText, setAllergyText] = useState("");
  const [medicalHistory, setMedicalHistory] = useState("");
  const [medicalHistoryImg, setMedicalHistoryImg] = useState(null);
  const [previewMedicalHistoryImg, setPreviewMedicalHistoryImg] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [imagePreviewUrl1, setImagePreviewUrl1] = useState(null);
  const [pdfPreviewUrl1, setPdfPreviewUrl1] = useState(null);
  const [imagePreviewUrl2, setImagePreviewUrl2] = useState(null);
  const [pdfPreviewUrl2, setPdfPreviewUrl2] = useState(null);
  const [imagePreviewUrl3, setImagePreviewUrl3] = useState(null);
  const [pdfPreviewUrl3, setPdfPreviewUrl3] = useState(null);
  const pdfOpenParams = 'toolbar=0&navpanes=0&scrollbar=0';
  const [medicalHistoryText, setMedicalHistoryText] = useState("");
  const [surgicalHistory, setSurgicalHistory] = useState("");
  const [surgicalHistoryImg, setSurgicalHistoryImg] = useState(null);
  const [previewSurgicalHistoryImg, setPreviewSurgicalHistoryImg] = useState(null);
  const [surgicalHistoryText, setSurgicalHistoryText] = useState("");
  const [presentComplain, setPresentComplain] = useState("");
  const [presentComplainImg, setPresentComplainImg] = useState(null);
  const [previewPresentComplainImg, setPreviewPresentComplainImg] = useState(null);
  const [presentComplainText, setPresentComplainText] = useState("");
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const previousPageURL = "/pro/section3-2";
  const [sectionData, setSectionData] = useState("");
  const history = useHistory();
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const updateSectionData = async (sectionData) => {
    console.log("user id from local storage", userData.id);
    const userId = userData.id;
    console.log("-------userId from local storage", userId);

    if (!userId) {
      console.log("User ID not found in local storage");
      return;
    }

    try {
      const existingSectionData = JSON.parse(localStorage.getItem("firstPageData2"));
      const section_data = [
        {
          0: existingSectionData, // Include the first page data at index 0
        },
        {
          1: JSON.parse(localStorage.getItem("secondPageData2")), // Include the second page data at index 1
        },
        {
          2: JSON.parse(localStorage.getItem("thirdPageData2")), // Include the second page data at index 1
        },
        {
          3: sectionData, // Include the second page data at index 1
        },
      ];

      console.log("Updated data of doc2");
      console.log(section_data);

      const updatedSectionData = await updatePro2(userId, section_data);

      if (updatedSectionData) {
        console.log("------Updated data of pro");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updatePro2.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const options = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  const handleNextClick = async (event) => {
    event.preventDefault();

    // Check if all fields are filled
    if (!allergy) {
      showErrorModal("Alert", "Please select an option for Allergy.");
      return;
    }

    if (!medicalHistory) {
      showErrorModal("Alert", "Please select an option for Medical History.");
      return;
    }

    if (!surgicalHistory) {
      showErrorModal("Alert", "Please select an option for Surgical History.");
      return;
    }

    if (!presentComplain) {
      showErrorModal("Alert", "Please select an option for Present Complaint.");
      return;
    }

    // Store all the data in local storage
    const forthPageData = {
      allergy: allergy,
      allergyImg: allergyImg,
      allergyText: allergyText,
      medicalHistory: medicalHistory,
      medicalHistoryImg: medicalHistoryImg,
      medicalHistoryText: medicalHistoryText,
      surgicalHistory: surgicalHistory,
      surgicalHistoryImg: surgicalHistoryImg,
      surgicalHistoryText: surgicalHistoryText,
      presentComplain: presentComplain,
      presentComplainImg: presentComplainImg,
      presentComplainText: presentComplainText,
    };
    localStorage.setItem("fourthPageData2", JSON.stringify(forthPageData));

    try {
      await saveAllergyImageUrlToDatabase();
    } catch (error) {
      console.error(error);
      return;
    }

    await updateSectionData(forthPageData);

    history.push("/pro/section5-2");
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("fourthPageData2"));

    if (storedData) {
      setAllergy(storedData.allergy || "");
      setAllergyImg(storedData.allergyImg);
      if (storedData.allergyImg && storedData.allergyImg.endsWith('.pdf')) {
        setPdfPreviewUrl(`https://docs.google.com/viewer?url=${encodeURIComponent(storedData.allergyImg)}&embedded=true`);
      } else {
        setImagePreviewUrl(storedData.allergyImg);
      }
      setAllergyText(storedData.allergyText || "");
      setMedicalHistory(storedData.medicalHistory || "");
      setMedicalHistoryImg(storedData.medicalHistoryImg);
      if (storedData.medicalHistoryImg && storedData.medicalHistoryImg.endsWith('.pdf')) {
        setPdfPreviewUrl1(`https://docs.google.com/viewer?url=${encodeURIComponent(storedData.medicalHistoryImg)}&embedded=true`);
      } else {
        setImagePreviewUrl1(storedData.medicalHistoryImg);
      }

      setMedicalHistoryText(storedData.medicalHistoryText || "");
      setSurgicalHistory(storedData.surgicalHistory || "");
      setSurgicalHistoryImg(storedData.surgicalHistoryImg);
      if (storedData.surgicalHistoryImg && storedData.surgicalHistoryImg.endsWith('.pdf')) {
        setPdfPreviewUrl2(`https://docs.google.com/viewer?url=${encodeURIComponent(storedData.surgicalHistoryImg)}&embedded=true`);
      } else {
        setImagePreviewUrl2(storedData.surgicalHistoryImg);
      }
      setSurgicalHistoryText(storedData.surgicalHistoryText || "");
      setPresentComplain(storedData.presentComplain || "");
      setPresentComplainImg(storedData.presentComplainImg);

      if (storedData.presentComplainImg && storedData.presentComplainImg.endsWith('.pdf')) {
        setPdfPreviewUrl3(`https://docs.google.com/viewer?url=${encodeURIComponent(storedData.presentComplainImg)}&embedded=true`);
      } else {
        setImagePreviewUrl3(storedData.presentComplainImg);
      }
      setPresentComplainText(storedData.presentComplainText || "");
    }
  }, []);

  const handleIsAllergy = (selectedOption) => {
    setAllergy(selectedOption);
    if (selectedOption === "no") {
      setAllergyImg(null);
      setPreviewAllergyImg(null);
    }
  };

  const handleAllergyTextChange = (event) => {
    setAllergyText(event.target.value);
  };

  const handleIsMedicalHistory = (selectedOption) => {
    setMedicalHistory(selectedOption);
    if (selectedOption === "no") {
      setMedicalHistoryImg(null);
      setPreviewMedicalHistoryImg(null);
    }
  };

  const handleMedicalHistoryTextChange = (event) => {
    setMedicalHistoryText(event.target.value);
  };

  const handleIsSurgicalHistory = (selectedOption) => {
    setSurgicalHistory(selectedOption);
    if (selectedOption === "no") {
      setSurgicalHistoryImg(null);
      setPreviewSurgicalHistoryImg(null);
    }
  };

  const handleSurgicalHistoryTextChange = (event) => {
    setSurgicalHistoryText(event.target.value);
  };

  const handleIsPresentComplain = (selectedOption) => {
    setPresentComplain(selectedOption);
    if (selectedOption === "no") {
      setPresentComplainImg(null);
      setPreviewPresentComplainImg(null);
    }
  };

  const handlePresentComplainTextChange = (event) => {
    setPresentComplainText(event.target.value);
  };

  const handleAllergyFileUpload = async (file) => {
    setAllergyImg(file);
    setPreviewAllergyImg(URL.createObjectURL(file));

    try {
      const mediaType = "PRO2";

      const uploadedImagePath = await uploadMedia(file, mediaType);

      console.log('Image URL:', uploadedImagePath);

      if (file.type.startsWith('image/')) {
        setImagePreviewUrl(URL.createObjectURL(file));
        setPdfPreviewUrl(null);
        setAllergyImg(uploadedImagePath);
        setPreviewAllergyImg(uploadedImagePath);
      }
      else if (file.type === 'application/pdf') {
        setPdfPreviewUrl(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
        setImagePreviewUrl(null);
        setAllergyImg(uploadedImagePath);
        setPreviewAllergyImg(uploadedImagePath);
      }

      // Save the image URL in the database
      await saveAllergyImageUrlToDatabase(uploadedImagePath);
    } catch (error) {
      console.error(error);
    }
  };

  const saveAllergyImageUrlToDatabase = async (previewAllergyImg) => {

    const userId = userData.id;
    const sectionData = { allergyImg: previewAllergyImg };
  
    try {
      const response = await updatePro2(userId, sectionData);  

      console.log("Image URL saved in the database:", response.data);
    } catch (error) {
      console.error(error);
    }

    setSectionData((prevData) => ({
      ...prevData,
      allergyImg: previewAllergyImg,
    }));
  };

  const handleMedicalHistoryFileUpload = async (file) => {
    setMedicalHistoryImg(file);
    setPreviewMedicalHistoryImg(URL.createObjectURL(file)); 

      try {
        const mediaType = "PRO2";
  
        const uploadedMediImagePath = await uploadMedia(file, mediaType);
      console.log("image url from pro2 medical", uploadedMediImagePath);

      if (file.type.startsWith('image/')) {
        setImagePreviewUrl1(URL.createObjectURL(file));
        setPdfPreviewUrl1(null);
        setMedicalHistoryImg(uploadedMediImagePath)
      }
      else if (file.type === 'application/pdf') {
        setPdfPreviewUrl1(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
        setImagePreviewUrl1(null);
        setMedicalHistoryImg(uploadedMediImagePath)
      }

      // Save the image URL in the database
      await saveMedicalHistoryImageUrlToDatabase(uploadedMediImagePath);
    } catch (error) {
      console.error(error);
    }
  };

  const saveMedicalHistoryImageUrlToDatabase = async (
    previewMedicalHistoryImg
  ) => {

    const userId = userData.id;
    const sectionData = { medicalHistoryImg: previewMedicalHistoryImg };
  
    try {
      const response = await updatePro2(userId, sectionData);  

      console.log("Image URL saved in the database:", response.data);
    } catch (error) {
      console.error(error);
    }

    setSectionData((prevData) => ({
      ...prevData,
      medicalHistoryImg: previewMedicalHistoryImg,
    }));
  };

  const handleSurgicalHistoryFileUpload = async (file) => {
    setSurgicalHistoryImg(file);
    setPreviewSurgicalHistoryImg(URL.createObjectURL(file));

    try {
      const mediaType = "PRO2";

      const uploadedSurgicalImagePath = await uploadMedia(file, mediaType);

      console.log('Image URL:', uploadedSurgicalImagePath);

      if (file.type.startsWith('image/')) {
        setImagePreviewUrl2(URL.createObjectURL(file));
        setPdfPreviewUrl2(null);
        setSurgicalHistoryImg(uploadedSurgicalImagePath)
      }
      else if (file.type === 'application/pdf') {
        setPdfPreviewUrl2(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
        setImagePreviewUrl2(null);
        setSurgicalHistoryImg(uploadedSurgicalImagePath)
      }

      // Save the image URL in the database
      await saveSurgicalHistoryImageUrlToDatabase(uploadedSurgicalImagePath);
    } catch (error) {
      console.error(error);
    }
  };

  const saveSurgicalHistoryImageUrlToDatabase = async (
    previewSurgicalHistoryImg
  ) => {

    const userId = userData.id;
    const sectionData = { surgicalHistoryImg: previewSurgicalHistoryImg };
  
    try {
      const response = await updatePro2(userId, sectionData);  
   
      console.log("Image URL saved in the database:", response.data);
    } catch (error) {
      console.error(error);
    }

    setSectionData((prevData) => ({
      ...prevData,
      surgicalHistoryImg: previewSurgicalHistoryImg,
    }));
  };

  const handlePresentComplainFileUpload = async (file) => {
    setPresentComplainImg(file);
    setPreviewPresentComplainImg(URL.createObjectURL(file));

    try {
      const mediaType = "PRO2";

      const uploadedPresentImagePath = await uploadMedia(file, mediaType);
   
      setPresentComplainImg(uploadedPresentImagePath);
      console.log("image url from pro2 present", uploadedPresentImagePath);

      if (file.type.startsWith('image/')) {
        setImagePreviewUrl3(URL.createObjectURL(file));
        setPdfPreviewUrl3(null);
        setPresentComplainImg(uploadedPresentImagePath);
      }
      else if (file.type === 'application/pdf') {
        setPdfPreviewUrl3(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
        setImagePreviewUrl3(null);
        setPresentComplainImg(uploadedPresentImagePath);
      }

      // Save the image URL in the database
      await savePresentComplainImageUrlToDatabase(uploadedPresentImagePath);
    } catch (error) {
      console.error(error);
    }
  };

  const savePresentComplainImageUrlToDatabase = async (previewPresentComplainImg) => {
    const userId = userData.id;
    const sectionData = { presentComplainImg: previewPresentComplainImg };
  
    try {
      const response = await updatePro2(userId, sectionData);  

      console.log("Image URL saved in the database:", response.data);
    } catch (error) {
      console.error(error);
    }

    setSectionData((prevData) => ({
      ...prevData,
      presentComplainImg: previewPresentComplainImg,
    }));
  };

  const handleClearForm = () => {
    setAllergy("");
    setAllergyImg("");
    setPreviewAllergyImg("");
    setAllergyText("");
    setMedicalHistory("");
    setMedicalHistoryImg(null); // Set medical history image to null
    setPreviewMedicalHistoryImg(null);
    setMedicalHistoryText("");
    setSurgicalHistory("");
    setSurgicalHistoryImg("");
    setPreviewSurgicalHistoryImg("");
    setPresentComplain("");
    setPresentComplainImg("");
    setPreviewPresentComplainImg("");
    setPdfPreviewUrl("")
    setImagePreviewUrl("")
    setPdfPreviewUrl1("")
    setImagePreviewUrl1("")
    setPdfPreviewUrl2("")
    setImagePreviewUrl2("")
    setPdfPreviewUrl3("")
    setImagePreviewUrl3("")
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <Page title="Health History">
      <FirstSection>
        <h4>Health History</h4>
      </FirstSection>
      <SectionComponent>
        <label className="sec-label">ANY DRUG OR FOOD OR OTHER ALLERGY?*</label>
        <div className="allergy-radio">
          <RadioButtonGroup
            name="radioOptions"
            value={allergy}
            options={options}
            onChange={handleIsAllergy}
          />
        </div>
        {allergy === "yes" && (
          <div>
            <label className="sec-label">
              GIVE MORE INFORMATION ABOUT YOUR ALLERGY
            </label>
            <CustomTextField
              id="outlined-basic"
              placeholder="Your information"
              variant="outlined"
              value={allergyText}
              onChange={handleAllergyTextChange} />
            <FileUpload onChange={handleAllergyFileUpload} value={allergyImg} />
          </div>
        )}
        {imagePreviewUrl && (
          <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
            <CustomButton type="button" onClick={() => setImagePreviewUrl(null)} // Reset the image preview URL
            >X</CustomButton>
          </div>
            <div style={{ border: '1px solid black' }}>
              <img src={imagePreviewUrl} alt="Selected" style={{ width: '100%' }} />
            </div>
          </>
        )}
        {pdfPreviewUrl && (
          <>
            <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
              <CustomButton type="button" onClick={() => setPdfPreviewUrl(null)} // Reset the PDF preview URL
              >X</CustomButton>
            </div>
            <div style={{ border: '1px solid black' }}>
              <iframe
                src={pdfPreviewUrl + '#' + pdfOpenParams}
                title="PDF Preview"
                style={{ width: '100%', height: '500px' }}
              />
            </div>
          </>
        )}
      </SectionComponent>
      <SectionComponent>
        <label className="sec-label">PAST MEDICAL HISTORY?*</label>
        <div className="allergy-radio">
          <RadioButtonGroup
            name="radioOptions"
            value={medicalHistory}
            options={options}
            onChange={handleIsMedicalHistory}
          />
        </div>
        {medicalHistory === "yes" && (
          <div>
            <label className="sec-label">
              GIVE MORE INFORMATION ABOUT YOUR PAST MEDICAL HISTORY
            </label>
            <CustomTextField
              id="outlined-basic"
              placeholder="Write information about your past medical history"
              variant="outlined"
              value={medicalHistoryText}
              onChange={handleMedicalHistoryTextChange}
            />
            <FileUpload
              onChange={handleMedicalHistoryFileUpload}
              value={medicalHistoryImg}
            />
          </div>
        )}

        {imagePreviewUrl1 && (
          <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
            <CustomButton type="button" onClick={() => setImagePreviewUrl1(null)} // Reset the image preview URL
            >X</CustomButton>
          </div>
            <div style={{ border: '1px solid black' }}>
              <img src={imagePreviewUrl1} alt="Selected" style={{ width: '100%' }} />
            </div>
          </>
        )}
        {pdfPreviewUrl1 && (
          <>
            <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
              <CustomButton type="button" onClick={() => setPdfPreviewUrl1(null)} // Reset the PDF preview URL
              >X</CustomButton>
            </div>
            <div style={{ border: '1px solid black' }}>
              <iframe
                src={pdfPreviewUrl1 + '#' + pdfOpenParams}
                title="PDF Preview"
                style={{ width: '100%', height: '500px' }}
              />
            </div>
          </>
        )}
      </SectionComponent>
      <SectionComponent>
        <label className="sec-label">PAST SURGICAL HISTORY?*</label>
        <div className="allergy-radio">
          <RadioButtonGroup
            name="radioOptions"
            value={surgicalHistory}
            options={options}
            onChange={handleIsSurgicalHistory}
          />
        </div>
        {surgicalHistory === "yes" && (
          <div>
            <label className="sec-label">
              GIVE MORE INFORMATION ABOUT YOUR PAST SURGICAL HISTORY
            </label>
            <CustomTextField
              id="outlined-basic"
              placeholder="Write information about your past medical history"
              variant="outlined"
              value={surgicalHistoryText}
              onChange={handleSurgicalHistoryTextChange}
            />
            <FileUpload
              onChange={handleSurgicalHistoryFileUpload}
              value={surgicalHistoryImg}
            />
          </div>
        )}

        {imagePreviewUrl2 && (
          <>
            <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
              <CustomButton type="button" onClick={() => setImagePreviewUrl2(null)} // Reset the image preview URL
              >X</CustomButton>
            </div>
            <div style={{ border: '1px solid black' }}>
              <img src={imagePreviewUrl2} alt="Selected" style={{ width: '100%' }} />
            </div>
          </>
        )}
        {pdfPreviewUrl2 && (
          <>
            <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
              <CustomButton type="button" onClick={() => setPdfPreviewUrl2(null)} // Reset the PDF preview URL
              >X</CustomButton>
            </div>
            <div style={{ border: '1px solid black' }}>
              <iframe
                src={pdfPreviewUrl2 + '#' + pdfOpenParams}
                title="PDF Preview"
                style={{ width: '100%', height: '500px' }}
              />
            </div>
          </>
        )}
      </SectionComponent>
      <SectionComponent>
        <label className="sec-label">
          GIVE DETAILS ON CURRENT MEDICATIONS AND GIVE DURATION OF PRESENT COMPLAIN*
        </label>
        <div className="allergy-radio">
          <RadioButtonGroup
            name="radioOptions"
            value={presentComplain}
            options={options}
            onChange={handleIsPresentComplain}
          />
        </div>
        {presentComplain === "yes" && (
          <div>
            <label className="sec-label">
              Give more information about your current medications
            </label>
            <CustomTextField
              id="outlined-basic"
              placeholder="Write information about your past medical history"
              variant="outlined"
              value={presentComplainText}
              onChange={handlePresentComplainTextChange}
            />
            <FileUpload
              onChange={handlePresentComplainFileUpload}
              value={presentComplainImg}
            />
          </div>
        )}
        {imagePreviewUrl3 && (
          <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
            <CustomButton type="button" onClick={() => setImagePreviewUrl3(null)} // Reset the image preview URL
            >X</CustomButton>
          </div>
            <div style={{ border: '1px solid black' }}>
              <img src={imagePreviewUrl3} alt="Selected" style={{ width: '100%' }} />
            </div>
          </>
        )}
        {pdfPreviewUrl3 && (
          <>
            <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
              <CustomButton type="button" onClick={() => setPdfPreviewUrl3(null)} // Reset the PDF preview URL
              >X</CustomButton>
            </div>
            <div style={{ border: '1px solid black' }}>
              <iframe
                src={pdfPreviewUrl3 + '#' + pdfOpenParams}
                title="PDF Preview"
                style={{ width: '100%', height: '500px' }}
              />
            </div>
          </>
        )}

      </SectionComponent>
      <Action
        backLink={previousPageURL}
        onClickNext={handleNextClick}
        onClearForm={handleClearForm}
      />
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
};

export default HealthHistory;
