import React, {useEffect} from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Banner from './section-components/banner';
import Client from './section-components/client';
import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';
import { useHistory } from 'react-router-dom';

const Testimonial = () => {
    const headerImage = localStorage.getItem("testimonialsimage");
    const history = useHistory();

    useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page on component mount
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0); // Scroll to the top of the page on route change
		});
		return () => unlisten();
	}, [history]);

    return <div>
        <Navbar />
        <PageHeader image={headerImage} headertitle="Testimonial" />
        <Client />
        <Subscribe />
        <Footer />
    </div>
}

export default Testimonial