import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import Page from '../pro-components/Page';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import CheckboxComponent from '../pro-components/CCheckBox';
import Webcam from 'react-webcam';
import FileUpload from '../pro-components/CFileUpload';
import CustomButton from '../pro-components/CButton';
import Action from '../pro-components/Action';
import { useHistory } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { BsPersonFill, BsCheckCircle } from 'react-icons/bs';
import Ticket from '../pro-components/ticket';
import CustomModal from "../../global-components/modal";
import { updatePro1, uploadMedia } from '../../../api';

const Contractualwork = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const [showCheckboxError, setShowCheckboxError] = useState(false);
  const [isCheckedTC, setIsCheckedTC] = useState(false);
  const [sectionData, setSectionData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isModalScrolled, setIsModalScrolled] = useState(false);
  const [isAgreeEnabled, setIsAgreeEnabled] = useState(false);
  const [isScrollbarAtEnd, setIsScrollbarAtEnd] = useState(false);
  const [checkboxError1, setCheckboxError1] = useState("");
  const [showCheckboxError1, setShowCheckboxError1] = useState(false);
  const [mainFileError, setMainFileError] = useState('');
  const [capctureImgPhotoerror, setcapctureImgPhotoerror] = useState("")
  let history = useHistory();
  const bodyRef = useRef(null);
  const previousPageURL = '/pro/section4';
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showWebcamModal, setShowWebcamModal] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [isAgreeButtonClicked, setIsAgreeButtonClicked] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [photoProof, setPhotProof] = useState(null);
  const pdfOpenParams = 'toolbar=0&navpanes=0&scrollbar=0';
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user',
  };

  const handleCaptureButtonClick = () => {
    setShowWebcamModal(true);
    setShowWebcam(true); // Show webcam feed when modal is opened
  };

  const handleCapturePhoto = () => {
    const video = webcamRef.current.video;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const photo = canvas.toDataURL('image/jpeg');
    setCapturedImage(photo);
    setShowWebcam(false);
  };

  const handleRecaptureButtonClick = () => {
    setCapturedImage(null);
    setShowWebcam(true);
    setUploadSuccess(false); // Reset upload success state
  };

  const handleUploadImage = async () => {
    if (capturedImage) {
      try {
        setLoading(true); // Set loading state to true

        const blob = await fetch(capturedImage).then((res) => res.blob());
        const uploadedImagePath = await uploadMedia(blob, 'Webcam');
        console.log('Image URL:', uploadedImagePath);

        // Store the uploaded image URL
        setUploadedImageUrl(uploadedImagePath);
        setCapturedImage(uploadedImagePath);
        // Further actions with the image URL if needed
        setcapctureImgPhotoerror("");
        setUploadSuccess(true);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading state to false
        setShowWebcamModal(false); // Close the modal after uploading
      }
    }
  };

  // useEffect(() => {
  //   if (capturedImage && capturedImage !== 'data:,') {
  //     setUploadSuccess(false); // Reset the upload success state
  //     setcapctureImgPhotoerror(""); // Clear any previous error message
  //   } else {
  //     setUploadSuccess(false); // Reset the upload success state
  //     setcapctureImgPhotoerror("Please capture a valid image."); // Display an error message
  //   }
  // }, [capturedImage]);



  const handleWebcamModalClose = () => {
    setShowWebcamModal(false);
    setShowWebcam(null); // Hide the webcam feed
    // setCapturedImage(null); // Reset the captured image
    // setUploadSuccess(false); // Reset the upload success state
    setLoading(false); // Reset the loading state
  };

  useEffect(() => {
    if (modalImage) {
      setCapturedImage(modalImage);
      setModalImage(null);
      setShowWebcamModal(false);
      setUploadSuccess(false);
      setShowWebcam(false);
    }
  }, [modalImage]);

  useEffect(() => {
    let webcamLoadingTimeout;
    if (showWebcam) {
      // Simulate webcam loading with a delay
      webcamLoadingTimeout = setTimeout(() => {
        setLoading(false); // Set loading state to false after delay
      }, 2000);
    }

    return () => {
      clearTimeout(webcamLoadingTimeout);
    };
  }, [showWebcam]);

  useEffect(() => {
    const isLoading = setTimeout(() => {
      setLoading(false);
    }, 2300);

    return () => {
      clearTimeout(isLoading);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const target = bodyRef.current;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      const scrollTop = target.scrollTop;
      const maxScrollTop = scrollHeight - clientHeight;
      setIsModalScrolled(scrollTop === maxScrollTop);

      if (scrollTop === maxScrollTop) {
        setIsAgreeEnabled(true);
      } else {
        setIsAgreeEnabled(false);
      }
    };

    if (bodyRef.current) {
      bodyRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (bodyRef.current) {
        bodyRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isScrollbarAtEnd) {
      setIsAgreeEnabled(true);
    } else {
      setIsAgreeEnabled(false);
    }
  }, [isScrollbarAtEnd]);

  const updateSectionData = async (sectionData) => {
    const proId = localStorage.getItem("proId");
    console.log("pro id from local storage", proId);
    if (!proId) {
      console.log("Pro ID not found in local storage");
      return;
    }

    try {
      const existingSectionData = JSON.parse(localStorage.getItem("firstPageData"));

      const section_data = [
        {
          0: existingSectionData, // Include the first page data at index 0
        },
        {
          1: JSON.parse(localStorage.getItem('secondPageData')), // Include the second page data at index 1
        },
        {
          2: JSON.parse(localStorage.getItem('thirdPageData')), // Include the second page data at index 1
        },
        {
          3: JSON.parse(localStorage.getItem('fourthPageData')), // Include the second page data at index 1
        },
        {
          4: sectionData, // Include the third page data at index 2
        },
      ]

      console.log("updated data of pro");
      console.log(section_data);
      const updatedSectionData = await updatePro1(proId, section_data);
      if (updatedSectionData) {
        console.log("updated data of pro");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updateDoctor1.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileUpload = async (file) => {

    try {
      const mediaType = "PRO";

      const uploadedImagePath = await uploadMedia(file, mediaType);

      console.log('Image URL:', uploadedImagePath);
      // Determine the file type and set the corresponding preview URL
      if (file.type.startsWith('image/')) {
        setImagePreviewUrl(URL.createObjectURL(file));
        setPdfPreviewUrl(null);
        setPhotProof(uploadedImagePath);
        setMainFileError("");
      } else if (file.type === 'application/pdf') {
        setPdfPreviewUrl(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
        setImagePreviewUrl(null);
        setPhotProof(uploadedImagePath);
        setMainFileError("");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextClick = async (event) => {
    event.preventDefault();

    if (!isChecked || checkboxError) {
      showErrorModal("Alert", "Contractual Commission work field is required.");
      setCheckboxError(true);
      return;
    }

    if (!isCheckedTC) {
      showErrorModal("Alert", "Company\'s terms & conditions field is required.");
      setCheckboxError1(true);
      return;
    }

    if (!photoProof) {
      showErrorModal("Alert", "Please provide your current photo ID Proof.");
      setMainFileError("Please upload a file.");
      return;
    }
    // if (!capturedImage) {
    //   showErrorModal("Alert", "Please capture your photo.");
    //   setcapctureImgPhotoerror("please Capcture a Image and Upload");
    //   return;

    // }
    else {
      // Store all the data in local storage
      const fifthPageData = {
        isChecked: isChecked,
        isCheckedTC: isCheckedTC,
        capturedImage: capturedImage,
        photoproof: photoProof,
      };
      localStorage.setItem('fifthPageData', JSON.stringify(fifthPageData));

      // Update the section data in the backend
      await updateSectionData(fifthPageData);

      history.push('/pro/submit-phase1');
    }
  };

  useEffect(() => {
    const storedFifthPageData = localStorage.getItem('fifthPageData');
    if (storedFifthPageData) {
      const parsedData = JSON.parse(storedFifthPageData);
      // Use the parsedData as required
      setIsChecked(parsedData.isChecked || false);
      setIsCheckedTC(parsedData.isCheckedTC || false);

      setUploadedImageUrl(parsedData.capturedImage);
      setCapturedImage(parsedData.capturedImage)

      setPhotProof(parsedData.photoproof || null);
      if (parsedData.photoproof && parsedData.photoproof.endsWith('.pdf')) {
        setPdfPreviewUrl(`https://docs.google.com/viewer?url=${encodeURIComponent(parsedData.photoproof)}&embedded=true`);
      } else {
        setImagePreviewUrl(parsedData.photoproof);
      }
    }
  }, []);

  const handleCheckboxChange = (checked) => {
    setIsChecked(checked);
    setCheckboxError(checked ? "" : "Contractual Commission work field is required");

    setSectionData((prevData) => ({
      ...prevData,
      isChecked: checked,
    }));
  };

  const handleCheckboxChangeBlur = () => {
    setShowCheckboxError(true);
  };

  const handleCheckboxChangeTC = (checked) => {
    let error = '';
    if (!isAgreeButtonClicked) {
      error = 'Please click on the "View company\'s terms & conditions" button and then click "Agree" before checking the checkbox.';
      setCheckboxError1(error);
      return;
    }
    setIsCheckedTC(checked);
    setCheckboxError1(checked ? "" : "Company's terms & conditions field is required");

    setSectionData((prevData) => ({
      ...prevData,
      isCheckedTC: checked,
    }));
  };

  const handleModalScroll = (event) => {
    const target = event.target;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    const scrollTop = target.scrollTop;
    const maxScrollTop = scrollHeight - clientHeight;
    setIsModalScrolled(scrollTop === maxScrollTop);

    if (isCheckedTC && scrollTop === maxScrollTop) {
      setIsAgreeEnabled(true);
    } else {
      setIsAgreeEnabled(false);
    }
  };

  const handleClearForm = () => {
    setIsChecked(false);
    setIsCheckedTC(false);
    setPhotProof(false);
    setImagePreviewUrl(false);
    setCapturedImage("");
    setPdfPreviewUrl("");
    setImagePreviewUrl("");
    setUploadedImageUrl("");
    setcapctureImgPhotoerror("");
  };

  const handleViewClick = () => {
    setShowModal(true);
  };

  const handleAgreeClick = () => {
    setIsCheckedTC(true);
    setIsAgreeEnabled(false);
    setIsScrollbarAtEnd(false);
    setShowModal(false);
    const target = bodyRef.current;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleCheckboxChangeTCBlur = () => {
    setShowCheckboxError1(true);
  };

  const handleCloseErrorModal = () => {

    // Hide the error modal
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <Page>
      <FirstSection>
        <h4>Terms and Conditions</h4>
      </FirstSection>
      <SectionComponent>
        IT'S A CONTRACTUAL COMMISSION BASED WORK, ARE YOU STILL INTRESTED TO JOIN US?*
        <br />
        <CheckboxComponent label="Yes" checked={isChecked}
          onChange={handleCheckboxChange}

          onBlur={handleCheckboxChangeBlur} error={!!checkboxError}
          helperText={checkboxError} />
        {showCheckboxError && checkboxError && (
          <div className="error-msg">{checkboxError}</div>
        )}
      </SectionComponent>

      <SectionComponent>
        I AGREE TO TERMS AND CONDITIONS*
        <br />
        <CheckboxComponent label="Yes" checked={isCheckedTC}
          // onChange={handleCheckboxChangeTC} 
          onChange={() => handleViewClick()}
          onBlur={handleCheckboxChangeTCBlur} error={!!checkboxError1}
          helperText={checkboxError1} />
        {showCheckboxError1 && checkboxError1 && (
          <div className="error-msg">{checkboxError1}</div>
        )}
        <div style={{ paddingTop: '10px' }}>
          <CustomButton type="button" onClick={handleViewClick}>
            View
          </CustomButton>
        </div>
      </SectionComponent>
      <SectionComponent>
        PLEASE PROVIDE YOUR PHOTO ID PROOF*
        <div>
          <FileUpload onChange={handleFileUpload} />
          {mainFileError && <div className="error-msg">{mainFileError}</div>}
          <div>
            {imagePreviewUrl && (
              <>
                <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                  <CustomButton
                    type="button"
                    onClick={() => setImagePreviewUrl(null)} // Reset the image preview URL
                  >
                    X
                  </CustomButton>
                </div>
                <div style={{ border: '1px solid black' }}>
                  <img src={imagePreviewUrl} alt="Selected" style={{ width: '100%' }} />
                </div>
              </>
            )}
            {pdfPreviewUrl && (
              <>
                <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                  <CustomButton type="button" onClick={() => setPdfPreviewUrl(null)}>X</CustomButton>
                </div>
                <div style={{ border: '1px solid black' }}>
                  <iframe
                    src={pdfPreviewUrl}
                    title="PDF Preview"
                    style={{ width: '100%', height: '500px' }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </SectionComponent>

      <SectionComponent>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          PLEASE PROVIDE YOUR CURRENT PHOTO FOR THE PURPOSE OF IDENTIFICATION*
        </div>
        <div style={{ paddingTop: '15px' }}>
          {!showWebcam && !capturedImage && !uploadSuccess && !uploadedImageUrl && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BsPersonFill size={25} color="gray" style={{ marginRight: '10px' }} />
              <CustomButton onClick={handleCaptureButtonClick}>Capture Your Image</CustomButton>
            </div>
          )}
          {(uploadSuccess || uploadedImageUrl) && (
            <div>
              <BsPersonFill size={25} color="green" style={{ marginRight: '10px' }} />
              <BsCheckCircle size={20} color="green" style={{ marginRight: '10px' }} />
            </div>
          )}
        </div>
        {capctureImgPhotoerror && <div className="error-msg">{capctureImgPhotoerror}</div>}
      </SectionComponent>
      <Action backLink={previousPageURL} onClickNext={handleNextClick} onClearForm={handleClearForm} />
      <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
        <Ticket variant="contained" color="secondary" section="PRO: Application Form - Terms and Conditions" />
      </div>
      <Modal show={showModal} className="newsletter-popup view-popup" backdrop="static">
        <Modal.Header className="modal-close-button" style={{ width: '100%' }}>
          <Modal.Title >Terms & Conditions for a Patient Relation Officer (P.R.O.) working with MITS Hospitality LLP:</Modal.Title>
        </Modal.Header>
        <Modal.Body
          ref={bodyRef}
          style={{
            maxHeight: '400px', // Adjust this value as needed
            overflowY: 'scroll',
          }}
          onScroll={(e) => {
            const target = e.target;
            const scrollHeight = target.scrollHeight;
            const clientHeight = target.clientHeight;
            const scrollTop = target.scrollTop;

            // Calculate a threshold for determining if the modal is scrolled to the end
            const scrollThreshold = 5; // Adjust this value as needed

            const isAtEnd = scrollHeight - scrollTop - clientHeight <= scrollThreshold;

            setIsModalScrolled(isAtEnd);

            if (isAtEnd) {
              setIsAgreeEnabled(true);
            } else {
              setIsAgreeEnabled(false);
            }
          }}
        >
          {/* Add your terms and conditions text here */}
          <span style={{ display: 'inline-block' }}>1. The P.R.O. is responsible for promoting MedITravelS's website and services to potential clients in their respective location. They will build relationships with potential clients, answer their questions about medical tourism in India and MedITravelS's services, and refer them to appropriate healthcare providers.</span>
          <span style={{ display: 'inline-block' }}>2. The P.R.O. must adhere to MITS Hospitality LLP's branding and marketing guidelines when promoting MedITravelS. This includes using approved messaging, logos, and other marketing materials provided by MITS Hospitality LLP.</span>
          <span style={{ display: 'inline-block' }}>3. The P.R.O. is only authorized to provide general information about medical tourism in India and to refer clients to appropriate healthcare providers. If the P.R.O. is unsure about a particular medical question or inquiry, they must refer the client to a healthcare professional or a MedITravelS team member.</span>
          <span style={{ display: 'inline-block' }}>4. The P.R.O. must act with professionalism and integrity at all times when representing MITS Hospitality LLP. This includes treating clients and healthcare providers courteously and respectfully, avoiding false or misleading claims, and steering clear of any conflicts of interest.</span>
          <span style={{ display: 'inline-block' }}>5. The P.R.O. is considered an independent contractor and is not an employee of MITS Hospitality LLP. They are responsible for their own taxes, insurance, and other business-related expenses. MITS Hospitality LLP will provide the P.R.O. with a commission structure based on their performance.</span>
          <span style={{ display: 'inline-block' }}>6. The P.R.O. will be compensated for their services based on an agreed-upon commission structure. The specific commission structure will be outlined in a separate agreement between the P.R.O. and MITS Hospitality LLP.</span>
          <span style={{ display: 'inline-block' }}>7. MITS Hospitality LLP reserves the right to terminate the P.R.O.'s services at any time for justified reasons.</span>
          <span style={{ display: 'inline-block' }}>8. The P.R.O. is responsible for complying with all local laws and regulations in their respective location when promoting MedITravelS.</span>
          <span style={{ display: 'inline-block' }}>9. The P.R.O. is not authorized to make any representations or warranties on behalf of MITS Hospitality LLP unless specifically authorized in writing by the company.</span>
          <span style={{ display: 'inline-block' }}>10. These terms and conditions represent the whole agreement between the P.R.O. and MITS Hospitality LLP, overriding any previous agreements or understandings, whether they were written or verbal.</span>
          <span style={{ display: 'inline-block' }}>11. The P.R.O. is responsible for ensuring that all promotional materials and communications comply with local laws and regulations, including those related to advertising and healthcare.</span>
          <span style={{ display: 'inline-block' }}>12. The P.R.O. must not engage in any conduct that could damage the reputation or credibility of MITS Hospitality LLP or its services.</span>
          <span style={{ display: 'inline-block' }}>13. The P.R.O. must maintain accurate records of all referrals and communications with potential clients and provide regular reports to MITS Hospitality LLP.</span>
          <span style={{ display: 'inline-block' }}>14. The P.R.O. must promptly notify MITS Hospitality LLP of any potential legal or regulatory issues related to their promotional activities or referrals.</span>
          <span style={{ display: 'inline-block' }}>15. MITS Hospitality LLP may provide training and support to the P.R.O. to ensure that they are able to effectively promote the company's services and adhere to all applicable laws and regulations.</span>
          <span style={{ display: 'inline-block' }}>16. The P.R.O. must maintain the confidentiality of all client information and not disclose any personal or medical information without the client's express consent.</span>
          <span style={{ display: 'inline-block' }}>17. The P.R.O. must promptly notify MITS Hospitality LLP of any conflicts of interest or potential conflicts of interest that could impact their ability to effectively promote the company's services.</span>
          <span style={{ display: 'inline-block' }}>18. MITS Hospitality LLP has the right to terminate the P.R.O.'s services with immediate effect if the P.R.O. violates any of these terms and conditions, or if their actions could potentially harm the reputation or credibility of the company.</span>
          <span style={{ display: 'inline-block' }}>19. The P.R.O. must comply with all applicable anti-corruption laws and regulations when promoting MedITravelS services and must not engage in any conduct that could be considered a violation of such laws.</span>
          <span style={{ display: 'inline-block' }}>20. These terms and conditions are governed by the laws of the jurisdiction in which MITS Hospitality LLP is incorporated and any disputes arising from or related to these terms and conditions will be resolved in accordance with the laws of such jurisdiction.</span>
          <span style={{ display: 'inline-block' }}>21. The P.R.O. must comply with all applicable data protection laws and regulations when handling any personal data or sensitive information related to clients or potential clients.</span>
          <span style={{ display: 'inline-block' }}>22. The P.R.O. is not authorized to enter into any agreements or contracts on behalf of MITS Hospitality LLP without obtaining prior written consent from the company.</span>
          <span style={{ display: 'inline-block' }}>23. The P.R.O. must promptly notify MITS Hospitality LLP of any changes to their contact information or other relevant details.</span>
          <span style={{ display: 'inline-block' }}>24. The P.R.O. is responsible for obtaining all necessary licenses, permits, and authorizations required to legally conduct their promotional activities in the relevant jurisdictions.</span>
          <span style={{ display: 'inline-block' }}>25. The P.R.O. must provide accurate and truthful information to clients and potential clients about MedITravelS services, capabilities, and limitations.</span>
          <span style={{ display: 'inline-block' }}>26. The P.R.O. must not offer any discounts, rebates, or other incentives to clients or potential clients without obtaining prior written approval from MITS Hospitality LLP.</span>
          <span style={{ display: 'inline-block' }}>27. The P.R.O. must comply with all applicable anti-money laundering and counter-terrorism financing laws and regulations when promoting MedITravelS services.</span>
          <span style={{ display: 'inline-block' }}>28. The P.R.O. must avoid engaging in any activities that could be perceived as misleading, deceptive, or unfair during their promotions.</span>
          <span style={{ display: 'inline-block' }}>29. The P.R.O. must comply with all applicable trade and export control laws and regulations when promoting MedITravelS services.</span>
          <span style={{ display: 'inline-block' }}>30. MITS Hospitality LLP may amend these terms and conditions from time to time. The P.R.O. will be notified of any such changes. Their continued promotion of MedITravelS services will constitute acceptance of any revised terms and conditions.</span>
          <span style={{ display: 'inline-block' }}>31. The P.R.O. must understand and clarify to clients that MedITravelS is not a direct service provider but a platform where service providers and clients meet.</span>
          {/* </div> */}
        </Modal.Body>

        <Modal.Footer>
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <Button
              className={`agree-btn ${isModalScrolled ? 'scrolled' : ''} ${isAgreeEnabled ? '' : 'disabled-btn'}`}
              onClick={isAgreeEnabled ? handleAgreeClick : undefined}
              style={{
                backgroundColor: isModalScrolled ? '#291211' : '#f4a460',
                pointerEvents: isModalScrolled && isAgreeEnabled ? 'auto' : 'none',
                filter: isModalScrolled ? 'none' : 'grayscale(100%)',
              }}
            >
              Agree
            </Button>
            <Button className="btn btn-yellow" variant="secondary" onClick={() => setShowModal(false)} label='Close'>
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showWebcamModal} className="newsletter-popup" backdrop="static">
        <Modal.Header>
          <Modal.Title>Capture Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showWebcam && (
            <div className='capture-modal' style={{ position: 'relative', width: '500px' }}>
              {loading && (
                <div className="loading" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <div></div>
                  <div></div>
                </div>
              )}
              {/* {!loading && ( */}
              <Webcam
                audio={false}
                height={300}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={500}
                videoConstraints={videoConstraints}
                imageSmoothing={true}
                mirrored={true}
                style={{ display: 'block' }}
              />
              {/* )} */}
              <div className='capture-action' style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <CustomButton onClick={handleCapturePhoto} disabled={loading}>
                  Take a Shot
                </CustomButton>
                <CustomButton variant="secondary" onClick={handleWebcamModalClose}>
                  Close
                </CustomButton>
              </div>
            </div>
          )}
          {capturedImage && !uploadSuccess && !loading && (
            <div>
              <img src={capturedImage} alt="Captured" style={{ transform: 'scaleX(-1)' }} />
              <div style={{ padding: '20px 0px 0px', display: 'flex', justifyContent: 'space-between' }}>
                <CustomButton onClick={handleUploadImage}>Upload</CustomButton>
                <CustomButton onClick={handleRecaptureButtonClick}>Try Again</CustomButton>
              </div>
            </div>
          )}
          {capturedImage && !uploadSuccess && loading && (
            <div className="loading">
              <div></div>
              <div></div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
};
export default Contractualwork;