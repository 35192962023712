import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

const CustomTextField = (props) => {
  // Define CSS styles for the label
  const labelStyles = {
    fontSize: '16px', 
    textAlign: 'center', 
  };

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        fullWidth
        InputLabelProps={{
          style: labelStyles, 
        }}
        {...props}
      />
    </div>
  );
};

export default CustomTextField;
