import React from "react";

const ParagraphSkeleton = () => {
  return (
    <div className="skeleton-loader2">
      <div className="skeleton-line2"></div>
      <div className="skeleton-line2"></div>
      <div className="skeleton-line2"></div>
      <div className="skeleton-line2"></div>
      <div className="skeleton-line2"></div>
      <div className="skeleton-line2"></div>
      <div className="skeleton-line2"></div>
      <div className="skeleton-line2"></div>
      <div className="skeleton-line2"></div>
      <div className="skeleton-line2"></div>
      <div className="skeleton-line2"></div>
      <div className="skeleton-line2"></div>
    </div>
  );
};

export default ParagraphSkeleton;
