import React, { Component, useEffect, useState, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, HashRouter, Route, Switch, Redirect } from 'react-router-dom';
// import DefaultLayout from './DefaultLayout';
// import Loadable from './@core/components/Loadable';
import Home_CS from './components/section-components/home-cs';
import HomeV1 from './components/home-v1';
import AboutUs from './components/about';
import TourList from './components/tour-list';
import TourDetails from './components/tour-details';
import Contact from './components/contact';
import Error from './components/error';
import CommingSoon from './components/comming-soon';
import UserProfile from './components/user-profile';
import Blog from './components/blog';
import BlogV2 from './components/blog-v2';
// import BlogV3 from './components/blog-v3';
import BlogDetails from './components/blog-details';
import TreatmentDetails from './components/treatment-details';
import Treatment_Blog from './components/section-components/treatment_blog';
import subgroup1 from './components/section-components/subgroup1';
import Signin from './components/login-signup/screens/Signin';
import Signup from './components/login-signup/screens/Signup';
// import Profile from './components/profile';
import Review from './components/section-components/review';
import Section1 from './components/PRO/pages/Section1';
import Section2 from './components/PRO/pages/Section2'
import Section3 from './components/PRO/pages/Section3';
import Section4 from './components/PRO/pages/Section4';
import Section5 from './components/PRO/pages/Section5';
import Section12 from './components/PRO/Phase2/Section1'
import Section22 from './components/PRO/Phase2/Section2';
import Section32 from './components/PRO/Phase2/Section3';
import Section42 from './components/PRO/Phase2/Section4';
import Section52 from './components/PRO/Phase2/Section5';
import Section62 from './components/PRO/Phase2/Section6';
import Section72 from './components/PRO/Phase2/Section7';
import SubmitPhase1 from './components/PRO/pages/SubmitPhase1';
import SubmitPhase2 from './components/PRO/Phase2/SubmitPhase2';
import ProDashboard from './components/PRO/Dashboard/ProDashboard';
import Sidebar from './components/PRO/Dashboard/Sidebar';
import Layout from './components/PRO/Dashboard/Layout';
import DocSection1 from './components/PRO/doctorForm1/Section1';
import DocSection2 from './components/PRO/doctorForm1/Section2';
import DocSection3 from './components/PRO/doctorForm1/Section3';
import DocSection4 from './components/PRO/doctorForm1/Section4';
import DocSection5 from './components/PRO/doctorForm1/Section5';
import DocSection12 from './components/PRO/doctorForm2/Section1'
import DocSection22 from './components/PRO/doctorForm2/Section2';
import DocSection32 from './components/PRO/doctorForm2/Section3';
import DocSection42 from './components/PRO/doctorForm2/Section4';
import DocSubmitPhase1 from './components/PRO/doctorForm1/SubmitPhase1';
import DocSubmitPhase2 from './components/PRO/doctorForm2/SubmitPhase2';
import DocDashboard from './components/PRO/doctorDashboard/DocDashboard';
import DocSidebar from './components/PRO/doctorDashboard/Sidebar';
import DocLayout from './components/PRO/doctorDashboard/Layout';
import Patientsec1 from './components/PRO/PatientForm/section1';
import Patientsec2 from './components/PRO/PatientForm/section2';
import Patientsec3 from './components/PRO/PatientForm/section3';
import Patientsubmit from './components/PRO/PatientForm/SubmitPatient';
import ConsultationForm from './components/PRO/ConsultForm/section';
import PatientDashboard from './components/PRO/PatientDashboard/PatientDashboard';
import PatientSidebar from './components/PRO/PatientDashboard/sidebar';
import PatientLayout from './components/PRO/PatientDashboard/PatientLayout';
import TicketPage from './components/PRO/Dashboard/ticketpage';
import PatTicketPage from './components/PRO/PatientDashboard/PatTicketpage';
import DocTicketPage from './components/PRO/doctorDashboard/Docticketpage';
import SelectTreatment from './components/PRO/PatientDashboard/SelectTreatment';
import ProtectedRoute from './components/PRO/ProtectedRoute';
import TermsConditions from './components/section-components/Terms/termsconditions';
import PrivacyPolicy from './components/section-components/Terms/privacypolicy';
import Testimonial from './components/testimonial';
import SuccessStripe from './components/PRO/ConsultForm/SuccessStripe';
import ProList from './components/global-components/proList'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// import LocalizationProvider from '@mui/x-date-pickers/LocalizationProvider'

const Root = () => {
	const stripePromise = loadStripe("pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3");
	// const stripePromise = loadStripe('pk_test_51NMLwISJgRPCKe21pDXCohlQN1hVQF4k6E3CvoeQc9xbjWc5W10LFi1a48HRoM884TxNFXii34NN3jr7Q4tIA97e00dNnyrtOB');


	// useEffect(() => {

	// 	const fetchClientSecret = async () => {
	// 		try {
	// 			const response = await fetch("/create-payment-intent", {
	// 				method: "POST",
	// 				headers: { "Content-Type": "application/json" },
	// 				body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
	// 			});
	// 			const data = await response.json();
	// 			setClientSecret(data.clientSecret);
	// 		} catch (error) {

	// 		}
	// 	};

	// 	fetchClientSecret();

	// 	return () => {

	// 		setIsMounted(false);
	// 	};
	// }, []);

	// const appearance = {
	// 	theme: 'stripe',
	// };
	// const options = {
	// 	clientSecret,
	// 	appearance,
	// };
	const userDataJSON = localStorage.getItem("user");
	console.log(userDataJSON);
	const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};

	// Get user role from the userData object
	const userRole = userData && userData.role ? userData.role : null;// Replace this with your own function to retrieve the user's role
	console.log("role", userRole)

	const RouteGuard = ({ path, element }) => {
		if (
			(path.startsWith('/pro') && userRole !== 'PRO') ||
			(path.startsWith('/doc') && userRole !== 'Doctor') ||
			(path.startsWith('/patient') && userRole !== 'Patient')
		) {
			return <Redirect to="/" />;
		}

		return element;
	};

	const isAuthenticated = false; // Change this to true when the user is authenticated
	const ProtectedRoutes = ({ component: Component, path, ...rest }) => (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect to={{ pathname: '/', state: { from: props.location } }} />
				)
			}
		/>
	);

	const App = () => {
		// Prevent users from directly accessing the /pro, /doc, and /patient routes
		const handleRouteChange = (event) => {
			const { pathname } = event.location;
			const isProtectedRoute = ['/pro', '/doc', '/patient'].includes(pathname);
			if (isProtectedRoute && !isAuthenticated) {
				event.preventDefault();
				// Redirect the user to the login page or show an appropriate message
			}
		};
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			{/* {clientSecret && ( */}
			<Elements stripe={stripePromise}>
				<HashRouter basename="/">
					<div>
						<Switch>
							{/* <Route exact path="/" component={Home_CS} /> */}
							<Route exact path="/" component={HomeV1} />
							<Route path="/about" component={AboutUs} />
							<Route path="/tour-list" component={TourList} />
							{/* <Route path="/tour-list-v2" component={TourListV2} />
	                    <Route path="/tour-list-v3" component={TourListV3} /> */}
							<Route path="/tour-details" component={TourDetails} />
							{/* <Route path="/destination-list" component={DestinationLIst} />
	                    <Route path="/destination-list-v2" component={DestinationLIstV2} />
	                    <Route path="/destination-details" component={DestinationDetails} /> */}
							{/* <Route path="/gallery" component={Gallery} />
	                    <Route path="/gallery-details" component={GalleryDetails} /> */}
							{/* <Route path="/faq" component={Faq} /> */}
							<Route path="/contact" component={Contact} />
							<Route path="/error" component={Error} />
							<Route path="/comming-soon" component={CommingSoon} />
							<Route path="/user-profile" component={UserProfile} />
							<Route path="/blog-list" component={Blog} />
							<Route path="/blog" component={BlogV2} />
							{/* <Route path="/blog-v3" component={BlogV3} /> */}
							<Route path="/blog-details" component={BlogDetails} />
							<Route path="/treatment-details" component={TreatmentDetails} />
							<Route path="/treatment-blog" component={Treatment_Blog} />
							<Route path="/subgroup1" component={subgroup1} />
							<Route path="/signin" component={Signin} />
							<Route path="/signup" component={Signup} />
							<Route path="/testimonial" component={Testimonial} />
							<Route path="/terms-conditions" component={TermsConditions} />
							<Route path="/privacy-policy" component={PrivacyPolicy} />
							<Route path="/success-payment" component={SuccessStripe} />
							<Route path="/pro-list" component={ProList} />
							{/* <Route path="/profile" component={Profile}/> */}
							<Route path="/review" component={Review} />
							<Route path="/pro/:path?" exact>
								<Layout>
									<Switch>
										<ProtectedRoute>
											<Route path="/pro/section1" allowedRoles={['PRO']} currentUserRole={userRole} component={Section1} />
											<Route path="/pro/section2" allowedRoles={['PRO']} currentUserRole={userRole} component={Section2} />
											<Route path="/pro/section3" allowedRoles={['PRO']} currentUserRole={userRole} component={Section3} />
											<Route path="/pro/section4" allowedRoles={['PRO']} currentUserRole={userRole} component={Section4} />
											<Route path="/pro/section5" allowedRoles={['PRO']} currentUserRole={userRole} component={Section5} />
											<Route path="/pro/section1-2" allowedRoles={['PRO']} currentUserRole={userRole} component={Section12} />
											<Route path="/pro/section2-2" allowedRoles={['PRO']} currentUserRole={userRole} component={Section22} />
											<Route path="/pro/section3-2" allowedRoles={['PRO']} currentUserRole={userRole} component={Section32} />
											<Route path="/pro/section4-2" allowedRoles={['PRO']} currentUserRole={userRole} component={Section42} />
											<Route path="/pro/section5-2" allowedRoles={['PRO']} currentUserRole={userRole} component={Section52} />
											<Route path="/pro/section6-2" allowedRoles={['PRO']} currentUserRole={userRole} component={Section62} />
											<Route path="/pro/section7-2" allowedRoles={['PRO']} currentUserRole={userRole} component={Section72} />
											<Route path="/pro/submit-phase1" allowedRoles={['PRO']} currentUserRole={userRole} component={SubmitPhase1} />
											<Route path="/pro/submit-phase2" allowedRoles={['PRO']} currentUserRole={userRole} component={SubmitPhase2} />
											<Route path="/pro/prodashboard" allowedRoles={['PRO']} currentUserRole={userRole} component={ProDashboard} />
											<Route path="/pro/proticket" allowedRoles={['PRO']} currentUserRole={userRole} component={TicketPage} />
											<Route path="/pro/prosidebar" allowedRoles={['PRO']} currentUserRole={userRole} component={Sidebar} />
										</ProtectedRoute>
									</Switch>
								</Layout>
							</Route>

							<Route path="/doc/:path?" exact>
								<DocLayout>
									<Switch>
										<ProtectedRoute>
											<Route path="/doc/section1" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocSection1} />
											<Route path="/doc/section2" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocSection2} />
											<Route path="/doc/section3" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocSection3} />
											<Route path="/doc/section4" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocSection4} />
											<Route path="/doc/section5" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocSection5} />
											<Route path="/doc/section1-2" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocSection12} />
											<Route path="/doc/section2-2" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocSection22} />
											<Route path="/doc/section3-2" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocSection32} />
											<Route path="/doc/section4-2" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocSection42} />
											<Route path="/doc/submit-phase1" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocSubmitPhase1} />
											<Route path="/doc/submit-phase2" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocSubmitPhase2} />
											<Route path="/doc/docdashboard" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocDashboard} />
											<Route path="/doc/docticket" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocTicketPage} />
											<Route path="/doc/docsidebar" allowedRoles={['Doctor']} currentUserRole={userRole} component={DocSidebar} />
										</ProtectedRoute>
									</Switch>
								</DocLayout>
							</Route>

							<Route path="/patient/:path?" exact>
								<PatientLayout>
									<Switch>
										{/* <ProtectedRoute> */}
										<Route path="/patient/sec-1" allowedRoles={['Patient']} currentUserRole={userRole} component={Patientsec1} />
										<Route path="/patient/sec-2" allowedRoles={['Patient']} currentUserRole={userRole} component={Patientsec2} />
										<Route path="/patient/sec-3" allowedRoles={['Patient']} currentUserRole={userRole} component={Patientsec3} />
										<Route path="/patient/submit" allowedRoles={['Patient']} currentUserRole={userRole} component={Patientsubmit} />
										<Route path="/patient/patientdashboard" allowedRoles={['Patient']} currentUserRole={userRole} component={PatientDashboard} />
										<Route path="/patient/patticket" allowedRoles={['Patient']} currentUserRole={userRole} component={PatTicketPage} />
										<Route path="/patient/patienttreatment" allowedRoles={['Patient']} currentUserRole={userRole} component={SelectTreatment} />
										<Route path="/patient/patientsidebar" allowedRoles={['Patient']} currentUserRole={userRole} component={PatientSidebar} />
										<Route path="/patient/consultation" allowedRoles={['Patient']} currentUserRole={userRole} component={ConsultationForm} />
										{/* </ProtectedRoute> */}
									</Switch>
								</PatientLayout>
							</Route>
							<Redirect to="/" />
						</Switch>
					</div>
				</HashRouter>
			</Elements>
			{/* )} */}
		</LocalizationProvider>
	)
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('viaje'));