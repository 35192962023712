import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../pro-components/Page';
import SubmitAction from '../pro-components/submitaction';
import FirstSection from '../pro-components/FirstSection'
import CustomModal from "../../global-components/modal";
import { updateDoctor2, sendEmail, createDoc2Status } from '../../../api';

const SubmitPage = () => {
  const history = useHistory();
  const [sectionData, setSectionData] = useState('');
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const docId = localStorage.getItem('docId');
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const updateSectionData = async (sectionData) => {
    console.log("user id from local storage", userData.id);
    const userId = userData.id;
    if (!userId) {
      console.log("User ID not found in local storage");
      return;
    }

    try {
      const existingSectionData = JSON.parse(localStorage.getItem("docfirstPageData2"));

      const section_data = [
        {
          0: existingSectionData, // Include the first page data at index 0
        },
        {
          1: JSON.parse(localStorage.getItem("docsecondPageData2")), // Include the second page data at index 1
        },
        {
          2: JSON.parse(localStorage.getItem("docthirdPageData2")), // Include the second page data at index 1
        },
        {
          3: JSON.parse(localStorage.getItem("docfourthPageData2")), // Include the second page data at index 1
        },
        {
          4: sectionData,
        },
      ];

      console.log("Updated data of doc2");
      console.log(section_data);

      const updatedSectionData = await updateDoctor2(userId, section_data);

      if (updatedSectionData) {
        console.log("Updated data of doc");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updateDoctor2.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitData = {
      is_submit: true,
    };
    await updateSectionData(submitData);
    console.log("docId and userid fromsubmit", docId, userData.id)

    if (docId && userData.id) {
      try {
        const doc2StatusData = {
          user_id: userData.id,
          doc_id: docId,
          is_submit: true,
          is_accepted: "pending",
        };
  
        const response = await createDoc2Status(doc2StatusData);

        console.log('API response:', response.data);
      } catch (error) {
        console.error('API error:', error);
      }
    } else {
      console.error('proId or userId not found in local storage');
    }
    localStorage.setItem('isSubmit', true);
    showErrorModal("Submitted successfully");
    history.push('/doc/docdashboard');

    const sendMailResponse = await sendEmail(userData.email, 'Confirmation of Doctor Verification Submission', emailLayout);

    console.log('Email sent successfully:', sendMailResponse.data);
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  const emailLayout = `
  <div style="max-width: 100%; margin: 0 auto; font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6;">
    <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; text-align: center;">
      <img src="https://meditravels.s3.amazonaws.com/Logo/952d4079-478e-44ce-92e8-f8e03e84852d-sticky-logo.png" alt="MediTravels Logo" style="max-width: 20%; height: auto;" />
      <p style="text-align: left; margin: 5px 0;">Dear ${userData.name},</p>
        <p style="text-align: left;">Thank you for submitting your verification form for Doctor membership with MediTravels. We have received your verification from and will now begin processing it.
        Our team will review your verification form and verify the information provided. We'll notify you as soon as your verification is approved or if we need any additional information.</p>
        <p style="text-align: left; margin: 5px 0 2px;">Best regards,</p>
        <p style="text-align: left; margin: 0px;">MeditravelS Team</p>
      </div>
    </div>`;

  return (
    <Page>
      <FirstSection title="Submit Your Application In Doctor">
        Thank you for filling out the form! Please click "Submit" to proceed further.
      </FirstSection>
      <SubmitAction backLink="/doc/section4-2" onSubmit={handleSubmit} />
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
};

export default SubmitPage;