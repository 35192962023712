import React, { useEffect, useState } from 'react';
import Page from '../PRO/pro-components/Page';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, Button } from '@material-ui/core';
import { GetAllProList, findOneUserById } from '../../api';
import Navbar from '../global-components/navbar';
import Footer from '../global-components/footer';
import PageHeader from '../global-components/page-header';
import ReactCountryFlag from 'react-country-flag';
import { getCode } from 'country-list';
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import TreatmentBlogSkeleton from "../skeleton/treatmentBlogSkeleton";

const ProList = () => {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const headerImage = localStorage.getItem("aboutimage");
    const [openModal, setOpenModal] = useState(false);
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page on component mount
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0); // Scroll to the top of the page on route change
        });
        return () => unlisten();
    }, [history]);
    
    useEffect(() => {
        setLoading(true);
        // Fetch all pro2Status
        GetAllProList()
            .then((proData) => {
                console.log("---printing proData")
                console.log(proData);
                setUserData(proData);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching pro2Status:', error);
                setLoading(false);
            });
    }, []);

    const handleViewDetails = (user) => {
        setSelectedUser(user);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setSelectedUser(null);
        setOpenModal(false);
    };

    const getCountryFlag = (countryName) => {
        // Get the country code based on the country name
        const countryCode = getCode(countryName);

        // Check if the countryCode is not null
        if (countryCode) {
            // Render the ReactCountryFlag component with the countryCode
            return <ReactCountryFlag countryCode={countryCode} svg />;
        } else {
            // Fallback or placeholder when the countryCode is not found
            return <span>No Flag</span>;
        }
    };

    return (
        <>
            <Navbar />
            {/* <PageHeader image={headerImage} headertitle="About Us" /> */}
            <Page style={{ background: '#321d1c' }}>
                <div className='proList'>
                    <h3 style={{ color: '#ffffff', fontWeight: 'bold', paddingBottom: '20px', textAlign: 'center' }}>
                        PRO's List of MediTravelS
                    </h3>
                    {/* {loading ? (
                        <TreatmentBlogSkeleton />
                    ) : (
                        <>
                            {userData.length === 0 ? (
                                <p style={{ color: '#ffffff', textAlign: 'center' }}>No PRO's found. Check back soon!</p>
                            ) : ( */}
                                <div className="row treatment-list">
                                    {userData.map((prodata) => (
                                        <div className="col-lg-6" key={prodata._id}>
                                            <div
                                                className="outerdiv"
                                                style={{
                                                    borderRadius: "25px",
                                                    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
                                                    background: "#fff",
                                                }}
                                            >
                                                <div className="mb-lg-3 mt-5" style={{ margin: "auto" }}>
                                                    <div
                                                        className="single-blog single-blog-wrap style-two"
                                                        style={{
                                                            borderRadius: "",
                                                            padding: "",
                                                            boxShadow: "0px 0px 0px #071c5500",
                                                            WebkitBoxShadow: "0px 0px 0px #071c5500",
                                                        }}
                                                    >
                                                        <div className="thumb2 single-blog-left-wrap proLeftImg">
                                                            <img
                                                                src={prodata.profile_img}
                                                                alt="blog"
                                                                style={styles.imagein}
                                                                className="subtreatment-img proListImg"
                                                            />
                                                        </div>
                                                        <div
                                                            className="single-blog-details single-blog-right-wrap proDataList"
                                                        >
                                                            <h3
                                                                className="title1 ProName"
                                                                style={{ fontSize: "22px", paddingBottom: "10px", marginBottom: '0px!important', wordBreak: 'break-all' }}
                                                            >
                                                                {prodata.name}
                                                            </h3>
                                                            <h5 className='proDetails' style={{ fontSize: '15px', wordBreak: 'break-all' }}>{prodata.email}</h5>
                                                            <h5 className='proDetails' style={{ fontSize: '15px' }}>{prodata.phone}</h5>
                                                            {getCountryFlag(prodata.country)} {prodata.country}
                                                            {/* <p className="content">{prodata.description}</p> */}
                                                            {/* <Link
                                                className="btn btn-yellow"
                                                to={{
                                                    pathname: `/subgroup1`,
                                                    state: {
                                                        subtreatment_id: prodata._id,
                                                        image: prodata.header_image,
                                                    },
                                                }}
                                                // onClick={() =>
                                                //     handleCLick(
                                                //         prodata._id,
                                                //         prodata.header_image
                                                //     )
                                                // }
                                            >
                                                <span>
                                                    Read More
                                                    <i className="la la-arrow-right" />
                                                </span>
                                            </Link> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            {/* )}
                        </>
                    )} */}
                </div>
            </Page>
            <Footer />
        </>
    );
};

let styles = {
    imagein: {
        height: '200px',
        width: '250px',
        borderRadius: "20px",
        boxShadow: "4px 13px 30px 1px rgba(163, 94, 78, 0.2)",
    },
};
export default ProList;