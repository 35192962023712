import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

const RadioButtonGroup = React.forwardRef(({ name, label, value, options, onChange, onBlur }, ref) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <RadioGroup name={name} label={label} value={value} onChange={handleChange} onBlur={onBlur}>
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={option.label}
          style={{
            minWidth: '100%',
          }}
        />
      ))}
    </RadioGroup>
  );
});

export default RadioButtonGroup;

// const options = [
//     { value: 'option1', label: 'Option 1' },
//     { value: 'option2', label: 'Option 2' },
//     { value: 'option3', label: 'Option 3' },
//   ];
  
//   <RadioButtonGroup
//     name="radioOptions"
//     value={selectedOption}
//     options={options}
//     onChange={handleOptionChange}
//   />  