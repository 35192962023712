import React, { useEffect } from 'react';
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import Page from '../../PRO/pro-components/Page';

const TermsConditions = () => {
    const history = useHistory();

    useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page on component mount
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0); // Scroll to the top of the page on route change
		});
		return () => unlisten();
	}, [history]);

    useEffect(() => {
        const minscript = document.createElement("script");
        minscript.async = true;
        document.body.appendChild(minscript);
      }, []);

  return (
    <Page>
        <h4>Terms and Conditions for Patients Traveling</h4>
        <div style={{padding: '30px', textAlign: 'justify'}}>
         <p>General Information: This term underscores that MediTravels provides healthcare coordination and logistical services for international patients seeking treatment in India. The firm does not deliver direct medical services, and it neither represents nor holds affiliations with healthcare institutions. The business merely acts as an intermediary between patients and healthcare providers.

Medical Treatment Arrangements: MediTravels helps to coordinate patients' healthcare requirements with hospitals and physicians in India, utilizing the information provided by patients or their home healthcare providers. The firm does not have the authority to make final decisions about patients' medical treatment. Those decisions remain strictly between the healthcare provider and the patient.

Hospital Services and Responsibilities: The term emphasizes that MediTravels' partnered hospitals and healthcare professionals are obligated to provide patients with competent medical care within the purview of their individual licenses and in accordance with the regulatory provisions of the Indian Medical Council. MediTravels is not responsible for the outcome of any medical procedures.

Tourism Services: MediTravels arranges tourism activities on behalf of patients. These services depend on patients’ physical health and healthcare providers' advice. While MediTravels will make reasonable efforts to ensure patients' enjoyment, it cannot guarantee specific experiences or outcomes.

Accommodation Arrangements: MediTravels facilitates accommodation reservations, depending on the patient's preferences and budget. The firm takes due diligence to ensure that partnered accommodation facilities maintain good standards, but MediTravels is not responsible for issues directly related to the accommodations.

Local Transportation Services: This term explains that MediTravels assists with arranging local transportation to and from healthcare facilities, accommodations, and tourism sites. Any additional transportation requests outside these prescribed services are to be organized and paid for by the patient.

Payment Conditions: MediTravels requires a 30% payment for its services before the patient's arrival in India. The remaining amount should be paid within 24 hours of arrival in India. Charges related to medical care, accommodation, tourism, and additional transportation services will be combined. MediTravels will provide a single invoice that includes all the charges. Booking will be confirmed only after 30% of the advance Payment.  For Consolation or 1st Consultation and Follow-up cases, the patient will be charged a fee of 75 USD for the initial consultation. This fee covers the cost of the first consultation with the healthcare provider to discuss the patient's medical condition, review medical records, and recommend a treatment plan. Please note that applicable taxes are extra as per the rules and regulations of the local government.

In the case of follow-up consultations within 10 days from the date of the initial consultation, a fee of 50 USD will be charged. These follow-up consultations are intended to monitor the patient's progress, address any concerns or questions, and provide ongoing medical guidance and support.

The consultation fees are separate from the overall treatment costs and are payable directly by the patient. Payment for the consultation fees should be made in accordance with the payment conditions specified in the terms and conditions.

It is important to note that the consultation fees are subject to change and may vary depending on the specific healthcare provider and treatment sought. MedITravelS will provide the patient with the current and accurate consultation fee information and assist in scheduling the consultations with the healthcare provider.  Please note that applicable taxes are extra as per the rules and regulations of the local government which may change time to time.

Refund and Cancellation Policy: In the event of cancellation of the whole package, MediTravels will provide a refund of 50% of the booking amount. The refund will be processed after 30 days from the date of cancellation.

Travel Insurance: MediTravels highly recommends that every patient purchase appropriate travel insurance to cover unforeseen circumstances such as trip cancellation, medical emergencies, travel delays, and lost luggage, Also Sent a copy of Travel Insurance Policy to MediTravels. MediTravels is not responsible for costs incurred due to such events. 

Medical Records and Confidentiality: MediTravels is committed to protecting your privacy and ensuring the security of your medical records. We will only share your medical records with relevant healthcare providers and any other parties you explicitly authorize.

Consent and Assumption of Risks: By using MediTravels' services, patients acknowledge the inherent risks associated with travel, medical treatments, and procedures. Patients are deemed to have accepted these risks and will not hold MediTravels accountable for any adverse outcomes associated with their treatments or travel.

Compliance with Local Laws and Regulations: Patients agree to abide by all local laws and regulations during their stay in India. Any failure to comply with these laws and regulations may result in the immediate termination of MediTravels' services without refund.

Liability and Indemnification: MediTravels is not liable for any direct or indirect damages, losses, or expenses arising from the use of its services, medical treatments, or the actions of any third-party service providers. The patient agrees to indemnify and hold harmless MediTravels, its officers, employees, agents, and suppliers from any liability, loss, claim, and expense (including legal fees) related to their breach of these terms and conditions.

Dispute Resolution: In the event of any dispute arising out of or in connection with these terms and conditions, both parties agree to first attempt to resolve the dispute through good faith negotiation. If resolution cannot be achieved through negotiation, the dispute will be referred to and finally resolved by arbitration under the Arbitration and Conciliation Act, 1996.

Force Majeure: MediTravels is not liable for failure to perform its obligations if such failure is as a result of Acts of God (including fire, flood, earthquake, storm, hurricane or other natural disaster), war, invasion, act of foreign enemies, hostilities (regardless of whether war is declared), civil war, rebellion, revolution, insurrection, military or usurped power or confiscation, terrorist activities, nationalisation, government sanction, blockage, embargo, labor dispute, strike, lockout or interruption or failure of electricity or telephone service.

Governing Law: These terms and conditions are governed by and construed in accordance with the laws of the Republic of India. The parties agree to submit to the exclusive jurisdiction of the courts in Rajkot, Gujarat, India only.

Dispute Resolution: In the event of a dispute arising between the patient and MediTravels or any service provider, it is agreed that any legal fees incurred by legal persons, documents, traveling expenses, or any other related expenses shall be the sole responsibility of the involved parties. MediTravels or any service provider will not be liable for reimbursing or covering such expenses in relation to the dispute resolution process.

Severability: If any provision of these terms and conditions is determined to be invalid or unenforceable, then such invalidity or unenforceability will not affect the other provisions of these terms and conditions, all of which will remain in full force and effect.

Amendments and Modifications: MediTravels reserves the right to amend or modify these terms and conditions at any time without prior notice. It is the patient's responsibility to review these terms periodically for updates.

Entire Agreement: These terms and conditions, along with any policies or operating rules posted on MediTravels' website, constitute the entire agreement and understanding between the patient and MediTravels.

Contact Information: If the patient has any questions about these terms and conditions or any aspect of MediTravels services, they are advised to contact MediTravels directly through the provided contact details.

Privacy and Data Protection: MediTravels maintains strict privacy policies to protect the personal information of patients. The firm complies with applicable data protection laws. Personal and health information of patients is stored, processed, and shared in accordance with these laws and only for the purpose of service delivery and coordination.

Medical Consultation and Advice: MediTravels facilitates interactions between patients and healthcare providers but does not itself provide any form of medical consultation or advice. All medical queries should be directed to the healthcare provider.

Treatment Options and Alternatives: The healthcare provider is solely responsible for advising the patient on suitable treatment options and alternatives. MediTravels does not influence or make any recommendations on the choice of treatment.

Follow-up Care and Assistance: the service provider is able to offer complimentary follow-up care as per their policies and regulations, MediTravels will try to pass on this benefit to the patient. The availability and extent of complimentary follow-up care will be determined by the service provider's terms and conditions. MediTravels will try to facilitate communication and coordination between the patient and the service provider for any necessary follow-up care, but the specific details and arrangements will be subject to the policies of the service provider.

Medical Emergencies: MediTravels will assist in coordinating care during medical emergencies, but the final response and decisions are the responsibility of the treating healthcare provider. Patients are advised to have appropriate insurance to cover emergency medical situations.

Health and Safety: MediTravels takes reasonable precautions to ensure the health and safety of patients during their stay. However, MediTravels is not liable for incidents or accidents that occur at healthcare providers' premises, accommodations, or during transportation and tourist activities.  If patient will came for the safe medical Treatment but during the treatment of that part if any other Medical Emergency arrives than it will not be included in the current Package, we may try to assist in Complimentary Medical Advice on Humanity basis to deal with such unpredictable Medical Emergency with some Extra cost of charges that Excludes in Package.  

Cultural Sensitivity and Language Support: MediTravels will provide language support to patients to ensure effective communication with healthcare providers and others. Patients are also expected to respect the cultural norms and practices of India during their stay.

Travel Arrangements and Documentation: MediTravels will assist in arranging travel logistics but the ultimate responsibility for obtaining necessary visas, passports, and other travel documentation rests with the patient. MediTravels is not responsible for denial of visas or entry into India by immigration authorities.

Third-Party Service Providers: Some services may be provided by third-party vendors, including accommodation, transportation, and tourist activities. MediTravels makes reasonable efforts to vet these service providers but is not responsible for their actions or the quality of services they provide.

Intellectual Property: All content, designs, text, graphics, software, and other material used or displayed on MediTravels' website or marketing materials are the exclusive property of MediTravels and may not be used without prior written permission.

Termination of Services: MediTravels reserves the right to terminate its services if the patient breaches these terms and conditions, fails to make payment, or behaves inappropriately or unlawfully. As a INDIAN Citizen we will Inform to Local Police and make a file Against You.

Communication and Customer Support: MediTravels maintains open communication channels for customer support and inquiries. However, for specific medical questions, the patient should directly consult with their healthcare provider.

Consent to Treatment and Procedures: By agreeing to these terms, the patient consents to receiving treatment in India and understands that all medical decisions are to be made by them and their healthcare provider.

Accompanying Individuals and Visitors: If the patient is accompanied by others, it is the patient's responsibility to inform MediTravels in advance. Any costs for accommodation, transportation, and services for these individuals are separate and not included in the patient's package.

Complaints and Grievance Redresses: Any complaints about MediTravels' services or the services of its affiliates must be submitted in writing. MediTravels will attempt to address these complaints promptly and effectively, but it does not have control over the services provided by third parties such as hospitals, doctors, or hotels, local taxi.

Medical Research or Clinical Trials: MediTravels does not coordinate or provide services related to medical research or clinical trials. Patients who are interested in participating in such activities should directly contact their healthcare provider or relevant institutions.

Publicity and Testimonials: MediTravels may request patients to provide testimonials or participate in publicity activities. However, patient participation is voluntary, and MediTravels will not use any patient's name, image, or statement without their explicit consent.


Medical Licensing and Accreditation: MediTravels works with healthcare providers that are licensed and accredited within their respective jurisdictions. However, MediTravels does not control the medical practices of these providers and cannot guarantee any outcomes or results of any treatment provided by them.

Pre-Treatment Assessments and Medical History: The patient is responsible for providing accurate and complete information about their medical history, current health condition, and any other information relevant to their treatment. MediTravels will facilitate communication of this information to the healthcare providers.

Discharge and Post-Treatment Care: Upon discharge from the healthcare facility, patients are responsible for following the post-treatment care instructions provided by their healthcare provider. MediTravels can facilitate communication and clarification of these instructions but is not responsible for the patients' compliance.

Health and Vaccination Requirements: The patient is responsible for meeting any health and vaccination requirements for travel to India. MediTravels may provide general guidance but does not provide medical advice. All such advice should be obtained from a healthcare professional.

Emergency Medical Evacuation: In the event of a critical medical emergency requiring evacuation, the patient is responsible for all associated costs. MediTravels strongly recommends that patients have appropriate travel insurance that includes coverage for emergency medical evacuation.

Currency Conversion and Exchange Rates: Any payments to MediTravels or associated service providers will be calculated using the current exchange rates. MediTravels is not responsible for any losses the patient may incur due to fluctuating currency exchange rates.


MedITravelS Service Fee and Commission: MedITravelS will provide a single, consolidated bill that includes the cost of treatment, accommodation, local transportation, and any related services, along with the service fee. The bill will not provide a separate breakdown of the individual amounts for each component.

Prohibited Activities: Patients and their accompanying persons are prohibited from engaging in any illegal activities during their stay in India. This includes, but is not limited to, drug use, trafficking, and any forms of criminal behaviour. Violation of this term may result in immediate termination of services by MediTravels.

Travel Advisories and Safety Precautions: Patients are responsible for reviewing and adhering to travel advisories issued by their home country and the government of India. MediTravels is not responsible for any adverse events that occur due to non-adherence to these advisories.

Success Rates and Outcome Expectations: MediTravels does not guarantee any specific outcomes or success rates associated with any medical treatment or procedure. Any statements regarding success rates or outcome expectations are solely the opinion of the respective healthcare provider.

Assistance to Family Members: MedITravelS will provide assistance in arranging necessary services for the care takers or family members accompanying the patient. This includes assistance with flight bookings, visa application procedures, accommodation arrangements, local travel arrangements, and any other related services required by the care takers or family members during their stay in India.
MedITravelS understands the importance of having support and companionship during the patient's journey and treatment. Therefore, MedITravelS will work closely with the care takers or family members to ensure their travel and stay are organized and comfortable. This may involve providing guidance on visa requirements, facilitating flight bookings, recommending suitable accommodation options near the medical facility, arranging local transportation, and offering any additional support needed during their stay.  The specific details and arrangements for the care takers or family members will be discussed and agreed upon in consultation with MedITravelS. Please note that the expenses and responsibilities related to the care takers or family members, including flight tickets, visa fees, accommodation charges, local travel expenses, and any other associated costs, will be separate from the patient's expenses and will be borne by the care takers or family members themselves. MedITravelS will assist in making the necessary arrangements but will not be liable for any costs or liabilities arising from these arrangements. 
Severability and Waiver: If any clause in these terms and conditions is found to be unenforceable under applicable law, such unenforceability will not affect the enforceability of the remainder of these terms and conditions. Failure by MediTravels to enforce strict performance of these terms and conditions does not constitute a waiver of any provision or right.

These terms and conditions are formulated to protect the rights and obligations of both MediTravels and the patients using its services. By availing MediTravels' services, patients agree to adhere to these terms and conditions in their entirety. It is highly recommended that patients carefully read and understand these terms before availing the services offered by MediTravels.</p>
        </div>
    </Page>
  );
};

export default TermsConditions;
