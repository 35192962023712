import React, { useEffect, useState } from 'react';
import Page from '../pro-components/Page';
import SectionComponent from '../pro-components/Section';
import { auth } from '../../login-signup/screens/firebase.config';
import { updateUser, findOneDoc1Status, findOneDoc2Status } from '../../../api';

const DocDashboard = () => {
  const [dashboardMessage, setDashboardMessage] = useState('Welcome to Doctor Dashboard');
  const [rejectedMessages, setRejectedMessages] = useState([]);
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const [isAccepted, setIsAccepted] = useState('');
  const [isAccepted2, setIsAccepted2] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(null);
  const minscript = document.createElement("script");
  minscript.async = true;

  document.body.appendChild(minscript);

  useEffect(() => {
    const fetchEmailVerifiedStatus = () => {
      if (auth.currentUser) {
        auth.currentUser.reload().then(() => {
          const isEmailVerified = auth.currentUser.emailVerified;
          setEmailVerified(isEmailVerified);
          console.log("printing email verified");
          console.log(isEmailVerified);

          if (isEmailVerified) {
            clearInterval(intervalId);
            updateUserData();
          }
        });
      }
    };

    const intervalId = setInterval(fetchEmailVerifiedStatus, 2000);

    return () => {
      clearInterval(intervalId);
    };

  }, []);

  const updateUserData = async () => {
    try {
      const userId = userData.id;
      const updateResponse = await updateUser(userId, { is_emailVerified: true });

      if (updateResponse) {
        console.log("User data updated successfully");
        console.log("User updated with response:", updateResponse);
      } else {
        console.error("User update failed");
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData2 = await findOneDoc2Status(userData.id);
        console.log('API response from FindOneDoc2Status:', responseData2);
        console.log('is_accepted from FindOneDoc2Status:', responseData2.is_accepted);
        setIsAccepted2(responseData2.is_accepted);

        if (responseData2.is_accepted === 'pending') {
          setDashboardMessage('Thank you for submitting your verification details. Your application is under processing.');
        } else if (responseData2.is_accepted === "true") {
          setDashboardMessage('Congratulations on becoming a part of Meditravels! We will be in touch with you soon using the contact information you provided.');
          return;
        } else if (responseData2.is_accepted === "false") {
          setDashboardMessage('We regret to inform you that your application form has been declined. Please make the necessary modifications and resubmit the form.');
        }
        const rejectedFields = responseData2.rejectmsg || [];
        console.log('Rejected Fields:', rejectedFields);
        const messages = rejectedFields.map((field) => field.message);
        setRejectedMessages(messages);
        console.log("list of rejectmsg", messages);
        return;
      } catch (error) {
        console.error('API Error from FindOnePro2Status:', error);
      }

      try {
        const responseData1 = await findOneDoc1Status(userData.id);
        console.log('API response from FindOnePro1Status:', responseData1);
        console.log('is_accepted from FindOnePro1Status:', responseData1.is_accepted);

        setIsAccepted(responseData1.is_accepted);

        if (responseData1.is_accepted === 'pending') {
          setDashboardMessage('Thank you for submitting your basic details. Your application is under processing');
        } else if (responseData1.is_accepted === 'true') {
          setDashboardMessage('We are pleased to inform you that your primary application has been successfully processed. You can now proceed to complete the form available on the dashboard for advanced verification.');
          return;
        } else if (responseData1.is_accepted === 'false') {
          setDashboardMessage(`We regret to inform you that your application form has been declined. Below is a list of the fields that have been not approved. Please make the necessary modifications to these fields and resubmit the form.`);
        }

        const rejectedFields1 = responseData1.rejectmsg || [];
        console.log('Rejected Fields:', rejectedFields1);
        const messages1 = rejectedFields1.map((field) => field.message);
        setRejectedMessages(messages1);
        console.log("list of rejectmsg", messages1);
      } catch (error) {
        console.error('API Error:', error);
        // Handle error scenario
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Page>
      <SectionComponent>
        <h5 className='dashboard-msg' style={{ color: '#000000' }}>{dashboardMessage}</h5>
        {isAccepted === 'false' && rejectedMessages.length > 0 && (
          <div>
            <p style={{ color: 'red' }}>Rejected fields:</p>
            <ul style={{ color: 'red' }}>
              {rejectedMessages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </div>
        )}
        {isAccepted2 === 'false' && rejectedMessages.length > 0 && (
          <div>
            <p style={{ color: 'red' }}>Rejected fields:</p>
            <ul style={{ color: 'red' }}>
              {rejectedMessages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </div>
        )}
      </SectionComponent>
    </Page>
  );
};

export default DocDashboard;