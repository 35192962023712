import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import Comments from "./comments";
import Sidebar from "./sidebar";
import BlogSkeleton2 from "../skeleton/BlogSkeleton2";
import ParagraphSkeleton from "../skeleton/ParagraphSkeleton";
import DateTitleSkeleton from "../skeleton/DateTitleSkeleton";

function BlogDetails({ blog, loading }) {
  // const [loading, setLoading] = useState(true);
  const [blogContent, setBlogContent] = useState("");
  const [paragraphLoading, setParagraphLoading] = useState(true); // Separate state for the ParagraphSkeleton

  const location = useLocation();
  // const blogId = location.state ? location.state.blog_id : null;
  const history = useHistory();
  let publicUrl = process.env.PUBLIC_URL + "/";
  const blogId = localStorage.getItem("blog_id");
  const image = localStorage.getItem("image");

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page on route change
    });
    return () => unlisten();
  }, []);

  useEffect(() => {
    if (blog && blog.blogdetails) {
      // Set the blog content in HTML format when the component mounts or the blog changes
      setBlogContent(blog.blogdetails);
    }
  }, [blog]);

  const renderParagraphs = () => {
    if (!blogContent) return null;

    // Split the content into paragraphs based on two or more consecutive line breaks
    const paragraphs = blogContent.split(/\n\s*\n/);

    return paragraphs.map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ));
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, []);

  const handleImageLoad = () => {
    setParagraphLoading(false); // Set paragraphLoading to false when the image is loaded
  };

  return (
    <>
      {loading ? (
        <>
          <BlogSkeleton2 />
          <ParagraphSkeleton />
        </>
      ) : (
        <div className="blog-details-area pd-top-120 viaje-go-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-blog blog-details mb-0">
                  <div className="thumb" style={{ display: "flex" }}>
                    <div className="col-lg-6">
                      {/* <img
                    src={blog.blogimage}
                    alt="blog"
                  /> */}

                      <img
                        src={blog.blogimage}
                        alt="blog"
                        onLoad={handleImageLoad}
                        style={{
                          animation: "fadeInImage 1.5s ease-in-out forwards",
                        }}
                      />
                    </div>
                    <div
                      className="col-lg-6"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <p className="date mb-0">
                        {new Date(blog.createdAt).toLocaleDateString()}
                      </p>
                      <h3 className="title" style={{ fontSize: "50px" }}>
                        {blog.blogname}
                      </h3>
                    </div>
                  </div>

                  {/* <div className="single-blog-details">
                <p className="content mb-0">
                  {blog.blogdetails}
                </p>
              </div> */}

                  <div
                    className="single-blog-details"
                  />
                  {renderParagraphs()}
                </div>
                <div className="gallery-area">
                  <div className="containerss">
                    <div className="gallery-filter-area row custom-gutter">
                      <div className="gallery-sizer col-1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BlogDetails;