import React, { useState, useEffect, useRef } from 'react';
import Page from '../pro-components/Page';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import CustomTextField from '../pro-components/CTextField';
import FileUpload from '../pro-components/CFileUpload';
import Action from '../pro-components/Action';
import { useHistory } from 'react-router-dom';
import CustomButton from '../pro-components/CButton';
import Ticket from '../pro-components/ticket';
import Webcam from 'react-webcam';
import { Modal, Button } from 'react-bootstrap';
import { BsPersonFill, BsCheckCircle } from 'react-icons/bs';
import CustomModal from "../../global-components/modal";
import CheckboxComponent from '../pro-components/CCheckBox'
import { updatePatient, uploadMedia } from '../../../api';

const EducationalBackground = () => {
    const [chiefInput, setChiefInput] = useState('');
    const [chiefImg, setChiefImg] = useState(null);
    const [chiefPreviewUrl, setChiefPreviewUrl] = useState(null);
    const [chiefInputError, setChiefInputError] = useState('');
    const [showChiefInputError, setshowChiefInputError] = useState(false);
    const [pastInput, setPastInput] = useState('');
    const [pastImg, setPastImg] = useState(null);
    const [pastPreviewUrl, setPastPreviewUrl] = useState(null);
    const [pastInputError, setPastInputError] = useState('');
    const [showPastInputError, setshowPastInputError] = useState(false);
    const [presentInput, setPresentInput] = useState('');
    const [presentImg, setPresentImg] = useState(null);
    const [presentPreviewUrl, setPresentPreviewUrl] = useState(null);
    const [presentInputError, setPresentInputError] = useState('');
    const [showPresentInputError, setshowPresentInputError] = useState(false);
    const [sectionData, setSectionData] = useState('');
    const [mainFileError, setMainFileError] = useState('');
    const [capctureImgPhotoerror, setcapctureImgPhotoerror] = useState("")
    let history = useHistory();
    const patientId = localStorage.getItem("patientId");
    const minscript = document.createElement("script");
    minscript.async = true;
    document.body.appendChild(minscript);
    const previousPageURL = '/patient/sec-2';
    const [imagePreviewUrl01, setImagePreviewUrl01] = useState(null);
    const [pdfPreviewUrl01, setPdfPreviewUrl01] = useState(null);
    const [imagePreviewUrl1, setImagePreviewUrl1] = useState(null);
    const [pdfPreviewUrl1, setPdfPreviewUrl1] = useState(null);
    const [imagePreviewUrl2, setImagePreviewUrl2] = useState(null);
    const [pdfPreviewUrl2, setPdfPreviewUrl2] = useState(null);
    const pdfOpenParams = 'toolbar=0&navpanes=0&scrollbar=0';
    const webcamRef = useRef(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [showWebcam, setShowWebcam] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showWebcamModal, setShowWebcamModal] = useState(false);
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const [modalImage, setModalImage] = useState(null);
    const [photoProof, setPhotProof] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckeds, setIsCheckeds] = useState(false);
    const [isAgreeButtonClicked1, setIsAgreeButtonClicked1] = useState(false);
    const [isAgreeButtonClicked2, setIsAgreeButtonClicked2] = useState(false);
    const [isModalScrolled, setIsModalScrolled] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [isModalScrolled1, setIsModalScrolled1] = useState(false);
    const [isModalScrolled2, setIsModalScrolled2] = useState(false);
    const [isAgreeEnabled1, setIsAgreeEnabled1] = useState(false);
    const [isAgreeEnabled2, setIsAgreeEnabled2] = useState(false);
    const [isScrollbarAtEnd1, setIsScrollbarAtEnd1] = useState(false);
    const [isScrollbarAtEnd2, setIsScrollbarAtEnd2] = useState(false);
    const [checkboxError1, setCheckboxError1] = useState(false);
    const [showCheckboxError1, setShowCheckboxError1] = useState(false);
    const [checkboxError2, setCheckboxError2] = useState("");
    const [showCheckboxError2, setShowCheckboxError2] = useState("");
    const pageRef = useRef(null);
    const bodyRef1 = useRef(null);
    const bodyRef2 = useRef(null);
    const [errorModalData, setErrorModalData] = useState({
        title: "",
        message: "",
        show: false,
    });

    useEffect(() => {
        const handleScroll = () => {
            const target = bodyRef1.current;
            const scrollHeight = target.scrollHeight;
            const clientHeight = target.clientHeight;
            const scrollTop = target.scrollTop;
            const maxScrollTop = scrollHeight - clientHeight;
            setIsModalScrolled1(scrollTop === maxScrollTop);

            if (scrollTop === maxScrollTop) {
                setIsAgreeEnabled1(true);
            } else {
                setIsAgreeEnabled1(false);
            }
        };

        if (bodyRef1.current) {
            bodyRef1.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (bodyRef1.current) {
                bodyRef1.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        if (isScrollbarAtEnd1) {
            setIsAgreeEnabled1(true);
        } else {
            setIsAgreeEnabled1(false);
        }
    }, [isScrollbarAtEnd1]);

    useEffect(() => {
        const handleScroll2 = () => {
            const target = bodyRef2.current;
            const scrollHeight = target.scrollHeight;
            const clientHeight = target.clientHeight;
            const scrollTop = target.scrollTop;
            const maxScrollTop = scrollHeight - clientHeight;
            setIsModalScrolled2(scrollTop === maxScrollTop);

            if (scrollTop === maxScrollTop) {
                setIsAgreeEnabled2(true);
            } else {
                setIsAgreeEnabled2(false);
            }
        };

        if (bodyRef2.current) {
            bodyRef2.current.addEventListener('scroll', handleScroll2);
        }

        return () => {
            if (bodyRef2.current) {
                bodyRef2.current.removeEventListener('scroll', handleScroll2);
            }
        };
    }, []);

    useEffect(() => {
        if (isScrollbarAtEnd2) {
            setIsAgreeEnabled2(true);
        } else {
            setIsAgreeEnabled2(false);
        }
    }, [isScrollbarAtEnd2]);

    const showErrorModal = (title, message) => {
        setErrorModalData({
            title: title,
            message: message,
            show: true,
        });
    };
    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: 'user',
    };

    const handleCaptureButtonClick = () => {
        setShowWebcamModal(true);
        setShowWebcam(true); // Show webcam feed when modal is opened
    };

    const handleCapturePhoto = () => {
        const video = webcamRef.current.video;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        const photo = canvas.toDataURL('image/jpeg');
        setCapturedImage(photo);
        setShowWebcam(false);
    };

    const handleRecaptureButtonClick = () => {
        setCapturedImage(null);
        setShowWebcam(true);
        setUploadSuccess(false); // Reset upload success state
    };

    const handleUploadImage = async () => {
        if (capturedImage) {
            try {
                setLoading(true); // Set loading state to true

                const blob = await fetch(capturedImage).then((res) => res.blob());
                const uploadedImagePath = await uploadMedia(blob, 'Webcam');
                console.log('Image URL:', uploadedImagePath);

                // Store the uploaded image URL
                setUploadedImageUrl(uploadedImagePath);
                setCapturedImage(uploadedImagePath);
                // Further actions with the image URL if needed
                setcapctureImgPhotoerror("");
                setUploadSuccess(true);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false); // Set loading state to false
                setShowWebcamModal(false); // Close the modal after uploading
            }
        }
    };

    const handleWebcamModalClose = () => {
        setShowWebcamModal(false);
        setShowWebcam(null); // Hide the webcam feed
        // setCapturedImage(null); // Reset the captured image
        // setUploadSuccess(false); // Reset the upload success state
        setLoading(false); // Reset the loading state
    };

    useEffect(() => {
        if (modalImage) {
            setCapturedImage(modalImage);
            setModalImage(null);
            setShowWebcamModal(false);
            setUploadSuccess(false);
            setShowWebcam(false);
        }
    }, [modalImage]);

    useEffect(() => {
        let webcamLoadingTimeout;
        if (showWebcam) {
            // Simulate webcam loading with a delay
            webcamLoadingTimeout = setTimeout(() => {
                setLoading(false); // Set loading state to false after delay
            }, 2000);
        }

        return () => {
            clearTimeout(webcamLoadingTimeout);
        };
    }, [showWebcam]);

    const updateSectionData = async (sectionData) => {
        const patientId = localStorage.getItem("patientId");
        console.log("#######patient id from local storage", patientId);
        if (!patientId) {
            console.log("!!!!!!Patient ID not found in local storage");
            return;
        }
        try {
            const existingSectionData = JSON.parse(localStorage.getItem('patientfirstPageData'));
            const section_data = [
                {
                    0: existingSectionData, // Include the first page data at index 0
                },
                {
                    1: JSON.parse(localStorage.getItem("patientsecondPageData"))
                },
                {
                    2: sectionData, // Include the second page data at index 1
                },
            ]
            console.log("^^^^^^updated data of pro");
            console.log(section_data);
            const updatedSectionData = await updatePatient(patientId, {section_data: section_data});
            if (updatedSectionData) {
                console.log("updated data of pro");
                console.log(updatedSectionData);
            } else {
                console.error("No data returned from updateDoctor1.");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleNextClick = async (event) => {
        event.preventDefault();

        let hasError = false;

        if (!validateChiefInput(chiefInput)) {
            setChiefPreviewUrl(true);
            hasError = true;
        }
        if (!validatePastInput(pastInput)) {
            setPastPreviewUrl(true);
            hasError = true;
        }
        if (!validatePresentInput(presentInput)) {
            setPresentPreviewUrl(true);
            hasError = true;
        }
        if (!isChecked) {
            showErrorModal("Alert", "Please fill the required checkbox for Agreement");
            setCheckboxError1("Please fill the required checkbox for Agreement");
            setShowCheckboxError1(true);
            return;
        }

        if (!isCheckeds) {
            showErrorModal("Alert", "Please fill the required checkbox for Privacy Policy");
            setCheckboxError2("Please fill the required checkbox for Privacy Policy");
            setShowCheckboxError2(true);
            return;
        }
        if (!photoProof) {
            showErrorModal("Alert", "Please provide your current photo ID Proof.");
            setMainFileError("Please upload a file.");
            return;
        }
        if (!capturedImage) {
            showErrorModal("Alert", "Please capture your photo.");
            setcapctureImgPhotoerror("please Capcture a Image and Upload");
            return;
        }
        if (hasError) {
            return;
        }
        // Store all the data in local storage
        const thirdPageData = {
            chiefInput: chiefInput,
            chiefImg: chiefImg,
            pastInput: pastInput,
            pastImg: pastImg,
            presentInput: presentInput,
            presentImg: presentImg,
            capturedImage: capturedImage,
            photoproof: photoProof,
            isChecked: isChecked,
            isCheckeds: isCheckeds,
        };
        localStorage.setItem('patientthirdPageData', JSON.stringify(thirdPageData));

        // Update the section data in the backend
        await updateSectionData(thirdPageData);

        history.push('/patient/submit');
    };

    const validateChiefInput = (value) => {
        setChiefInput(value);
        let error = '';
        if (!value) {
            error = 'Chief  of Education is required';
        } else if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        }
        setChiefInputError(error);

        return !error; // Return true if there is no error
    };

    const handleChiefChange = (event) => {
        const chiefInput = event.target.value;
        setChiefInput(chiefInput);
        validateChiefInput(chiefInput);
    };

    const handleChiefBlur = () => {
        setshowChiefInputError(true);
    };

    const validatePastInput = (value) => {
        setPastInput(value);
        let error = '';
        if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        }
        setPastInputError(error);

        return !error; // Return true if there is no error
    };

    // useEffect(() => {
    //     if (capturedImage && capturedImage !== 'data:,') {
    //         setUploadSuccess(false); // Reset the upload success state
    //         setcapctureImgPhotoerror(""); // Clear any previous error message
    //     } else {
    //         setUploadSuccess(false); // Reset the upload success state
    //         setcapctureImgPhotoerror("Please capture a valid image."); // Display an error message
    //     }
    // }, [capturedImage]);

    const handlePastChange = (event) => {
        const pastInput = event.target.value;
        setPastInput(pastInput);
        validatePastInput(pastInput);
    };

    const handlePastBlur = () => {
        setshowPastInputError(true);
    };

    const validatePresentInput = (value) => {
        setPresentInput(value);
        let error = '';
        if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        }
        setPresentInputError(error);

        return !error; // Return true if there is no error
    };

    const handlePresentChange = (event) => {
        const presentInput = event.target.value;
        setPresentInput(presentInput);
        validatePresentInput(presentInput);
    };

    const handlePresentBlur = () => {
        setshowPresentInputError(true);
    };

    const handleFileUpload = async (file) => {
        try {
            const mediaType = "Patient";

            const uploadedImagePath = await uploadMedia(file, mediaType);

            console.log('Image URL:', uploadedImagePath);

            // Determine the file type and set the corresponding preview URL
            if (file.type.startsWith('image/')) {
                setImagePreviewUrl(URL.createObjectURL(file));
                setPdfPreviewUrl(null);
                setPhotProof(uploadedImagePath);
                setMainFileError("");
            } else if (file.type === 'application/pdf') {
                setPdfPreviewUrl(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
                setImagePreviewUrl(null);
                setPhotProof(uploadedImagePath);
                setMainFileError("");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleFileUpload1 = async (file) => {
        try {
            const mediaType = "Patient";

            const uploadedImagePath = await uploadMedia(file, mediaType);

            console.log('Image URL:', uploadedImagePath);
            // Determine the file type and set the corresponding preview URL
            if (file.type.startsWith('image/')) {
                setImagePreviewUrl01(URL.createObjectURL(file));
                setPdfPreviewUrl01(null);
                setChiefImg(uploadedImagePath);
            }
            else if (file.type === 'application/pdf') {
                setPdfPreviewUrl01(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
                setImagePreviewUrl01(null);
                setChiefImg(uploadedImagePath);
            }

            await saveChiefImageUrlToDatabase(uploadedImagePath)
        } catch (error) {
            console.error(error);
        }
    };

    const saveChiefImageUrlToDatabase = async (imageUrl) => {
        const sectionData = { chiefImg: imageUrl };
  
        try {
          const response = await updatePatient(patientId, sectionData); 

            console.log('Image URL saved in the database:', response.data);
        } catch (error) {
            console.error(error);
        }
        setSectionData((prevData) => ({
            ...prevData,
            chiefImg: imageUrl,
        }));
    };

    const handleFileUploadPast = async (file) => {
        try {
            const mediaType = "Patient";

            const uploadedImagePath = await uploadMedia(file, mediaType);
            console.log('Image URL:', uploadedImagePath);
            // Determine the file type and set the corresponding preview URL
            if (file.type.startsWith('image/')) {
                setImagePreviewUrl1(URL.createObjectURL(file));
                setPdfPreviewUrl1(null);
                setPastImg(uploadedImagePath);
            }
            else if (file.type === 'application/pdf') {
                setPdfPreviewUrl1(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
                setImagePreviewUrl1(null);
                setPastImg(uploadedImagePath);
            }
            await savePastImageUrlToDatabase(uploadedImagePath)
        } catch (error) {
            console.error(error);
        }
    };

    const savePastImageUrlToDatabase = async (imageUrl) => {
        const sectionData = { pastImg: imageUrl };
  
        try {
          const response = await updatePatient(patientId, sectionData); 
       
            console.log('Image URL saved in the database:', response.data);
        } catch (error) {
            console.error(error);
        }
        setSectionData((prevData) => ({
            ...prevData,
            pastImg: imageUrl,
        }));
    };

    const handleFileUploadPresent = async (file) => {
        try {
            const mediaType = "Patient";

            const uploadedImagePath = await uploadMedia(file, mediaType);
            console.log('Image URL:', uploadedImagePath);
            // Determine the file type and set the corresponding preview URL
            if (file.type.startsWith('image/')) {
                setImagePreviewUrl2(URL.createObjectURL(file));
                setPdfPreviewUrl2(null);
                setPresentImg(uploadedImagePath);
            }
            else if (file.type === 'application/pdf') {
                setPdfPreviewUrl2(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
                setImagePreviewUrl2(null);
                setPresentImg(uploadedImagePath);
            }

            await savePresentImageUrlToDatabase(uploadedImagePath)
        } catch (error) {
            console.error(error);
        }
    };

    const savePresentImageUrlToDatabase = async (imageUrl) => {
        const sectionData = { presentImg: imageUrl };
  
        try {
          const response = await updatePatient(patientId, sectionData); 

            console.log('Image URL saved in the database:', response.data);
        } catch (error) {
            console.error(error);
        }
        setSectionData((prevData) => ({
            ...prevData,
            presentImg: imageUrl,
        }));
    };

    useEffect(() => {
        const storedSectionData = JSON.parse(localStorage.getItem('patientthirdPageData'));

        if (storedSectionData) {
            setChiefInput(storedSectionData.chiefInput || '');
            setChiefImg(storedSectionData.chiefImg);
            if (storedSectionData.chiefImg && storedSectionData.chiefImg.endsWith('.pdf')) {
                setPdfPreviewUrl01(`https://docs.google.com/viewer?url=${encodeURIComponent(storedSectionData.chiefImg)}&embedded=true`);
            } else {
                setImagePreviewUrl01(storedSectionData.chiefImg);
            }

            setPastInput(storedSectionData.pastInput || '');
            setPastImg(storedSectionData.pastImg);
            if (storedSectionData.pastImg && storedSectionData.pastImg.endsWith('.pdf')) {
                setPdfPreviewUrl1(`https://docs.google.com/viewer?url=${encodeURIComponent(storedSectionData.pastImg)}&embedded=true`);
            } else {
                setImagePreviewUrl1(storedSectionData.pastImg);
            }

            setPresentInput(storedSectionData.presentInput || '');
            setPresentImg(storedSectionData.presentImg);

            if (storedSectionData.presentImg && storedSectionData.presentImg.endsWith('.pdf')) {
                setPdfPreviewUrl2(`https://docs.google.com/viewer?url=${encodeURIComponent(storedSectionData.pastImg)}&embedded=true`);
            } else {
                setImagePreviewUrl2(storedSectionData.presentImg);
            }
            setUploadedImageUrl(storedSectionData.capturedImage);
            setCapturedImage(storedSectionData.capturedImage)

            setPhotProof(storedSectionData.photoproof || null);
            if (storedSectionData.photoproof && storedSectionData.photoproof.endsWith('.pdf')) {
                setPdfPreviewUrl(`https://docs.google.com/viewer?url=${encodeURIComponent(storedSectionData.photoproof)}&embedded=true`);
            } else {
                setImagePreviewUrl(storedSectionData.photoproof);
            }
            setIsChecked(storedSectionData.isChecked || false);
            setIsCheckeds(storedSectionData.isCheckeds || false);
        }
    }, []);

    const handleClearForm = () => {
        setChiefInput('');
        setChiefInputError('');
        setshowChiefInputError('');
        setChiefImg('');
        setChiefPreviewUrl('');
        setPastInput('');
        setPastImg('');
        setPastPreviewUrl('');
        setPastInputError('');
        setshowPastInputError('');
        setPresentInput('');
        setPresentImg('');
        setPresentPreviewUrl('');
        setPresentInputError('');
        setshowPresentInputError('');
        setPdfPreviewUrl01("");
        setImagePreviewUrl01("");
        setPdfPreviewUrl2("");
        setImagePreviewUrl2("");
        setPdfPreviewUrl1("");
        setImagePreviewUrl1("");
        setPhotProof('');
        setCapturedImage("");
        setPdfPreviewUrl("");
        setImagePreviewUrl("");
        setUploadedImageUrl("");
        setcapctureImgPhotoerror("");
        setIsChecked('');
        setIsCheckeds('');
        setCheckboxError1("");
        setCheckboxError2("");
    };

    const handleCloseErrorModal = () => {
        setErrorModalData({
            title: "",
            message: "",
            show: false,
        });
    };

    const handleCheckboxChange = (checked) => {
        let error = '';
        if (!isAgreeButtonClicked1) {
            // Display error message for terms and conditions
            error = 'Please click on the "View company\'s terms & conditions" button and then click "Agree" before checking the checkbox.';
            return;
        } setCheckboxError1(error);
        setIsChecked(checked);
        setSectionData((prevData) => ({
            ...prevData,
            isChecked: checked,
        }));

    };

    const handleCheckboxChangeBlur = () => {
        setShowCheckboxError1(!isChecked);
    };

    const handleCheckboxChanges = (checked) => {
        let error = '';
        if (!isAgreeButtonClicked2) {
            // Display error message for privacy policy
            error = 'Please click on the "View Privacy policy consent" button and then click "Agree" before checking the checkbox.';
            return;
        } setCheckboxError2(error);
        setIsCheckeds(checked);
        setSectionData((prevData) => ({
            ...prevData,
            isCheckeds: checked,
        }));
    };

    const handleCheckboxChangesBlur = () => {
        setShowCheckboxError2(!isCheckeds);
    };

    useEffect(() => {
        const storedSectionData = JSON.parse(localStorage.getItem('seventhPageData2'));

        if (storedSectionData) {
            setIsChecked(storedSectionData.isChecked || '');
            setIsCheckeds(storedSectionData.isCheckeds || '');
        }
    }, []);

    const handleViewClick1 = () => {
        setShowModal1(true);
    };

    const handleViewClick2 = () => {
        setShowModal2(true);
    };

    const handleAgreeClick = () => {
        setIsAgreeButtonClicked1(true);
        setShowCheckboxError1(false);
        setIsChecked(true);
        setIsAgreeEnabled1(false);
        setShowModal1(false);
        const target = bodyRef1.current;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        const scrollTop = target.scrollTop;
        const maxScrollTop = scrollHeight - clientHeight;
        setIsScrollbarAtEnd1(scrollTop === maxScrollTop);
    };

    const handleAgreeClick2 = () => {
        setIsAgreeButtonClicked2(true);
        setIsCheckeds(true);
        setIsAgreeEnabled2(false);
        setIsScrollbarAtEnd2(false);
        setShowCheckboxError2(false);
        setShowModal2(false);
        const target = bodyRef2.current;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        const scrollTop = target.scrollTop;
        const maxScrollTop = scrollHeight - clientHeight;
    };

    const handleModalClose1 = () => {
        setShowModal1(false);
    };

    const handleModalClose2 = () => {
        setShowModal2(false);
    };

    function addBulletsAndLineBreaks(text) {
        const lines = text.split('\n');
        let isPreviousLineEmpty = false;

        const formattedLines = lines.map((line, index) => {
            if (line.trim() !== '') {
                const [heading, content] = line.split(':');
                if (heading && content) {
                    // Wrap the heading in <strong> tag
                    if (index === 0) {
                        return `<strong>${heading}:</strong> ${content.trim()}`;
                    } else {
                        return `• <strong>${heading}:</strong> ${content.trim()}`;
                    }
                } else {
                    // If there's no colon, treat it as a regular line
                    return `• ${line}`;
                }
            } else if (!isPreviousLineEmpty) {
                // Only add a bullet point for an empty line if the previous line was not empty
                isPreviousLineEmpty = true;
                return ''; // Empty line
            }
            return ''; // Empty line
        });

        const newText = formattedLines.join('<br/>');
        return newText;
    }

    const originalText = `General Information: This term underscores that MediTravels provides healthcare coordination and logistical services for international patients seeking treatment in India.The firm does not deliver direct medical services, and it neither represents nor holds affiliations with healthcare institutions.The business merely acts as an intermediary between patients and healthcare providers.
    
    Medical Treatment Arrangements: MediTravels helps to coordinate patients' healthcare requirements with hospitals and physicians in India, utilizing the information provided by patients or their home healthcare providers.The firm does not have the authority to make final decisions about patients' medical treatment. Those decisions remain strictly between the healthcare provider and the patient.
    
    Hospital Services and Responsibilities: The term emphasizes that MediTravels' partnered hospitals and healthcare professionals are obligated to provide patients with competent medical care within the purview of their individual licenses and in accordance with the regulatory provisions of the Indian Medical Council. MediTravels is not responsible for the outcome of any medical procedures.
    
    Tourism Services: MediTravels arranges tourism activities on behalf of patients. These services depend on patients’ physical health and healthcare providers' advice. While MediTravels will make reasonable efforts to ensure patients' enjoyment, it cannot guarantee specific experiences or outcomes.
    
    Accommodation Arrangements: MediTravels facilitates accommodation reservations, depending on the patient's preferences and budget. The firm takes due diligence to ensure that partnered accommodation facilities maintain good standards, but MediTravels is not responsible for issues directly related to the accommodations.
    
    Local Transportation Services: This term explains that MediTravels assists with arranging local transportation to and from healthcare facilities, accommodations, and tourism sites. Any additional transportation requests outside these prescribed services are to be organized and paid for by the patient.
    
    Payment Conditions: MediTravels requires a 30% payment for its services before the patient's arrival in India. The remaining amount should be paid within 24 hours of arrival in India. Charges related to medical care, accommodation, tourism, and additional transportation services will be combined. MediTravels will provide a single invoice that includes all the charges. Booking will be confirmed only after 30% of the advance Payment.For Consolation or 1st Consultation and Follow-up cases, the patient will be charged a fee of 75 USD for the initial consultation. This fee covers the cost of the first consultation with the healthcare provider to discuss the patient's medical condition, review medical records, and recommend a treatment plan. Please note that applicable taxes are extra as per the rules and regulations of the local government. In the case of follow-up consultations within 10 days from the date of the initial consultation, a fee of 50 USD will be charged. These follow-up consultations are intended to monitor the patient's progress, address any concerns or questions, and provide ongoing medical guidance and support. The consultation fees are separate from the overall treatment costs and are payable directly by the patient. Payment for the consultation fees should be made in accordance with the payment conditions specified in the terms and conditions. It is important to note that the consultation fees are subject to change and may vary depending on the specific healthcare provider and treatment sought. MedITravelS will provide the patient with the current and accurate consultation fee information and assist in scheduling the consultations with the healthcare provider.Please note that applicable taxes are extra as per the rules and regulations of the local government which may change time to time.
    
    Refund and Cancellation Policy: In the event of cancellation of the whole package, MediTravels will provide a refund of 50% of the booking amount. The refund will be processed after 30 days from the date of cancellation.
    
    Travel Insurance: MediTravels highly recommends that every patient purchase appropriate travel insurance to cover unforeseen circumstances such as trip cancellation, medical emergencies, travel delays, and lost luggage, Also Sent a copy of Travel Insurance Policy to MediTravels. MediTravels is not responsible for costs incurred due to such events.
    
    Medical Records and Confidentiality: MediTravels is committed to protecting your privacy and ensuring the security of your medical records. We will only share your medical records with relevant healthcare providers and any other parties you explicitly authorize.
    
    Consent and Assumption of Risks: By using MediTravels' services, patients acknowledge the inherent risks associated with travel, medical treatments, and procedures. Patients are deemed to have accepted these risks and will not hold MediTravels accountable for any adverse outcomes associated with their treatments or travel.
    
    Compliance with Local Laws and Regulations: Patients agree to abide by all local laws and regulations during their stay in India. Any failure to comply with these laws and regulations may result in the immediate termination of MediTravels' services without refund.
    
    Liability and Indemnification: MediTravels is not liable for any direct or indirect damages, losses, or expenses arising from the use of its services, medical treatments, or the actions of any third-party service providers. The patient agrees to indemnify and hold harmless MediTravels, its officers, employees, agents, and suppliers from any liability, loss, claim, and expense (including legal fees) related to their breach of these terms and conditions.
    
    Dispute Resolution: In the event of any dispute arising out of or in connection with these terms and conditions, both parties agree to first attempt to resolve the dispute through good faith negotiation. If resolution cannot be achieved through negotiation, the dispute will be referred to and finally resolved by arbitration under the Arbitration and Conciliation Act, 1996.
    
    Force Majeure: MediTravels is not liable for failure to perform its obligations if such failure is as a result of Acts of God (including fire, flood, earthquake, storm, hurricane or other natural disaster), war, invasion, act of foreign enemies, hostilities (regardless of whether war is declared), civil war, rebellion, revolution, insurrection, military or usurped power or confiscation, terrorist activities, nationalisation, government sanction, blockage, embargo, labor dispute, strike, lockout or interruption or failure of electricity or telephone service.
    
    Governing Law: These terms and conditions are governed by and construed in accordance with the laws of the Republic of India. The parties agree to submit to the exclusive jurisdiction of the courts in Rajkot, Gujarat, India only.
    
    Dispute Resolution: In the event of a dispute arising between the patient and MediTravels or any service provider, it is agreed that any legal fees incurred by legal persons, documents, traveling expenses, or any other related expenses shall be the sole responsibility of the involved parties. MediTravels or any service provider will not be liable for reimbursing or covering such expenses in relation to the dispute resolution process.
    
    Severability: If any provision of these terms and conditions is determined to be invalid or unenforceable, then such invalidity or unenforceability will not affect the other provisions of these terms and conditions, all of which will remain in full force and effect.
    
    Amendments and Modifications: MediTravels reserves the right to amend or modify these terms and conditions at any time without prior notice. It is the patient's responsibility to review these terms periodically for updates.
    
    Entire Agreement: These terms and conditions, along with any policies or operating rules posted on MediTravels' website, constitute the entire agreement and understanding between the patient and MediTravels.
    
    Contact Information: If the patient has any questions about these terms and conditions or any aspect of MediTravels services, they are advised to contact MediTravels directly through the provided contact details.
    
    Privacy and Data Protection: MediTravels maintains strict privacy policies to protect the personal information of patients. The firm complies with applicable data protection laws. Personal and health information of patients is stored, processed, and shared in accordance with these laws and only for the purpose of service delivery and coordination.
    
    Medical Consultation and Advice: MediTravels facilitates interactions between patients and healthcare providers but does not itself provide any form of medical consultation or advice. All medical queries should be directed to the healthcare provider.
    
    Treatment Options and Alternatives: The healthcare provider is solely responsible for advising the patient on suitable treatment options and alternatives. MediTravels does not influence or make any recommendations on the choice of treatment.
    
    Follow-up Care and Assistance: the service provider is able to offer complimentary follow-up care as per their policies and regulations, MediTravels will try to pass on this benefit to the patient. The availability and extent of complimentary follow-up care will be determined by the service provider's terms and conditions. MediTravels will try to facilitate communication and coordination between the patient and the service provider for any necessary follow-up care, but the specific details and arrangements will be subject to the policies of the service provider.
    
    Medical Emergencies: MediTravels will assist in coordinating care during medical emergencies, but the final response and decisions are the responsibility of the treating healthcare provider. Patients are advised to have appropriate insurance to cover emergency medical situations.
    
    Health and Safety: MediTravels takes reasonable precautions to ensure the health and safety of patients during their stay. However, MediTravels is not liable for incidents or accidents that occur at healthcare providers' premises, accommodations, or during transportation and tourist activities.If patient will came for the safe medical Treatment but during the treatment of that part if any other Medical Emergency arrives than it will not be included in the current Package, we may try to assist in Complimentary Medical Advice on Humanity basis to deal with such unpredictable Medical Emergency with some Extra cost of charges that Excludes in Package.
    
    Cultural Sensitivity and Language Support: MediTravels will provide language support to patients to ensure effective communication with healthcare providers and others. Patients are also expected to respect the cultural norms and practices of India during their stay.
    
    Travel Arrangements and Documentation: MediTravels will assist in arranging travel logistics but the ultimate responsibility for obtaining necessary visas, passports, and other travel documentation rests with the patient. MediTravels is not responsible for denial of visas or entry into India by immigration authorities.
    
    Third-Party Service Providers: Some services may be provided by third-party vendors, including accommodation, transportation, and tourist activities. MediTravels makes reasonable efforts to vet these service providers but is not responsible for their actions or the quality of services they provide.
    
    Intellectual Property: All content, designs, text, graphics, software, and other material used or displayed on MediTravels' website or marketing materials are the exclusive property of MediTravels and may not be used without prior written permission.
    
    Termination of Services: MediTravels reserves the right to terminate its services if the patient breaches these terms and conditions, fails to make payment, or behaves inappropriately or unlawfully. As a INDIAN Citizen we will Inform to Local Police and make a file Against You.
    
    Communication and Customer Support: MediTravels maintains open communication channels for customer support and inquiries. However, for specific medical questions, the patient should directly consult with their healthcare provider.
    
    Consent to Treatment and Procedures: By agreeing to these terms, the patient consents to receiving treatment in India and understands that all medical decisions are to be made by them and their healthcare provider.
    
    Accompanying Individuals and Visitors: If the patient is accompanied by others, it is the patient's responsibility to inform MediTravels in advance. Any costs for accommodation, transportation, and services for these individuals are separate and not included in the patient's package.
    
    Complaints and Grievance Redresses: Any complaints about MediTravels' services or the services of its affiliates must be submitted in writing. MediTravels will attempt to address these complaints promptly and effectively, but it does not have control over the services provided by third parties such as hospitals, doctors, or hotels, local taxi.
    
    Medical Research or Clinical Trials: MediTravels does not coordinate or provide services related to medical research or clinical trials. Patients who are interested in participating in such activities should directly contact their healthcare provider or relevant institutions.
    
    Publicity and Testimonials: MediTravels may request patients to provide testimonials or participate in publicity activities. However, patient participation is voluntary, and MediTravels will not use any patient's name, image, or statement without their explicit consent.
    
    Medical Licensing and Accreditation: MediTravels works with healthcare providers that are licensed and accredited within their respective jurisdictions. However, MediTravels does not control the medical practices of these providers and cannot guarantee any outcomes or results of any treatment provided by them.
    
    Pre-Treatment Assessments and Medical History: The patient is responsible for providing accurate and complete information about their medical history, current health condition, and any other information relevant to their treatment. MediTravels will facilitate communication of this information to the healthcare providers.
    
    Discharge and Post-Treatment Care: Upon discharge from the healthcare facility, patients are responsible for following the post-treatment care instructions provided by their healthcare provider. MediTravels can facilitate communication and clarification of these instructions but is not responsible for the patients' compliance.
    
    Health and Vaccination Requirements: The patient is responsible for meeting any health and vaccination requirements for travel to India. MediTravels may provide general guidance but does not provide medical advice. All such advice should be obtained from a healthcare professional.
    
    Emergency Medical Evacuation: In the event of a critical medical emergency requiring evacuation, the patient is responsible for all associated costs. MediTravels strongly recommends that patients have appropriate travel insurance that includes coverage for emergency medical evacuation.
    
    Currency Conversion and Exchange Rates: Any payments to MediTravels or associated service providers will be calculated using the current exchange rates. MediTravels is not responsible for any losses the patient may incur due to fluctuating currency exchange rates.
    
    MedITravelS Service Fee and Commission: MedITravelS will provide a single, consolidated bill that includes the cost of treatment, accommodation, local transportation, and any related services, along with the service fee. The bill will not provide a separate breakdown of the individual amounts for each component.
    
    Prohibited Activities: Patients and their accompanying persons are prohibited from engaging in any illegal activities during their stay in India. This includes, but is not limited to, drug use, trafficking, and any forms of criminal behaviour. Violation of this term may result in immediate termination of services by MediTravels.
    
    Travel Advisories and Safety Precautions: Patients are responsible for reviewing and adhering to travel advisories issued by their home country and the government of India. MediTravels is not responsible for any adverse events that occur due to non-adherence to these advisories.
    
    Success Rates and Outcome Expectations: MediTravels does not guarantee any specific outcomes or success rates associated with any medical treatment or procedure. Any statements regarding success rates or outcome expectations are solely the opinion of the respective healthcare provider.
    
    Assistance to Family Members: MedITravelS will provide assistance in arranging necessary services for the care takers or family members accompanying the patient. This includes assistance with flight bookings, visa application procedures, accommodation arrangements, local travel arrangements, and any other related services required by the care takers or family members during their stay in India. MedITravelS understands the importance of having support and companionship during the patient's journey and treatment. Therefore, MedITravelS will work closely with the care takers or family members to ensure their travel and stay are organized and comfortable. This may involve providing guidance on visa requirements, facilitating flight bookings, recommending suitable accommodation options near the medical facility, arranging local transportation, and offering any additional support needed during their stay.The specific details and arrangements for the care takers or family members will be discussed and agreed upon in consultation with MedITravelS. Please note that the expenses and responsibilities related to the care takers or family members, including flight tickets, visa fees, accommodation charges, local travel expenses, and any other associated costs, will be separate from the patient's expenses and will be borne by the care takers or family members themselves. MedITravelS will assist in making the necessary arrangements but will not be liable for any costs or liabilities arising from these arrangements.
    
    Severability and Waiver: If any clause in these terms and conditions is found to be unenforceable under applicable law, such unenforceability will not affect the enforceability of the remainder of these terms and conditions. Failure by MediTravels to enforce strict performance of these terms and conditions does not constitute a waiver of any provision or right. 
    
    These terms and conditions are formulated to protect the rights and obligations of both MediTravels and the patients using its services. By availing MediTravels' services, patients agree to adhere to these terms and conditions in their entirety. It is highly recommended that patients carefully read and understand these terms before availing the services offered by MediTravels.
    `;

    const updatedText = addBulletsAndLineBreaks(originalText);

    return (
        <Page>
            <FirstSection>
                <h4>Complain page</h4>
            </FirstSection>
            <SectionComponent>
                <label className="sec-label">CHIEF COMPLAIN</label>
                <CustomTextField
                    id="outlined-basic"
                    placeholder="Chief complain"
                    variant="outlined"
                    value={chiefInput}
                    onChange={handleChiefChange}
                    onBlur={handleChiefBlur}
                />
                <span className="error-msg">
                    {showChiefInputError && chiefInputError && (
                        <p className="error-msg">{chiefInputError}</p>
                    )}
                </span>
                <FileUpload onChange={handleFileUpload1} value={chiefImg} />
                {imagePreviewUrl01 && (
                    <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                        <CustomButton type="button" onClick={() => setImagePreviewUrl(null)} // Reset the image preview URL
                        >X</CustomButton>
                    </div>
                        <div style={{ border: '1px solid black' }}>
                            <img src={imagePreviewUrl01} alt="Selected" style={{ width: '100%' }} />
                        </div>
                    </>
                )}
                {pdfPreviewUrl01 && (
                    <>
                        <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                            <CustomButton type="button" onClick={() => setPdfPreviewUrl(null)} // Reset the PDF preview URL
                            >X</CustomButton>
                        </div>
                        <div style={{ border: '1px solid black' }}>
                            <iframe
                                src={pdfPreviewUrl01}
                                title="PDF Preview"
                                style={{ width: '100%', height: '500px' }}
                            />
                        </div>
                    </>
                )}
            </SectionComponent>
            <SectionComponent>
                <label className="sec-label">PAST HISTORY</label>
                <CustomTextField
                    id="outlined-basic"
                    placeholder="Past history"
                    variant="outlined"
                    value={pastInput}
                    onChange={handlePastChange}
                    onBlur={handlePastBlur}
                />
                <span className="error-msg">
                    {showPastInputError && pastInputError && (
                        <p className="error-msg">{pastInputError}</p>
                    )}
                </span>
                <FileUpload onChange={handleFileUploadPast} value={pastImg} />
                {imagePreviewUrl1 && (
                    <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                        <CustomButton type="button" onClick={() => setImagePreviewUrl1(null)} // Reset the image preview URL
                        >X</CustomButton>
                    </div>
                        <div style={{ border: '1px solid black' }}>
                            <img src={imagePreviewUrl1} alt="Selected" style={{ width: '100%' }} />
                        </div>
                    </>
                )}
                {pdfPreviewUrl1 && (
                    <>
                        <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                            <CustomButton type="button" onClick={() => setPdfPreviewUrl1(null)} // Reset the PDF preview URL
                            >X</CustomButton>
                        </div>
                        <div style={{ border: '1px solid black' }}>
                            <iframe
                                src={pdfPreviewUrl1}
                                title="PDF Preview"
                                style={{ width: '100%', height: '500px' }}
                            />
                        </div>
                    </>
                )}
            </SectionComponent>
            <SectionComponent>
                <label className="sec-label">PRESENT MEDICATIONS</label>
                <CustomTextField
                    id="outlined-basic"
                    placeholder="Present Medications"
                    variant="outlined"
                    value={presentInput}
                    onChange={handlePresentChange}
                    onBlur={handlePresentBlur}
                />
                <span className="error-msg">
                    {showPresentInputError && presentInputError && (
                        <p className="error-msg">{presentInputError}</p>
                    )}
                </span>
                <FileUpload onChange={handleFileUploadPresent} value={presentImg} />
                {imagePreviewUrl2 && (
                    <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                        <CustomButton type="button" onClick={() => setImagePreviewUrl2(null)} // Reset the image preview URL
                        >X</CustomButton>
                    </div>
                        <div style={{ border: '1px solid black' }}>
                            <img src={imagePreviewUrl2} alt="Selected" style={{ width: '100%' }} />
                        </div>
                    </>
                )}
                {pdfPreviewUrl2 && (
                    <>
                        <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                            <CustomButton type="button" onClick={() => setPdfPreviewUrl2(null)} // Reset the PDF preview URL
                            >X</CustomButton>
                        </div>
                        <div style={{ border: '1px solid black' }}>
                            <iframe
                                src={pdfPreviewUrl2}
                                title="PDF Preview"
                                style={{ width: '100%', height: '500px' }}
                            />
                        </div>
                    </>
                )}
            </SectionComponent>
            <SectionComponent>
                AGREEMENT TO COMPANY'S TERMS AND CONDITIONS*
                <br />
                <CheckboxComponent
                    label="I Agree"
                    checked={isChecked}
                    // onChange={handleCheckboxChange}
                    onChange={() => handleViewClick1()}
                    onBlur={handleCheckboxChangeBlur}
                />
                {showCheckboxError1 && checkboxError1 && (
                    <div className="error-msg">{checkboxError1}</div>
                )}
                <div style={{ paddingTop: '10px' }}>
                    <CustomButton type="button" onClick={handleViewClick1} >
                        View
                    </CustomButton>
                </div>
            </SectionComponent>
            <SectionComponent>
                PRIVACY POLICY CONSENT*
                <br />
                <CheckboxComponent
                    label="I Agree"
                    checked={isCheckeds}
                    // onChange={handleCheckboxChanges}
                    onChange={() => handleViewClick2()}
                    onBlur={handleCheckboxChangesBlur}
                />
                {showCheckboxError2 && checkboxError2 && (
                    <div className="error-msg">{checkboxError2}</div>
                )}
                <div style={{ paddingTop: '10px' }}>
                    <CustomButton type="button" onClick={handleViewClick2} >
                        View
                    </CustomButton>
                </div>
            </SectionComponent>
            <SectionComponent>
                PLEASE PROVIDE YOUR PHOTO ID PROOF*
                <div>
                    <FileUpload onChange={handleFileUpload} />
                    {mainFileError && <div className="error-msg">{mainFileError}</div>}
                    <div style={{ paddingTop: '15px' }}>
                        {imagePreviewUrl && (
                            <>
                                <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                                    <CustomButton
                                        type="button"
                                        onClick={() => setImagePreviewUrl(null)} // Reset the image preview URL
                                    >
                                        X
                                    </CustomButton>
                                </div>
                                <div style={{ border: '1px solid black' }}>
                                    <img src={imagePreviewUrl} alt="Selected" style={{ width: '100%' }} />
                                </div>
                            </>
                        )}
                        {pdfPreviewUrl && (
                            <>
                                <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                                    <CustomButton
                                        type="button"
                                        onClick={() => setPdfPreviewUrl(null)} // Reset the PDF preview URL
                                    >
                                        X
                                    </CustomButton>
                                </div>
                                <div style={{ border: '1px solid black' }}>
                                    <iframe
                                        src={pdfPreviewUrl}
                                        title="PDF Preview"
                                        style={{ width: '100%', height: '500px' }}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </SectionComponent>
            <SectionComponent>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    PLEASE PROVIDE YOUR CURRENT PHOTO FOR THE PURPOSE OF IDENTIFICATION*
                </div>
                <div style={{ paddingTop: '15px' }}>
                    {!showWebcam && !capturedImage && !uploadSuccess && !uploadedImageUrl && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <BsPersonFill size={25} color="gray" style={{ marginRight: '10px' }} />
                            <CustomButton onClick={handleCaptureButtonClick}>Capture Your Image</CustomButton>
                        </div>
                    )}
                    {(uploadSuccess || uploadedImageUrl) && (
                        <div>
                            <BsPersonFill size={25} color="green" style={{ marginRight: '10px' }} />
                            <BsCheckCircle size={20} color="green" style={{ marginRight: '10px' }} />
                        </div>
                    )}
                </div>
                {capctureImgPhotoerror && <div className="error-msg">{capctureImgPhotoerror}</div>}
            </SectionComponent>
            <Modal show={showWebcamModal} className="newsletter-popup" backdrop="static">
                <Modal.Header>
                    <Modal.Title>Capture Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showWebcam && (
                        <div className='capture-modal' style={{ position: 'relative', width: '500px' }}>
                            {loading && (
                                <div className="loading" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                    <div></div>
                                    <div></div>
                                </div>
                            )}
                            {/* {!loading && ( */}
                            <Webcam
                                audio={false}
                                height={300}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={500}
                                videoConstraints={videoConstraints}
                                imageSmoothing={true}
                                mirrored={true}
                                style={{ display: 'block' }}
                            />
                            {/* )} */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <CustomButton onClick={handleCapturePhoto} disabled={loading}>
                                    Take a Shot
                                </CustomButton>
                                <CustomButton variant="secondary" onClick={handleWebcamModalClose}>
                                    Close
                                </CustomButton>
                            </div>
                        </div>
                    )}
                    {capturedImage && !uploadSuccess && !loading && (
                        <div>
                            <img src={capturedImage} alt="Captured" style={{ transform: 'scaleX(-1)' }} />
                            <div style={{ padding: '20px 0px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                <CustomButton onClick={handleUploadImage}>Upload</CustomButton>
                                <CustomButton onClick={handleRecaptureButtonClick}>Try Again</CustomButton>
                            </div>
                        </div>
                    )}
                    {capturedImage && !uploadSuccess && loading && (
                        <div className="loading">
                            <div></div>
                            <div></div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            <Modal show={showModal1} onHide={handleModalClose1} className="newsletter-popup view-popup" backdrop="static">
                <Modal.Header className="modal-close-button" style={{ width: '100%' }}>
                    <Modal.Title >Terms and Conditions for Patients Traveling to India through MediTravels MITS HOSPITALITY LLP
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    ref={bodyRef1}
                    style={{
                        maxHeight: '400px', // Adjust this value as needed
                        overflowY: 'scroll',
                    }}
                    onScroll={(e) => {
                        const target = e.target;
                        const scrollHeight = target.scrollHeight;
                        const clientHeight = target.clientHeight;
                        const scrollTop = target.scrollTop;

                        // Calculate a threshold for determining if the modal is scrolled to the end
                        const scrollThreshold = 5; // Adjust this value as needed

                        const isAtEnd = scrollHeight - scrollTop - clientHeight <= scrollThreshold;

                        setIsModalScrolled1(isAtEnd);

                        if (isAtEnd) {
                            setIsAgreeEnabled1(true);
                        } else {
                            setIsAgreeEnabled1(false);
                        }
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: updatedText }} className='tc-text'></div>
                    {/* <span style={{ display: 'inline-block' }}>
                        
                    </span> */}
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                        <Button
                            className={`agree-btn ${isModalScrolled1 ? 'scrolled' : ''} ${isAgreeEnabled1 ? '' : 'disabled-btn'}`}
                            onClick={isAgreeEnabled1 ? handleAgreeClick : undefined}
                            style={{
                                backgroundColor: isModalScrolled1 ? '#291211' : '#f4a460',
                                pointerEvents: isModalScrolled1 && isAgreeEnabled1 ? 'auto' : 'none',
                                filter: isModalScrolled1 ? 'none' : 'grayscale(100%)',
                            }}
                        >
                            Agree
                        </Button>
                        <Button className="btn btn-yellow" variant="secondary" onClick={() => setShowModal1(false)} label='Close'>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={showModal2} onHide={handleModalClose2} className="newsletter-popup view-popup" backdrop="static">
                <Modal.Header className="modal-close-button" style={{ width: '100%' }}>
                    <Modal.Title >Privacy Policy for MedITravelS</Modal.Title>

                </Modal.Header>
                <Modal.Body
                    ref={bodyRef2}
                    style={{
                        maxHeight: '400px', // Adjust this value as needed
                        overflowY: 'scroll',
                    }}
                    onScroll={(e) => {
                        const target = e.target;
                        const scrollHeight = target.scrollHeight;
                        const clientHeight = target.clientHeight;
                        const scrollTop = target.scrollTop;

                        // Calculate a threshold for determining if the modal is scrolled to the end
                        const scrollThreshold = 5; // Adjust this value as needed

                        const isAtEnd = scrollHeight - scrollTop - clientHeight <= scrollThreshold;

                        setIsModalScrolled2(isAtEnd);

                        if (isAtEnd) {
                            setIsAgreeEnabled2(true);
                        } else {
                            setIsAgreeEnabled2(false);
                        }
                    }}
                >
                    {/* Add your privacy policy text here */}
                    <span style={{ display: 'inline-block' }}>At MedITravelS, we prioritize the privacy of our visitors and are committed to protecting their personal information. This Privacy Policy explains how we collect, use, and safeguard the information you provide when using our website.</span>
                    <span style={{ display: 'inline-block' }}>If you have any questions or concerns about this Privacy Policy, please feel free to contact us.</span>
                    <span style={{ display: 'inline-block' }}>Information We Collect</span>
                    <span style={{ display: 'inline-block' }}>When you visit our website or interact with our services, we may collect the following types of personal information:</span>
                    <span style={{ display: 'inline-block' }}>1. Personal Identifiable Information: This may include your full name, email address, postal address, telephone number, date of birth, and other contact details. We collect this information when you register for an account, subscribe to our newsletters, submit inquiries or feedback, or engage in transactions on our website.</span>
                    <span style={{ display: 'inline-block' }}>2. Financial Information: If you engage in financial transactions, such as making payments for services or products, we may collect financial information, including credit card details, bank account information, or other payment-related information. Please note that we utilize secure payment processors to handle these transactions, and we do not store your financial information on our servers.</span>
                    <span style={{ display: 'inline-block' }}>3. Medical Information: In order to provide personalized medical services or assistance, we may collect relevant medical information such as your medical history, current medical conditions, prescription details, and other health-related data. This information is collected with your explicit consent and is treated with the utmost confidentiality.</span>
                    <span style={{ display: 'inline-block' }}>4. Usage Information: We may collect information about how you interact with our website and services, including your browsing activities, pages viewed, links clicked, and other actions taken on our site. This data is collected using cookies, log files, and similar technologies to analyze trends, improve our website functionality, and enhance your overall user experience. For more details on the use of cookies, please refer to the "Cookies and Web Beacons" section of this Privacy Policy.</span>
                    <span style={{ display: 'inline-block' }}>5. Device and Technical Information: When you access our website, we may automatically collect certain technical information about your device, such as your IP address, browser type, operating system, device identifiers, and referring URLs. This information helps us diagnose technical issues, prevent fraudulent activities, and optimize our website performance.</span>
                    <span style={{ display: 'inline-block' }}>6. Geolocation Information: With your explicit consent, we may collect and process information about your approximate location based on your IP address or other Geolocation technologies. This information may be used to provide location-specific services or tailor content to your region.</span>
                    <span style={{ display: 'inline-block' }}>7. Log Files: Like many other websites, we gather certain information automatically and store it in log files. This information may include your IP address, browser type, Internet Service Provider (ISP), referring/exit pages, date and time stamps, and other browsing-related data. We use this information to analyze trends, administer the site, track user movement, and gather demographic information.</span>
                    <span style={{ display: 'inline-block' }}>8. Cookies and Web Beacons: We use cookies and web beacons to enhance your browsing experience. Cookies are small files stored on your device that allow us to recognize your preferences and provide customized content. Web beacons are small graphic images embedded in web pages or emails that help us analyze user behaviour and improve our services. You can manage your cookie preferences through your browser settings.</span>
                    <span style={{ display: 'inline-block' }}>Please note that the types of information collected may vary depending on the nature of your interaction with our website and the services you utilize. We only collect personal information that is necessary for the purposes outlined in this Privacy Policy, and we strive to minimize the data collected to what is essential for providing our services effectively.</span>
                    <span style={{ display: 'inline-block' }}>If you have any concerns about the specific information being collected or would like further clarification, please contact us using the information provided in the "Contact Us" section of this Privacy Policy.</span>
                    <span style={{ display: 'inline-block' }}>How We Use Your Information</span>
                    <span style={{ display: 'inline-block' }}>We use the information we collect for various purposes, including:</span>
                    <span style={{ display: 'inline-block' }}>1. Providing and improving our Services: We use your information to operate, maintain, and enhance our website, ensuring a smooth user experience and optimizing our services.</span>
                    <span style={{ display: 'inline-block' }}>2. Communication: We may contact you directly or through our partners for customer service, to provide updates and information related to our services, and for marketing and promotional purposes. You have the option to unsubscribe from these communications at any time.</span>
                    <span style={{ display: 'inline-block' }}>3. Personalization: We may use the information we collect to personalize your experience on our website, such as tailoring content and recommendations based on your preferences.</span>
                    <span style={{ display: 'inline-block' }}>4. Fraud Prevention: We may use the information to detect and prevent fraudulent activities, ensuring the security and integrity of our services.</span>
                    <span style={{ display: 'inline-block' }}>Data Sharing and Third Parties</span>
                    <span style={{ display: 'inline-block' }}>We respect your privacy and do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except in the following circumstances:</span>
                    <span style={{ display: 'inline-block' }}>1. Service Providers: We may engage trusted third-party service providers to assist us in operating our website and providing services. These service providers are bound by confidentiality agreements and are prohibited from using your personal information for any other purpose.</span>
                    <span style={{ display: 'inline-block' }}>2. Legal Requirements: We may disclose your personal information if required by law, regulation, legal process, or governmental request, or in cases where we believe it is necessary to protect our rights, safety, or the rights of others.</span>
                    <span style={{ display: 'inline-block' }}>Data Security</span>
                    <span style={{ display: 'inline-block' }}>We take appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</span>
                    <span style={{ display: 'inline-block' }}>Advertising Partners Privacy Policies</span>
                    <span style={{ display: 'inline-block' }}>You may consult this list to find the Privacy Policy for each of the advertising partners of www. meditravels.in.
                        Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on www. meditravels.in, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                        Note that www.meditravels.in has no access to or control over these cookies that are used by third-party advertisers</span>
                    <span style={{ display: 'inline-block' }}>GDPR Data Protection Rights</span>
                    <span style={{ display: 'inline-block' }}>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                        The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
                        The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
                        The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
                        The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
                        The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
                        The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</span>
                    <span style={{ display: 'inline-block' }}>Children's Privacy</span>
                    <span style={{ display: 'inline-block' }}>Protecting the privacy of children is important to us. Our services are not directed at individuals under the age of 18, and we do not knowingly collect personal information from children. If you believe that your child has provided us with personal information, please contact us immediately, and we will take steps to remove that information from our records.</span>
                    <span style={{ display: 'inline-block' }}>Changes to the Privacy Policy</span>
                    <span style={{ display: 'inline-block' }}>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date will be revised accordingly. We encourage you to review this Privacy Policy periodically for any updates.</span>

                    {/* </div> */}
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                        <Button
                            className={`agree-btn ${isModalScrolled2 ? 'scrolled' : ''} ${isAgreeEnabled2 ? '' : 'disabled-btn'}`}
                            onClick={isAgreeEnabled2 ? handleAgreeClick2 : undefined}
                            style={{
                                backgroundColor: isModalScrolled2 ? '#291211' : '#f4a460',
                                pointerEvents: isModalScrolled2 && isAgreeEnabled2 ? 'auto' : 'none',
                                filter: isModalScrolled2 ? 'none' : 'grayscale(100%)',
                            }}
                        >
                            Agree
                        </Button>
                        <Button className="btn btn-yellow" variant="secondary" onClick={() => setShowModal2(false)} label='Close'>
                            Close
                        </Button>

                    </div>
                </Modal.Footer>
            </Modal>
            <Action backLink={previousPageURL} onClickNext={handleNextClick} onClearForm={handleClearForm} />
            <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
                <Ticket variant="contained" color="secondary" section="Patient Form: Complain page" />
            </div>
            <CustomModal
                show={errorModalData.show}
                onHide={handleCloseErrorModal}
                titleStyle={{ fontWeight: "bold" }}
                title={errorModalData.title}
                message={errorModalData.message}
            />
        </Page>
    );
};

export default EducationalBackground;