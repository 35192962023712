import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { fetchBlogs, fetchBlogById } from '../../api';

const HolidayFun = (props) => {
  const [blogs, setBlogs] = useState([]);
  const location = useLocation();
  const { blog_id } = location.state || {};
  const history = useHistory();

  let publicUrl = process.env.PUBLIC_URL + '/';

  useEffect(() => {
    const page = 1; 
    const category = 'Travel'; 

    fetchBlogs(page, category)
      .then((responseData) => {
        // Process and set your blog list data here
        setBlogs(responseData.data.list);
      })
      .catch((error) => {
        console.error('Error in BlogListPage:', error);
      });
  }, []);

  useEffect(() => {
    if (blog_id) {
      fetchBlogById(blog_id)
        .then((blogData) => {
          console.log('blog data:', blogData);
          // Process and set your blog data here
          setBlogs(blogData);
        })
        .catch((error) => {
          console.error('Error in BlogPage:', error);
        });
    }
  }, [blog_id]);

  const handleClick = (blog_id, image) => {
    localStorage.setItem('blog_id', blog_id);
    localStorage.setItem('image', image);
  };

  const blogDetailsPage = (blogId) => {
    history.push(`/blog-details/${blogId}`);
  };

  const truncateText = (text, limit) => {
    const words = text.split(' ');
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...';
    }
    return text;
  };

  return (
    <div className="holiday-plan-area tp-holiday-plan-area mg-top-96" style={{ backgroundImage: 'url(' + publicUrl + 'assets/img/bg/8.png)' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-9">
            <div className="section-title text-center">
              <h2 className="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">
                Perfect Refreshing Plan
              </h2>
              <p className="wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">
                A perfect tourism refreshing plan should prioritize self-care, staying active, getting enough rest, and incorporating relaxation activities
                to promote physical, mental, and emotional well-being, offering unique experiences that showcase local culture and natural beauty.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <React.Fragment>
            <div className="row">
              {blogs.length > 0 &&
                blogs.slice(-4).map((blog) => (
                  <div className="col-lg-3 col-md-6" key={blog._id}>
                    <div className="single-blog">
                      <div className="thumb">
                        <img src={blog.blogimage} alt="blog" />
                      </div>
                      <div className="single-blog-details">
                        <h4 className="title" style={{height: '50px', fontSize: '21px'}}>
                          <Link
                            to={{
                              pathname: `/blog-details/${blog._id}`,
                              state: { blog_id: blog._id, image: blog.image },
                            }}
                            onClick={() => handleClick(blog._id, blog.image)}
                          >
                            {truncateText(blog.blogname, 9)}
                          </Link>
                        </h4>
                        <p className="content" style={{height: '95px'}}>{blog.blogdetails.substring(0, 100)}{blog.blogdetails.length > 100 ? "..." : ""}</p>
                        <Link
                          className="btn-read-more"
                          to={{
                            pathname: `/blog-details/${blog._id}`,
                            state: { blog_id: blog._id, image: blog.image },
                          }}
                          onClick={() => handleClick(blog._id, blog.image)}
                        >
                          <span>Read More<i className="la la-arrow-right" /></span>
                        </Link>
                        {/* <Link
                          className="btn-read-more"
                          to={{ pathname: `/blog-details/${blog._id}`, state: { blog_id: blog._id } }}
                        >
                          <span>Read More <i className="la la-arrow-right" /></span>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

export default HolidayFun;
