import React from "react";

const TreatmentBlogSkeleton = () => {
  const blogItems = Array.from({ length: 4 });

  return (
    <div className="all-blog">
      {blogItems.map((_, index) => (
        <div className="skeletonblog" key={index}>
          <div className="loading-line"></div>
          <div className="skeleton-image"></div>
          <div className="skeleton-details">
            <div className="skeleton-title"></div>
            <div className="skeleton-button"></div>
          </div>
        </div>
      ))}{" "}
    </div>
  );
};

export default TreatmentBlogSkeleton;
