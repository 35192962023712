import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import BlogDetailsPage from '../blog-details';
// import parse from 'html-react-parser';
// import Page_header from '../global-components/page-header';
// import TreatmentDetails from '../treatment-details';
// import Physician from './treatments2';
// import Surgeon from './treatments3';
// import Surgeon1 from './treatments6';
// import ElbowReplacement from './elbow-replacement';
class TourList extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        
        return <>
        <div className="container">
          <div className='row'>
              <div className="col-md-5">
                <ul className="nav nav-tabs tp-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#tabs_1">ORTHOPEDICS</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tabs_2">CARDIOLOGY</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tabs_3">GYNECOLOGIST AND OBSTRATRICIAN</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tabs_4">SURGEON</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tabs_5">PHYSICIANS</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tabs_6">ONCOLOGY</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tabs_7">SKIN</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tabs_8">OPHTHLAMOLOGY</a>
                  </li>
                </ul>
              </div>

              <div className="col-md-7">
                <div className='tab-content faq-tab-conten'>
                  <div className="tab-pane fade show active" id="tabs_1">
                      <ul className="nav nav-tabs tp-tabs" id="tabs_1">
                      <li className="nav-item">
                        <Link className="nav-link active"  to={{pathname:"/treatment-details",}}>KNEE REPLACEMENT - FULL / PARTIAL</Link>
                      </li>
                      <li className="nav-item">
                      <Link className="nav-link " to='/hip-replacement'>HIP REPLACEMENT - FULL / PARTIAL</Link>
                      </li>
                      <li className="nav-item">
                      <Link className="nav-link " to='/elbow-replacement'>ELBOW REPLACEMNET</Link>
                      </li>
                      <li className="nav-item">
                      <Link className="nav-link " to='/shoulder-replacement'>SHOULDER REPLACEMENT</Link>
                      </li>
                      <li className="nav-item">
                      <Link className="nav-link " to='/arthroscopy'>ARTHROSCOPY</Link>
                      </li>
                     
                      </ul>
                  </div>  
                  <div className="tab-pane fade show" id="tabs_2">
                  <ul className="nav nav-tabs tp-tabs" id="tabs_1">
                      <li className="nav-item">
                      <Link className="nav-link active" to='/treatments2'>PHYSICIAN</Link>
                      </li>
                      <li className="nav-item">
                      <Link className="nav-link " to='/treatments3'>SURGEON</Link>
                      </li>
                    
                      </ul>
                  </div>
                  <div className="tab-pane fade show" id="tabs_3">
                      <ul className="nav nav-tabs tp-tabs" id="tabs_1">
                      <li className="nav-item">
                      <Link className="nav-link " to='/blog-details'>IVF</Link>
                      </li>
                      <li className="nav-item">
                      <Link className="nav-link " to='/blog-details'>HYSTRECTOMY</Link>
                      </li>
                      
                      </ul>
                  </div>
                  <div className="tab-pane fade show" id="tabs_4">
                      <ul className="nav nav-tabs tp-tabs" id="tabs_1">
                      <li className="nav-item">
                      <Link className="nav-link " to='/treatments4'>GASTRO SUREGON</Link>
                      </li>
                      <li className="nav-item">
                      <Link className="nav-link " to='/treatments5'>GENERAL SURGEON</Link>
                      </li>
                      
                      </ul>
                  </div>
                  <div className="tab-pane fade show" id="tabs_5">
                      {/* <ul className="nav nav-tabs tp-tabs" id="tabs_1">
                      <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#tabs_1">GASTRO SUREGON</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#tabs_2">GENERAL SURGEON</a>
                      </li>
                      
                      </ul> */}
                  </div>
                  <div className="tab-pane fade show" id="tabs_6">
                      <ul className="nav nav-tabs tp-tabs" id="tabs_1">
                      <li className="nav-item">
                      <Link className="nav-link " to='/treatments6'>SURGEON</Link>
                      </li>
                      <li className="nav-item">
                      <Link className="nav-link " to='/treatments7'>PHYSICIAN</Link>
                      </li>
                      
                      </ul>
                  </div>
                  <div className="tab-pane fade show" id="tabs_7">
                      <ul className="nav nav-tabs tp-tabs" id="tabs_1">
                      <li className="nav-item">
                      <Link className="nav-link " to='/blog-details'>HAIR REMOVAL</Link>
                      </li>
                      <li className="nav-item">
                      <Link className="nav-link " to='/blog-details'>TATTOO REMOVAL</Link>
                      </li>
                      <li className="nav-item">
                      <Link className="nav-link " to='/blog-details'>GLOWNESS</Link>
                      </li>
                      
                      </ul>
                  </div>
                  <div className="tab-pane fade show" id="tabs_8">
                      <ul className="nav nav-tabs tp-tabs" id="tabs_1">
                      <li className="nav-item">
                      <Link className="nav-link " to='/blog-details'>CATARACT</Link>
                      </li>
                      <li className="nav-item">
                      <Link className="nav-link " to='/blog-details'>GLUCOMA</Link>
                      </li>
                      <li className="nav-item">
                      <Link className="nav-link " to='/blog-details'>LASIK</Link>
                      </li>
                      
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

        }
}

export default TourList