import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UploadMedia_api_url, UpdateUser_api_url } from "../../constant/url";
import { createContactUs } from '../../api';

function UserProfile() {
	const history = useHistory();
	const [selectedImage, setSelectedImage] = useState(null);
	const [updatedUser, setUpdatedUser] = useState(null);
	const [password, setpassword] = useState("");
	const [confirm_password, setconfirm_password] = useState("");
	const [oldPassword, setOldPassword] = useState('');
	const [passwordError, setPasswordError] = useState(false);
	const [confirmPasswordError, setConfirmPasswordError] = useState(false);
	const [showPasswordError, setshowPasswordError] = useState(false);
	const [showConfirmPasswordError, setshowConfirmPasswordError] = useState(false);

	const userDataJSON = localStorage.getItem("user");
	const userData = userDataJSON ? JSON.parse(userDataJSON) : {};

	const publicUrl = process.env.PUBLIC_URL;

	const handleSave = async () => {
		if (!selectedImage) {
			return;
		}

		const formData = new FormData();
		formData.append('media', selectedImage);
		formData.append('type', 'user-profile');

		try {
			const uploadResponse = await fetch(UploadMedia_api_url, {
				method: 'POST',
				body: formData,
			});

			if (!uploadResponse.ok) {
				const error = await uploadResponse.json();
				alert(error.message);
				throw new Error(error.message);
			}

			const { filepath: uploadedImagePath } = await uploadResponse.json();
			console.log("Uploaded Image Path:", uploadedImagePath);

			// Update the user's profile image in the database using the UpdateUser_api_url
			const updateUserResponse = await fetch(`${UpdateUser_api_url.replace(":id", userData.id)}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					...userData,
					profile_picture: uploadedImagePath,
				}),
			});

			if (!updateUserResponse.ok) {
				const error = await updateUserResponse.json();
				alert(error.message);
				throw new Error(error.message);
			}

			const updatedUser = await updateUserResponse.json();
			console.log("Updated User API Response:", updatedUser);
			setUpdatedUser(updatedUser);

			// Save the updated user data to local storage
			const updatedUserData = {
				...userData,
				profile_picture: uploadedImagePath,
			};
			localStorage.setItem("user", JSON.stringify(updatedUserData));

			// Perform any additional actions, such as displaying a success message or redirecting to a different page
		} catch (error) {
			console.error("Error uploading image or updating user:", error);
			// Handle the error, display an error message, or perform any necessary actions
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page on component mount
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0); // Scroll to the top of the page on route change
		});
		return () => unlisten();
	}, [history]);

	const handleImagePreview = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setSelectedImage(file);
				const previewImage = document.getElementById('tp_imagePreview');
				previewImage.style.backgroundImage = `url(${reader.result})`;
			};
			reader.readAsDataURL(file);
		}
	};

	const handlesavepassword = async () => {
		console.log("settings", userData.password);
		try {
		  const updatedUserData = {
			...userData,
			password: password,
		  };
	  
		  // Update the user's profile image in the database using the UpdateUser_api_url
		  const updateUserResponse = await fetch(
			`${UpdateUser_api_url.replace(":id", userData.id)}`,
			{
			  method: "PUT",
			  headers: {
				"Content-Type": "application/json",
			  },
			  body: JSON.stringify(updatedUserData),
			}
		  );
	  
		  if (!updateUserResponse.ok) {
			const error = await updateUserResponse.json();
			alert(error.message);
			throw new Error(error.message);
		  }
	  
		  const updatedUser = await updateUserResponse.json();
		  console.log("Updated User API Response:", updatedUser);
		  setUpdatedUser(updatedUser);
		} catch (error) {
		  console.error("Error uploading image or updating user:", error);
		  // Handle the error, display an error message, or perform any necessary actions
		}
	  };
	  
	  useEffect(() => {
		if (updatedUser) {
		  console.log("updatedUser", updatedUser);
		  // Save the updated user data to local storage
		  localStorage.setItem("user", JSON.stringify(updatedUser));
	  
		  // Perform any additional actions, such as displaying a success message or redirecting to a different page
		  console.log("password", password);
		}
	  }, [updatedUser]);
	  
	const handleOldPasswordChange = (event) => {
		setOldPassword(event.target.value);
	};

	const handlePasswordChange = (event) => {
		const passwordValue = event.target.value;
		setpassword(passwordValue);
		setPasswordError(passwordValue.length < 8);
		setConfirmPasswordError(
			confirm_password && confirm_password !== passwordValue
		);
	};

	const handleConfirmPasswordChange = (event) => {
		const confirmPasswordValue = event.target.value;
		setconfirm_password(confirmPasswordValue);
		setConfirmPasswordError(
			password && password !== confirmPasswordValue
		);
	};

	const handlePasswordBlur = () => {
		setshowPasswordError(true);
	};

	const handleConfirmPasswordBlur = () => {
		setshowConfirmPasswordError(true);
	};

	return <div className="user-profile-area pd-top-120">
		<div className="container">
			<div className="row">
				<div className="col-xl-10 col-lg-12">
					<div className="row">
						<div className="col-lg-4">
							<ul className="nav nav-tabs tp-tabs style-two">
								<li className="nav-item">
									<a className="nav-link active" data-toggle="tab" href="#tabs_1"><i className="fa fa-user" />Profile</a>
								</li>
								{/* <li className="nav-item">
			                <a className="nav-link" data-toggle="tab" href="#tabs_2"><i className="fa fa-check-square-o" />Verifications</a>
			              </li> */}
								{/* <li className="nav-item">
									<a className="nav-link" data-toggle="tab" href="#tabs_3"><i className="fa fa-cog" /> Password Settings</a>
								</li> */}
								{/* <li className="nav-item">
									<a className="nav-link" data-toggle="tab" href="#tabs_4"><i className="fa fa-recycle" />Recently Viewed</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" data-toggle="tab" href="#tabs_5"><i className="fa fa-credit-card-alt" />Payment Methods</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" data-toggle="tab" href="#tabs_6"><i className="fa fa-star-o" />Reviews</a>
								</li>
								<li className="text-center">
									<a className="btn btn-yellow" href="#"><i className="fa fa-sign-in" aria-hidden="true" /> <span>Log Out</span></a>
								</li> */}
							</ul>
						</div>
						<div className="col-xl-7 col-lg-8 offset-xl-1">
							<div className="tab-content user-tab-content">
								<div className="tab-pane fade show active" id="tabs_1">
									<div className="user-details">
										<h3 className="user-details-title">Profile</h3>
										<div className="tp-img-upload">
											<div className="tp-avatar-preview">
												{/* <div id="tp_imagePreview" style={{backgroundImage: 'url('+publicUrl+'assets/img/team/1.png)'}}></div> */}
												<div id="tp_imagePreview" style={{ backgroundImage: 'url(' + userData.profile_picture + ')' }}></div>

											</div>
											<div className="tp-avatar-edit">
												<label className="profile-upload-btn" htmlFor="upload">
													<input type="file" id="upload" accept="image/*" onChange={handleImagePreview} />
													Change Avatar
												</label>
												{/* <input type="file" id="tp_imageUpload" accept=".png, .jpg, .jpeg" onChange={(e) => setSelectedImage(e.target.files[0])} />
												<label className="btn btn-transparent" htmlFor="tp_imageUpload" onClick={handleImageUpload}><i className="fa fa-picture-o" />Change Photo</label> */}
												<h4 className="name">{userData.name}</h4>
											</div>
										</div>
										<form className="tp-form-wrap">
											<div className="row">
												<div className="col-md-6">
													<label className="single-input-wrap style-two">
														<span className="single-input-title">Email Address</span>
														<input type="text" name="first-name" value={userData.email} />
													</label>
												</div>
												{/* <div className="col-md-6">
			                        <label className="single-input-wrap style-two">
			                          <span className="single-input-title">Last Number</span>
			                          <input type="text" name="last-name"/>
			                        </label>
			                      </div> */}
												{/* <div className="col-lg-12">
			                        <label className="single-input-wrap style-two">
			                          <span className="single-input-title">Tell us about yourself.</span>
			                          <textarea defaultValue={""} name="message" />
			                        </label>
			                      </div>
			                      <div className="col-md-7">
			                        <label className="single-input-wrap style-two">
			                          <span className="single-input-title">Country</span>
			                          <input type="text"  name="country"/>
			                        </label>
			                      </div>
			                      <div className="col-md-6">
			                        <label className="single-input-wrap style-two">
			                          <span className="single-input-title">Email Address</span>
			                          <input type="text" name="email" />
			                        </label>
			                      </div> */}
												<div className="col-md-6">
													<label className="single-input-wrap style-two">
														<span className="single-input-title">Phone</span>
														<input type="text" value={userData.phone} name="phone" />
													</label>
												</div>
												<div className="col-12">
													{/* <input className="btn btn-yellow mt-3 text-center" type="submit" /> */}
													<button className="btn btn-yellow mt-3 text-center" type="button" onClick={handleSave}>
														Save Changes
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
								<div className="tab-pane fade" id="tabs_2">
									<div className="user-verification">
										<div className="row">
											<div className="col-lg-7">
												<h3 className="user-details-title">Verification</h3>
												<div className="notice"><i className="fa fa-exclamation-triangle" /> Your email hasn't been verified.</div>
												<span>imshuvo97@gmail.com</span>
											</div>
										</div>
									</div>
								</div>
								<div className="tab-pane fade" id="tabs_3">
									<div className="user-settings">
										<h3 className="user-details-title">Password Settings</h3>
										
										<div className="row">
										{userData.login_type === "Email" && (							
											<div className="col-lg-7">
											<label className="single-input-wrap style-two">
												<span className="single-input-title mb-3">Change your password</span>
											
												<span className="single-input-title">Old password</span>
												<input
													type="text"
													placeholder="Old password"
													// value={userData.password}
													onChange={handleOldPasswordChange}
												/>
											</label>
										</div>
									)}
											{userData.login_type === "Google" && (
												<div className="col-lg-7">
													<label className="single-input-wrap style-two">
														<span className="single-input-title mb-3">Set your password</span>
													</label>
												</div>
											)}
											<div className="col-lg-7">
										
												<span className="single-input-title">New password</span>
												<label className="single-input-wrap style-two">
													<input
														type="text"
														placeholder="New password"
														onClick={handlePasswordChange}
														onBlur={handlePasswordBlur}
													/>
												</label>
											</div>
											<div className="col-lg-7">
										
												<span className="single-input-title">Confirm password</span>
												<label className="single-input-wrap style-two">
													<input
														type="text"
														placeholder="Confirm password"
														onClick={handleConfirmPasswordChange}
														onBlur={handleConfirmPasswordBlur}
													/>
												</label>
											</div>
											<div className="col-12">
												<button className="btn btn-yellow mt-3 text-center" type="button" onClick={handlesavepassword}>
													Save Changes
												</button>
											</div>
										</div> 
									</div>
								</div>
								<div className="tab-pane fade" id="tabs_4">
									<div className="user-recent-view">
										<h3 className="user-details-title">Recently Viewed</h3>
										<div className="row">
											<div className="col-sm-6">
												<div className="single-destinations-list style-two">
													<div className="thumb">
														<img src={publicUrl + "assets/img/destination-list/4.png"} alt="list" />
													</div>
													<div className="details">
														<p className="location"><img src={publicUrl + "assets/img/icons/1.png"} alt="map" />Maldives</p>
														<h4 className="title"><a href="#">Hurawalhi Island</a></h4>
														<p className="content">7Days Tour on 2 person</p>
														<div className="tp-price-meta">
															<h2>620 <small>$</small></h2>
														</div>
													</div>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="single-destinations-list style-two">
													<div className="thumb">
														<img src={publicUrl + "assets/img/destination-list/5.png"} alt="list" />
													</div>
													<div className="details">
														<p className="location"><img src={publicUrl + "assets/img/icons/1.png"} alt="map" />Indonesia</p>
														<h4 className="title"><a href="#">Bali Province</a></h4>
														<p className="content">4days 2 person</p>
														<div className="tp-price-meta">
															<h2>780 <small>$</small></h2>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="tab-pane fade" id="tabs_5">
									<div className="user-payment-method">
										<div className="location-review-area">
											<h3 className="user-details-title">Payment Methods</h3>
											<form className="tp-form-wrap bg-gray tp-form-wrap-one">
												<div className="row">
													<div className="col-lg-7">
														<label className="single-input-wrap">
															<span className="single-input-title">Credit card number</span>
															<input type="text" />
														</label>
														<label className="single-input-wrap">
															<span className="single-input-title">Card holder name</span>
															<input type="text" />
														</label>
														<label className="single-input-wrap">
															<span className="single-input-title">Expiry date (Example: 01/17)</span>
															<input type="text" />
														</label>
														<label className="single-input-wrap">
															<span className="single-input-title">Issuing bank</span>
															<input type="text" />
														</label>
													</div>
													<div className="col-lg-5">
														<div className="user-payment-card">
															<img src={publicUrl + "assets/img/others/16.png"} alt="img" />
															<span>Available payment method:</span>
															<div className="payment-card">
																<i className="fa fa-cc-paypal" />
																<i className="fa fa-cc-visa" />
																<i className="fa fa-cc-mastercard" />
																<i className="fa fa-credit-card-alt" />
															</div>
															<a className="btn btn-transparent" href="#">Cancel</a>
															<a className="btn btn-yellow" href="#">Save</a>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
								<div className="tab-pane fade" id="tabs_6">
									<div className="user-tour-details">
										<h3 className="user-details-title">Reviews</h3>
										<span className="user-tour-details-title">Reviews About You</span>
										<span>| Reviews By You</span>
										<div className="comments-area tour-details-review-area">
											<ul className="comment-list mb-0">
												<li>
													<div className="single-comment-wrap">
														<div className="thumb">
															<img src={publicUrl + "assets/img/client/2.png"} alt="img" />
														</div>
														<div className="content">
															<h4 className="title">Tyler Bailey</h4>
															<span className="date">13 August 2019</span>
															<div className="tp-review-meta">
																<i className="ic-yellow fa fa-star" />
																<i className="ic-yellow fa fa-star" />
																<i className="ic-yellow fa fa-star" />
																<i className="ic-yellow fa fa-star" />
																<i className="ic-yellow fa fa-star" />
															</div>
															<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata</p>
														</div>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

}

export default UserProfile