import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../pro-components/Page';
import SubmitAction from '../pro-components/submitaction';
import FirstSection from '../pro-components/FirstSection'
import CustomModal from "../../global-components/modal";
import { updatePro2, sendEmail, createPro2Status, findOnePro2Status,  fetchPRO2Data, updatePro2Status} from '../../../api';

const SubmitPage = () => {
  const history = useHistory();
  const [sectionData, setSectionData] = useState('');
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  // const pro2Id = localStorage.getItem('pro2Id');
  const proId = localStorage.getItem('proId');
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const updateSectionData = async (sectionData) => {
    console.log("user id from local storage", userData.id);
    const userId = userData.id;
    console.log("-------userId from local storage", userId);

    if (!userId) {
      console.log("User ID not found in local storage");
      return;
    }

    try {
      const existingSectionData = JSON.parse(localStorage.getItem("firstPageData2"));
      const section_data = [
        {
          0: existingSectionData, // Include the first page data at index 0
        },
        {
          1: JSON.parse(localStorage.getItem("secondPageData2")), // Include the second page data at index 1
        },
        {
          2: JSON.parse(localStorage.getItem("thirdPageData2")), // Include the second page data at index 1
        },
        {
          3: JSON.parse(localStorage.getItem("fourthPageData2")), // Include the second page data at index 1
        },
        {
          4: JSON.parse(localStorage.getItem("fifthPageData2")),
        },
        {
          5: JSON.parse(localStorage.getItem("sixthPageData2")),
        },
        {
          6: JSON.parse(localStorage.getItem("seventhPageData2")),
        },
        {
          7: sectionData,
        },
      ];

      console.log("Updated data of pro2");
      console.log(section_data);

      const updatedSectionData = await updatePro2(userId, section_data);

      if (updatedSectionData) {
        console.log("------Updated data of pro");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updatePro2.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitData = {
      is_submit: true,
    };
  
    console.log("pro2Id and userid from submit", userData.id);
  
    if (userData.id) {
      try {
        const pro2StatusData = {
          user_id: userData.id,
          // pro2_id: pro2Id,
          pro_id: proId,
          is_submit: true,
          is_accepted: "pending",
        };
        console.log("proid and userid from submit", proId, userData.id);
  
        if (proId && userData.id) {
          const existingPROData = await fetchPRO2Data(userData.id);
          console.log('Existing PRO Data:', existingPROData);
  
          if (existingPROData && existingPROData.is_accepted === false) {
            const updateStatusResponse = await updatePro2(existingPROData._id, { is_accepted: null });
            console.log("Update PRO Status API is called to set is_accepted to null");
  
            if (updateStatusResponse) {
              console.log('Pro1 Status updated successfully');
              console.log('API response:', updateStatusResponse); // Log the API response
            } else {
              console.error('Failed to update Pro1 Status');
            }
          } else {
            const existingPro2Status = await findOnePro2Status(userData.id);
  
            if (!existingPro2Status) {
              console.log('Existing PRO 2 status Data:', existingPro2Status);
              console.log('Before createPro1Status API call');
              const response = await createPro2Status(pro2StatusData);
              console.log('After createPro1Status API call');
              console.log('API response createPro1Status:', response.data);
              const pro2statusid = response.data.data._id; // Retrieve pro1statusid from response
              localStorage.setItem('pro1statusid', pro2statusid); // Store pro1statusid in local storage
              console.log("pro1statusid from local storage in setitem", pro2statusid);
            } else {
              const existingPro1Status = await findOnePro2Status(userData.id);
              if (existingPro1Status) {
                if (existingPro1Status.is_accepted === "false") {
                  console.log('Existing PRO 2 status Data:', existingPro1Status);
                  console.log("update pro 2 status api is been called 1");
                  const pro1statusid = existingPro1Status._id; // Retrieve pro1statusid from existing status
                  console.log("pro1statusid from existing status", pro1statusid);
                  const updateStatusResponse = await updatePro2Status(pro1statusid, { is_accepted: "pending" });
                  console.log("update pro 2 status api is been called 2");
                  if (updateStatusResponse) {
                    console.log('Pro2 Status updated successfully');
                    console.log('API response:', updateStatusResponse); // Log the API response
                  } else {
                    console.error('Failed to update Pro2 Status');
                  }
                } else {
                  console.log('Pro2 Status is already accepted');
                }
              }
            }
          }
        } else {
          console.error('proId or userId not found in local storage');
        }
  
        localStorage.setItem('isSubmit', true);
        showErrorModal("Submitted successfully");
        history.push('/pro/prodashboard');
  
        const sendMailResponse = await sendEmail(userData.email, 'Confirmation of PRO Verification Submission', emailLayout);
  
        console.log('Email sent successfully:', sendMailResponse.data);
      } catch (error) {
        console.error('API error:', error);
      }
    }
  };
  
  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  const emailLayout = `
  <div style="max-width: 100%; margin: 0 auto; font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6;">
    <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; text-align: center;">
      <img src="https://meditravels.s3.amazonaws.com/Logo/952d4079-478e-44ce-92e8-f8e03e84852d-sticky-logo.png" alt="MediTravels Logo" style="max-width: 20%; height: auto;" />
      <p style="text-align: left; margin: 5px 0;">Dear ${userData.name},</p>
    <p style="text-align: left;">Thank you for submitting your verification form for PRO membership with MediTravels. We have received your verification details and will now begin processing it.
    Our team will review your verification details and verify the information provided. We'll notify you as soon as your verification is approved or if we need any additional information.</p>
    <p style="text-align: left; margin: 5px 0 2px;">Best regards,</p>
    <p style="text-align: left; margin: 0px;">MeditravelS Team</p>
  </div>
</div>`;

  return (
    <Page>
      <FirstSection title="Submit Your Application In PRO">
        Thank you for filling out the form! Please click "Submit" to proceed further.
      </FirstSection>
      <SubmitAction backLink="/pro/section7-2" onSubmit={handleSubmit} />
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
};

export default SubmitPage;