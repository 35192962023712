import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const DatePickerValue = ({ value, onChange }) => {
  const maxDate = dayjs().endOf('day');

  const handleDateChange = (date) => {
    const selectedDate = dayjs(date).format('DD/MM/YYYY');
    const today = dayjs().startOf('day');

    if (date.isAfter(today)) {
      alert('You cannot select future dates.');
      return;
    }

    onChange(selectedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DesktopDatePicker']}>
        <DemoItem>
          <DesktopDatePicker
            value={value !== null ? dayjs(value, 'DD/MM/YYYY') : null}
            onChange={handleDateChange}
            format='DD/MM/YYYY'
            disableFuture
            maxDate={maxDate}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}

export default DatePickerValue;