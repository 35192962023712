import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import BlogSkeleton from "../skeleton/BlogSkeleton";
import DateTitleSkeleton from "../skeleton/DateTitleSkeleton";
import { fetchBlogs } from '../../api';

function BlogV2(props) {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
  
      try {
        const responseData = await fetchBlogs(currentPage, selectedCategory);
        setBlogs(responseData.data.list);
        setLoading(false);
        const totalPages = Math.ceil(responseData.totalBlogs / 6);
        setTotalBlogs(responseData.totalBlogs);
        setTotalPages(totalPages);
        window.scrollTo(0, 0);
        localStorage.setItem("blog_id", responseData.blog_id);
        // localStorage.setItem("blogimage", response.data.data.image_url);
        props.setRefresh((current) => current++);
      } catch (error) {
        console.error('Error in BlogListPage:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [currentPage, selectedCategory]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const blogDetailsPage = (blog_id) => {
    history.push(`/blog-details/`);
  };

  const handleCLick = (blog_id, image) => {
    localStorage.setItem("blog_id", blog_id);
    localStorage.setItem("image", image);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
    fetchBlogs(1, category);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page on route change
    });
    return () => unlisten();
  }, [history]);

  const getCategoryLinkClass = (category) => {
    return selectedCategory === category ? "selected" : "";
  };

  const truncateText = (text, limit) => {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  };

  return (
    <>
      {loading ? (
        <BlogSkeleton />
      ) : (
        <div className="blog-list-area pd-top-90 viaje-go-top">
          <div className="container">
            <div className="widget widget_categorie">
              <h2 className="widget-title">Categories</h2>
              <ul>
                <li>
                  <Link
                    className={getCategoryLinkClass("all")}
                    onClick={() => handleCategoryChange("all")}
                  >
                    All
                  </Link>
                </li>
                <li>
                  <Link
                    className={getCategoryLinkClass("Medical")}
                    onClick={() => handleCategoryChange("Medical")}
                  >
                    Medical
                  </Link>
                </li>
                <li>
                  <Link
                    className={getCategoryLinkClass("Travel")}
                    onClick={() => handleCategoryChange("Travel")}
                  >
                    Travel
                  </Link>
                </li>
              </ul>
            </div>
            <div className="row justify-content-center">
              {blogs.map((blog, index) => (
                <div className="col-lg-4 col-md-6" key={blog._id}>
                  <div className="single-blog">
                    <div className="thumb">
                      <img src={blog.blogimage} alt="blog" />
                    </div>
                    <div className="single-blog-details">
                      <p className="date">
                        {new Date(blog.createdAt).toLocaleDateString()}
                      </p>
                      <h4
                        className="title"
                        style={{ height: "50px", fontSize: "19px" }}
                      >
                        <Link to={{
                          pathname: `/blog-details/${blog._id}`,
                          state: { blog_id: blog._id, image: blog.image },
                        }}
                          onClick={() => handleCLick(blog._id, blog.image)}>
                          {truncateText(blog.blogname, 6)}
                        </Link>
                      </h4>
                      <p className="content">
                        {blog.blogdetails.substring(0, 100)}
                        {blog.blogdetails.length > 100 ? "..." : ""}
                      </p>
                      <Link
                        className="btn-read-more"
                        to={{
                          pathname: `/blog-details/${blog._id}`,
                          state: { blog_id: blog._id, image: blog.image },
                        }}
                        onClick={() => handleCLick(blog._id, blog.image)}
                      >
                        <span>
                          Read More
                          <i className="la la-arrow-right" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="col-lg-12 text-md-center text-left">
              <div className="tp-pagination text-md-center text-left d-inline-block mt-4">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={6}
                  totalItemsCount={totalBlogs}
                  pageRangeDisplayed={3}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BlogV2;