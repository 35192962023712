import React, { useState, useEffect } from "react";
import Page from "../pro-components/Page";
import CustomTextField from "../pro-components/CTextField";
import SectionComponent from "../pro-components/Section";
import FirstSection from "../pro-components/FirstSection";
import Action from "../pro-components/Action";
import CheckboxComponent from '../pro-components/CCheckBox';
import { useHistory } from "react-router-dom";
import Dropdown from "../pro-components/CDropDown";
import FileUpload from "../pro-components/CFileUpload";
import CustomButton from "../pro-components/CButton";
import CustomModal from "../../global-components/modal";
import { updateDoctor2, uploadMedia } from '../../../api';

const AdditionalInformation = () => {
  const [joinMotive, setJoinMotive] = useState("");
  const [joinMotiveError, setJoinMotiveError] = useState("");
  const [showJoinMotiveError, setShowJoinMotiveError] = useState(false);
  const [socialLink, setSocialLink] = useState("");
  const [socialLinkError, setSocialLinkError] = useState("");
  const [showSocialLinkError, setShowSocialLinkError] = useState(false);
  const [sectionData, setSectionData] = useState({});
  const [relaventcertiImg, setRelaventCertiImg] = useState(null);
  const [bachelordegreePreviewUrl, setRelaventCertiPreviewUrl] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [socialMediaEntries, setSocialMediaEntries] = useState([]);
  const [showSocialmediaError, setShowSocialmediaError] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [userplatformInput, setUserPlatformInput] = useState("");
  const [socialmediaError, setSocialmediaError] = useState(false);
  const [socialmediaplatformError, setSocialmediaPlatformError] = useState(false);
  const [showSocialmediaplatformError, setShowSocialmediaplatformError] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const pdfOpenParams = 'toolbar=0&navpanes=0&scrollbar=0';
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const [certificationChecked, setCertificationChecked] = useState(false);
  const [customPlatform, setCustomPlatform] = useState("");
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const history = useHistory();
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const previousPageURL = "/doc/section2-2";
  const nextPageURL = "/terms-and-conditions";

  const updateSectionData = async (sectionData) => {
    console.log("user id from local storage", userData.id);
    const userId = userData.id;
    if (!userId) {
      console.log("User ID not found in local storage");
      return;
    }

    try {
      const existingSectionData = JSON.parse(localStorage.getItem("docfirstPageData2"));

      const section_data = [
        {
          0: existingSectionData, // Include the first page data at index 0
        },
        {
          1: JSON.parse(localStorage.getItem("docsecondPageData2")), // Include the second page data at index 1
        },
        {
          2: sectionData, // Include the second page data at index 1
        },
      ];

      console.log("Updated data of doc2");
      console.log(section_data);

      const updatedSectionData = await updateDoctor2(userId, section_data);

      if (updatedSectionData) {
        console.log("Updated data of doc");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updateDoctor2.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validateJoinMotive = (value) => {
    let error = "";

    if (/\s{2,}/.test(value)) {
      error = "Multiple consecutive spaces are not allowed";
    }

    setJoinMotiveError(error);

    return !error; // Return true if there is no error
  };

  const handleJoinMotiveChange = (event) => {
    const joinMotive = event.target.value;
    setJoinMotive(joinMotive);
    // setJoinMotiveError(validateJoinMotive(joinMotive) ? '' : 'Motivation to join our company is required');
    setSectionData((prevData) => ({
      ...prevData,
      join_motive: joinMotive,
    }));
  };

  // const handleJoinMotiveBlur = () => {
  //   setShowJoinMotiveError(true);
  // };

  //   const validateSocialMedia = (value) => {
  //     let error = '';
  //     if (!value) {
  //         error = 'Social Media ID is required';
  //     } else if (/\s/.test(value)) {
  //         error = 'Spaces are not allowed';
  //     } else if (!/[a-zA-Z].{1,}/.test(value)) {
  //         error = 'At least one letter is required';
  //     }
  //     setSocialmediaError(error);
  // };

  const validateSocialMedia = (entries) => {
    let error = "";

    if (entries.length === 0) {
      error = "Social media link is required";
    } else {
      for (const entry of entries) {
        if (!entry.userId || !entry.platform) {
          error = "Please fill all social media entries";
          break;
        }
      }
    }

    setSocialmediaError(error);

    return !error; // Return true if there is no error
  };

  const validateSocialMediaPlatform = (value) => {
    let error = "";
    if (!value) {
      error = "Social Media ID is required";
    } else if (/\s/.test(value)) {
      error = "Spaces are not allowed";
    } else if (!/[a-zA-Z].{1,}/.test(value)) {
      error = "At least one letter is required";
    }
    setSocialmediaPlatformError(error);
  };

  const handlePlatformSelection = (platform) => {
    setSelectedPlatform(platform);
    setUserInput("");
    setUserPlatformInput("");
    setShowSocialmediaError(false);
    setShowSocialmediaplatformError(false);
    setSocialmediaError("");
    if (platform.value === "Other") {
      setCustomPlatform("");
    }

    // Store the selected platform and its custom platform name in the section data
    setSectionData((prevData) => ({
      ...prevData,
      selectedPlatform: platform.value,
      customPlatform: platform.value === "Other" ? customPlatform : "",
    }));
  };

  const handleAddSocialMedia = () => {
    const trimmedInput = userInput.trim();
    const trimmedInput2 = userplatformInput.trim();

    if (!trimmedInput) {
      setShowSocialmediaError(true);
      setSocialmediaError("Please enter a value.");
      return;
    }

    let error = "";
    let platformError = "";

    if (selectedPlatform && !socialmediaError && !socialmediaplatformError) {
      const newEntry = {
        platform: trimmedInput2 || selectedPlatform,
        userId:
          trimmedInput.length > 60
            ? trimmedInput.slice(0, 60) + "..."
            : trimmedInput,
      };

      // Update the social media entries state
      const updatedSocialMediaEntries = [...socialMediaEntries, newEntry];
      setSocialMediaEntries(updatedSocialMediaEntries);

      // Update the section data with the new social media entries
      setSectionData((prevData) => ({
        ...prevData,
        list_platform: updatedSocialMediaEntries,
      }));

      setSelectedPlatform("");
      setUserInput("");
      setUserPlatformInput("");
      setShowSocialmediaError(false);
      setShowSocialmediaplatformError(false);
      setSocialmediaError("");
      setSocialmediaPlatformError("");


    } else {
      if (!trimmedInput2) {
        platformError = "Platform is required";
      } else if (/\s/.test(trimmedInput2)) {
        platformError = "Spaces are not allowed";
      } else if (!/[a-zA-Z]{2,}/.test(trimmedInput2)) {
        platformError = "At least two letters are required";
      }
      error = validateSocialMedia(trimmedInput);
      setShowSocialmediaError(true);
      setShowSocialmediaplatformError(true);
    }

    setSocialmediaError(error);
    setSocialmediaPlatformError(platformError);
  };

  const handleRemoveSocialMedia = (index) => {
    const updatedEntries = [...socialMediaEntries];
    updatedEntries.splice(index, 1);
    setSocialMediaEntries(updatedEntries);
  };

  const handleUserInputChange = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, ""); // Remove spaces from the input value
    setUserInput(sanitizedValue);

    if (showSocialmediaError && sanitizedValue) {
      setShowSocialmediaError(false);
      setSocialmediaError("");
    }

    // Update the section data with the user input
    setSectionData((prevData) => ({
      ...prevData,
      userInput: sanitizedValue,
    }));

    // Store the updated section data in local storage
    localStorage.setItem("secondPageData", JSON.stringify(sectionData));
  };

  const handleCertificationCheckboxChange = () => {
    // Toggle the checked state
    setCertificationChecked((prevChecked) => !prevChecked);

    // Clear the certification image preview and URL when the checkbox is toggled off
    if (!certificationChecked) {
      setRelaventCertiImg("");
      setImagePreviewUrl("");
      setPdfPreviewUrl("");
    }
  };

  const handleUserInputChange2 = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, ""); // Remove spaces from the input value
    setUserPlatformInput(sanitizedValue);

    if (showSocialmediaError && sanitizedValue) {
      setShowSocialmediaplatformError(false);
      setSocialmediaPlatformError("");
    }

    // Update the section data with the custom platform name
    if (selectedPlatform === "Other") {
      setSectionData((prevData) => ({
        ...prevData,
        customPlatform: sanitizedValue,
      }));
    }
  };

  // const validateSocialLink = (value) => {
  //   let error = '';

  //   if (!value) {
  //     error = 'Social media link is required';
  //   } else if (/\s{2,}/.test(value)) {
  //     error = 'Multiple consecutive spaces are not allowed';
  // }

  //   setSocialLinkError(error);

  //   return !error; // Return true if there is no error
  // };

  // const handleSocialLinksChange = (event) => {
  //   const socialLink = event.target.value;
  //   setSocialLink(socialLink);
  //   setSocialLinkError(validateSocialLink(socialLink)? '' : 'Social media link is required');
  //   setSectionData((prevData) => ({
  //     ...prevData,
  //     social_link: socialLink,
  //   }));
  // };

  // const handleSocialLinksBlur = () => {
  //   setShowSocialLinkError(true);
  // };

  const handleFileUpload = async (file) => {
    setRelaventCertiPreviewUrl(URL.createObjectURL(file));

    try {
      const mediaType = "DOC2";

      const uploadedImagePath = await uploadMedia(file, mediaType);

      console.log("image url from doc2", uploadedImagePath);
      if (file.type.startsWith('image/')) {
        setImagePreviewUrl(URL.createObjectURL(file));
        setPdfPreviewUrl(null);
        setRelaventCertiImg(uploadedImagePath);
      }
      else if (file.type === 'application/pdf') {
        setPdfPreviewUrl(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
        setImagePreviewUrl(null);
        setRelaventCertiImg(uploadedImagePath);
      }
      // Save the image URL in the database
      await saveRelaventCertiPreviewUrlToDatabase(uploadedImagePath); // Make sure to implement this function
    } catch (error) {
      console.error(error);
    }
  };

  const saveRelaventCertiPreviewUrlToDatabase = async (imageUrl) => {
    const userId = userData.id;
    const sectionData = { relaventcertiImg: imageUrl };
  
    try {
      const response = await updateDoctor2(userId, sectionData);  

      console.log("Image URL saved in the database:", response.data);
    } catch (error) {
      console.error(error);
    }
    setSectionData((prevData) => ({
      ...prevData,
      bachelordegreeImg: imageUrl,
    }));
  };

  const handleNextClick = async (event) => {
    event.preventDefault();

    let hasError = false;

    // if (!validateJoinMotive(joinMotive)) {
    //     alert('Please fill the Motivation to join our company');
    //     setShowJoinMotiveError(true);
    //     hasError = true;
    //     return
    //   }

    // if (!validateSocialMedia(socialMediaEntries)) {
    //   alert('Please fill the Social media link');
    //   setSocialmediaError(true);
    //   hasError = true;
    //   return;
    // }

    // if (hasError) {
    //   return;
    // }

    // Store all the data in local storage
    const docthirdPageData2 = {
      join_motive: joinMotive,
      social_link: socialMediaEntries,
      relaventcertiImg: relaventcertiImg,
    };

    localStorage.setItem(
      "docthirdPageData2",
      JSON.stringify(docthirdPageData2)
    );

    // Update the section data in the backend
    await updateSectionData(docthirdPageData2);

    history.push("/doc/section4-2");
  };

  useEffect(() => {
    const storedSectionData = JSON.parse(
      localStorage.getItem("docthirdPageData2")
    );

    if (storedSectionData) {

      setJoinMotive(storedSectionData.join_motive || "");
      setSocialMediaEntries(storedSectionData.social_link || "");
      //setSocialMediaEntries(parsedSectionData.list_platform);
      setRelaventCertiImg(storedSectionData.relaventcertiImg);
      if (storedSectionData.relaventcertiImg && storedSectionData.relaventcertiImg.endsWith('.pdf')) {
        setPdfPreviewUrl(`https://docs.google.com/viewer?url=${encodeURIComponent(storedSectionData.relaventcertiImg)}&embedded=true`);
      } else {
        setImagePreviewUrl(storedSectionData.relaventcertiImg);
      }
    }
  }, []);

  const handleClearForm = () => {
    setJoinMotive("");
    setSocialMediaEntries("");
    setRelaventCertiImg("");
    setPdfPreviewUrl("");
    setImagePreviewUrl("")

    setJoinMotiveError("");
    setShowJoinMotiveError(false);
    setShowSocialLinkError(false);
    setRelaventCertiPreviewUrl("");

  };

  const platforms = [
    { value: "Twitter", label: "Twitter" },
    { value: "Telegram", label: "Telegram" },
    { value: "Linkdin", label: "Linkdin" },
    { value: "Facebook", label: "Facebook" },
    { value: "Instagram", label: "Instagram" },
    { value: "Snapchat", label: "Snapchat" },
    { value: "Discord", label: "Discord" },
    { value: "Other", label: "Other" },
  ];

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <Page>
      <FirstSection>
        <h4>Additional Information</h4>
      </FirstSection>
      <SectionComponent>
        <label className="sec-label">
          A SHORT INTRODUCTION OR PERSONAL STATEMENT DESCRIBING THEIR MOTIVATION
          TO JOIN YOUR COMPANY AS A CONSULTANT
        </label>
        <CustomTextField
          id="outlined-basic"
          placeholder="What motivates you to join our company as a Doctor?"
          variant="outlined"
          value={joinMotive}
          onChange={handleJoinMotiveChange}
        // onBlur={handleJoinMotiveBlur}
        />
        {/* {joinMotiveError && showJoinMotiveError && <p className="error-msg">{joinMotiveError}</p>} */}
      </SectionComponent>
      <SectionComponent>
        <label className="sec-label">
          ANY RELEVANT CERTIFICATIONS OR LICENSES (IF APPLICABLE)
        </label>
        <div>
          <CheckboxComponent
            type="checkbox"
            id="certificationCheckbox"
            checked={certificationChecked}
            onChange={handleCertificationCheckboxChange}
          />
          <label className="sec-label" htmlFor="certificationCheckbox">Yes</label>
        </div>
        {certificationChecked && (
          <FileUpload onChange={handleFileUpload} value={relaventcertiImg} />
        )}
        {/* <FileUpload onChange={handleFileUpload} value={relaventcertiImg} /> */}
        {imagePreviewUrl && (
          <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
            <CustomButton type="button" onClick={() => setImagePreviewUrl(null)} // Reset the image preview URL
            >X</CustomButton>
          </div>
            <div style={{ border: '1px solid black' }}>
              <img src={imagePreviewUrl} alt="Selected" style={{ width: '100%' }} />
            </div>
          </>
        )}
        {pdfPreviewUrl && (
          <>
            <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
              <CustomButton type="button" onClick={() => setPdfPreviewUrl(null)} // Reset the PDF preview URL
              >X</CustomButton>
            </div>
            <div style={{ border: '1px solid black' }}>
              <iframe
                src={pdfPreviewUrl}
                title="PDF Preview"
                style={{ width: '100%', height: '500px' }}
              />
            </div>
          </>
        )}
      </SectionComponent>
      <SectionComponent>
        <label className="sec-label">LINK TO SOCIAL MEDIA PROFILES OR WEBSITE</label>
        <br />
        <Dropdown
          className="dropdown-compo"
          label={selectedPlatform ? "" : "Select Platform"}
          value={selectedPlatform}
          options={platforms}
          onChange={handlePlatformSelection}
        />
        <br></br>
        {selectedPlatform === "Other" && (
          <div
            className='social-list social-link'
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              paddingTop: "15px",
            }}
          >
            <div style={{ width: "100%" }}>
              <>
                <div className='social-list social-link'>
                  <CustomTextField
                    id="custom-platform"
                    label="Social Media Platform"
                    variant="outlined"
                    value={userplatformInput}
                    onChange={handleUserInputChange2}
                    error={!!socialmediaplatformError}
                    helperText={
                      showSocialmediaplatformError && socialmediaplatformError
                    }
                  />
                </div>
              </>
            </div>
          </div>
        )}
        {selectedPlatform && (
          <>
            <div
              className='social-list social-link'
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                paddingTop: "15px",
              }}
            >
              <div style={{ width: "100%" }}>
                <CustomTextField
                  id={`social-media-${selectedPlatform}`}
                  label={`Social Media Link (${selectedPlatform})`}
                  variant="outlined"
                  value={userInput}
                  onChange={handleUserInputChange}
                  error={!!socialmediaError}
                  helperText={showSocialmediaError && socialmediaError}
                />
              </div>
              <div className='social-list-add-btn' style={{ paddingTop: "25px" }}>
                <CustomButton type="button" onClick={handleAddSocialMedia}>Add</CustomButton>
              </div>
            </div>
          </>
        )}

        <div className='social-list-heading' style={{ paddingTop: "20px" }}>
          <h7>SOCIAL MEDIA ENTRIES</h7>
          <ul style={{ marginTop: '25px', marginBottom: "0px" }}>

            {socialMediaEntries.map((entry, index) => (
              <li key={index} style={{ paddingBottom: "15px", display: "flex" }}>
                <div
                  style={{ paddingRight: "10px", borderRadius: "50%" }}
                  className="close-btn"
                >
                  <CustomButton
                    type="button"
                    onClick={() => handleRemoveSocialMedia(index)}
                  >
                    X
                  </CustomButton>
                </div>
                <div className='social-name'>
                  <strong style={{ paddingRight: "10px" }}>
                    {entry.platform}:{" "}
                  </strong>
                  {entry.userId}
                </div>
              </li>
            ))}
          </ul>
        </div>

      </SectionComponent>
      <Action backLink={previousPageURL} onClickNext={handleNextClick} onClearForm={handleClearForm} />
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />

    </Page>
  );
};

export default AdditionalInformation;
