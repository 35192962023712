import React, { useEffect, useState } from 'react';
import Page from '../pro-components/Page';
import SectionComponent from '../pro-components/Section';
import { auth } from '../../login-signup/screens/firebase.config';
import { updateUser, findOnePatientStatus } from '../../../api';

const PatientDashboard = () => {
  const [dashboardMessage, setDashboardMessage] = useState('Welcome to Patient Dashboard');
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const [customer_id, setcustomer_id] = useState('');
  // const [isAccepted, setIsAccepted] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(null);
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);

  useEffect(() => {
    const fetchEmailVerifiedStatus = () => {
      if (auth.currentUser) {
        auth.currentUser.reload().then(() => {
          const isEmailVerified = auth.currentUser.emailVerified;
          setEmailVerified(isEmailVerified);
          console.log("printing email verified");
          console.log(isEmailVerified);

          if (isEmailVerified) {
            // If email is verified, stop the interval
            clearInterval(intervalId);

            // Call the update user API
            updateUserData();
          }
        });
      }
    };

    const intervalId = setInterval(fetchEmailVerifiedStatus, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const updateUserData = async () => {
    try {
      const userId = userData.id;
      const updateResponse = await updateUser(userId, { is_emailVerified: true });

      if (updateResponse) {
        console.log("User updated with response:", updateResponse);
      } else {
        console.error("User update failed");
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await findOnePatientStatus(userData.id);
        console.log('API response from FindOnePatientStatus:', responseData);
        console.log('is_accepted from FindOnePatientStatus:', responseData.subsub_treatment_id);

        // setIsAccepted(responseData.is_accepted); // Set the isAccepted state
        if (responseData.subsub_treatment_id) {
          setDashboardMessage(`Thank you for choosing your treatment! Kindly complete the consultation form to continue.`);
        } else if (responseData.is_accepted === 'pending') {
          setDashboardMessage('Thank you for submitting your basic details. Your application is under processing');
        } else if (responseData.is_accepted === 'true' && responseData.treatmentselect_status !== 'selected') {
          setDashboardMessage('We are pleased to inform you that your primary application has been successfully processed.');
          return; // Exit the function to prevent executing the code for FindOnePro2Status_api_url
        } else if (responseData.is_accepted === 'false') {
          setDashboardMessage(`We regret to inform you that your application form has been declined.`);
        } else if (responseData.is_accepted === 'true' && responseData.treatmentselect_status === 'selected' && responseData.payment_status !== "pending" && responseData.payment_status !== "success") {
          setDashboardMessage('Thank you for choosing your treatment! Please complete the consultation form to proceed further.');
        } else if (responseData.is_accepted === 'true' && responseData.treatmentselect_status === 'selected' && responseData.payment_status === 'pending') {
          setDashboardMessage('Please first fill out the consultation form and complete payment for further process.');
        } else if (responseData.is_accepted === 'true' && responseData.treatmentselect_status === 'selected' && responseData.payment_status === 'success' && responseData.consultation_status !== "date_selected" && responseData.consultation_status !== "completed") {
          setDashboardMessage('Thank you for your submission! Meditravels will be in touch shortly to schedule your consultation.');
        } else if (responseData.is_accepted === 'true' && responseData.treatmentselect_status === 'selected' && responseData.payment_status === 'success' && responseData.consultation_status === "date_selected") {
          setDashboardMessage('Your consultation has been scheduled by Meditravels. Please check the consultation page for details.');
        } else if (responseData.is_accepted === 'true' && responseData.treatmentselect_status === 'selected' && responseData.payment_status === 'success' && responseData.consultation_status === "completed") {
          setDashboardMessage("Thank you for your consultation with Meditravels. If you have any follow-up questions or require additional assistance, please don't hesitate to contact us. Your health and well-being are important to us.");
        }
        setcustomer_id(responseData.customer_id);
        localStorage.setItem('customer_id', responseData.customer_id);
        console.log("^^^printing customer_if from FindOnePatientStatus", responseData.customer_id)

      } catch (error) {
        console.error('API Error:', error);
        // Handle error scenario
      }
    };

    fetchData();
  }, []);

  return (
    <Page>
      <SectionComponent>
        <h5 className='dashboard-msg' style={{ color: '#000000' }}>{dashboardMessage}</h5>
      </SectionComponent>
    </Page>
  );
};

export default PatientDashboard;