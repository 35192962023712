import { useState, useEffect } from "react";
import React from "react";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import TreatmentBlogSkeleton from "../skeleton/treatmentBlogSkeleton";
import { fetchEnableSubTreatmentsData } from '../../api';

function Treatment_Blog(props) {
  const [loading, setLoading] = useState(true);
  const [subTreatments, setSubTreatments] = useState([]);

  const location = useLocation();
  let { treatment_url } = useParams();
  const history = useHistory();
  const treatment_id = localStorage.getItem("treatment_id");

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  const Img = styled.img`
    border-radius: 50%;
    transform: translate(42px, 18px);
  `;

  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => unlisten();
  }, [history]);

  useEffect(() => {
    setLoading(true);
    fetchEnableSubTreatmentsData(treatment_id)
      .then((data) => {
        setSubTreatments(data);
        localStorage.setItem("subtreatment_id", data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error in API call:', error);
        setLoading(false);
      });
  }, [treatment_id]);

  // Modify this part to filter subTreatments based on 'enabled' property
  const enabledSubTreatments = subTreatments.filter(
    (subTreatment) => subTreatment.enabled === true
  );

  const handleCLick = (subtreatment_id, header_image) => {
    localStorage.setItem("subtreatment_id", subtreatment_id);
    localStorage.setItem("subtreatmentimage", header_image);
  };

  return (
    <>
      {loading ? (
        <TreatmentBlogSkeleton />
      ) : (
        <>
          <div className="row treatment-list">
            {subTreatments.map((subTreatment) => (
              <div className="col-lg-6" key={subTreatment._id}>
                <div
                  className="outerdiv"
                  style={{
                    borderRadius: "25px",
                    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
                    background: "#fff",
                  }}
                >
                  <div className="mb-lg-5 mt-5" style={{ margin: "auto" }}>
                    <div
                      className="single-blog single-blog-wrap style-two"
                      style={{
                        borderRadius: "",
                        padding: "",
                        boxShadow: "0px 0px 0px #071c5500",
                        WebkitBoxShadow: "0px 0px 0px #071c5500",
                      }}
                    >
                      <div className="thumb2 single-blog-left-wrap">
                        <img
                          src={subTreatment.image}
                          alt="blog"
                          style={styles.imagein}
                          className="subtreatment-img"
                        />
                      </div>
                      <div
                        className="single-blog-details single-blog-right-wrap"
                        style={{ padding: "30px 30px" }}
                      >
                        {/* <Link className="tag" to="/blog-details">Europe</Link>
                              <p className="date">19 September 2019</p> */}
                        <h3
                          className="title"
                          style={{ fontSize: "40px", paddingBottom: "40px" }}
                        >
                          {subTreatment.name}
                        </h3>
                        {/* <p className="content">{subTreatment.description}</p> */}

                        <Link
                          className="btn btn-yellow"
                          to={{
                            pathname: `/subgroup1`,
                            state: {
                              subtreatment_id: subTreatment._id,
                              image: subTreatment.header_image,
                            },
                          }}
                          onClick={() =>
                            handleCLick(
                              subTreatment._id,
                              subTreatment.header_image
                            )
                          }
                        >
                          {/* <Link className="btn btn-yellow" to={`/${subTreatment.url}`}> */}
                          <span>
                            Read More
                            <i className="la la-arrow-right" />
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

let styles = {
  imagein: {
    borderRadius: "20px",
    boxShadow: "4px 13px 30px 1px rgba(163, 94, 78, 0.2)",
  },
};

export default Treatment_Blog;