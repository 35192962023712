import React from 'react';
import {Button} from '@material-ui/core';

const CustomButton = ({ variant, color, onClick, children }) => {
    return (
      <Button variant={variant} color={color} onClick={onClick} style={{backgroundColor: '#291211', color: '#fff'}}>
        {children}
      </Button>
    );
  };
  
  export default CustomButton;
  
//   <CustomButton variant="contained" color="primary" onClick={handleClick}>
//   Click me
// </CustomButton>
