import React, { useState, useEffect, useRef } from 'react';
import Page from '../pro-components/Page';
import CustomTextField from '../pro-components/CTextField';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import RadioButtonGroup from '../pro-components/CRadioButton';
import Dropdown from '../pro-components/CDropDown';
import Action from '../pro-components/Action';
import CustomButton from '../pro-components/CButton';
import countries from '../../../countries.json';
import { useHistory } from "react-router-dom";
import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { set } from 'date-fns';
import Ticket from '../pro-components/ticket'
import CustomModal from "../../global-components/modal";
import { updatePro1 } from '../../../api';

const ContactInformation = () => {
    const userDataJSON = localStorage.getItem("user");
    const userData = userDataJSON ? JSON.parse(userDataJSON) : {};
    let history = useHistory();
    let loginEmail = userData.email;
    const [email, setEmail] = useState(loginEmail || '');
    const [emailError, setEmailError] = useState(false);
    const [showEmailError, setshowEmailError] = useState(false);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [showPhoneError, setshowPhoneError] = useState(false);
    const [is_whatsapp, setIs_whatsapp] = useState('');
    const [is_whatsappError, setIs_whatsappError] = useState(false);
    const [showis_whatsappError, setshowIs_whatsappError] = useState(false);
    const [otherphone, setOtherphone] = useState('')
    const [selectedPlatform, setSelectedPlatform] = useState('');
    const [socialMediaEntries, setSocialMediaEntries] = useState([]);
    const [showSocialmediaError, setShowSocialmediaError] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [userplatformInput, setUserPlatformInput] = useState('');
    const [socialmediaError, setSocialmediaError] = useState(false);
    const [socialmediaplatformError, setSocialmediaPlatformError] = useState(false);
    const [showSocialmediaplatformError, setShowSocialmediaplatformError] = useState(false);
    // const [showsocialmediaError, setShowSocialmediaError] = useState(false);
    const [country, setCountry] = useState('');
    const [countryError, setCountryError] = useState(false);
    const [showcountryError, setshowCountryError] = useState(false);
    const [state, setState] = useState('');
    const [stateError, setStateError] = useState('');
    const [showstateError, setshowStateError] = useState(false);
    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState(false);
    const [showcityError, setshowCityError] = useState(false);
    const [zipcode, setZipcode] = useState('');
    const [zipcodeError, setZipcodeError] = useState(false);
    const [showzipcodeError, setshowZipcodeError] = useState(false);
    const [street, setStreet] = useState('');
    const [streetError, setStreetError] = useState(false);
    const [showstreetError, setshowStreetError] = useState(false);
    const [landmark, setLandmark] = useState('');
    const [landmarkError, setLandmarkError] = useState(false);
    const [showlandmarkError, setshowLandmarkError] = useState(false);
    const [sectionData, setSectionData] = useState("");
    const [customPlatform, setCustomPlatform] = useState('');
    const minscript = document.createElement("script");
    const is_whatsappErrorRef = useRef(null);
    minscript.async = true;
    document.body.appendChild(minscript);
    const defaultCountry = 'IN';
    console.log("prinitng login email", loginEmail)
    console.log("prinitng user data from  pro 1", userData)
    const previousPageURL = '/pro/section1';
    const [errorModalData, setErrorModalData] = useState({
        title: "",
        message: "",
        show: false,
    });

    const showErrorModal = (title, message) => {
        setErrorModalData({
            title: title,
            message: message,
            show: true,
        });
    };

    useEffect(() => {
        const storedSectionData = localStorage.getItem('secondPageData');
        if (storedSectionData) {
            const parsedSectionData = JSON.parse(storedSectionData);
            setPhone(parsedSectionData.phone || '');
            setIs_whatsapp(parsedSectionData.same_wp || '');
            setOtherphone(parsedSectionData.otherphone || '');
            setSocialMediaEntries(parsedSectionData.list_platform || []);
            setCountry(parsedSectionData.country || '');
            setState(parsedSectionData.state || '');
            setCity(parsedSectionData.city || '');
            setZipcode(parsedSectionData.zipcode || '');
            setStreet(parsedSectionData.street || '');
            setLandmark(parsedSectionData.landmark || '');
        }
        const parsedSectionData = JSON.parse(storedSectionData);
        // Set the email state to the stored email or the loginEmail value
        const storedEmail = storedSectionData ? parsedSectionData.email : loginEmail;
        setEmail(storedEmail);
    }, [loginEmail]);

    const updateSectionData = async (sectionData) => {
        const proId = localStorage.getItem("proId");
        console.log("pro id from local storage", proId);
        if (!proId) {
            console.log("Pro ID not found in local storage");
            return;
        }

        try {
            const existingSectionData = JSON.parse(localStorage.getItem("firstPageData"));

            const section_data = [
                {
                    0: existingSectionData, // Include the first page data at index 0
                },
                {
                    1: sectionData, // Include the second page data at index 1
                },
            ]

            console.log("updated data of pro");
            console.log(section_data);
            const updatedSectionData = await updatePro1(proId, section_data);
            if (updatedSectionData) {
                console.log("updated data of pro");
                console.log(updatedSectionData);
            } else {
                console.error("No data returned from updateDoctor1.");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleNextClick = async (event) => {
        event.preventDefault();

        // Reset all error states before validation
        setEmailError(false);
        setPhoneError(false);
        setIs_whatsappError(false);
        setCountryError(false);
        setStateError(false);
        setCityError(false);
        setZipcodeError(false);
        setStreetError(false);
        setLandmarkError(false);

        if (!email) {
            setEmailError(true);
            setshowEmailError(true);
            showErrorModal("Alert", "Email is required.");
            return;
        }

        if (!phone) {
            setPhoneError(true);
            setshowPhoneError(true);
            showErrorModal("Alert", "Phone number is required.");
            return;
        }

        if (!is_whatsapp) {
            setIs_whatsappError(true);
            setshowIs_whatsappError(true);
            showErrorModal("Alert", "You are using same Whatsapp or not is required.");
            return;
        }
        // if (!street) {
        //     setStreetError(true);
        //     setshowStreetError(true);
        //     showErrorModal("Alert", "Building Name and Street Name is required");
        //     return;
        // }
        if (!city) {
            setCityError(true);
            setshowCityError(true);
            showErrorModal("Alert", "City is required.");
            return;
        }

        if (!zipcode) {
            setZipcodeError(true);
            setshowZipcodeError(true);
            showErrorModal("Alert", "Zipcode is required.");
            return;
        }

        if (!state) {
            setStateError(true);
            setshowStateError(true);
            showErrorModal("Alert", "State is required.");
            return;
        }

        if (!country) {
            setCountryError(true);
            setshowCountryError(true);
            showErrorModal("Alert", "Country is required.");
            return;
        }

        // if (!landmark) {
        //     setLandmarkError(true);
        //     setshowLandmarkError(true);
        //     alert('Landmark is required');
        //     scrollToFirstError();
        //     return;
        // }

        // Retrieve the first page data from local storage
        const firstPageData = JSON.parse(localStorage.getItem('firstPageData'));

        // Store all the data in local storage
        const secondPageData = {
            email: email,
            phone: phone,
            same_wp: is_whatsapp,
            otherphone: otherphone,
            list_platform: socialMediaEntries,
            country: country,
            state: state,
            city: city,
            zipcode: zipcode,
            street: street,
            landmark: landmark,
        };
        localStorage.setItem('secondPageData', JSON.stringify(secondPageData));

        // Update the section data in the backend
        await updateSectionData(secondPageData);

        history.push('/pro/section3');
    };

    const validateEmail = (email) => {
        let isValid = true;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

        // Test the email against the regex pattern
        isValid = emailRegex.test(email);

        // Additional validation for dot after the @ symbol
        if (isValid) {
            const atIndex = email.indexOf('@');
            const dotIndex = email.indexOf('.', atIndex);
            isValid = dotIndex !== -1 && dotIndex > atIndex + 1;
        }

        return isValid;
    };

    const handleEmailBlur = () => {
        setshowEmailError(true);
    };
    
    const validatePhoneNumber = (value) => {
        if (!value) {
            setPhoneError("Phone Number is required");
        } else {
            setPhoneError("");
        }
    };    

    const handlePhoneChange = (value) => {
        setPhone(value);
        validatePhoneNumber(value);

        setSectionData((prevData) => ({
            ...prevData,
            phone: value,
        }));
    };

    const validateSocialMedia = (value) => {
        let error = '';
        if (!value) {
            error = 'Social Media ID is required';
        } else if (/\s/.test(value)) {
            error = 'Spaces are not allowed';
        } else if (!/[a-zA-Z].{1,}/.test(value)) {
            error = 'At least one letter is required';
        }
        setSocialmediaError(error);
    };

    const validateSocialMediaPlatform = (value) => {
        let error = '';
        if (!value) {
            error = 'Social Media ID is required';
        } else if (/\s/.test(value)) {
            error = 'Spaces are not allowed';
        } else if (!/[a-zA-Z].{1,}/.test(value)) {
            error = 'At least one letter is required';
        }
        setSocialmediaPlatformError(error);
    };

    const handleOtherPhoneChange = (value) => {
        setOtherphone(value);
        // setPhoneError();
        console.log("phone number", value, phone)
    };

    const handleEmailChange = (event) => {
        const emailValue = event.target.value;
        setEmail(emailValue);
        const isValidEmail = validateEmail(emailValue);
        setEmailError(!isValidEmail);
        setshowEmailError(true); // Show the error message immediately on change

        setSectionData((prevData) => ({
            ...prevData,
            email: emailValue,
        }));
    };

    const validateisWhatsapp = (options) => {
        let error = '';
        if (!options) {
            error = "Please specify if you are using WhatsApp on the same number.";
        } else if (/\s/.test(options)) {
            error = 'Spaces are not allowed';
        }
        setIs_whatsappError(error);

    };

    const handleIsWhatsapp = (options) => {
        setIs_whatsapp(options);
        setIs_whatsappError(!validateisWhatsapp(options))
        setSectionData((prevData) => ({
            ...prevData,
            same_wp: options,
        }));
    };

    const handleIsWhatsappBlur = () => {
        setshowIs_whatsappError(true);
    };

    const validateCountry = (option) => {
        if (!option) {
            setCountryError("Country is required");
        } else {
            setCountryError("");
        }
    };

    const handleSelectCountry = (option) => {
        setCountry(option);
        setCountryError(!validateCountry(option));
        setSectionData((prevData) => ({
            ...prevData,
            country: option,
        }));
    };

    const handleCountryBlur = () => {
        setshowCountryError(true);
    };

    const handlePlatformSelection = (platform) => {
        setSelectedPlatform(platform);
        setUserInput('');
        setUserPlatformInput('');
        setShowSocialmediaError(false);
        setShowSocialmediaplatformError(false);
        setSocialmediaError('');
        if (platform.value === 'Other') {
            setCustomPlatform('');
        }

        // Store the selected platform and its custom platform name in the section data
        setSectionData((prevData) => ({
            ...prevData,
            selectedPlatform: platform.value,
            customPlatform: platform.value === 'Other' ? customPlatform : '',
        }));
    };

    const handleAddSocialMedia = () => {
        const trimmedInput = userInput.trim();
        const trimmedInput2 = userplatformInput.trim();

        if (!trimmedInput) {
            setShowSocialmediaError(true);
            setSocialmediaError('Please enter a value.');
            return;
        }

        let error = '';
        let platformError = '';

        if (selectedPlatform && !socialmediaError && !socialmediaplatformError) {
            const newEntry = {
                platform: trimmedInput2 || selectedPlatform,
                userId: trimmedInput,
            };

            // Update the social media entries state
            const updatedSocialMediaEntries = [...socialMediaEntries, newEntry];
            setSocialMediaEntries(updatedSocialMediaEntries);

            // Update the section data with the new social media entries
            setSectionData((prevData) => ({
                ...prevData,
                list_platform: updatedSocialMediaEntries,
            }));

            setSelectedPlatform('');
            setUserInput('');
            setUserPlatformInput('');
            setShowSocialmediaError(false);
            setShowSocialmediaplatformError(false);
            setSocialmediaError('');
            setSocialmediaPlatformError('');
        } else {
            if (!trimmedInput2) {
                platformError = 'Platform is required';
            } else if (/\s/.test(trimmedInput2)) {
                platformError = 'Spaces are not allowed';
            } else if (!/[a-zA-Z]{2,}/.test(trimmedInput2)) {
                platformError = 'At least two letters are required';
            }
            error = validateSocialMedia(trimmedInput);
            setShowSocialmediaError(true);
            setShowSocialmediaplatformError(true);
        }

        setSocialmediaError(error);
        setSocialmediaPlatformError(platformError);
    };

    const handleRemoveSocialMedia = (index) => {
        const updatedEntries = [...socialMediaEntries];
        updatedEntries.splice(index, 1);
        setSocialMediaEntries(updatedEntries);
    };

    const handleUserInputChange = (event) => {
        const value = event.target.value;
        const sanitizedValue = value.replace(/\s/g, ''); // Remove spaces from the input value
        setUserInput(sanitizedValue);

        if (showSocialmediaError && sanitizedValue) {
            setShowSocialmediaError(false);
            setSocialmediaError("");
        }

        // Update the section data with the user input
        setSectionData((prevData) => ({
            ...prevData,
            userInput: sanitizedValue,
        }));

        // Store the updated section data in local storage
        localStorage.setItem('secondPageData', JSON.stringify(sectionData));
    };

    const handleUserInputChange2 = (event) => {
        const value = event.target.value;
        const sanitizedValue = value.replace(/\s/g, ''); // Remove spaces from the input value
        setUserPlatformInput(sanitizedValue);

        if (showSocialmediaError && sanitizedValue) {
            setShowSocialmediaplatformError(false);
            setSocialmediaPlatformError("");
        }

        // Update the section data with the custom platform name
        if (selectedPlatform === 'Other') {
            setSectionData((prevData) => ({
                ...prevData,
                customPlatform: sanitizedValue,
            }));
        };
    }
    const validateState = (value) => {
        let error = '';
        if (!value) {
            error = 'State is required';
        } else if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        }
        setStateError(error);

    };
    const handleStateChange = (event) => {
        const stateValue = event.target.value;
        validateState(stateValue);
        setState(stateValue);
        setSectionData((prevData) => ({
            ...prevData,
            state: stateValue,
        }));
    };

    const handleStateBlur = () => {
        setshowStateError(true);
    };
    const validateCity = (value) => {
        let error = '';
        if (!value) {
            error = 'City is required';
        } else if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        }
        setCityError(error);
    };

    const handleCityChange = (event) => {
        const cityValue = event.target.value;
        validateCity(cityValue);
        setCity(cityValue);
        setSectionData((prevData) => ({
            ...prevData,
            city: cityValue,
        }));
    };

    const handleCityBlur = () => {
        setshowCityError(true);
    };

    const validateZipcode = (value) => {
        let error = '';
        if (!value) {
            error = 'Zipcode is required';
        } else if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        }
        setZipcodeError(error);

    };

    const handleZipcodeChange = (event) => {
        const zipcodeValue = event.target.value;
        setZipcode(zipcodeValue);
        validateZipcode(zipcodeValue);
        setSectionData((prevData) => ({
            ...prevData,
            zipcode: zipcodeValue,
        }));
    };

    const handleZipcodeBlur = () => {
        setshowZipcodeError(true);
    };

    const validateStreet = (value) => {
        let error = '';
        if (!value) {
            error = 'Street is required';
        } else if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        }
        setStreetError(error);
    };

    const handleStreetChange = (event) => {
        const streetValue = event.target.value;
        setStreet(streetValue);
        validateStreet(streetValue);
        setSectionData((prevData) => ({
            ...prevData,
            street: streetValue,
        }));
    };

    const handleStreetBlur = () => {
        setshowStreetError(true);
    };

    const validateLandmark = (value) => {
        let error = '';
        if (!value) {
            error = 'Landmark is required';
        } else if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        }
        setLandmarkError(error);
    };

    const handleLandmarkChange = (event) => {
        const landmarkValue = event.target.value;
        setLandmark(landmarkValue);
        validateLandmark(landmarkValue);
        setSectionData((prevData) => ({
            ...prevData,
            landmark: landmarkValue,
        }));
    };

    const handleLandmarkBlur = () => {
        setshowLandmarkError(true);
    };

    const handleClearForm = () => {
        setIs_whatsapp('');
        setSelectedPlatform('');
        setSocialMediaEntries([]);
        setUserInput('');
        setUserPlatformInput('');
        setCountry('');
        setEmail('');
        setPhone('');
        setState('');
        setCity('');
        setZipcode('');
        setStreet('');
        setLandmark('');
        setEmailError('');
        setshowEmailError('');
        setPhoneError('');
        setshowPhoneError('');
        setCountryError('');
        setshowCountryError('');
        setStateError('');
        setshowStateError('');
        setCityError('');
        setshowCityError('');
        setZipcodeError('');
        setshowZipcodeError('');
        setStreetError('');
        setshowStreetError('');
        setLandmarkError('');
        setshowLandmarkError('');
        setIs_whatsappError('')
        setshowIs_whatsappError('')
    };

    const WhatsApp = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
    ];

    const platforms = [
        { value: 'Twitter', label: 'Twitter' },
        { value: 'Telegram', label: 'Telegram' },
        { value: 'Instagram', label: 'Instagram' },
        { value: 'Snapchat', label: 'Snapchat' },
        { value: 'Discord', label: 'Discord' },
        { value: 'Other', label: 'Other' },
    ];

    useEffect(() => {
        console.log("socialMediaEntries during useEffect:", socialMediaEntries);
    }, []);

    console.log("socialMediaEntries before rendering:", socialMediaEntries);

    let socialMediaEntriesContent = null; // Initialize to null

    if (socialMediaEntries.length > 0) {
        socialMediaEntriesContent = (
            <ul style={{ marginTop: '25px', marginBottom: '0px' }}>
                {socialMediaEntries.map((entry, index) => (
                    <li key={index} style={{ paddingBottom: '15px', display: 'flex' }}>
                        <div style={{ paddingRight: '10px', borderRadius: '50%' }} className='close-btn'>
                            <CustomButton type="button" onClick={() => handleRemoveSocialMedia(index)}>
                                X
                            </CustomButton>
                        </div>
                        <div className='social-name'>
                            <strong style={{ paddingRight: '10px' }}>{entry.platform}: </strong>
                            {entry.userId}
                        </div>
                    </li>
                ))}
            </ul>
        );
    }

    const handleCloseErrorModal = () => {
        setErrorModalData({
            title: "",
            message: "",
            show: false,
        });
    };

    return (
        <Page>
            <FirstSection >
                <h4>Contact Information</h4>
            </FirstSection >
            <SectionComponent>
                <label className='sec-label'>EMAIL ADDRESS*</label>
                <CustomTextField id="outlined-basic" placeholder="Email Address" variant="outlined" value={email} onBlur={handleEmailBlur}
                    onChange={handleEmailChange} />
                {emailError && showEmailError && (<p className="error-msg">Please enter a valid email</p>)}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>PHONE NUMBER*</label>
                <PhoneInput
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={handlePhoneChange}
                    country="in"
                    inputProps={{
                        required: true,
                        autoFocus: true,
                    }}
                />
                {phoneError && <p className="error-msg">Please enter a valid phone number</p>}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>ARE YOU USING WHATSAPP ON THE SAME NUMBER?*</label>
                <div className='wp-radio'>
                    <RadioButtonGroup
                        name="radioOptions"
                        value={is_whatsapp}
                        options={WhatsApp}
                        onChange={handleIsWhatsapp}
                        onBlur={handleIsWhatsappBlur}
                    >
                        {is_whatsappError && showis_whatsappError && (<p className="error-msg">Using Whastapp on same number?</p>)}
                    </RadioButtonGroup>
                </div>
                {is_whatsapp === 'no' && (
                    <div className='other-wpnumber'>
                        <label className='sec-label' style={{ marginTop: '20px' }}>ADD YOUR WHATSAPP NUMBER HERE </label>
                        <PhoneInput
                            placeholder="Enter phone number"
                            value={otherphone}
                            onChange={handleOtherPhoneChange}
                            inputProps={{
                                required: true,
                                autoFocus: true,
                            }}
                            country="in"
                        />
                        {/* {phoneError && <p className="error">Please enter a valid phone number</p>} */}
                    </div>
                )}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>IF NOT USING WHATSAPP, THEN PLEASE STATE YOUR PREFERRED COMMUNICATION PLATFORM</label>
                <Dropdown
                    className="dropdown-compo"
                    label={selectedPlatform ? '' : 'Select Platform'}
                    value={selectedPlatform}
                    options={platforms}
                    onChange={handlePlatformSelection}
                />
                <br></br>
                {selectedPlatform === 'Other' && (
                    <div className='social-list' style={{ display: 'flex', flexDirection: 'column', width: '50%', paddingTop: '15px' }}>
                        <div style={{ width: '100%' }}>
                            <>
                                <div className='social-list'>
                                    <CustomTextField
                                        id="custom-platform"
                                        label="Social Media Platform"
                                        variant="outlined"
                                        value={userplatformInput}
                                        onChange={handleUserInputChange2}
                                        error={!!socialmediaplatformError}
                                        helperText={showSocialmediaplatformError && socialmediaplatformError}
                                    />
                                </div>
                            </>
                        </div>
                    </div>
                )}
                {selectedPlatform && (
                    <>
                        <div className='social-list' style={{ display: 'flex', flexDirection: 'column', width: '50%', paddingTop: '15px' }}>
                            <div style={{ width: '100%' }}>
                                <CustomTextField
                                    id={`social-media-${selectedPlatform}`}
                                    label={`Social Media ID (${selectedPlatform})`}
                                    variant="outlined"
                                    value={userInput}
                                    onChange={handleUserInputChange}
                                    error={!!socialmediaError}
                                    helperText={showSocialmediaError && socialmediaError}
                                />
                            </div>

                            <div className='social-list-add-btn' style={{ paddingTop: '25px' }}>
                                <CustomButton type="button" onClick={handleAddSocialMedia}>
                                    Add
                                </CustomButton>
                            </div>
                        </div>
                    </>
                )}
                <div className='social-list-heading' style={{ paddingTop: '15px' }}>
                    <h7>SOCIAL MEDIA ENTRIES</h7>
                    {socialMediaEntriesContent}
                </div>
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>BUILDING NAME, STREET ADDRESS</label>
                <CustomTextField id="outlined-basic" placeholder="Building Name, Street Address" variant="outlined" value={street} onChange={handleStreetChange}
                    onBlur={handleStreetBlur}
                />
                {streetError && showstreetError && (<p className="error-msg">{streetError}</p>)}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>LANDMARK</label>
                <CustomTextField id="outlined-basic" placeholder="Landmark" variant="outlined" value={landmark} onChange={handleLandmarkChange}
                    onBlur={handleLandmarkBlur}
                />
                {landmarkError && showlandmarkError && (<p className="error-msg">{landmarkError}</p>)}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>CITY*</label>
                <CustomTextField id="outlined-basic" placeholder="City" variant="outlined" value={city} onChange={handleCityChange}
                    onBlur={handleCityBlur}
                />
                {cityError && showcityError && (<p className="error-msg">{cityError}</p>)}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>POSTAL/ZIP CODE*</label>
                <CustomTextField id="outlined-basic" placeholder="Postal/Zip Code" variant="outlined" value={zipcode} onChange={handleZipcodeChange}
                    onBlur={handleZipcodeBlur}
                />
                {zipcodeError && showzipcodeError && (<p className="error-msg">{zipcodeError}</p>)}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>STATE/PROVINCE*</label>
                <CustomTextField id="outlined-basic" placeholder="State/Province" variant="outlined" value={state}
                    onChange={handleStateChange}
                    onBlur={handleStateBlur}
                />
                {stateError && showstateError && (<p className="error-msg">{stateError}</p>)}
            </SectionComponent>
            <SectionComponent>
                <label className='sec-label'>RESIDENCE COUNTRY*</label>
                <br />
                <Dropdown
                    className="dropdown-compo"
                    label={country ? '' : 'Select Country'}
                    value={country}
                    options={countries.map((country) => ({
                        value: country.name,
                        label: country.name,
                    }))}
                    onChange={handleSelectCountry}
                // onBlur={handleCountryBlur}

                /> {countryError && showcountryError && (<p className="error-msg">Please select Country</p>)}
            </SectionComponent>
            <Action
                backLink={previousPageURL}
                onClickNext={handleNextClick}
                onClearForm={handleClearForm}
            />
            <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
                <Ticket variant="contained" color="secondary" section="PRO: Application Form - Contact Information" />
            </div>
            <CustomModal
                show={errorModalData.show}
                onHide={handleCloseErrorModal}
                titleStyle={{ fontWeight: "bold" }}
                title={errorModalData.title}
                message={errorModalData.message}
            />
        </Page>
    );
};

export default ContactInformation;