import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { Modal, Button } from 'react-bootstrap';
import { subscribeToNewsletter } from '../../api';

function Subscribe() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setEmailError(!validateEmail(emailValue));
    if (emailValue.trim() === "") {
      setMessage("Please enter an email address");
    } else {
      setMessage("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (email.trim() === "") {
      setMessage("Please enter an email address");
    } else if (!validateEmail(email)) {
      alert("Please enter a valid email address");
    } else {
      try {
        const response = await subscribeToNewsletter(email);

        if (response) {
          setShowModal(true);
          setEmail("");
          setMessage("");
        }
      } catch (error) {
        setMessage(error.message || "An error occurred while subscribing to the newsletter");
      }
    }
  };

  let publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <div className="newslatter-area pd-top-120">
      <div className="container">
        <div className="newslatter-area-wrap border-tp-solid">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-6 col-md-5">
              <div className="section-title mb-md-0">
                <h2 className="title">Newsletter</h2>
                <p>Sign up to receive the best offers</p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-7 align-self-center offset-xl-1">
              <form onSubmit={handleSubmit}>
                <div className="input-group newslatter-wrap">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><i className="fa fa-envelope" /></span>
                  </div>
                  <input
                    name="email"
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                  />

                  <div className="input-group-append">
                    <input type="submit" className="btn btn-yellow vaijesubmit" value="Subscribe" />
                  </div>
                </div>
              </form>
              {/* {message && <div className="text-center mt-3 error-msg">{message}</div>} */}
              {message && !message.includes("error") && <p className="error">{message}</p>}
              {message && message.includes("error") && <p className="text-danger">{message}</p>}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} className='newsletter-popup' backdrop="static">
        <Modal.Header>
          <Modal.Title>Thank You</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You are now subscribed to our newsletter!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-yellow" variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Subscribe;