import React, { useState } from 'react';
import Page from '../pro-components/Page';
import SectionComponent from '../pro-components/Section';
import CustomButton from '../pro-components/CButton';
import { useHistory } from 'react-router-dom';
import { updatePatientStatusAPI, sendEmail, updateConsultation } from '../../../api';

const StripeStatus = () => {
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON ? JSON.parse(userDataJSON) : {};
  const patientStatusId = localStorage.getItem("patientStatusId");
  const history = useHistory();

  const handleClickOk = async () => {
    try {
      const consultationResponse = await updateConsultation(userData.id, { payment_status: "success" });
    console.log("Consultation API Response:", consultationResponse.data);

    const patientStatusResponse = await updatePatientStatusAPI(patientStatusId, { payment_status: "success" });
    console.log("Patient Status API Response:", patientStatusResponse.data);
      console.log("Patient Status API Response:", patientStatusResponse.data);

      history.push('/');

      const sendMailResponse = await sendEmail(userData.email, 'Thank You for Completing the Consultation Form and Successful Payment', emailLayout);

      console.log('Email sent successfully:', sendMailResponse.data);
    } catch (error) {
      console.error("Error updating APIs:", error);
    }
  };

  const emailLayout = `
  <div style="max-width: 100%; margin: 0 auto; font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6;">
    <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; text-align: center;">
      <img src="https://meditravels.s3.amazonaws.com/Logo/952d4079-478e-44ce-92e8-f8e03e84852d-sticky-logo.png" alt="MediTravels Logo" style="max-width: 20%; height: auto;" />
      <p style="text-align: left; margin: 5px 0;">Dear ${userData.name},</p>
      <p style="text-align: left;">We would like to express our gratitude for completing the consultation form and for your successful payment. Your commitment to your health is greatly appreciated.</p>
      <p style="text-align: left;">Our team will now review your form and payment details. We will contact you shortly to schedule your consultation meeting with our healthcare professionals.</p>
      <p style="text-align: left;">If you have any immediate questions or need assistance, please don't hesitate to reach out to our support team. Your well-being is our top priority.</p>
      <p style="text-align: left;">Thank you for choosing MediTravels, and please take care. We look forward to assisting you on your healthcare journey.</p>
      <p style="text-align: left; margin: 5px 0 2px;">Best regards,</p>
      <p style="text-align: left; margin: 0px;">MeditravelS Team</p>
    </div>
  </div>`;

  return (
    <Page style={{ justifyContent: "center" }}>
      <SectionComponent style={{ textAlign: 'center' }}>
        <h3 style={{ paddingBottom: '10px' }}>Thank you, your payment for the consultation has been successfully processed.</h3>
        <CustomButton variant="contained" color="primary" onClick={handleClickOk}>
          OK
        </CustomButton>
      </SectionComponent>
    </Page >
  );
};

export default StripeStatus;