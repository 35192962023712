import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Page from '../pro-components/Page';
import CustomTextField from '../pro-components/CTextField';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import RadioButtonGroup from '../pro-components/CRadioButton';
import DatePickerDialog from '../pro-components/CDatePicker';
import Dropdown from '../pro-components/CDropDown';
import FirstAction from '../pro-components/FirstPageAction';
import countries from '../../../countries.json';
import { useHistory } from "react-router-dom";
import Ticket from '../pro-components/ticket';
import CustomModal from "../../global-components/modal";
import { updatePatient } from '../../../api';

const PersonalInformation = () => {
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON ? JSON.parse(userDataJSON) : {};
  console.log("printing user data on loign", userData.name);
  const fullNameParts = userData.name ? userData.name.split(' ') : [];
  const fname = fullNameParts[0] || '';
  const lname = fullNameParts.slice(1).join(' ') || '';
  const [selectedOption, setSelectedOption] = useState('');
  const [selectCountry, setSelectCountry] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [age, setAge] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [showFirstNameError, setShowFirstNameError] = useState(false);
  const [showLastNameError, setShowLastNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [showDobError, setShowDobError] = useState(false);
  const [sexError, setSexError] = useState(false);
  const [nationalityError, setNationalityError] = useState('');
  const [sectionData, setSectionData] = useState("");
  // const [zipcode, setZipcode] = useState('');
  // const [zipcodeError, setZipcodeError] = useState(false);
  // const [showzipcodeError, setshowZipcodeError] = useState(false);
  let history = useHistory();
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };
  const options = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'non-binary', label: 'Non-Binary' },
    { value: 'prefer not to say', label: 'Prefer Not to Say' },
  ];

  useEffect(() => {
    const storedSectionData = JSON.parse(localStorage.getItem('patientfirstPageData'));

    if (storedSectionData) {

      const storedFirstName = storedSectionData.fname || '';
      const storedLastName = storedSectionData.lname || '';

      setFirstName(storedFirstName || '');
      setLastName(storedLastName || '');
      setSelectedDate(storedSectionData.dob || null);
      setAge(storedSectionData.age || '');
      setSelectedOption(storedSectionData.gender || '');
      // setSelectCountry(storedSectionData.nationality || ''); 
      // setZipcode(storedSectionData.zipcode || '');
    } else {
      // Use the full name from user data if no local storage data is available
      setFirstName(fname);
      setLastName(lname);
    }
  }, [fname, lname]);

  const updateSectionData = async (sectionData) => {
    const patientId = localStorage.getItem("patientId");
    console.log("#######patient id from local storage", patientId);
    if (!patientId) {
      console.log("!!!!!!Patient ID not found in local storage");
      return;
    }
    try {
      const section_data =  [
        {
          0: sectionData,
        },
      ]
      console.log("^^^^^^updated data of pro");
      console.log(section_data);
      const updatedSectionData = await updatePatient(patientId, {section_data: section_data});
      if (updatedSectionData) {
        console.log("updated data of pro");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updateDoctor1.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextClick = async (event) => {
    event.preventDefault();

    validateFirstName(firstName);
    validateLastName(lastName);
    validateDateOfBirth(selectedDate);
    validateGender(selectedOption);
    // validateNationality(selectCountry);

    if (!firstName || firstNameError) {
      showErrorModal("Alert", "First Name is required.");
      return;
    }

    if (!lastName) {
      showErrorModal("Alert", "Last Name is required.");
      return;
    }

    if (!selectedDate || dobError) {
      showErrorModal("Alert", "Date of Birth is required.");
      return;
    }

    if (!selectedOption) {
      showErrorModal("Alert", "Gender is required.");
      return;
    }

    // if (!selectCountry || nationalityError) {
    //   alert("Nationality is required");
    //   return;
    // }
    // if (!zipcode) {
    //   setZipcodeError(true);
    //   setshowZipcodeError(true);
    //   alert("Zipcode is required");
    //   return;
    // }

    // Store the first page data separately in local storage
    const firstPageData = {
      fname: firstName,
      lname: lastName,
      dob: selectedDate,
      age: age,
      gender: selectedOption,
      // nationality: selectCountry,
      // zipcode: zipcode,
    };
    localStorage.setItem("patientfirstPageData", JSON.stringify(firstPageData));

    // Reset the error states
    setFirstNameError(false);
    setLastNameError(false);
    setDobError(false);
    setSexError(false);
    // setNationalityError(false);

    // Update the section data with the new values
    await updateSectionData(firstPageData);

    history.push("/patient/sec-2");
  };

  const validateFirstName = (value) => {
    let error = '';

    if (!value) {
      error = 'First Name is required';
    } else if (/\s/.test(value)) {
      error = 'Spaces are not allowed';
    } else if (/[!@#$%^&*(),.?":{}|<>0-9]/.test(value)) {
      error = 'Special characters and numbers are not allowed';
    } else if (value.length < 2) {
      error = 'First Name should be at least 2 characters long';
    } else if (/\d/.test(value)) {
      error = 'Numbers are not allowed';
    }

    setFirstNameError(error);
  };

  const handlefnameBlur = () => {
    setShowFirstNameError(true);
  };

  const validateLastName = (value) => {
    let error = '';

    if (!value) {
      error = 'Last Name is required';
    } else if (/\s/.test(value)) {
      error = 'Spaces are not allowed';
    } else if (/[!@#$%^&*(),.?":{}|<>0-9]/.test(value)) {
      error = 'Special characters and numbers are not allowed';
    } else if (value.length < 2) {
      error = 'Last Name should be at least 2 characters long';
    } else if (/\d/.test(value)) {
      error = 'Numbers are not allowed';
    }

    setLastNameError(error);
  };

  const handlelnamelBlur = () => {
    setShowLastNameError(true);
  };

  const capitalizeFirstLetter = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const handleFirstNameChange = (event) => {
    const value = event.target.value;
    const capitalizedValue = capitalizeFirstLetter(value);
    setFirstName(capitalizedValue);
    validateFirstName(capitalizedValue);

    // Store the updated first name in local storage
    const docfirstPageData = {
      ...JSON.parse(localStorage.getItem("docfirstPageData")),
      fname: capitalizedValue,
    };
    localStorage.setItem("docfirstPageData", JSON.stringify(docfirstPageData));
  };

  const handleLastNameChange = (event) => {
    const value = event.target.value;
    const capitalizedValue = capitalizeFirstLetter(value);
    setLastName(capitalizedValue);
    validateLastName(capitalizedValue);

    // Store the updated last name in local storage
    const docfirstPageData = {
      ...JSON.parse(localStorage.getItem("docfirstPageData")),
      lname: capitalizedValue,
    };
    localStorage.setItem("docfirstPageData", JSON.stringify(docfirstPageData));
  };

  const validateDateOfBirth = (value) => {
    if (!value) {
      setDobError("Date of Birth is required");
      return;
    }

    const calculatedAge = calculateAge(value);

    if (calculatedAge < 3) {
      setDobError("Your age must be at least 3 years");
    } else {
      setDobError("");
    }
  };

  const handledobBlur = () => {
    setShowDobError(true);
  };

  const calculateAge = (birthdate) => {
    const today = new Date();
    console.log("today date", today);
    const birthDateParts = birthdate.split('/');
    const formattedBirthDate = `${birthDateParts[1]}/${birthDateParts[0]}/${birthDateParts[2]}`;
    const birthDate = new Date(formattedBirthDate);
    console.log("dob", birthDate);

    let calculatedAge = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      calculatedAge--;
    }
    console.log("dob2", birthdate);
    return calculatedAge;
  };

  const handleDateChange = (value) => {
    if (value) {
      setSelectedDate(value);

      // Validate the date of birth
      validateDateOfBirth(value);

      // Calculate the age based on the validated date of birth
      const calculatedAge = calculateAge(value);

      setAge(calculatedAge);

      // Update the section data with the new value
      setSectionData((prevData) => ({
        ...prevData,
        dob: selectedDate, // Store the date as ISO string in section data
        age: calculatedAge,
      }));

      // Update the stored section data in local storage
      localStorage.setItem(
        'sectionData',
        JSON.stringify({
          ...sectionData,
          dob: selectedDate,
          age: calculatedAge,
        })
      );
    } else {
      setSelectedDate(null);
      setDobError('Date of Birth is required');
      setAge(''); // Reset the age if the date is cleared
    }
  };

  // const validateZipcode = (value) => {
  //   let error = '';
  //   if (!value) {
  //     setZipcodeError("Zipcode is required");
  //   } else if (/\s{2,}/.test(value)) {
  //     error = 'Multiple consecutive spaces are not allowed';
  // } else {
  //     setZipcodeError("");
  //   }
  // };

  // const handleZipcodeChange = (event) => {
  //   const zipcodeValue = event.target.value;
  //   setZipcode(zipcodeValue);
  //   setZipcodeError(!validateZipcode(zipcodeValue));
  //   setSectionData((prevData) => ({
  //     ...prevData,
  //     zipcode: zipcodeValue,
  //   }));
  // };

  // const handleZipcodeBlur = () => {
  //   setshowZipcodeError(true);
  // };

  // Validate the gender field
  const validateGender = (option) => {
    if (!option) {
      setSexError("Gender is required");
    } else {
      setSexError("");
    }
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    validateGender(option);

    // Update the section data with the new value
    setSectionData((prevData) => ({
      ...prevData,
      gender: option,
    }));

    // Update the stored section data in local storage
    localStorage.setItem(
      "sectionData",
      JSON.stringify({
        ...sectionData,
        gender: option,
      })
    );
  };

  // Validate the nationality field
  // const validateNationality = (option) => {
  //   if (!option) {
  //     setNationalityError("Nationality is required");
  //   } else {
  //     setNationalityError("");
  //   }
  // };

  // const handleSelectCountry = (option) => {
  //   validateNationality(option);
  //   setSelectCountry(option);

  //   // Update the section data with the new value
  //   const updatedSectionData = {
  //     ...sectionData,
  //     // 1: {
  //     // ...sectionData[1],
  //     nationality: option,
  //     // },
  //   };
  //   setSectionData(updatedSectionData);

  //   // Update the stored section data in local storage
  //   localStorage.setItem('sectionData', JSON.stringify(updatedSectionData));
  // };

  const handleClearForm = () => {
    setFirstName('');
    setLastName('');
    setSelectedOption([]);
    // setSelectCountry([]);
    setAge('');
    setSelectedDate('');
    setDobError('');
    setFirstNameError('');
    setLastNameError('');
    setSexError('');
    // setNationalityError('');
    // setZipcode('');
    // setZipcodeError('');
    // setshowZipcodeError('');
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <Page>
      <FirstSection title="PATIENT FORM">
        <h4 style={{ margin: '0px' }}>Primary form for consultation</h4>
      </FirstSection>
      <SectionComponent>
        <label className="sec-label">FIRST NAME*</label>
        <CustomTextField
          id="outlined-basic"
          placeholder="First Name"
          variant="outlined"
          value={firstName}
          onChange={handleFirstNameChange}
          onBlur={handlefnameBlur}
          error={!!firstNameError}
          helperText={firstNameError}
        />
      </SectionComponent>
      <SectionComponent>
        <label className="sec-label">LAST NAME*</label>
        <CustomTextField
          id="outlined-basic"
          placeholder="Last Name"
          variant="outlined"
          value={lastName}
          onChange={handleLastNameChange}
          onBlur={handlelnamelBlur}
          error={!!lastNameError}
          helperText={lastNameError}
        />
      </SectionComponent>
      <SectionComponent>
        <label className="sec-label">DATE OF BIRTH*</label>
        <div className="date-pick">
          <DatePickerDialog
            value={selectedDate !== null ? selectedDate : ''}
            onChange={handleDateChange}
            onBlur={handledobBlur}
          />
          {dobError && <span className="error-msg" style={{ color: 'red', marginBottom: '20px' }}>{dobError}</span>}
        </div>
        <br></br>
        <CustomTextField
          id="outlined-basic"
          label="Age According to Date of Birth"
          variant="outlined"
          value={age}
          readOnly
        />
      </SectionComponent>
      <SectionComponent>
        <label className="sec-label">SEX*</label>
        <div id="gender">
          <RadioButtonGroup
            name="radioOptions"
            value={selectedOption}
            options={options}
            onChange={handleOptionChange}
          />
        </div>
        {sexError && sexError && (
          <p className="error-msg">{sexError}</p>
        )}
      </SectionComponent>
      {/* <SectionComponent>
        <label className="sec-label">Country</label>
        <br></br>
        <Dropdown
        className="dropdown-compo"
          label={selectCountry ? '' : 'Select Country'}
          options={countries.map((country) => ({
            value: country.name,
            label: country.name,
          }))}
          onChange={handleSelectCountry}
          value={selectCountry}
          error={!!nationalityError}
          helperText={nationalityError}
        />
        {nationalityError && nationalityError && (
          <p className="error-msg">{nationalityError}</p>
        )}
      </SectionComponent> */}
      {/* <SectionComponent>
        <label className='sec-label'>Postal/Zip Code</label>
        <CustomTextField id="outlined-basic" placeholder="Postal/Zip Code" variant="outlined" value={zipcode} onChange={handleZipcodeChange} 
        // onBlur={handleZipcodeBlur} 
        />
        {zipcodeError && showzipcodeError && (<p className="error-msg">Please enter zipcode</p>)}
      </SectionComponent> */}
      <FirstAction
        onClickNext={handleNextClick}
        onClearForm={handleClearForm}
      />
      <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
        <Ticket variant="contained" color="secondary" section="Patient Form: Primary form for consultation" />
      </div>
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
};

export default PersonalInformation;