import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../pro-components/Page';
import SubmitAction from '../pro-components/submitaction';
import FirstSection from '../pro-components/FirstSection'
import CustomModal from "../../global-components/modal";
import { updatePro1, sendEmail, createPro1Status, updatePro1Status, findOnePro1Status, fetchPROData } from '../../../api';

const SubmitPage = () => {
  const history = useHistory();
  const [sectionData, setSectionData] = useState('');
  const userDataJSON = localStorage.getItem("user");
  console.log("user data from local storage", userDataJSON);
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const proId = localStorage.getItem('proId');
  console.log('pro id from local storage', proId);
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const updateSectionData = async (sectionData) => {
    const proId = localStorage.getItem("proId");
    console.log("pro id from local storage", proId);
    if (!proId) {
      console.log("Pro ID not found in local storage");
      return;
    }

    try {
      const existingSectionData = JSON.parse(localStorage.getItem("firstPageData"));

     
      const section_data = [
        {
          0: existingSectionData, // Include the first page data at index 0
        },
        {
          1: JSON.parse(localStorage.getItem('secondPageData')), // Include the second page data at index 1
        },
        {
          2: JSON.parse(localStorage.getItem('thirdPageData')), // Include the second page data at index 1
        },
        {
          3: JSON.parse(localStorage.getItem('fourthPageData')), // Include the second page data at index 1
        },
        {
          4: JSON.parse(localStorage.getItem('fifthPageData')), // Include the second page data at index 1
        },
        {
          5: sectionData, // Include the third page data at index 2
        },
      ]

      console.log("updated data of pro");
      console.log(section_data);
      const updatedSectionData = await updatePro1(proId, section_data);
      if (updatedSectionData) {
        console.log("updated data of pro");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updateDoctor1.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const pro1StatusData = {
        user_id: userData.id,
        pro_id: proId,
        is_submit: true,
        is_accepted: "pending"
      };

      console.log("proid and userid from submit", proId, userData.id);
  
      if (proId && userData.id) {
        const existingPROData = await fetchPROData(userData.id);
        console.log('Existing PRO Data:', existingPROData);
  
        if (existingPROData && existingPROData.is_accepted === false) {
          const updateStatusResponse = await updatePro1Status(existingPROData._id, { is_accepted: null });
          console.log("Update PRO Status API is called to set is_accepted to null");
  
          if (updateStatusResponse) {
            console.log('Pro1 Status updated successfully');
            console.log('API response:', updateStatusResponse); // Log the API response
          } else {
            console.error('Failed to update Pro1 Status');
          }
        } else {
          const existingPro1Status = await findOnePro1Status(userData.id);

          if (!existingPro1Status) {
            console.log('Existing PRO 1 status Data:', existingPro1Status);
            console.log('Before createPro1Status API call');
            const response = await createPro1Status(pro1StatusData);
            console.log('After createPro1Status API call');
            console.log('API response createPro1Status:', response.data);
            const pro1statusid = response.data.data._id; // Retrieve pro1statusid from response
            localStorage.setItem('pro1statusid', pro1statusid); // Store pro1statusid in local storage
            console.log("pro1statusid from local storage in setitem", pro1statusid);
            }
  
         else {
            const existingPro1Status = await findOnePro1Status(userData.id);
            if (existingPro1Status) {
              if (existingPro1Status.is_accepted === "false") {
                console.log('Existing PRO 1 status Data:', existingPro1Status);
                console.log("update pro 1 status api is been called 1");
                const pro1statusid = existingPro1Status._id; // Retrieve pro1statusid from existing status
                console.log("pro1statusid from existing status", pro1statusid);
                const updateStatusResponse = await updatePro1Status(pro1statusid, { is_accepted: "pending" });
                console.log("update pro 1 status api is been called 2");
                if (updateStatusResponse) {
                  console.log('Pro1 Status updated successfully');
                  console.log('API response:', updateStatusResponse); // Log the API response
                } else {
                  console.error('Failed to update Pro1 Status');
                }
              } else {
                console.log('Pro1 Status is already accepted');
              }
            }
          }
        }
      } else {
        console.error('proId or userId not found in local storage');
      }
  
      localStorage.setItem('isSubmit', true);
      showErrorModal("Submitted successfully");
      history.push('/pro/prodashboard');
  
      const sendMailResponse = await sendEmail(userData.email, 'Confirmation of PRO Application Submission', emailLayout);
      console.log('Email sent successfully:', sendMailResponse.data);
    } catch (error) {
      console.error('API error:', error);
    }
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  const emailLayout = `
  <div style="max-width: 100%; margin: 0 auto; font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6;">
    <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; text-align: center;">
      <img src="https://meditravels.s3.amazonaws.com/Logo/952d4079-478e-44ce-92e8-f8e03e84852d-sticky-logo.png" alt="MediTravels Logo" style="max-width: 20%; height: auto;" />
      <p style="text-align: left; margin: 5px 0;">Dear ${userData.name},</p>
        <p style="text-align: left;">Thank you for submitting your application for PRO membership with MediTravels. We have received your application and will now begin processing it.
        Our team will review your application and verify the information provided. We'll notify you as soon as your application is approved or if we need any additional information.</p>
        <p style="text-align: left; margin: 5px 0 2px;">Best regards,</p>
        <p style="text-align: left; margin: 0px;">MeditravelS Team</p>
      </div>
    </div>`;

  return (
    <Page>
      <FirstSection title="Submit Your Application In PRO">
        After collecting this information, we will review the submitted applications to ensure that we hire qualified individuals who meet our company's requirements for providing accurate and helpful information to local patients seeking medical treatment in India.
      </FirstSection>
      <SubmitAction backLink="/pro/section5" onSubmit={handleSubmit} />
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
};

export default SubmitPage;