import { baseurl } from "./baseurl";

export const Signup_api_url = baseurl + "signup";
export const Login_api_url = baseurl + "login";
export const UpdateUser_api_url = baseurl + "updateuser/:id";
export const Logout_api_url = baseurl + "logout";
export const FindAlladd_api_url = baseurl + "findalladdresses";
export const Treatmentdetails_api_url = baseurl + "findonetreatmentdetails";
export const Findoneuser_api_url = baseurl + "findoneuser/:id";
export const Findonebyemail_api_url = baseurl + "findonebyemail/:email";
export const FindAllTreatment_api_url = baseurl + "findalltreatment";
export const FindAllSubTreatment_api_url = baseurl + "findallsubtreatment";
export const FindAllenableSubTreatment_api_url = baseurl + "findallenablesubtreatment";
export const FindAllSubSubTreatment_api_url = baseurl + "findallsubsubtreatment";
export const FindAllBlog_api_url = baseurl + "findallblog";
export const FindBlogbyId_api_url = baseurl + "findoneblog";
export const Newsletter_api_url = baseurl + "createnewsletter";
export const About_api_url = baseurl + "findallaboutus";
export const Testimonial_api_url = baseurl + "findalltestimonials";
export const PRO_Create_api_url = baseurl + "createpro";
export const PRO_Update_api_url = baseurl + "updateupdatepro/:id";
export const UploadMedia_api_url = baseurl + "media/upload";
export const Patient_Create_api_url = baseurl + "createpatient";
export const Patient_Update_api_url = baseurl + "updateupdatepatient/:id";
export const Patient_FindById_api_url = baseurl + "findonepatient";
export const PRO2_Update_api_url = baseurl + "updateupdatepro2/:userId";
export const Doc_Create_api_url = baseurl + "createdoctor";
export const Doc_Update_api_url = baseurl + "updateupdatedoctor/:id";
export const Doc_FindById_api_url = baseurl + "findonedoctor";
export const Doc2_Create_api_url = baseurl + "createdoctor2";
export const Doc2_Update_api_url = baseurl + "updateupdatedoctor2/:userId";
export const Doc2_FindById_api_url = baseurl + "findonedoctor2";
export const FindOnePRO_api_url = baseurl + "findonepro";
export const FindOnePRO2_api_url = baseurl + "findonepro2";
export const PRO1Status_Create_api_url = baseurl + "createpro1Status";
export const FindOnePro1Status_api_url = baseurl + "findonepro1Status";
export const PRO2Status_Create_api_url = baseurl + "createpro2Status";
export const FindOnePro2Status_api_url = baseurl + "findonepro2Status";
export const PatientStatus_Create_api_url = baseurl + "createpatientStatus";
export const Doc1Status_Create_api_url = baseurl + "createdoc1Status";
export const Doc2Status_Create_api_url = baseurl + "createdoc2Status";
export const FindOneDoc1Status_api_url = baseurl + "findonedoc1Status";
export const FindOneDoc2Status_api_url = baseurl + "findonedoc2Status";
export const FindOnePatientStatus_api_url = baseurl + "findonepatientStatus";
export const CheckToken_api_url = baseurl + "checktoken";
export const CreateContactUs_api_url = baseurl + "createcontactus";
export const FindAllContact_api_url = baseurl + "findallcontactus";
export const ForgetPassword_api_url = baseurl + "createforgetpassword"
export const VerifyOtp_api_url = baseurl + "verifyotp"
export const ResendOtp_api_url = baseurl + "resendotp";
export const ResetPassword_api_url = baseurl + "resetpassword";
export const Ticket_Create_api_url = baseurl + "createticket";
export const Ticket_FindAllByEmail_api_url = baseurl + "findallbyemailticket";
export const Ticket_FindAllByUserId_api_url = baseurl + "findallbyuseridticket";
export const ActiveTreatment_Create_api_url = baseurl + "createactivetreatment";
export const PatientStatus_Update_api_url = baseurl + "updateupdatepatientStatus/:id";
export const ActiveTreatment_FindOne_api_url = baseurl + "findoneactivetreatment";
export const ActiveTreatment_Update_api_url = baseurl + "updateactivetreatment/:userId";
export const Consultation_Create_api_url = baseurl + "createconsultation";
export const Consultation_Update_api_url = baseurl + "updateconsultation/:user_id";
export const FindConsultation_api_url = baseurl + "findoneconsultation";
export const Customer_Create_api_url = baseurl + "create-customer";
export const Session_Create_api_url = baseurl + "create-session";
export const Send_Mail_api_url = baseurl + "sendmail";
export const FindAll_PRO2_Status_api_url = baseurl + "findallpro2Status"
export const Get_All_ProList_api_url = baseurl + "getallprolist"
export const Updatepro1status_api_url = baseurl +"updateupdatepro1Status/:id";
export const Updatepro2status_api_url = baseurl + "updateupdatepro2Status/:id";
export const Updatedoc1status_api_url = baseurl + "updateupdatedoc1Status/:id";
export const UpdateDoc2status_api_url = baseurl + "updateupdatedoc2Status/:id";