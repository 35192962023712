import React, {useEffect} from 'react';
import { useHistory} from 'react-router-dom';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import BlogList from './blog-components/blog-list';
import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';

const BlogPage = () => {
  const headerImage = localStorage.getItem("blogimage");
    const history = useHistory();
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page on component mount
      const unlisten = history.listen(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page on route change
      });
      return () => unlisten();
    }, [history]);

    return <div>
        <Navbar />
        <PageHeader image={headerImage} headertitle="Blog"  />
        <BlogList />
        <Subscribe />
        <Footer />
    </div>
}

export default BlogPage

