import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from './CButton';

const Action = ({ backLink, onClearForm, onClickNext }) => {
  const handleClearForm = () => {
    // Add your logic to clear the form here
    onClearForm();
  };

  return (
    <div className='action-component' style={{ display: 'flex', overflow: 'unset', width: '50%', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '20px' }}>
          {backLink && (
            <Link
              to={backLink}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              <CustomButton className='action-btn' variant="contained" color="primary" label="Back">
                Back
              </CustomButton>
            </Link>
          )}
        </div>
        <div>
          <Link
            onClick={(event) => {
              event.preventDefault();
              window.scrollTo({ top: 0, behavior: 'smooth' });
              if (onClickNext) {
                onClickNext(event); // Pass the event object to the onClickNext function
              }
            }}
          >
            <CustomButton className='action-btn' variant="contained" color="primary" label="Next">
              Next
            </CustomButton>
          </Link>
        </div>
      </div>
      <div>
        <CustomButton className='action-btn' variant="contained" color="secondary" label="Clear Form" onClick={handleClearForm}>
          Clear Form
        </CustomButton>
      </div>
    </div>
  );
};

export default Action;

// const handleNextClick = () => {
//   // Add your custom logic for the "Next" button click
// };

// <Action
//   backLink={previousPageURL}
//   onClearForm={handleClearForm}
//   onClickNext={handleNextClick}
// />