import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CustomTextField from './CTextField';
import CustomTextArea from '../pro-components/CTextArea';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { createTicket } from '../../../api';

const Ticket = ({ variant, color, section }) => {
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [showPhoneError, setShowPhoneError] = useState(false);
    const [problem, setProblem] = useState('');
    const [problemError, setProblemError] = useState('');
    const [showproblemError, setShowProblemError] = useState(false);
    const userDataJSON = localStorage.getItem("user");
    const userData = userDataJSON ? JSON.parse(userDataJSON) : {};
    let loginEmail = userData.email;
    console.log("prinitng login email", loginEmail, userData._id)

    useEffect(() => {
        console.log("********prinitng user id from create ticket")
        console.log(userData.id)
        if (userData) {
            setEmail(loginEmail || '');
        }
    }, []);

    const handleEmailChange = (event) => {
        const emailValue = event.target.value;
        setEmail(emailValue);
        validateEmail(emailValue);
        setEmailError(!validateEmail(emailValue));
        setEmailError(false);
    };

    const validateEmail = (email) => {
        let isValid = true;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

        // Test the email against the regex pattern
        isValid = emailRegex.test(email);

        // Additional validation for dot after the @ symbol
        if (isValid) {
            const atIndex = email.indexOf('@');
            const dotIndex = email.indexOf('.', atIndex);
            isValid = dotIndex !== -1 && dotIndex > atIndex + 1;
        }

        return isValid;
    };

    const handlePhoneChange = (value) => {
        setPhone(value);
        setPhoneError(!value);
        setShowPhoneError(false);
    };

    const handleReportClick = () => {
        setShowModal(true);
    };

    const validateProblem = (value) => {
        let error = '';
        if (/\s{2,}/.test(value)) {
            error = 'Multiple consecutive spaces are not allowed';
        }
        setProblemError(error);
        return !error;
    };

    const handleProblemChange = (event) => {
        const joinMotive = event.target.value;
        setProblem(joinMotive);
        const isProblemValid = validateProblem(joinMotive);
        if (isProblemValid) {
            setProblemError(''); // Clear the error if the input is valid
        }
    };

    const handleProblemBlur = () => {
        setShowProblemError(true);
    };
    const resetForm = () => {
        setShowProblemError(false);
        setProblemError('');
        setProblem('');
    };

    const handleSendClick = async () => {
        setEmailError(false);
        setPhoneError(false);
        setShowEmailError(false);
        setShowPhoneError(false);
        setShowProblemError(false);
        setProblemError(false);

        // Check and set specific error states
        if (!email) {
            setEmailError(true);
            setShowEmailError(true);
            alert('Email is required.');
            return;
        }
        if (!phone) {
            setPhoneError(true);
            setShowPhoneError(true);
            alert('Phone is required.');
            return;
        }
        if (!problem) {
            setProblemError(true);
            setShowProblemError(true);
            alert('Problem description is required.');
            return;
        }
        const ticketData = {
            user_id: userData.id,
            email,
            contact: phone,
            section: section,
            problem: problem,
            status: 'open'
        };

        try {
            const response = await createTicket(ticketData);
            console.log('Ticket created successfully:', response);
            setShowModal(false);
            resetForm();
        } catch (error) {
            console.error('Error creating ticket:', error);
        }
    };

    return (
        <div>
            <Button variant={variant} color={color} onClick={handleReportClick} style={{ backgroundColor: '#291211', color: '#fff', padding: '6px 16px', height: '36px', lineHeight: '20px' }}>
                REPORT A PROBLEM
            </Button>
            <Modal show={showModal} className="newsletter-popup" backdrop="static">
                {/* <Modal.Header>
                    <Modal.Title>Report your problem here.</Modal.Title>
                </Modal.Header> */}
                <Modal.Body style={{
                    backgroundColor: '#fff',
                    border: '1px solid #dadce0',
                    borderRadius: '8px',
                    width: '100%',
                    marginTop: '20px'
                }}>
                    <label className='section-label' style={{ paddingTop: '10px', marginBottom: '5px' }}>Email Address</label>
                    <CustomTextField
                        id="outlined-basic"
                        placeholder="Email Address"
                        variant="outlined"
                        value={email}
                        onChange={handleEmailChange}
                    />
                    {emailError && showEmailError && (<p className="error-msg">Please enter a valid email address.</p>)}
                    <label className='section-label' style={{ paddingTop: '10px', marginBottom: '5px' }}>Phone Number</label>
                    <div className='report-phone'>
                        <PhoneInput
                            placeholder="Enter phone number"
                            width='100%'
                            value={phone}
                            onChange={handlePhoneChange}
                            country="in"
                            inputProps={{
                                required: true,
                            }}
                        />
                    </div>
                    {showPhoneError && (<p className="error-msg">Please enter a valid phone number.</p>)}
                    <label className="section-label" style={{ paddingTop: '10px', marginBottom: '5px' }}>Message</label>
                    <CustomTextArea
                        id="outlined-basic"
                        variant="outlined"
                        value={problem}
                        onChange={handleProblemChange}
                        onBlur={handleProblemBlur}
                        placeholder="What problem you are facing?"
                        rows={4}
                        rowsMax={8}
                    />
                    {problemError && showproblemError && (<p className="error-msg">{problemError}</p>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-yellow" variant="secondary" onClick={handleSendClick}>
                        Send
                    </Button>
                    <Button className="btn btn-yellow" variant="secondary" onClick={() => setShowModal(false)} label='Close'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Ticket;