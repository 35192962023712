import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import jwt from "jsonwebtoken";
import { useHistory } from "react-router-dom";
import { fetchContactData, fetchBlogs, fetchAboutData, fetchTreatmentData, fetchTestimonialData, checkTokenValidity, logoutUser } from '../../api';

function Navbar() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [treatments, setTreatments] = useState([]);
  const history = useHistory();
  const [showButtons, setShowButtons] = useState(true);
  const userDataJSON = localStorage.getItem("user");
  const Role = localStorage.getItem("userrole");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const userRole = userData && userData.role || Role;

  let publicUrl = process.env.PUBLIC_URL + "/";

  let imgattr = "logo";
  let anchor = "#";

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem("token");
      // fetchTreatment();
      if (token) {
        try {
          const isValid = await checkTokenValidity(token);

          if (isValid) {
            // Token is valid, user is logged in
            setLoggedIn(true);
          } else {
            // Token is invalid, user is not logged in
            setLoggedIn(false);
            localStorage.removeItem("token");
            localStorage.clear();
          }
        } catch (error) {
          console.log("Error checking token:", error);
          // Handle error condition, e.g., show an error message
          // For example, if there's a network error, consider the user as logged out
          setLoggedIn(false);
          localStorage.removeItem("token");
          localStorage.clear();
        };
      } else {
        // Token is missing, user is not logged in
        setLoggedIn(false);
        localStorage.removeItem("token");
      }
    };
    checkToken();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    // fetchTreatment();
    fetchBlogs();

    if (token) {
      const decodedToken = jwt_decode(token);
      const userRole = decodedToken.userrole;

      setLoggedIn(true);

      if (userRole === "Patient") {
        setLoggedIn(false);
        localStorage.removeItem("token");
      }
    } else {
      setLoggedIn(false);
      localStorage.removeItem("token");
    }
  }, []);

  useEffect(() => {
    fetchTreatmentData()
      .then((data) => {
        setTreatments(data);
      })
      .catch((error) => {
        console.error('Error in contact:', error);
      });
  }, []);

  useEffect(() => {
    fetchAboutData()
      .then((data) => {
        localStorage.setItem('aboutimage', data.data.image_url);
      })
      .catch((error) => {
        console.error('Error in AboutPage:', error);
      });
  }, []);

  useEffect(() => {
    fetchTestimonialData()
      .then((data) => {
        localStorage.setItem('testimonialsimage', data.data.image_url);
      })
      .catch((error) => {
        console.error('Error in AboutPage:', error);
      });
  }, []);

  useEffect(() => {
    fetchBlogs()
      .then((data) => {
        localStorage.setItem('blogimage', data.data.image_url);
      })
      .catch((error) => {
        console.error('Error in contact:', error);
      });
  }, []);

  useEffect(() => {
    fetchContactData()
      .then((data) => {
        localStorage.setItem('contactimage', data.data.image_url);
      })
      .catch((error) => {
        console.error('Error in contact:', error);
      });
  }, []);

  const handleLogout = async () => {
    console.log("Logout button clicked");

    const token = localStorage.getItem("token");
    if (token) {
      try {
        console.log("Token found in local storage");

        const decodedToken = jwt.decode(token);
        const userId = decodedToken && decodedToken.user_id;

        // Check the token validity before logging out
        const isValidToken = await checkTokenValidity(token);

        if (!isValidToken) {
          console.log("Token is invalid. Logging out without API call.");
          // Remove token from local storage
          localStorage.removeItem("token");
          // Remove all items from local storage
          localStorage.clear();
          setLoggedIn(false);
          // Set the showButtons state to true
          setShowButtons(true);
          // Redirect to the login page or any other desired page
          history.push("/");
          return;
        }

        console.log("Token is valid. Proceeding with logout API call...");

        // Call the logout API
        const logoutSuccess = await logoutUser(userId);

        if (logoutSuccess) {
          console.log("Logout API call successful");

          // Remove token from local storage
          localStorage.removeItem("token");
          // Remove all items from local storage
          localStorage.clear();
          console.log("Logout successful");
          setLoggedIn(false);
          // Redirect to the login page or any other desired page
          history.push("/");
        } else {
          console.error("Logout API call failed");
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle error
      }
    } else {
      history.push("/");
      console.error("Token not found in local storage");
    }
  };

  const getUserName = () => {
    if (userData) {
      return userData.name;
    } else {
      return "";
    }
  };

  const handleWorkWithUsClick = (role) => {
    localStorage.setItem("selectedRole", role);
  };

  const handleConsultUsClick = (role) => {
    localStorage.setItem("selectedRole", role);
  };

  const handleCLick = (treatment_id, header_image) => {
    localStorage.setItem("treatment_id", treatment_id);
    localStorage.setItem("treatmentimage", header_image);
  };

  return (
    <nav className="navbar navbar-area navbar-expand-lg nav-style-01 navbar-area-fixed ">
      <div className="container nav-container">
        <div className="responsive-mobile-menu">
          <div className="mobile-logo">
            <Link to="/">
              <img
                src={publicUrl + "assets/img/sticky-logo.png"}
                alt={imgattr}
              />
            </Link>
          </div>
          <button
            className="navbar-toggler float-right"
            type="button"
            data-toggle="collapse"
            data-target="#tp_main_menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggle-icon">
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </span>
          </button>
          <div className="nav-right-content">
            <ul className="pl-0">
              {!loggedIn && showButtons && (
                <li>
                  <Link className="btn btn-yellow" to="/signin">
                    Login
                  </Link>
                </li>
              )}
              {loggedIn && (
                <li className="dropdown user-profile-button">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                    Hi, {getUserName()}
                  </a>
                  <ul className="dropdown-menu user-dropdown">
                    <li>
                      <i className="fa fa-user-circle-o user-profile-icon" />
                      <Link to="/user-profile">User Profile</Link>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={handleLogout}
                        className="btn btn-yellow"
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="tp_main_menu">
          <div className="logo-wrapper desktop-logo">
            <Link to="/" className="main-logo">
              <img src={publicUrl + "assets/img/sticky-logo.png"} alt="logo" />
            </Link>
            <Link to="/" className="sticky-logo">
              <img src={publicUrl + "assets/img/sticky-logo.png"} alt="logo" />
            </Link>
          </div>
          <ul className="navbar-nav">
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li className="menu-item-has-children">
              <a href="#">Treatment</a>
              <ul className="sub-menu">
                {treatments.map((treatment) => (
                  <li key={treatment._id}>
                    <Link
                      to={{
                        pathname: `/tour-list`,
                        state: { treatment_id: treatment._id, image: treatment.header_image },
                      }}
                      onClick={() => handleCLick(treatment._id, treatment.header_image)}
                    >
                      {treatment.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            {/* <li className="menu-item-has-children">
              <a href="#">Tourism</a>
            </li> */}
            <li className="menu-item-has-children">
              <li>
                <Link to={{ pathname: `/blog`, state: { image: undefined } }}>
                  Blog
                </Link>
              </li>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <Link to="/testimonial">Testimonial</Link>
            </li>
            {!loggedIn && showButtons && (
              <li className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  id="work-with-us-dropdown"
                >
                  Work With Us
                </a>
                <div
                  className="dropdown-menu work-with-us-dropdown"
                  aria-labelledby="work-with-us-dropdown"
                >
                  <Link
                    className="dropdown-item work"
                    to="/signin"
                    onClick={() => handleWorkWithUsClick("Doctor")}
                  >
                    Doctor
                  </Link>
                  <Link
                    className="dropdown-item work"
                    to="/signin"
                    onClick={() => handleWorkWithUsClick("PRO")}
                  >
                    PRO
                  </Link>
                </div>
              </li>
            )}
            {loggedIn && userRole !== "Patient" && (
              <li className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  id="work-with-us-dropdown"
                >
                  Work With Us
                </a>
                <div
                  className="dropdown-menu work-with-us-dropdown"
                  aria-labelledby="work-with-us-dropdown"
                >
                  {userRole === "Doctor" && (
                    <Link
                      className="dropdown-item work"
                      to="/doc/docdashboard"
                    >
                      Doctor
                    </Link>
                  )}
                  {userRole === "PRO" && (
                    <Link
                      className="dropdown-item work"
                      to="/pro/prodashboard"
                    >
                      PRO
                    </Link>
                  )}
                </div>
              </li>
            )}
            <li>
              <Link to="/pro-list">Our PRO</Link>
            </li>
          </ul>
        </div>
        <div className="nav-right-content desktop-buttons">
          <ul>
            {!loggedIn && showButtons && (
              <li>
                <Link
                  className="btn btn-yellow"
                  to="/signin"
                  onClick={() => handleConsultUsClick("Patient")}
                >
                  Consult Us <i className="fa fa-paper-plane" />
                </Link>
              </li>
            )}
            {loggedIn && userRole === "Patient" && (
              <li>
                <Link className="btn btn-yellow" to="/patient/patientdashboard">
                  Consult Us <i className="fa fa-paper-plane" />
                </Link>
              </li>
            )}
            {!loggedIn && showButtons && (
              <li>
                <Link className="btn btn-yellow" to="/signin" onClick={() => { localStorage.removeItem("selectedRole"); }}>
                  Login
                </Link>
              </li>
            )}
            {loggedIn && (
              <li className="dropdown user-profile-button">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  Hi, {getUserName()}
                </a>
                <ul className="dropdown-menu user-dropdown">
                  <li>
                    <i className="fa fa-user-circle-o user-profile-icon" />
                    <Link to="/user-profile">User Profile</Link>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={handleLogout}
                      className="btn btn-yellow"
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;