import React, { useState, useEffect } from 'react';
import Page from '../pro-components/Page'
import SectionComponent from '../pro-components/Section'
import FirstSection from '../pro-components/FirstSection'
import CustomButton from '../pro-components/CButton'
import CheckboxComponent from '../pro-components/CCheckBox'
import Dropdown from '../pro-components/CDropDown'
import Action from '../pro-components/Action'
import { useHistory } from "react-router-dom";
import language from '../../../language.json';
import Ticket from '../pro-components/ticket'
import CustomModal from "../../global-components/modal";
import { updatePro1 } from '../../../api';

const LanguageProficiency = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [checkboxes, setCheckboxes] = useState(Array(16).fill(false));
    // const [methodCheckboxes, setMethodCheckboxes] = useState(Array(communicationMethod.length).fill(false));
    const [addedlanguage, setAddedlanguage] = useState('')
    const [languages, setLanguages] = useState([]);
    const [selectlanguage, setSelectLanguage] = useState('');
    const [languageserror, setLanguageserror] = useState(false);
    const [showlanguageError, setshowlanguageError] = useState(false);
    const [error, setError] = useState('');
    const [checkboxerror, setCheckboxError] = useState('');
    const [sectionData, setSectionData] = useState("");
    const labels = ['Fluent,Understanding', 'Professional,Understanding', 'Elementary,Understanding', 'Novice,Understanding',
        'Fluent,Reading', 'Professional,Reading', 'Elementary,Reading', 'Novice,Reading',
        'Fluent,Speaking', 'Professional,Speaking', 'Elementary,Speaking', 'Novice,Speaking',
        'Fluent,Writing', 'Professional,Writing', 'Elementary,Writing', 'Novice,Writing'];
    let history = useHistory();
    const previousPageURL = '/pro/section2';
    // const nextPageURL = '/educational-background';
    const minscript = document.createElement("script");
    minscript.async = true;
    document.body.appendChild(minscript);
    const [options, setOptions] = useState(language);
    const [errorModalData, setErrorModalData] = useState({
        title: "",
        message: "",
        show: false,
    });

    const showErrorModal = (title, message) => {
        setErrorModalData({
            title: title,
            message: message,
            show: true,
        });
    };

    const updateSectionData = async (sectionData) => {
        const proId = localStorage.getItem("proId");
        console.log("pro id from local storage", proId);
        if (!proId) {
            console.log("Pro ID not found in local storage");
            return;
        }

        try {
            const existingSectionData = JSON.parse(localStorage.getItem("firstPageData"));

            const section_data = [
                {
                    0: existingSectionData, // Include the first page data at index 0
                },
                {
                    1: JSON.parse(localStorage.getItem("secondPageData")), // Include the second page data at index 1
                },
                {
                    2: sectionData,
                }
            ]

            console.log("updated data of pro");
            console.log(section_data);
            const updatedSectionData = await updatePro1(proId, section_data);
            if (updatedSectionData) {
                console.log("updated data of pro");
                console.log(updatedSectionData);
            } else {
                console.error("No data returned from updateDoctor1.");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleCheckboxChange = (index, isChecked) => {
        setCheckboxes(prevCheckboxes => {
            const updatedCheckboxes = [...prevCheckboxes];

            // Calculate the starting index of the current line
            const lineStartIndex = Math.floor(index / 4) * 4;

            // Uncheck all checkboxes in the same line
            for (let i = lineStartIndex; i < lineStartIndex + 4; i++) {
                updatedCheckboxes[i] = false;
            }

            // Check the clicked checkbox
            updatedCheckboxes[index] = isChecked ? labels[index] : '';

            localStorage.setItem('checkboxes', JSON.stringify(updatedCheckboxes));
            setCheckboxes(updatedCheckboxes);
            return updatedCheckboxes;
        });
    };


    const handleNextClick = async (event) => {
        event.preventDefault();

        if (languages.length === 0) {
            showErrorModal("Alert", "Please Add selected language Or please select at least one language");
            return;
        } else {
            const selectedLanguage = options.find((option) => option.value === selectlanguage);
            const languageLabel = selectedLanguage ? selectedLanguage.label : "";

            const thirdPageData = {
                // native_language: selectlanguage,
                addedlanguage: languages.map(language => ({
                    selectedOption: language.selectedOption,
                    checkboxes: language.checkboxes,
                })),
            };

            localStorage.setItem("thirdPageData", JSON.stringify(thirdPageData));

            await updateSectionData(thirdPageData);

            history.push("/pro/section4");
        }
    };

    useEffect(() => {
        const storedCheckboxes = localStorage.getItem("checkboxes");
        if (storedCheckboxes) {
            setCheckboxes(JSON.parse(storedCheckboxes));
        }
    }, []);

    useEffect(() => {
        const storedLanguages = localStorage.getItem("languages");
        if (storedLanguages) {
            setLanguages(JSON.parse(storedLanguages));
        }
    }, []);

    const validateSelectLanguage = (option) => {
        setSelectLanguage(option);

        if (!option) {
            setLanguageserror('Language is required');
            return false;
        } else if (option === selectedOption) {
            setLanguageserror('Language already selected');
            return false;
        } else {
            setLanguageserror('');
            return true;
        }
    };

    const handleLanguageChange = (option) => {
        setSelectedOption(option);
        const isLanguageValid = validateSelectLanguage(option);

        if (isLanguageValid) {
            setSectionData((prevData) => {
                const newData = { ...prevData };
                newData.native_language = option;

                localStorage.setItem('thirdPageData', JSON.stringify(newData));

                return newData;
            });
            console.log('Language updated:', option);
        }
    };

    const handlelanguageBlur = () => {
        setshowlanguageError(true);
        validateSelectLanguage(selectedOption);
    };

    const handleClearForm = () => {
        setSelectedOption('');
        setCheckboxes(Array(16).fill(false));
        setLanguages([]);
        setSelectLanguage('');
        setCheckboxError('')
    };

    const handleAddLanguage = () => {
        if (!selectedOption) {
            setError('Please select a language');
            return;
        }

        if (!checkboxes.some(checkbox => checkbox)) {
            setCheckboxError('Please select at least one checkbox');
            return;
        }

        const isLanguageSelected = languages.some(language => language.selectedOption === selectedOption);

        if (isLanguageSelected) {
            setError('Language already selected');
            return;
        }

        const newLanguage = {
            selectedOption,
            checkboxes: [...checkboxes],
        };

        const newCheckboxes = {
            selectedOption,
            checkboxes: [...checkboxes],
        };

        const storedLanguages = localStorage.getItem('languages');
        let updatedLanguages = [];
        if (storedLanguages) {
            updatedLanguages = JSON.parse(storedLanguages);
        }
        updatedLanguages.push(newLanguage);
        localStorage.setItem('languages', JSON.stringify(updatedLanguages));

        const storedCheckboxes = localStorage.getItem('checkboxes');
        let updatedCheckboxes = [];
        if (storedCheckboxes) {
            updatedCheckboxes = JSON.parse(storedCheckboxes);
        }
        updatedCheckboxes.push(newCheckboxes);
        localStorage.setItem('checkboxes', JSON.stringify(updatedCheckboxes));

        // Update the entries state
        const addedlanguage = [...addedlanguage, newCheckboxes];
        setAddedlanguage(addedlanguage);

        // Update the section data with the new entries
        setSectionData((prevData) => ({
            ...prevData,
            addedlanguage: addedlanguage,
        }));

        // Store the updated section data in local storage
        localStorage.setItem('thirdPageData', JSON.stringify(sectionData));

        setLanguages(prevLanguages => [...prevLanguages, newLanguage]);
        setSelectedOption('')
        setAddedlanguage('');
        setCheckboxes(Array(16).fill(false));
        setError('');
        setCheckboxError('')
    };

    const handleDeleteLanguage = (language) => {
        setLanguages((prevLanguages) =>
            prevLanguages.filter(
                (lang) => lang.selectedOption !== language.selectedOption
            )
        );
        setSelectedOption(''); // Clear the selectedOption field
    };

    useEffect(() => {
        const storedSectionData = localStorage.getItem('thirdPageData');
        console.log("get thirdPageData in section 3", storedSectionData);
        if (storedSectionData) {
            const parsedSectionData = JSON.parse(storedSectionData);
            setSelectedOption(parsedSectionData.selectedOption);
            setCheckboxes(parsedSectionData.checkboxes);
            setAddedlanguage(parsedSectionData.addedlanguage);
            setSelectLanguage(parsedSectionData.native_language);
            setCheckboxes(Array(16).fill(false));
            setCheckboxes(parsedSectionData.checkboxes || Array(16).fill(false));
            setSelectLanguage(parsedSectionData.selectlanguage);
            setSectionData(parsedSectionData);
        }
    }, []);

    const handleCloseErrorModal = () => {

        // Hide the error modal
        setErrorModalData({
            title: "",
            message: "",
            show: false,
        });
    };

    const LanguageSection = ({ language, handleDeleteLanguage }) => {
        const { selectedOption, checkboxes } = language;
        const selectedLanguage = options.find(
            (option) => option.value === selectedOption
        );

        const handleRemoveLanguage = () => {
            handleDeleteLanguage(language);
        };

        return (
            <SectionComponent>
                <p>SELECTED LANGUAGE:   <strong>{selectedLanguage ? selectedLanguage.label : ''}</strong></p>

                <div style={{ marginBottom: '8px', marginTop: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className='lang-list-title list-title1' style={{ width: '260px', textAlign: 'center' }}></div>
                        <div className='lang-list-title list-title2' style={{ width: '25%', textAlign: 'center' }}>Fluent</div>
                        <div className='lang-list-title list-title3' style={{ width: '25%', textAlign: 'center', paddingLeft: '5px' }}>Professional</div>
                        <div className='lang-list-title list-title4' style={{ width: '25%', textAlign: 'center', paddingLeft: '25px' }}>Elementary</div>
                        <div className='lang-list-title list-title5' style={{ width: '25%', textAlign: 'center', paddingLeft: '20px' }}>Novice</div>
                    </div>
                    <br />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '5px 10px' }}>
                    <div className='lang-list' style={{ marginRight: '16px', width: '200px' }}>Understanding</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
                        {checkboxes.slice(0, 4).map((isChecked, index) => (
                            <CheckboxComponent
                                key={index}
                                checked={isChecked}
                                onChange={(isChecked) => handleCheckboxChange(index, isChecked)}
                            />
                        ))}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '5px 10px' }}>
                    <div className='lang-list' style={{ marginRight: '16px', width: '200px' }}>Reading</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
                        {checkboxes.slice(4, 8).map((isChecked, index) => (
                            <CheckboxComponent
                                key={index + 4}
                                checked={isChecked}
                                onChange={(isChecked) => handleCheckboxChange(index + 4, isChecked)}
                            />
                        ))}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '5px 10px' }}>
                    <div className='lang-list' style={{ marginRight: '16px', width: '200px' }}>Speaking</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
                        {checkboxes.slice(8, 12).map((isChecked, index) => (
                            <CheckboxComponent
                                key={index + 8}
                                checked={isChecked}
                                onChange={(isChecked) => handleCheckboxChange(index + 8, isChecked)}
                            />
                        ))}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '5px 10px' }}>
                    <div className='lang-list' style={{ marginRight: '16px', width: '200px' }}>Writing</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
                        {checkboxes.slice(12, 16).map((isChecked, index) => (
                            <CheckboxComponent
                                key={index + 12}
                                checked={isChecked}
                                onChange={(isChecked) => handleCheckboxChange(index + 12, isChecked)}
                            />
                        ))}
                    </div>
                </div>
                <div className='remove-lang-btn' style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '20px' }}>
                    <CustomButton variant="contained" color="primary" onClick={handleRemoveLanguage}>
                        Remove language
                    </CustomButton>
                </div>
            </SectionComponent>
        );
    };

    return (
        <Page>
            <FirstSection >
                <h4>Language Proficiency</h4>
            </FirstSection >
            <SectionComponent>
            NATIVE LANGUAGE*
                <br />
                <Dropdown
                className="dropdown-compo"
                    label="Select language"
                    value={selectedOption}
                    options={language.map((language) => ({
                        value: language.value,
                        label: language.label,
                    }))}
                    onChange={handleLanguageChange}
                    onBlur={handlelanguageBlur}
                />
                {error && <div className="error-msg">{error}</div>}
                <br />
                <br />
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className='lang-list-title list-title1' style={{ width: '260px', textAlign: 'center' }}></div>
                        <div className='lang-list-title list-title2' style={{ width: '25%', textAlign: 'center' }}>Fluent</div>
                        <div className='lang-list-title list-title3' style={{ width: '25%', textAlign: 'center', paddingLeft: '5px' }}>Professional</div>
                        <div className='lang-list-title list-title4' style={{ width: '25%', textAlign: 'center', paddingLeft: '25px' }}>Elementary</div>
                        <div className='lang-list-title list-title5' style={{ width: '25%', textAlign: 'center', paddingLeft: '20px' }}>Novice</div>
                    </div>
                    <br />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '5px 10px' }}>
                    <div className='lang-list' style={{ marginRight: '16px', width: '200px' }}>Understanding</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>

                        {checkboxes.slice(0, 4).map((isChecked, index) => (
                            <CheckboxComponent
                                key={index}
                                checked={isChecked}
                                onChange={(isChecked) => handleCheckboxChange(index, isChecked)}
                            />
                        ))}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '5px 10px' }}>
                    <div className='lang-list' style={{ marginRight: '16px', width: '200px' }}>Reading</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
                        {checkboxes.slice(4, 8).map((isChecked, index) => (
                            <CheckboxComponent
                                key={index + 4}
                                checked={isChecked}
                                onChange={(isChecked) => handleCheckboxChange(index + 4, isChecked)}
                            />
                        ))}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '5px 10px' }}>
                    <div className='lang-list' style={{ marginRight: '16px', width: '200px' }}>Speaking</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
                        {checkboxes.slice(8, 12).map((isChecked, index) => (
                            <CheckboxComponent
                                key={index + 8}
                                checked={isChecked}
                                onChange={(isChecked) => handleCheckboxChange(index + 8, isChecked)}
                            />
                        ))}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '5px 10px' }}>
                    <div className='lang-list' style={{ marginRight: '16px', width: '200px' }}>Writing</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
                        {checkboxes.slice(12, 16).map((isChecked, index) => (
                            <CheckboxComponent
                                key={index + 12}
                                checked={isChecked}
                                onChange={(isChecked) => handleCheckboxChange(index + 12, isChecked)}
                            />
                        ))}
                    </div>
                </div>
                <br />
                {checkboxerror && <div className="error-msg">{checkboxerror}</div>}
                <div className='add-lang-btn' style={{ paddingTop: '20px' }}>
                    <CustomButton variant="contained" color="primary" onClick={handleAddLanguage}>
                        Add language
                    </CustomButton>
                </div>
            </SectionComponent>
            <>
                {languages.map((language, index) => (
                    <LanguageSection key={index} language={language} handleDeleteLanguage={handleDeleteLanguage} />
                ))}
            </>
            <Action backLink={previousPageURL}
                onClickNext={handleNextClick}
                onClearForm={handleClearForm}
            />
            <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
                <Ticket variant="contained" color="secondary" section="PRO: Application Form - Language Proficiency" />
            </div>
            <CustomModal
                show={errorModalData.show}
                onHide={handleCloseErrorModal}
                titleStyle={{ fontWeight: "bold" }}
                title={errorModalData.title}
                message={errorModalData.message}
            />
        </Page>
    )
}

export default LanguageProficiency