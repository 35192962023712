import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Intro from './section-components/intro-v3';
import About from './section-components/about';
import History from './section-components/history';
import Team from './section-components/team';
import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';
import { fetchAboutData } from '../api';

const AboutPage = () => {
  const [aboutData, setAboutData] = useState([]);
  const history = useHistory();
  const headerImage = localStorage.getItem("aboutimage");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAboutData()
      .then((data) => {
        // Process and set your data here
        let json = JSON.parse(data.aboutUs[0].description)
        setAboutData(JSON.stringify(json));
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error in AboutPage:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => unlisten();
  }, [history]);

  // Render the components only when the data is available and loading is finished
  if (!aboutData) {
    return null; // Or you can render a loading spinner or skeleton components if desired
  }

  return (
    <div>
      <Navbar />
      <PageHeader image={headerImage} headertitle="About Us" />
      <Intro aboutData={aboutData} />
      <About aboutData={aboutData} />
      {/* <History aboutData={aboutData} /> */}
      <Team />
      <Subscribe />
      <Footer />
    </div>
  );
};

export default AboutPage;
