import React, { useState, useEffect } from "react";
// import styled from 'styled-components';
import Navbar from "../global-components/navbar";
import Subscribe from "./subscribe";
import Footer from "../global-components/footer";
// import Treatments from './treatments1';
import { Link, useLocation, useHistory } from "react-router-dom";
import PageHeader from "../global-components/page-header";
import TreatmentBlogSkeleton2 from "../skeleton/TreatmentBlogSkeleton2";
import { fetchSubsubTreatmentsData } from '../../api';

function subgroup1(props) {
  const subtreatmentId = localStorage.getItem("subtreatment_id");
  const [subsubTreatments, setSubsubTreatments] = useState([]);
  const [loading, setLoading] = useState(true);
  const headerImage = localStorage.getItem("subtreatmentimage");
  const image = localStorage.getItem("image");
  const history = useHistory();

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  const imgsrc = publicUrl + "assets/img/bg/1.png";

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page on route change
    });
    return () => unlisten();
  }, [history]);

  useEffect(() => {
    fetchSubsubTreatmentsData(subtreatmentId)
        .then((data) => {
            setSubsubTreatments(data.data);
            console.log("++++++prinitng sub sub treatment data",data.data)
            console.log(subtreatmentId)
            setLoading(false);
        })
        .catch((error) => console.log("error", error));
}, [subtreatmentId]);

  // useEffect(() => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: JSON.stringify({ subtreatment_id: subtreatment_id }),
  //   };

  //   console.log("id:" + subtreatmentId);

  //   fetch(`${FindAllSubSubTreatment_api_url}`, requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setSubsubTreatments(data.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => console.log("error", error));
  // }, [subtreatmentId]);

  console.log("props:" + props);
  console.log("subsubTreatments:", subsubTreatments);

  const handleCLick = (subsubTreatment_id, header_image) => {
    localStorage.setItem("subsubTreatment_id", subsubTreatment_id);
    localStorage.setItem("subsubtreatmentimage", header_image);
    console.log("localstoarge subsub id:", subsubTreatment_id);
    console.log("localstoarge sub sub image:", header_image);
  };

  return (
    <div>
      <Navbar />

      <PageHeader image={headerImage} headertitle="" />

      {loading ? (
        <TreatmentBlogSkeleton2 />
      ) : (
        <>
          <div className="container mb-3 p-5 subtreatment-main-box">
            <div className="row">
              {subsubTreatments.map((subsubTreatment) => (
                <div
                  className="col-md-6 col-sm-12 box subtratment-sub-box"
                  style={styles.box}
                  key={subsubTreatment._id}
                >
                  <Link
                    to={{
                      pathname: `/treatment-details`,
                      state: {
                        subsubTreatment_id: subsubTreatment._id,
                        image: subsubTreatment.header_image,
                      },
                    }}
                    onClick={() =>
                      handleCLick(
                        subsubTreatment._id,
                        subsubTreatment.header_image
                      )
                    }
                  >
                    <div className="row">
                      <div className="col-sm-5 subsubtreatment-img">
                        <div className="single-blog single-blog-wrap subsubtreatment-card">
                          <div
                            className="img-thumbnail subgroup-img-card"
                            style={{ background: "#fff" }}
                          >
                            <img
                              className="subgroup-img"
                              src={subsubTreatment.image}
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-7 subsubtreatment-heading">
                        <div className="single-blog-details single-blog-right-wrap">
                          <h4 className="subsubtreatment-title">
                            {subsubTreatment.name}
                          </h4>
                          <p className="content">
                            {subsubTreatment.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>

          <Subscribe />
          <Footer />
        </>
      )}
    </div>
  );
}

const styles = {
  box: {
    textAlign: "center",
    boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.07)",
    borderRadius: "10px",
    padding: "30px",
    transition: "0.3s",
    // '&:hover': {
    //     color: 'red',
    //     borderBottom:'1px solid',
    //     borderColor:'red',
    //     transform: 'translateY(-5px);',
    //     },
  },
};

export default subgroup1;
