import { useState, useEffect } from "react";
import Button from "../Button";
// import Logo from "../Images/MTlogo.png";
import { auth, provider, sendEmailVerification, isEmailVerified } from './firebase.config';
import { signInWithPopup } from "firebase/auth";
import firebase from "firebase/compat/app";
import axios from "axios";
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { useAccordionButton } from "react-bootstrap";
import { Radio, FormControlLabel } from '@mui/material';
import { getToken } from "firebase/messaging";
import { messaging } from "../screens/firebase.config";
import CustomModal from "../../global-components/modal";
import data from "../../login-signup/screens/BlockedEmailDomains"
// import {MTLogo} from "../../../../public/assets/img/MedITravelS.png"
import { createPro, createPatient, createDoctor, signup, sendEmail, checkEmailExistence, loginUser, fetchPROData, fetchPRO2Data, fetchPatientData, fetchDoctorData, fetchDoctor2Data, updateUser } from '../../../api';

const Signup = () => {
  const [name, setname] = useState("");
  const [nameError, setNameError] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [DeviceToken, setdeviceTocken] = useState("");
  const [showEmailError, setshowEmailError] = useState(false);
  const [showPasswordError, setshowPasswordError] = useState(false);
  const [showConfirmPasswordError, setshowConfirmPasswordError] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [mailName, setMailName] = useState("");
  const isSocialLogin = false;
  const login_type = "Email";
  const socialToken = null;
  let history = useHistory();
  const userDataJSON = localStorage.getItem("user");
  console.log(userDataJSON);
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };
  let publicUrl = process.env.PUBLIC_URL + '/'
  const minscript = document.createElement("script");
  minscript.async = true;
  minscript.src = publicUrl + "assets/js/main.js";

  document.body.appendChild(minscript);
  let imgattr = 'logo'

  const validateName = (value) => {
    let error = '';

    if (!value) {
      error = 'Full Name is required';
    } else if (/[!@#$%^&*(),.?":{}|<>0-9]/.test(value)) {
      error = 'Special characters and numbers are not allowed';
    } else if (value.length < 2) {
      error = 'Full Name should be at least 2 characters long';
    } else if (/\d/.test(value)) {
      error = 'Numbers are not allowed';
    } else if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }

    setNameError(error);
  };

  const handlenameBlur = () => {
    setShowNameError(true);
  };

  const capitalizeFirstLetter = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    const capitalizedValue = capitalizeFirstLetter(value);
    setname(capitalizedValue);
    validateName(value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log(permission)
      const token = await getToken(messaging, {
        vapidKey: "BEsOY7yPQDo7kE_6uV1b_vcSgYw_SwKNKHZvM3Blp3-TIxnQdb_4c1QLpN9fjg5osCbPB_ad52mvpRF_2UTiVKI",
      });

      localStorage.setItem("DeviceTocken", selectedRole)
      setdeviceTocken(token)
      // Send this token  to server ( db)
    } else if (permission === "denied") {
      showErrorModal("Alert", "You denied for the notification");
    }
  }
  const showMessaging = () => {
    messaging.onMessage((messages) => {
      console.log(messages)
      showErrorModal("Alert", `Title ${messages.notification.title} Body:${messages.notification.body}`);
    })
  }

  useEffect(() => {
    requestPermission()
    showMessaging()
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };


  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setemail(emailValue);
    setEmailError(!validateEmail(emailValue));
  };

  const handlePasswordChange = (event) => {
    const passwordValue = event.target.value;
    setpassword(passwordValue);
    setPasswordError(passwordValue.length < 8);
    setConfirmPasswordError(
      confirm_password && confirm_password !== passwordValue
    );
  };

  const handleConfirmPasswordChange = (event) => {
    const confirmPasswordValue = event.target.value;
    setconfirm_password(confirmPasswordValue);
    setConfirmPasswordError(
      password && password !== confirmPasswordValue
    );
  };

  const handleEmailBlur = () => {
    setshowEmailError(true);
  };

  const handlePasswordBlur = () => {
    setshowPasswordError(true);
  };

  const handleConfirmPasswordBlur = () => {
    setshowConfirmPasswordError(true);
  };

  useEffect(() => {
    // Get the selected role from localStorage
    const storedRole = localStorage.getItem('selectedRole');
    if (storedRole) {
      setSelectedRole(storedRole);
    }
  }, []);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleRoleSelect = async () => {
    if (!selectedRole) {
      showErrorModal("Alert", "Please select a role.");
      return;
    }
    setShowModal(false);

    const dataToUpdate = {
      role: selectedRole,
    };

    try {
      const updated = await updateUser(userData.id, dataToUpdate);
      if (updated) {
        localStorage.setItem("userrole", selectedRole);

        // Redirect to the appropriate page based on the selected role
        if (selectedRole === "PRO") {
          history.push("/pro/prodashboard");
          const user = JSON.parse(localStorage.getItem("user"));
          const userId = user ? user.id : null;
          if (userId) {
            await createPro(userId);
          }
        } else if (selectedRole === "Patient") {
          history.push("/patient/patientdashboard");
          // window.location.href = "/patient/patientdashboard";
          const user = JSON.parse(localStorage.getItem("user"));
          const userId = user ? user.id : null;
          if (userId) {
            await createPatient(userId);
          }
        } else if (selectedRole === "Doctor") {
          history.push("/doc/docdashboard");
          const user = JSON.parse(localStorage.getItem("user"));
          const userId = user ? user.id : null;
          if (userId) {
            await createDoctor(userId);
          }
        } else {
          // Handle invalid or no role selection
          showErrorModal("Alert", "Invalid role selected. Redirecting to the home page.");
          history.push("/");
        }
      };
    } catch (error) {
      console.log("Error:", error);
      // Handle the error here or show an error message to the user
    }
  };

  const handleSubmit = async (value) => {
    value.preventDefault();

    console.log(DeviceToken)

    if (!name || !email || !password || !confirm_password) {
      showErrorModal("Alert", "Please fill in all the fields.");
      return;
    }
    if (emailError || passwordError || confirmPasswordError) {
      showErrorModal("Alert", "Please correct the errors in the form.");
      return;
    }
    if (!selectedRole) {
      showErrorModal("Alert", "Please select a role.");
      return;
    }

    const isEmailExists = await checkEmailExistence(email);

    if (isEmailExists) {
      showErrorModal("Alert", "This email is already in use by another user. Please login with this email or use a different email.");
      return;
    }

    const usersData = {
      name: name,
      email: email,
      password: password,
      confirm_password: confirm_password,
      isSocialLogin: isSocialLogin,
      login_type: "email",
      socialToken: socialToken,
      role: selectedRole,
      DeviceToken: DeviceToken,
    };

    try {
      setIsLoading(true);
      const response = await signup(usersData);
      if (response.status === 200) {
        const data = response.data;
        // showErrorModal("Alert", "Signup Successfully.");
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data.data));
        const userEmail = data.data.email;

        const userCredential = await auth.createUserWithEmailAndPassword(email, password);
        const user = userCredential.user;
        // Send email verification link

        await sendEmailVerification(user);

        if (selectedRole === "PRO") {
          history.push("/pro/prodashboard");
          const user = JSON.parse(localStorage.getItem("user"));
          const userId = user ? user.id : null;
          if (userId) {
            await createPro(userId);
          }
        } else if (selectedRole === "Patient") {
          history.push("/patient/patientdashboard");
          // window.location.href = "/patient/patientdashboard";
          const user = JSON.parse(localStorage.getItem("user"));
          const userId = user ? user.id : null;
          if (userId) {
            await createPatient(userId);
          }
        } else if (selectedRole === "Doctor") {
          history.push("/doc/docdashboard");
          const user = JSON.parse(localStorage.getItem("user"));
          const userId = user ? user.id : null;
          if (userId) {
            await createDoctor(userId);
          }
        }
        const emailResponse = await sendEmail(email, 'Welcome to MediTravels!', emailLayout);

      } else {
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      console.log(error);
      showErrorModal("Alert", "An error occurred while trying to sign up. Please try again later.");
    } finally {
      setIsLoading(false); // Set loading to false after API request completes
    }
  };

  const GoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const email = result.user.email;
      setMailName(result.user.displayName);
      const isEmailExists = await checkEmailExistence(email);
      const emailLayoutSocial = `
      <div style="max-width: 100%; margin: 0 auto; font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6;">
        <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; text-align: center;">
          <img src="https://meditravels.s3.amazonaws.com/Logo/952d4079-478e-44ce-92e8-f8e03e84852d-sticky-logo.png" alt="MediTravels Logo" style="max-width: 20%; height: auto;" />
          <p style="text-align: left; margin: 5px 0;">Dear ${result.user.displayName},</p>
          <p style="text-align: left;">Thank you for signing up with MediTravels. We are delighted to welcome you to our healthcare community. With MediTravels, you'll have access to a world of medical expertise, personalized care, and a wide network of healthcare providers. If you have any questions or need assistance, please don't hesitate to reach out to our dedicated support team.</p>
          <p style="text-align: left; margin: 5px 0 2px;">Best regards,</p>
          <p style="text-align: left; margin: 0px;">MeditravelS Team</p>
        </div>
      </div>`;

      if (!isEmailExists) {
        // User doesn't exist, create a new user and prompt for role selection
        const { displayName, email, phoneNumber, photoURL } = result.user;
        console.log("Data to be sent to the API: ", result);
        const usersData = {
          name: displayName,
          email: email,
          mobile_number: phoneNumber,
          profile_picture: photoURL,
          isSocialLogin: true,
          role: selectedRole,
          login_type: "google",
          socialToken: result.user.accessToken,
        };

        const response = await signup(usersData);

        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        const sendMailResponse = await sendEmail(email, 'Welcome to MediTravels!', emailLayoutSocial);


        // Show the modal for role selection if no role is selected
        if (!selectedRole) {
          setShowModal(true);
          return;
        }

        switch (selectedRole) {
          case "PRO":
            history.push("/pro/prodashboard");
            const user = JSON.parse(localStorage.getItem("user"));
            console.log(user.role);
            const userId = user ? user.id : null;
            if (userId) {
              await createPro(userId);
            }
            setShowModal(false);
            break;

          case "Patient":
            history.push("/patient/patientdashboard");
            const patientUser = JSON.parse(localStorage.getItem("user"));
            const patientUserId = patientUser ? patientUser.id : null;
            if (patientUserId) {
              await createPatient(patientUserId);
            }
            setShowModal(false);
            break;

          case "Doctor":
            history.push("/doc/docdashboard");
            const doctorUser = JSON.parse(localStorage.getItem("user"));
            const doctorUserId = doctorUser ? doctorUser.id : null;
            if (doctorUserId) {
              await createDoctor(doctorUserId);
            }
            setShowModal(false);
            break;

          default:
            // Handle unknown role
            break;
        }
      } else {
        console.log("printing from else conditon.....")
        const credentials = {
          email: email,
          password: "",
          isSocialLogin: true,
          login_type: "google",
          socialToken: result.user.accessToken,
        };

        const loginResponse = await loginUser(credentials);
        if (loginResponse.status === 200) {
          const data = loginResponse.data;
          // alert("You're logged in successfully with your existing email account.");
          localStorage.setItem("token", data.token);
          localStorage.setItem("user", JSON.stringify(data.userObj));

          // Check the role and redirect accordingly
          const role = data.userObj.role;
          if (role === "PRO") {
            try {
              const proData = await fetchPROData(data.userObj.id);
              const pro2Data = await fetchPRO2Data(data.userObj.id);
              if (proData || pro2Data) {

                // Store the PRO data in local storage
                localStorage.setItem("proData", JSON.stringify(proData));
                localStorage.setItem("proId", proData._id);
                
                const firstPageData = proData.section_data[0];
                const secondPageData = proData.section_data[1];
                const thirdPageData = proData.section_data[2];
                const fourthPageData = proData.section_data[3];
                const fifthPageData = proData.section_data[4];
                if (firstPageData) {
                  const modifiedFirstPageData = { ...firstPageData[0] };
                  localStorage.setItem(
                    "firstPageData",
                    JSON.stringify(modifiedFirstPageData)
                  );
                }
                if (secondPageData) {
                  const modifiedSecondPageData = { ...secondPageData[1] };
                  localStorage.setItem(
                    "secondPageData",
                    JSON.stringify(modifiedSecondPageData)
                  );
                }
                if (thirdPageData) {
                  const modifiedThirdPageData = { ...thirdPageData[2] };
                  localStorage.setItem(
                    "thirdPageData",
                    JSON.stringify(modifiedThirdPageData)
                  );
                }
                if (fourthPageData) {
                  const modifiedFourthPageData = { ...fourthPageData[3] };
                  localStorage.setItem(
                    "fourthPageData",
                    JSON.stringify(modifiedFourthPageData)
                  );
                }
                if (fifthPageData) {
                  const modifiedFifthPageData = { ...fifthPageData[4] };
                  localStorage.setItem(
                    "fifthPageData",
                    JSON.stringify(modifiedFifthPageData)
                  );
                }
                // Store the PRO data in local storage
                localStorage.setItem("proData2", JSON.stringify(pro2Data));
                localStorage.setItem("pro2Id", pro2Data._id);
                const firstPageData2 = pro2Data.section_data[0];
                const secondPageData2 = pro2Data.section_data[1];
                const thirdPageData2 = pro2Data.section_data[2];
                const fourthPageData2 = pro2Data.section_data[3];
                const fifthPageData2 = pro2Data.section_data[4];
                const sixthPageData2 = pro2Data.section_data[5];
                const seventhPageData2 = pro2Data.section_data[6];
                if (firstPageData2) {
                  const modifiedFirstPageData2 = { ...firstPageData2[0] };
                  localStorage.setItem(
                    "firstPageData2",
                    JSON.stringify(modifiedFirstPageData2)
                  );
                }
                if (secondPageData2) {
                  const modifiedSecondPageData2 = { ...secondPageData2[1] };
                  localStorage.setItem(
                    "secondPageData2",
                    JSON.stringify(modifiedSecondPageData2)
                  );
                }
                if (thirdPageData2) {
                  const modifiedThirdPageData2 = { ...thirdPageData2[2] };
                  localStorage.setItem(
                    "thirdPageData2",
                    JSON.stringify(modifiedThirdPageData2)
                  );
                }
                if (fourthPageData2) {
                  const modifiedFourthPageData2 = { ...fourthPageData2[3] };
                  localStorage.setItem(
                    "fourthPageData2",
                    JSON.stringify(modifiedFourthPageData2)
                  );
                }
                if (fifthPageData2) {
                  const modifiedFifthPageData2 = { ...fifthPageData2[4] };
                  localStorage.setItem(
                    "fifthPageData2",
                    JSON.stringify(modifiedFifthPageData2)
                  );
                }
                if (sixthPageData2) {
                  const modifiedSixthPageData2 = { ...sixthPageData2[5] };
                  localStorage.setItem(
                    "sixthPageData2",
                    JSON.stringify(modifiedSixthPageData2)
                  );
                }
                if (seventhPageData2) {
                  const modifiedSeventhPageData2 = { ...seventhPageData2[6] };
                  localStorage.setItem(
                    "seventhPageData2",
                    JSON.stringify(modifiedSeventhPageData2)
                  );
                }
              } else {
                throw new Error("Failed to fetch PRO data.");
              }
            } catch (error) {
              console.log(error);
              // alert(error.message);
            }
            history.push("/pro/prodashboard");
          } else if (role === "Patient") {
            try {
              const patientData = await fetchPatientData(data.userObj.id);

              if (patientData) {
                localStorage.setItem("patientData", JSON.stringify(patientData));
                localStorage.setItem("patientId", patientData.data._id);

                // Store the PRO data in local storage
                localStorage.setItem("patientData", JSON.stringify(patientData));
                localStorage.setItem("patientId", patientData._id);
                localStorage.setItem("Patientphone", patientData.section_data[1][1].phone);
                localStorage.setItem("patientSidebar", 'form')
                const patientfirstPageData = patientData.section_data[0];
                const patientsecondPageData = patientData.section_data[1];
                const patientthirdPageData = patientData.section_data[2];
                if (patientfirstPageData) {
                  const modifiedPatientFirstPageData = {
                    ...patientfirstPageData[0],
                  };
                  localStorage.setItem(
                    "patientfirstPageData",
                    JSON.stringify(modifiedPatientFirstPageData)
                  );
                }
                if (patientsecondPageData) {
                  const modifiedPatientSecondPageData = {
                    ...patientsecondPageData[1],
                  };
                  localStorage.setItem(
                    "patientsecondPageData",
                    JSON.stringify(modifiedPatientSecondPageData)
                  );
                }
                if (patientthirdPageData) {
                  const modifiedPatientthirdPageData = {
                    ...patientthirdPageData[1],
                  };
                  localStorage.setItem(
                    "patientthirdPageData",
                    JSON.stringify(modifiedPatientthirdPageData)
                  );
                }
              } else {
                throw new Error("Failed to fetch patient data.");
              }
            } catch (error) {
              console.log(error);
              // alert(error.message);
            }
            history.push("/patient/patientdashboard");
          } else if (role === "Doctor") {
            try {
              const docData = await fetchDoctorData(data.userObj.id);
              const doc2Data = await fetchDoctor2Data(data.userObj.id);
              if (docData || doc2Data) {

                // Store the PRO data in local storage
                localStorage.setItem("docData", JSON.stringify(docData));
                localStorage.setItem("docId", docData._id);
                const docfirstPageData = docData.section_data[0];
                const docsecondPageData = docData.section_data[1];
                const docthirdPageData = docData.section_data[2];
                const docfourthPageData = docData.section_data[3];
                const docfifthPageData = docData.section_data[4];
                const docsixthPageData = docData.section_data[5];
                if (docfirstPageData) {
                  const modifiedDocFirstPageData = { ...docfirstPageData[0] };
                  localStorage.setItem(
                    "docfirstPageData",
                    JSON.stringify(modifiedDocFirstPageData)
                  );
                }
                if (docsecondPageData) {
                  const modifiedDocSecondPageData = { ...docsecondPageData[1] };
                  localStorage.setItem(
                    "docsecondPageData",
                    JSON.stringify(modifiedDocSecondPageData)
                  );
                }
                if (docthirdPageData) {
                  const modifiedDocThirdPageData = { ...docthirdPageData[2] };
                  localStorage.setItem(
                    "docthirdPageData",
                    JSON.stringify(modifiedDocThirdPageData)
                  );
                }
                if (docfourthPageData) {
                  const modifiedDocFourthPageData = { ...docfourthPageData[3] };
                  localStorage.setItem(
                    "docfourthPageData",
                    JSON.stringify(modifiedDocFourthPageData)
                  );
                }
                if (docfifthPageData) {
                  const modifiedDocFifthPageData = { ...docfifthPageData[4] };
                  localStorage.setItem(
                    "docfifthPageData",
                    JSON.stringify(modifiedDocFifthPageData)
                  );
                }
                if (docsixthPageData) {
                  const modifiedDocSixthPageData = { ...docsixthPageData[4] };
                  localStorage.setItem(
                    "docsixthPageData",
                    JSON.stringify(modifiedDocSixthPageData)
                  );
                }
                // Store the PRO data in local storage
                localStorage.setItem("docData2", JSON.stringify(doc2Data));
                localStorage.setItem("doc2Id", doc2Data._id);
                const docfirstPageData2 = doc2Data.section_data[0];
                const docsecondPageData2 = doc2Data.section_data[1];
                const docthirdPageData2 = doc2Data.section_data[2];
                const docfourthPageData2 = doc2Data.section_data[3];
                if (docfirstPageData2) {
                  const modifiedFirstPageData2 = { ...docfirstPageData2[0] };
                  localStorage.setItem(
                    "docfirstPageData2",
                    JSON.stringify(modifiedFirstPageData2)
                  );
                }
                if (docsecondPageData2) {
                  const modifiedSecondPageData2 = { ...docsecondPageData2[1] };
                  localStorage.setItem(
                    "docsecondPageData2",
                    JSON.stringify(modifiedSecondPageData2)
                  );
                }
                if (docthirdPageData2) {
                  const modifiedThirdPageData2 = { ...docthirdPageData2[2] };
                  localStorage.setItem(
                    "docthirdPageData2",
                    JSON.stringify(modifiedThirdPageData2)
                  );
                }
                if (docfourthPageData2) {
                  const modifiedFourthPageData2 = { ...docfourthPageData2[3] };
                  localStorage.setItem(
                    "docfourthPageData2",
                    JSON.stringify(modifiedFourthPageData2)
                  );
                }
              } else {
                throw new Error("Failed to fetch PRO data.");
              }
            } catch (error) {
              console.log(error);
              // alert(error.message);
            }
            history.push("/doc/docdashboard");
          } else {
            // Handle invalid role selection
            setShowModal(true);
          }
        } else if (loginResponse.status === 401) {
          showErrorModal("Alert", "Invalid email or password.");
        } else if (loginResponse.status === 400) {
          throw new Error("User not found. Please sign up.");
        } else {
          throw new Error("Something went wrong!");
        }
      }
    } catch (error) {
      console.error(error);
      showErrorModal("Alert", error.message);
    }
  };

  const emailLayout = `
  <div style="max-width: 100%; margin: 0 auto; font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6;">
    <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; text-align: center;">
      <img src="https://meditravels.s3.amazonaws.com/Logo/952d4079-478e-44ce-92e8-f8e03e84852d-sticky-logo.png" alt="MediTravels Logo" style="max-width: 20%; height: auto;" />
      <p style="text-align: left; margin: 5px 0;">Dear ${name},</p>
      <p style="text-align: left;">Thank you for signing up with MediTravels. We are delighted to welcome you to our healthcare community. With MediTravels, you'll have access to a world of medical expertise, personalized care, and a wide network of healthcare providers. If you have any questions or need assistance, please don't hesitate to reach out to our dedicated support team.</p>
      <p style="text-align: left; margin: 5px 0 2px;">Best regards,</p>
      <p style="text-align: left; margin: 0px;">MeditravelS Team</p>
    </div>
  </div>`;

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <div className="main-box">
      <div className="split left">
        <div className="splitleft">
          <form>
            <div className="wlc">New User?</div>
            <div className="wlcback">Please enter your details.</div>
            <div className="textborder">
              <div className="fname signintext">
                <label> Full Name<span className="required">*</span></label>
                <div className="inputcontainer">
                  <input
                    type="text"
                    id="fname"
                    name="name"
                    value={name}
                    placeholder="Enter your full name"
                    onChange={handleNameChange}
                    onBlur={handlenameBlur}
                  ></input>
                </div>
                {nameError && showNameError && <p className="error">{nameError}</p>}
              </div>
              <div className="semail signintext">
                <label>Email<span className="required">*</span></label>
                <div className="inputcontainer">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="Enter your email"
                    onBlur={handleEmailBlur}
                    onChange={handleEmailChange}
                  ></input>
                </div>
                {emailError && showEmailError && (<p className="error">Please enter a valid email</p>)}
              </div>
              <div className="password signintext">
                <label>Password<span className="required">*</span></label>
                <div className="inputcontainer">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    value={password}
                    placeholder="Enter your password"
                    onBlur={handlePasswordBlur}
                    onChange={handlePasswordChange}
                  ></input>
                  <i
                    className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'} eye-btn`}
                    onClick={handlePasswordToggle}
                    style={{ marginRight: "10px", marginTop: "-27px", position: "absolute", marginLeft: "330px" }}
                  />
                </div>
                {passwordError && showPasswordError && (<p className="error">Password must be 8 characters long</p>)}
              </div>
              <div className="conpassword signintext">
                <label> Confirm Password<span className="required">*</span></label>
                <div className="inputcontainer">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="con-password"
                    name="confirm_password"
                    value={confirm_password}
                    placeholder="Confirm your password"
                    onBlur={handleConfirmPasswordBlur}
                    onChange={handleConfirmPasswordChange}
                  ></input>
                  <i
                    className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'} eye-btn`}
                    onClick={handlePasswordToggle}
                    style={{ marginRight: "10px", marginTop: "-27px", position: "absolute", marginLeft: "330px" }}
                  />
                </div>
                {confirmPasswordError && showConfirmPasswordError && (<p className="error">Passwords do not match</p>)}
              </div>
            </div>

            <div className="role">
              <label>Select Role<span className="required">*</span></label>
              <div className="role-button">
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedRole === 'Doctor'}
                      onChange={handleRoleChange}
                      value="Doctor"
                      style={{ color: '#ffffff' }}
                    />
                  }
                  label="Doctor"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedRole === 'PRO'}
                      onChange={handleRoleChange}
                      value="PRO"
                      style={{ color: '#ffffff' }}
                    />
                  }
                  label="PRO"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedRole === 'Patient'}
                      onChange={handleRoleChange}
                      value="Patient"
                      style={{ color: '#ffffff' }}
                    />
                  }
                  label="Patient"
                />
              </div>
            </div>
            {/* )} */}
            <br></br>
            <Button
              className="btn-normal"
              type="submit"
              label="Sign up"
              disabled={isLoading}
              onClick={handleSubmit}>
              Sign up
            </Button>
            <Link className="center account" to="/signin">Already have an account?<span className="signuptext">&nbsp; Log in</span></Link>
            <button
              type="button"
              className="google-btn"
              onClick={GoogleLogin}
            ><Link><span className="btn-text">Sign up with Google</span></Link>
            </button>
          </form>
        </div>
      </div>
      <div className="split right">
        <div className="centeredimg">
          <Link to="/" className="main-logo">
            <img src={publicUrl + "assets/img/logo.png"} alt="logo" />
          </Link>
          {/* <div className="card" style={{boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2)" }}>
          <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
          <Link to="/" className="main-logo">
            <img src={publicUrl + "assets/img/logo.png"} alt="logo" />
          </Link>
        </div>
             <div className="card-body">
              <h5 className="card-title">Card title</h5>
             <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

             <div className="socials-iconss">
             <a className="facebook" href="https://www.facebook.com/codingeek.net/" target="_blank"><i className="fa fa-facebook"/></a>
             <a className="twitter" href="https://twitter.com/codingeeknet" target="_blank"><i className="fa fa-twitter"/></a>
             <a className="pinterest" href="https://www.instagram.com/codingeeknet/" target="_blank"><i className="fa fa-instagram" /></a>
            </div>

            </div>
          </div> */}
        </div>
      </div>
      <Modal show={showModal} className="newsletter-popup" backdrop="static">
        <Modal.Header>
          <Modal.Title>Select Your Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControlLabel
            control={
              <Radio
                checked={selectedRole === 'Doctor'}
                onChange={handleRoleChange}
                value="Doctor"
              />
            }
            label="Doctor"
          />
          <br />
          <FormControlLabel
            control={
              <Radio
                checked={selectedRole === 'PRO'}
                onChange={handleRoleChange}
                value="PRO"
              />
            }
            label="PRO"
          />
          <br />
          <FormControlLabel
            control={
              <Radio
                checked={selectedRole === 'Patient'}
                onChange={handleRoleChange}
                value="Patient"
              />
            }
            label="Patient"
          />
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-yellow" variant="secondary" onClick={handleRoleSelect} label="OK">Ok</Button>
          {/* <Button className="btn btn-yellow" variant="secondary" onClick={() => setShowModal(false)} label='Close'>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
      <Modal
        show={verifyEmail}
        onHide={() => setVerifyEmail(false)}
        className="newsletter-popup"
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <h4>Verification mail sent successfully!</h4>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-yellow"
            label="OK"
            onClick={() => setVerifyEmail(false)}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
      {/* <Modal
        show={verifyemailSuccess}
        onHide={() => setVerifyEmailSuccess(false)}
        className="newsletter-popup"
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <h3>Verified successfully!</h3>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-yellow"
            label="OK"
            onClick={() => setVerifyEmailSuccess(false)}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal> */}
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </div>
  );
};
export default Signup;