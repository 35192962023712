import React from "react";

const ParagraphSkeleton2 = () => {
  return (
    <div className="skeleton-loader3">
      <div className="skeleton-line3"></div>
      <div className="skeleton-line3"></div>
      <div className="skeleton-line3"></div>
      <div className="skeleton-line3"></div>
      <div className="skeleton-line3"></div>
      <div className="skeleton-line3"></div>
      <div className="skeleton-line3"></div>
      <div className="skeleton-line3"></div>
      <div className="skeleton-line3"></div>
      <div className="skeleton-line3"></div>
      <div className="skeleton-line3"></div>
      <div className="skeleton-line3"></div>
      <div className="skeleton-line3"></div>
    </div>
  );
};

export default ParagraphSkeleton2;
