import React, { useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, InputLabel, FormControl } from '@mui/material';


const YearPickerComponent = ({ value, onChange }) => {
  const maxDate = dayjs(); 

  const handleYearChange = (date) => {
    if (date) {
      onChange(date.year()); 
    }
  };

  const [isLabelFloating, setIsLabelFloating] = useState(false);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setIsLabelFloating(inputValue !== '');

  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <FormControl>
          <InputLabel htmlFor="year-input" 
                      shrink={isLabelFloating}>
            
          </InputLabel>
          <DatePicker
            views={['year']} 
            disableFuture
            maxDate={maxDate}
            value={value ? dayjs().year(value) : null} 
            onChange={handleYearChange}
            renderInput={(params) => (
              <TextField
                {...params}
                id="year-input"
                onChange={handleInputChange}
              />
            )}
          />
        </FormControl>

      </DemoContainer>
    </LocalizationProvider>
  );
}

export default YearPickerComponent;