import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Route, useLocation, useHistory,Redirect } from 'react-router-dom';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  useEffect(() => {
    const role = localStorage.getItem('userRole');
    if (role) {
      setUserRole(role);
      setIsAuthenticated(true);
    }
  }, [isLoggedOut]); // Include isLoggedOut in the dependencies

  const logout = () => {
    localStorage.removeItem('userRole');
    setIsAuthenticated(false);
    setIsLoggedOut(true); // Trigger a re-render after logout
  };

  return { isAuthenticated, userRole, logout }; 
};

const ProtectedRoute = ({ path, component: Component,allowedRoles, ...rest }) => {
  const [currentPath, setCurrentPath] = useState('/');
  const paths = ['/pro', '/doc', '/patient'];
  const locations = ['/pro', '/doc', '/patient'];
  const location = useLocation();
  const history = useHistory();
  const handleNextRef = useRef();
  const handleBackRef = useRef();
  const { isAuthenticated, userRole, isLoggedOut  } = useAuth();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    // useEffect(() => {
    //   if (isLoggedOut) {
    //     history.push('/'); // Redirect to homepage after logout
    //   }
    // }, [isLoggedOut]);

    const handlePopState = () => {
      history.replace(location.pathname);
    };

    handleNextRef.current = handleNext;
    // handleBackRef.current = handleBack;

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    // Add event listeners for next and back buttons
    const nextButton = document.getElementById('next-button');
    const backButton = document.getElementById('back-button');

    if (nextButton) {
      nextButton.addEventListener('click', handleNextClick);
    }

    if (backButton) {
      backButton.addEventListener('click', handleBackClick);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);

      // Remove event listeners for next and back buttons
      if (nextButton) {
        nextButton.removeEventListener('click', handleNextClick);
      }

      if (backButton) {
        backButton.removeEventListener('click', handleBackClick);
      }
    };
  }, [history, location.pathname]);

  useEffect(() => {
    // Get the path from the URL
    const currentPath = location.pathname;
    setCurrentPath(currentPath);
  }, [location]);

  //If the user is trying to navigate to a protected route from the home page, redirect them back to the home page
  if (currentPath === '/' && !locations.some((location) => location.match(currentPath))) {
    history.push('/');
  }

  // Prevent the user from redirecting to protected routes from the home page

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    if (currentPath === '/') {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [history, location.pathname]);

  const handleNext = () => {
    const currentPath = location.pathname;
    let nextPath;

    if (currentPath ==='/pro/prodashboard') {
      nextPath = '/pro/section1';
    } else if (currentPath === '/pro/section1') {
      nextPath = '/pro/section2';
    } else if (currentPath === '/pro/section2') {
      nextPath = '/pro/section3';
    } else if (currentPath === '/pro/section3') {
      nextPath = '/pro/section4';
    } else if (currentPath === '/pro/section4') {
      nextPath = '/pro/section5';
    } else if (currentPath === '/pro/section5') {
      nextPath = '/pro/submit-phase1';
    } else if (currentPath === '/pro/submit-phase1') {
      nextPath = '/pro/prodashboard';
    } else if (currentPath === '/pro/section1-2') {
      nextPath = '/pro/section2-2';
    } else if (currentPath === '/pro/section2-2') {
      nextPath = '/pro/section3-2';
    } else if (currentPath === '/pro/section3-2') {
      nextPath = '/pro/section4-2';
    } else if (currentPath === '/pro/section4-2') {
      nextPath = '/pro/section5-2';
    } else if (currentPath === '/pro/section5-2') {
      nextPath = '/pro/section6-2';
    } else if (currentPath === '/pro/section6-2') {
      nextPath = '/pro/section7-2';
    } else if (currentPath === '/pro/section7-2') {
      nextPath = '/pro/submit-phase2';
    } else if (currentPath === '/pro/submit-phase2') {
      nextPath = '/pro/prodashboard';
    } else if (currentPath === '/doc/section1') {
      nextPath = '/doc/section2';
    } else if (currentPath === '/doc/section2') {
      nextPath = '/doc/section3';
    } else if (currentPath === '/doc/section3') {
      nextPath = '/doc/section4';
    } else if (currentPath === '/doc/section4') {
      nextPath = '/doc/section5';
    } else if (currentPath === '/doc/section5') {
      nextPath = '/doc/submit-phase1';
    } else if (currentPath === '/doc/submit-phase1') {
      nextPath = 'doc/docdashboard';
    } else if (currentPath === '/doc/section1-2') {
      nextPath = '/doc/section2-2';
    } else if (currentPath === '/doc/section2-2') {
      nextPath = '/doc/section3-2';
    } else if (currentPath === '/doc/section3-2') {
      nextPath = '/doc/section4-2';
    } else if (currentPath === '/doc/section4-2') {
      nextPath = '/doc/submit-phase2';
    } else if (currentPath === '/doc/submit-phase2') {
      nextPath = '/doc/docdashboard';
    }
    //  else if (currentPath === '/patient/sec-1') {
    //   nextPath = '/patient/sec-2';
    // } else if (currentPath === '/patient/sec-2') {
    //   nextPath = '/patient/sec-3';
    // } else if (currentPath === '/patient/sec-3') {
    //   nextPath = '/patient/submit';
    // } else if (currentPath === '/patient/submit') {
    //   nextPath = '/patient/patientdashboard';
    // } 
    else {
      nextPath = '/'; // Default fallback path
    }
  
    history.push(nextPath);
  };
 
  const handleNextClick = () => {
    handleNextRef.current();
  };

  // const handleBack = () => {
  //   const currentPath = location.pathname;
  //   let previousPath;
  
  //   // Define the previous path based on the current path
  //   if (currentPath === '/pro/section1') {
  //     previousPath = '/'; // Go back to the homepage or the appropriate path
  //   } else if (currentPath === '/pro/section2') {
  //     previousPath = '/pro/section1';
  //   } else if (currentPath === '/pro/section3') {
  //     previousPath = '/pro/section2';
  //   } else if (currentPath === '/pro/section4') {
  //     previousPath = '/pro/section3';
  //   } else if (currentPath === '/pro/section5') {
  //     previousPath = '/pro/section4';
  //   } else if (currentPath === '/pro/submit-phase1') {
  //     previousPath = '/pro/section5';
  //   } else if (currentPath === '/pro/prodashboard') {
  //     previousPath = '/pro/submit-phase1';
  //   } else if (currentPath === '/pro/section1-2') {
  //     previousPath = '/pro/prodashboard'; // Go back to the appropriate path
  //   } else if (currentPath === '/pro/section2-2') {
  //     previousPath = '/pro/section1-2';
  //   } else if (currentPath === '/pro/section3-2') {
  //     previousPath = '/pro/section2-2';
  //   } else if (currentPath === '/pro/section4-2') {
  //     previousPath = '/pro/section3-2';
  //   } else if (currentPath === '/pro/section5-2') {
  //     previousPath = '/pro/section4-2';
  //   } else if (currentPath === '/pro/section6-2') {
  //     previousPath = '/pro/section5-2';
  //   } else if (currentPath === '/pro/section7-2') {
  //     previousPath = '/pro/section6-2';
  //   } else if (currentPath === '/pro/submit-phase2') {
  //     previousPath = '/pro/section7-2';
  //   } else if (currentPath === '/doc/section1') {
  //     previousPath = '/pro/submit-phase2'; // Go back to the appropriate path
  //   } else if (currentPath === '/doc/section2') {
  //     previousPath = '/doc/section1';
  //   } else if (currentPath === '/doc/section3') {
  //     previousPath = '/doc/section2';
  //   } else if (currentPath === '/doc/section4') {
  //     previousPath = '/doc/section3';
  //   } else if (currentPath === '/doc/section5') {
  //     previousPath = '/doc/section4';
  //   } else if (currentPath === '/doc/submit-phase1') {
  //     previousPath = '/doc/section5';
  //   } else if (currentPath === 'doc/docdashboard') {
  //     previousPath = '/doc/submit-phase1'; // Go back to the appropriate path
  //   } else if (currentPath === '/doc/section1-2') {
  //     previousPath = '/doc/docdashboard'; // Go back to the appropriate path
  //   } else if (currentPath === '/doc/section2-2') {
  //     previousPath = '/doc/section1-2';
  //   } else if (currentPath === '/doc/section3-2') {
  //     previousPath = '/doc/section2-2';
  //   } else if (currentPath === '/doc/section4-2') {
  //     previousPath = '/doc/section3-2';
  //   } else if (currentPath === '/doc/submit-phase2') {
  //     previousPath = '/doc/section4-2';
  //   } else if (currentPath === '/doc/docdashboard') {
  //     previousPath = '/doc/submit-phase2';
  //   } else if (currentPath === '/patient/sec-1') {
  //     previousPath = '/doc/docdashboard'; // Go back to the appropriate path
  //   } else if (currentPath === '/patient/sec-2') {
  //     previousPath = '/patient/sec-1';
  //   } else if (currentPath === '/patient/sec-3') {
  //     previousPath = '/patient/sec-2';
  //   } else if (currentPath === '/patient/submit') {
  //     previousPath = '/patient/sec-3';
  //   } else if (currentPath === '/patient/patientdashboard') {
  //     previousPath = '/patient/submit';
  //   } else {
  //     previousPath = '/'; // Default fallback path
  //   } 
  //   history.push(previousPath);
  // };

  const handleBackClick = () => {
    history.goBack(); // Navigate back to the previous page
  };

  useEffect(() => {
    const currentPathFromLocation = location.pathname;
    setCurrentPath(currentPathFromLocation);
  }, [location.pathname]); // Listen for changes to location.pathname


  // useEffect(() => {
  //   // Update the URL with the current path
  //   history.replace(location.pathname);
  // }, [history, location.pathname]);

  useEffect(() => {
    console.log('Location Pathname Changed:', location.pathname);
    // Update the URL with the current path
    history.replace(location.pathname);
  }, [history, location.pathname]);
  
  
  return (
    <Route
    {...rest}
    render={(props) => (
      isAuthenticated ? (
        <Component
          {...props}
          onNext={handleNext}
          onBack={handleBackClick}
          nextButtonId="next-button" 
          backButtonId="back-button" 
        />
      ) : (
        <Redirect to="/" />
      )
    )}
  />
);
};

export default ProtectedRoute;