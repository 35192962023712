import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';

function Footer_v1() {
	let publicUrl = process.env.PUBLIC_URL + '/'
	const minscript = document.createElement("script");
	minscript.async = true;
	minscript.src = publicUrl + "assets/js/main.js";

	document.body.appendChild(minscript);

	return (
		<footer className="footer-area" style={{ backgroundImage: 'url(' + publicUrl + 'assets/img/bg/2.png)' }}>
			<div className="container">
				<div className="row footer-row">
					<div className="col-lg-4 col-md-8">
						<div className="footer-widget widget">
							<div className="about_us_widget">
								<Link to="/" className="footer-logo">
									<img src={publicUrl + "assets/img/logo.png"} alt="footer logo" />
								</Link>
								<br></br>
								{/* <p>We believe brand interaction is key in commu- nication. Real innovations and a positive customer experience are the heart of successful communication.</p> */}
								<ul className="social-icon">
									<li>
										<a className="facebook" href="https://www.facebook.com/meditravels.in?mibextid=ZbWKwL" target="_blank"><i className="fa fa-facebook  " /></a>
									</li>
									<li>
										<a className="twitter" href="https://twitter.com/Meditravels_in" target="_blank"><i className="fa fa-twitter  " /></a>
									</li>
									<li>
										<a className="pinterest" href="https://www.instagram.com/codingeeknet/" target="_blank"><i className="fa fa-instagram" /></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-8">
						<div className="footer-widget widget ">
							<div className="widget-contact">
								<h4 className="widget-title">Contact us</h4>
								<p>
									<i className="fa fa-map-marker" />
									<span>Pushkardham Avenue - 2, Opp. Nagbai Temple, Morbi Road. Rajkot - 360003</span>
								</p>
								<p className="location">
									<i className="fa fa-envelope-o" />
									<span>
										<a href="mailto:admin@meditravels.in">admin@meditravels.in</a>
									</span>
								</p>
								<p className="telephone">
									<i className="fa fa-phone base-color" />
									<span>
										<a href="tel:+9199 135 136 84">+91  99 135 136 84</a>
									</span>
								</p>

							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-8">
						<div className="footer-widget widget">
							<h4 className="widget-title">Quick Link</h4>
							<ul className="widget_nav_menu  viaje-go-top">
								<li><Link to="/">Home</Link></li>
								<li><Link to="/about">About Us</Link></li>
								<li><Link to="/">Treament</Link></li>
								<li><Link to="/">Tourism</Link></li>
								<li><Link to="/blog">Blog</Link></li>
								<li><Link to="/contact">Contact Us</Link></li>
								<li><Link to="/testimonial">Testimonial</Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="copyright-inner">
				<div className="copyright-text">
					copyright © 2025 MediTravels - <Link to="/privacy-policy">Privacy Policy</Link> - <Link to="/terms-conditions">Terms and Conditions</Link> 
					{/* © Viaje 2019 All rights reserved. Powered with by <a href="https://codingeek.net/" target="_blank"><i className="fa fa-heart" /><span>Codingeek.</span></a> */}
				</div>
			</div>
		</footer>
	)
}

export default Footer_v1