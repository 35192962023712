import React, { useRef } from 'react';

const PageComponent = React.forwardRef(({ children, style }, ref) => {
  const pageStyle = {
    backgroundColor: '#f4a46029',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 0px',
    minHeight: '100vh',
    ...style, // Merge the additional styles with pageStyle
  };

  return <div ref={ref} style={pageStyle}>{children}</div>;
});

export default PageComponent;