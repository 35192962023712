import React from 'react';
import CommingSoon from '../section-components/comming-soon';


const Home_CS = () => {
    return <div>
        <CommingSoon />
    </div>
}

export default Home_CS

