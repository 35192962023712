import React from 'react';
import { Card, CardContent } from '@material-ui/core';

const SectionComponent = ({ children, style }) => {
  const sectionStyle = {
    backgroundColor: '#fff',
    border: '1px solid #dadce0',
    borderRadius: '8px',
    marginBottom: '12px',
    padding: '8px',
    width: '50%',
    overflow: 'unset',

  };
  const cardStyle = {
    ...style,
  }

  return (
    <Card className='card-component' style={sectionStyle}>
      <CardContent className='cardContent-component' style={cardStyle}>{children}</CardContent>
    </Card>
  );
};

export default SectionComponent;

{/* <SectionComponent> */ }
{/* Your content here */ }
{/* </SectionComponent> */ }