import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../pro-components/Page';
import SubmitAction from '../pro-components/submitaction';
import FirstSection from '../pro-components/FirstSection'
import CustomModal from "../../global-components/modal";
import { updatePatient, sendEmail, createPatientStatus, updateActiveTreatmentStatusAPI } from '../../../api';

const SubmitPage = () => {
  const history = useHistory();
  const [sectionData, setSectionData] = useState('');
  const userDataJSON = localStorage.getItem("user");
  console.log("user data from local storage", userDataJSON);
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const patientId = localStorage.getItem("patientId");
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const updateActiveTreatmentStatus = async () => {
    try {
      const userId = userData.id;
      const updateResponse = await updateActiveTreatmentStatusAPI(userId, { status: 'form_completed' });

      if (updateResponse) {
        console.log("active treatment data updated successfully");
        console.log("active treatment updated with response:", updateResponse);
      } else {
        console.log('Failed to update active treatment status');
      }
    } catch (error) {
      console.log('Error updating active treatment status:', error);
    }
  };

  const updateSectionData = async (sectionData) => {
    const patientId = localStorage.getItem("patientId");
    
    if (!patientId) {
      
      return;
    }
    try {
      const existingSectionData = JSON.parse(localStorage.getItem('patientfirstPageData'));
      const section_data = [
        {
          0: existingSectionData, // Include the first page data at index 0
        },
        {
          1: JSON.parse(localStorage.getItem('patientsecondPageData')), // Include the second page data at index 1
        },
        {
          2: JSON.parse(localStorage.getItem("patientthirdPageData"))
        },
        {
          3: sectionData, // Include the second page data at index 1
        },
      ]
     
      const updatedSectionData = await updatePatient(patientId, {section_data: section_data});
      if (updatedSectionData) {
        console.log("updated data of pro");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updateDoctor1.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitData = {
      is_submit: true,
    };
    await updateSectionData(submitData);
    console.log("proid and userid fromsubmit", patientId, userData.id)

    if (patientId && userData.id) {
      try {
        const patientStatusData = {
          user_id: userData.id,
          patient_id: patientId,
          is_submit: true,
          is_accepted: "pending",
          treatmentselect_status: "pending",
          consultation_status: "pending"
        };
        const response = await createPatientStatus(patientStatusData);
        console.log('^^^^API response:', response.data.data._id);
        localStorage.setItem('patientStatusId', response.data.data._id);
        console.log('^^^Created Patient Status ID:',response, response.data.data._id);
      } catch (error) {
        console.error('API error:', error);
      }
    } else {
      console.error('proId or userId not found in local storage');
    }
    await updateActiveTreatmentStatus();
    localStorage.setItem("patientSidebar", 'form-submit');
    localStorage.setItem('isSubmitpatient', true);
    showErrorModal("Submitted successfully");
    history.push('/patient/patientdashboard');

    const sendMailResponse = await sendEmail(userData.email, 'Confirmation: Your Patient Form Submission with MediTravels', emailLayout);

    console.log('Email sent successfully:', sendMailResponse.data);
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  const emailLayout = `
  <div style="max-width: 100%; margin: 0 auto; font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6;">
    <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; text-align: center;">
      <img src="https://meditravels.s3.amazonaws.com/Logo/952d4079-478e-44ce-92e8-f8e03e84852d-sticky-logo.png" alt="MediTravels Logo" style="max-width: 20%; height: auto;" />
      <p style="text-align: left; margin: 5px 0;">Dear ${userData.name},</p>
    <p style="text-align: left;">Thank you for submitting your patient form with MediTravels. We have received your information and will now begin processing it. Rest assured, your health is important to us.</p>
    <p style="text-align: left;">Take care, and once again, thank you for choosing MediTravels!</p>
    <p style="text-align: left; margin: 5px 0 2px;">Best regards,</p>
    <p style="text-align: left; margin: 0px;">MeditravelS Team</p>
  </div>
</div>`;

  return (
    <Page>
      <FirstSection title="Submit Your Complain Form">
        Your form has been successfully submitted. Our team will review your information and process it accordingly. Thank you for providing the required details.
      </FirstSection>
      <SubmitAction backLink="/patient/sec-3" onSubmit={handleSubmit} />
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
};

export default SubmitPage;