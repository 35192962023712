import React, { useState, useEffect } from 'react';
import Page from '../pro-components/Page';
import CustomTextField from '../pro-components/CTextField';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import Action from '../pro-components/Action';
import CheckboxComponent from '../pro-components/CCheckBox';
import { useHistory } from "react-router-dom";
import Ticket from '../pro-components/ticket';
import CustomModal from "../../global-components/modal";
import { updatePro2 } from '../../../api';

const LegalRequirements = () => {
  const [eligibility, setEligibility] = useState(false);
  const [bgCheck, setBgCheck] = useState(false);
  const [sectionData, setSectionData] = useState({});
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  let history = useHistory();
  const previousPageURL = '/pro/section4-2';
  const nextPageURL = '/additional-information';
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const updateSectionData = async (sectionData) => {
    console.log("user id from local storage", userData.id);
    const userId = userData.id;
    console.log("-------userId from local storage", userId);

    if (!userId) {
      console.log("User ID not found in local storage");
      return;
    }

    try {
      const existingSectionData = JSON.parse(localStorage.getItem("firstPageData2"));
      const section_data = [
        {
          0: existingSectionData, // Include the first page data at index 0
        },
        {
          1: JSON.parse(localStorage.getItem("secondPageData2")), // Include the second page data at index 1
        },
        {
          2: JSON.parse(localStorage.getItem("thirdPageData2")), // Include the second page data at index 1
        },
        {
          3: JSON.parse(localStorage.getItem("fourthPageData2")), // Include the second page data at index 1
        },
        {
          4: sectionData,
        },
      ];

      console.log("Updated data of doc2");
      console.log(section_data);

      const updatedSectionData = await updatePro2(userId, section_data);

      if (updatedSectionData) {
        console.log("------Updated data of pro");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updatePro2.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEligibilityChange = (checked) => {
    setEligibility(checked);
    setSectionData((prevData) => ({
      ...prevData,
      eligibility: checked,
    }));
  };

  const handleBgCheckChange = (checked) => {
    setBgCheck(checked);
    setSectionData((prevData) => ({
      ...prevData,
      bg_check: checked,
    }));
  };

  useEffect(() => {
    const storedSectionData = JSON.parse(localStorage.getItem('fifthPageData2'));

    if (storedSectionData) {
      setEligibility(storedSectionData.eligibility || false);
      setBgCheck(storedSectionData.bg_check || false);
    }
  }, []);

  const handleNextClick = async (event) => {
    event.preventDefault();

    // Store all the data in local storage
    const fifthPageData = {
      eligibility: eligibility,
      bg_check: bgCheck,
    };
    localStorage.setItem("fifthPageData2", JSON.stringify(fifthPageData));

    // Update the section data in the backend
    await updateSectionData(fifthPageData);

    history.push("/pro/section6-2");
  };

  const handleClearForm = () => {
    setEligibility(false);
    setBgCheck(false);
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <Page>
      <FirstSection>
        <h4>Legal Requirements</h4>
      </FirstSection>
      <SectionComponent>
        <label className='sec-label'>CONFIRMATION OF LEGAL ELIGIBILITY TO WORK IN THEIR COUNTRY</label>
        <br></br>
        <CheckboxComponent label="Yes" checked={eligibility} onChange={handleEligibilityChange} />

        {/* {showEligibilityError && eligibilityError && (
          <p className="error-msg">{eligibilityError}</p>
        )} */}
      </SectionComponent>
      <SectionComponent>
        <label className='sec-label'>BACKGROUND CHECK CONSENT (IF REQUIRED)</label>
        <br></br>
        <CheckboxComponent label="Yes" checked={bgCheck} onChange={handleBgCheckChange} />
        {/* {showBgCheckError && bgCheckError && (
          <p className="error-msg">{bgCheckError}</p>
        )} */}
      </SectionComponent>
      <Action
        backLink={previousPageURL}
        onClickNext={handleNextClick}
        onClearForm={handleClearForm}
      />
      <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
        <Ticket variant="contained" color="secondary" section="PRO: Verification Form - Legal Requirements" />
      </div>
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
}

export default LegalRequirements;