import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import 'firebase/compat/messaging';
//import { sendEmailVerification } from 'firebase/auth';

const firebaseConfig = {
	apiKey: "AIzaSyAybWdGSnOjTWjV43tofWvH6D7vWdvBbF4",
	authDomain: "meditravels-3b054.firebaseapp.com",
	projectId: "meditravels-3b054",
	storageBucket: "meditravels-3b054.appspot.com",
	messagingSenderId: "197934892000",
	appId: "1:197934892000:web:1a75d196a35337ef112185",
	measurementId: "G-K6WRC22JTM"
};

const app = firebase.initializeApp(firebaseConfig);
// const messaging = app.messaging();
// Check if Firebase messaging is supported by the browser
let messaging = null;
if (firebase.messaging.isSupported()) {
	messaging = app.messaging();
} else {
	console.error("Firebase messaging is not supported by the browser.");
}

const auth = app.auth();
// const provider = new firebase.auth.EmailAuthProvider();

const provider = new firebase.auth.GoogleAuthProvider();

const signUpWithEmailPassword = async (email, password) => {
	try {
		await auth.createUserWithEmailAndPassword(email, password);
	} catch (error) {
		console.error("Error signing up:", error);
	}
};

const signInWithEmailPassword = async (email, password) => {
	try {
		await auth.signInWithEmailAndPassword(email, password);
	} catch (error) {
		console.error("Error signing in:", error);
	}
};

const sendEmailVerification = async () => {
	const user = auth.currentUser;

	try {
		await user.sendEmailVerification();
	} catch (error) {
	}
};

const isEmailVerified = () => {
	const user = auth.currentUser.emailVerified;

	if (user && user.emailVerified) {
		console.log("User Email is verified 1.", true);
		return true;
	} else {
		console.log("Email is not verified 1.", false);
		return false;
	}
};

auth.onAuthStateChanged((user) => {
	if (user) {
		// When the user's authentication state changes, check if the email is verified
		isEmailVerified();
		if (user && user.emailVerified) {
			console.log("User Email is verified 2.", true);
			return true;
		} else {
			console.log("Email is not verified 2.", false);
			return false;
		}
	}
});

export { auth, provider, messaging, signUpWithEmailPassword, signInWithEmailPassword, sendEmailVerification, isEmailVerified };