import React, { useState, useEffect } from 'react';
import Page from '../pro-components/Page';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import CustomTextField from '../pro-components/CTextField';
import Dropdown from '../pro-components/CDropDown';
import FileUpload from '../pro-components/CFileUpload';
import Action from '../pro-components/Action';
import CustomTextArea from '../pro-components/CTextArea';
import { useHistory } from 'react-router-dom';
import CustomButton from '../pro-components/CButton';
import Ticket from '../pro-components/ticket';
import CustomModal from "../../global-components/modal";
import { updatePro2, uploadMedia } from '../../../api';

const RelevantExprience = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [degreeInput, setDegreeInput] = useState('');
  const [degreeInputError, setDegreeInputError] = useState('');
  const [showdegreeInputError, setshowDegreeInputError] = useState(false);
  const [summary, setSummary] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [profexperienceError, setProfexperienceError] = useState('');
  const [showprofexperienceError, setshowProfexprienceError] = useState(false);
  const [summaryError, setSummaryError] = useState('');
  const [showsummaryError, setshowSummaryError] = useState(false);
  const [expicertificate, setExpicertificate] = useState('');
  const [showImageError, setShowImageError] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [sectionData, setSectionData] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const pdfOpenParams = 'toolbar=0&navpanes=0&scrollbar=0';
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  let history = useHistory();
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const previousPageURL = '/pro/section1-2';
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const options = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  const updateSectionData = async (sectionData) => {
    console.log("user id from local storage", userData.id);
    const userId = userData.id;
    console.log("-------userId from local storage", userId);

    if (!userId) {
      console.log("User ID not found in local storage");
      return;
    }

    try {
      const existingSectionData = JSON.parse(localStorage.getItem("firstPageData2"));
      const section_data = [
        {
          0: existingSectionData,
        },
        {
          1: sectionData,
        },
      ];

      console.log("Updated data of doc2");
      console.log(section_data);

      const updatedSectionData = await updatePro2(userId, section_data);

      if (updatedSectionData) {
        console.log("------Updated data of pro");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updatePro2.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextClick = async (event) => {
    event.preventDefault();

    let hasError = false;

    // if (!validateDegreeInput(degreeInput)) {
    //   setshowDegreeInputError(true);
    //   hasError = true;
    // }

    if (!validateProfexperienceLanguage(selectedOption)) {
      setshowProfexprienceError(true);
      hasError = true;
    }

    // if (!validateSummary(summary)) {
    //   setshowSummaryError(true);
    //   hasError = true;
    // }

    if (selectedOption === 'yes' && !expicertificate) {
      setShowImageError(true);
      hasError = true;
      return
    }

    if (selectedOption === 'yes' && !summary) {
      setshowSummaryError(true);
      hasError = true;
      return
    }

    if (hasError) {
      return;
    }
    // Store all the data in local storage
    const secondPageData = {
      // degreeinput: degreeInput,
      expicertificate: expicertificate,
      summary: summary,
      selectedOption: selectedOption,
    };
    localStorage.setItem('secondPageData2', JSON.stringify(secondPageData));

    // Update the section data in the backend
    await updateSectionData(secondPageData);

    history.push('/pro/section3-2');
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('secondPageData2'));
    console.log("sec2, p2, img", storedData)

    if (storedData) {
      setSelectedOption(storedData.selectedOption || '');
      setSummary(storedData.summary || '');
      setExpicertificate(storedData.expicertificate);

      if (storedData.expicertificate && storedData.expicertificate.endsWith('.pdf')) {
        setPdfPreviewUrl(`https://docs.google.com/viewer?url=${encodeURIComponent(storedData.expicertificate)}&embedded=true`);
      } else {
        setImagePreviewUrl(storedData.expicertificate);
      }


      setSelectedFile(null);
      setPreviewUrl(null);
    }
  }, []);

  // const validateDegreeInput = (value) => {
  //   setDegreeInput(value);
  //   let error = '';
  //   if (!value) {
  //     error = 'Highest Level of Education Completed is required';
  //   }
  //   setDegreeInputError(error);
  //   return !error; // Return true if there is no error
  // };
  // const handleDegreeChange = (event) => {
  //   const degreeInput = event.target.value;
  //   setDegreeInput(degreeInput);
  //   setDegreeInputError(validateDegreeInput(degreeInput) ? '' :  'Highest Level of Education Completed is required');
  // };
  // const handleDegreeBlur = () => {
  //   setshowDegreeInputError(true);
  // };

  const validateProfexperienceLanguage = (value) => {
    setSelectedOption(value);

    let error = '';

    if (!value) {
      error = 'Professional experience is required';
    } else if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }
    setProfexperienceError(error);

    return !error; // Return true if there is no error
  };

  const handleProfexprienceChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption === 'no') {
      setExpicertificate(null);
      setImageUrl(null);
      setSelectedFile(null);
      setPreviewUrl(null);
    }
    const isValid = validateProfexperienceLanguage(selectedOption);
    setProfexperienceError(isValid ? '' : 'Professional experience is required');

    setSectionData((prevData) => ({
      ...prevData,
      selectedOption: selectedOption,
    }));
  };

  const handleProfexprienceBlur = () => {
    setshowProfexprienceError(true);
  };

  const validateSummary = (value) => {
    setSummary(value);

    let error = '';

    if (/\s{2,}(?=[^\s-])|^\s|\s$(?=[^\s-])/.test(value)) {
      error = 'Multiple consecutive spaces or leading/trailing spaces are not allowed';
    }
    setSummaryError(error);

    return !error; // Return true if there is no error
  };

  const handleSummaryChange = (event) => {
    const summaryValue = event.target.value;
    setSummary(summaryValue);

    // Check if the summary field is not empty before applying the validation
    if (summaryValue.trim().length > 0) {
      // Apply the validation for multiple consecutive spaces or leading/trailing spaces
      if (/\s{2,}(?=[^\s-])|^\s|\s$(?=[^\s-])/.test(summaryValue)) {
        // Show the validation error if the condition is met
        setSummaryError('Multiple consecutive spaces or leading/trailing spaces are not allowed');
      } else {
        // Clear the validation error if the condition is not met
        setSummaryError('');
      }
    } else {
      // Clear the validation error if the field is empty
      setSummaryError('');
    }

    setSectionData((prevData) => ({
      ...prevData,
      summary: summaryValue,
    }));
  };

  const handleSummaryBlur = () => {
    setshowSummaryError(true);
  };

  const handleFileUpload = async (file) => {
    try {
      const mediaType = "PRO2";

      const uploadedImagePath = await uploadMedia(file, mediaType);

      console.log('Image URL:', uploadedImagePath);

      // Determine the file type and set the corresponding preview URL
      if (file.type.startsWith('image/')) {
        setImagePreviewUrl(URL.createObjectURL(file));
        setPdfPreviewUrl(null);
        setExpicertificate(uploadedImagePath);
      }
      else if (file.type === 'application/pdf') {
        setPdfPreviewUrl(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
        setImagePreviewUrl(null);
        setExpicertificate(uploadedImagePath);
      }

      await saveImageUrlToDatabase(uploadedImagePath)
    } catch (error) {
      console.error(error);
    }
  };

  const saveImageUrlToDatabase = async (imageUrl) => {

    const userId = userData.id;
    const sectionData = { expicertificate: imageUrl };
  
    try {
      const response = await updatePro2(userId, sectionData);  

      console.log('Image URL saved in the database:', response.data);
    } catch (error) {
      console.error(error);
    }
    setSectionData((prevData) => ({
      ...prevData,
      expicertificate: imageUrl,
    }));
  };

  // const handleDegreeChange = (event) => {
  //   const degreeInput = event.target.value;
  //   setDegreeInput(degreeInput);
  // };

  const handleClearForm = () => {
    setDegreeInput('');
    setSelectedOption('');
    setSelectedFile('');
    setSummary('');
    setProfexperienceError('');
    setSummaryError('');
    setshowProfexprienceError('');
    setshowSummaryError('');
    setSelectedFile(null);
    setPreviewUrl(null);
    setPdfPreviewUrl("");
    setImagePreviewUrl("")
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <Page>
      <FirstSection>
        <h4>Relevant Experience</h4>
      </FirstSection>
      {/* <SectionComponent>
        <label className="sec-label">Highest Level of Education Completed</label>
        <CustomTextField
          id="outlined-basic"
          placeholder="Highest Level of Education Completed"
          variant="outlined"
          value={degreeInput}
          onChange={handleDegreeChange}
          onBlur={handleDegreeBlur}
        />
        <span className="error-msg" >
        {showdegreeInputError && degreeInputError &&  (
          <p className="error-msg">{degreeInputError}</p>
        )} </span>
      </SectionComponent> */}
      <SectionComponent>
        <label className="sec-label">PROFESSIONAL EXPERIENCE IN THE HEALTHCARE INDUSTRY*</label>
        <br />
        <Dropdown
          className="dropdown-compo"
          label="Choose"
          value={selectedOption}
          options={options}
          onChange={handleProfexprienceChange}
          onBlur={handleProfexprienceBlur}
        />
        {showprofexperienceError && profexperienceError && (
          <p className="error-msg">{profexperienceError}</p>
        )}

        {selectedOption === 'yes' && (
          <div>
            <div className='exp-certificate' style={{ marginTop: '18px' }}>
              <label className="sec-label">EXPERIENCE CERTIFICATE</label>
            </div>
            <FileUpload onChange={handleFileUpload} value={expicertificate} />
          </div>
        )}
        {imagePreviewUrl && (
          <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
            <CustomButton type="button" onClick={() => setImagePreviewUrl(null)} // Reset the image preview URL
            >X</CustomButton>
          </div>
            <div style={{ border: '1px solid black' }}>
              <img src={imagePreviewUrl} alt="Selected" style={{ width: '100%' }} />
            </div>
          </>
        )}
        {pdfPreviewUrl && (
          <>
            <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
              <CustomButton type="button" onClick={() => setPdfPreviewUrl(null)} // Reset the PDF preview URL
              >X</CustomButton>
            </div>
            <div style={{ border: '1px solid black' }}>
              <iframe
                src={pdfPreviewUrl}
                title="PDF Preview"
                style={{ width: '100%', height: '500px' }}
              />
            </div>
          </>
        )}
        {showImageError && selectedOption === 'yes' && !expicertificate && (
          <p className="error-msg">Please attach Experience Certificate</p>
        )}
      </SectionComponent>
      <SectionComponent>
        <label className="sec-label">BRIEF SUMMARY OF RELEVANT WORK EXPERIENCE</label>
        <CustomTextArea
          id="outlined-basic"
          variant="outlined"
          onChange={handleSummaryChange}
          onBlur={handleSummaryBlur}
          placeholder="Brief summary of relevant work experience"
          rows={4}
          rowsMax={8}
          value={summary}
        />
        {showsummaryError && selectedOption === 'yes' && !summary && (
          <p className="error-msg">Please add breif summary about work experiencee</p>
        )}
        {/* {showsummaryError && summaryError && (
          <p className="error-msg">{summaryError}</p>
        )} */}
      </SectionComponent>
      <Action backLink={previousPageURL} onClickNext={handleNextClick} onClearForm={handleClearForm} />
      <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
        <Ticket variant="contained" color="secondary" section="PRO: Verification Form - Relevant Experience" />
      </div>
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
};

export default RelevantExprience;