import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import BlogDetails from "./blog-components/blog-details";
import Subscribe from "./section-components/subscribe";
import Footer from "./global-components/footer";
import { fetchBlogById } from '../api';

const BlogDetailsPage = () => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const headerImage = localStorage.getItem("blogimage");
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => unlisten();
  }, [history]);

  useEffect(() => {
    // Fetch the API here
    fetchBlogData();
  }, []);

  const fetchBlogData = async () => {
    try {
      const blogId = localStorage.getItem("blog_id");
      if (blogId) {
        const blogData = await fetchBlogById(blogId);
        console.log("blog data:", blogData);
        setBlog(blogData);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching blog data:", error);
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, []);

  return (
    <div>
      <Navbar />

      <PageHeader image={headerImage} headertitle="" />

      {/* <BlogDetails /> */}
      {/* {blog ? <BlogDetails blog={blog} /> : <p>Loading...</p>} */}

      <BlogDetails blog={blog} loading={loading} />

      <Subscribe />
      <Footer />
    </div>
  );
};

export default BlogDetailsPage;
