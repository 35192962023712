import React from "react";

const BlogSkeleton2 = () => {
  return (
    <div
      style={{
        marginTop: "100px",
        marginLeft: "300px",
        width: "35%",
        height: "350px",
        backgroundColor: "#f2f2f2",
        borderRadius: "10px",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          background: `linear-gradient(to right, #f2f2f2 0%, #e6e6e6 20%, #f2f2f2 40%)`,
          position: "absolute",
          top: 0,
          left: 0,
          animation: "loading 1.5s linear infinite",
        }}
      />
    </div>
  );
};

export default BlogSkeleton2;
