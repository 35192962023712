import React, { useState, useEffect, useRef } from 'react';
import Page from '../pro-components/Page';
import CustomTextField from '../pro-components/CTextField';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import FutureDatePickerDialog from '../pro-components/FutureDatePicker';
import Dropdown from '../pro-components/CDropDown';
import TimePickerComponent from '../pro-components/CTimePicker';
import timezones from '../../../timezones.json';
import CustomButton from '../pro-components/CButton';
import { useHistory } from "react-router-dom";
import Ticket from '../pro-components/ticket';
import CustomModal from "../../global-components/modal";
import moment from 'moment-timezone';
import { fetchConsultationData, createConsultation, updatePatientStatusAPI, createSession } from '../../../api';

const section = () => {
    const userDataJSON = localStorage.getItem("user");
    const userData = userDataJSON ? JSON.parse(userDataJSON) : {};
    console.log("printing user data on loign", userData.id);
    const [selectedlanguage, setSelectedLanguage] = useState('');
    const [day1, setDay1] = useState('');
    const [day2, setDay2] = useState('');
    const [day3, setDay3] = useState('');
    const [timezone, setTimezone] = useState('');
    const [time1, setTime1] = useState('');
    const [time2, setTime2] = useState('');
    const [time3, setTime3] = useState('');
    const [timeind1, setTimeind1] = useState('');
    const [timeind2, setTimeind2] = useState('');
    const [timeind3, setTimeind3] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const sub_treatment_id = localStorage.getItem("selectedsubtreatment_id");
    const formStatus = localStorage.getItem('form_status');
    const [statusConsult, setStatusConsult] = useState('');
    const [finalSlot, setFinalSlot] = useState('');
    const [meet, setMeet] = useState('');
    const [finalDay, setFinalDay] = useState('');
    const [finalTime, setFinalTime] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);

    const language = [
        { value: 'English', label: 'English' },
        { value: 'Hindi', label: 'Hindi' },
        { value: 'Gujrati', label: 'Gujarati' },
    ];
    const [errorModalData, setErrorModalData] = useState({
        title: "",
        message: "",
        show: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            if (!userData.id) {
                console.error('No user ID available.');
                return;
            }
            try {
                const consultationResponse = await fetchConsultationData(userData.id);
                if (consultationResponse) {
                    console.log('API Response of FindConsultation _api_url from section page:', consultationResponse);
                    console.log("printing status of consultation", consultationResponse.status);
                    setStatusConsult(consultationResponse.status);
                    setPaymentStatus(consultationResponse.payment_status);
                    localStorage.setItem('payment_status', consultationResponse.payment_status);
                    console.log("prinitng payment status: ", consultationResponse.payment_status);
                    localStorage.setItem('form_status', consultationResponse.status);
                    console.log("prinitng slot", consultationResponse.finalslot);
                    setFinalSlot(consultationResponse.finalslot);
                    setMeet(consultationResponse.meet);

                    // Adding the condition to setFinalDay and setFinalTime based on setFinalSlot
                    if (consultationResponse.finalslot === '1') {
                        setFinalDay(consultationResponse.day1);
                        setFinalTime(consultationResponse.time1);
                    } else if (consultationResponse.finalslot === '2') {
                        setFinalDay(consultationResponse.day2);
                        setFinalTime(consultationResponse.time2);
                    } else if (consultationResponse.finalslot === '3') {
                        setFinalDay(consultationResponse.day3);
                        setFinalTime(consultationResponse.time3);
                    }

                    console.log("setFinalDay:", finalDay);
                    console.log("setFinalTime:", finalTime);
                } else {
                    console.error('No data returned from fetchConsultationData.');
                }
            } catch (error) {
                console.error('API Error of FindConsultation _api_url:', error);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 3000);
        return () => {
            clearInterval(intervalId);
        };
    }, [userData.id, history]);

    const showErrorModal = (title, message) => {
        setErrorModalData({
            title: title,
            message: message,
            show: true,
        });
    };

    const handlePlatformSelection = (platform) => {
        setSelectedLanguage(platform);
        setValidationErrors((errors) => ({ ...errors, selectedlanguage: '' }));
    };

    const handleDateChange = (value) => {
        setDay1(value);
        setValidationErrors((errors) => ({ ...errors, day1: '' }));
    };

    const handleTimeChange = (value) => {
        setTime1(value);
        setValidationErrors((errors) => ({ ...errors, time1: '' }));
    };

    const handleDateChange2 = (value) => {
        setDay2(value);
        setValidationErrors((errors) => ({ ...errors, day2: '' }));
    };

    const handleTimeChange2 = (value) => {
        setTime2(value);
        setValidationErrors((errors) => ({ ...errors, time2: '' }));
    };

    const handleDateChange3 = (value) => {
        setDay3(value);
        setValidationErrors((errors) => ({ ...errors, day3: '' }));
    };

    const handleTimeChange3 = (value) => {
        setTime3(value);
        setValidationErrors((errors) => ({ ...errors, time3: '' }));
    };

    const handleSelectTimeZone = (option) => {
        setTimezone(option);
        setValidationErrors((errors) => ({ ...errors, timezone: '' }));
    };

    const convertToIndianTime = (selectedTime, selectedTimezone) => {
        const selectedTimezoneOption = timezones.find(option => option.value === selectedTimezone);
        if (selectedTimezoneOption) {
            const utc = selectedTimezoneOption.utc[0];
            const indianTime = moment.tz(selectedTime, 'hh:mm A', utc).tz('Asia/Kolkata');
            return indianTime.format('hh:mm A');
        }
        return selectedTime;
    };

    const handleClickPay = () => {
        const errors = {};

        if (!selectedlanguage) {
            errors.selectedlanguage = 'Language is required';
        }
        if (!timezone) {
            errors.timezone = 'Timezone is required';
        }
        if (!day1) {
            errors.day1 = 'Slot 1 Date is require';
        }
        if (!time1) {
            errors.time1 = 'Slot 1 Time is required';
        }
        if (!day2) {
            errors.day2 = 'Slot 2 Date is required';
        }
        if (!time2) {
            errors.time2 = 'Slot 2 Time is required';
        }
        if (!day3) {
            errors.day3 = 'Slot 3 Date is required';
        }
        if (!time3) {
            errors.time3 = 'Slot 3 Time is required';
        }

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            showErrorModal("Alert", "Please fill in all required fields.");
            return;
        }

        const consultationRequestData = {
            user_id: userData.id,
            status: 'form_incomplete',
            subtreatment_id: sub_treatment_id,
            language: selectedlanguage,
            day1: day1,
            day2: day2,
            day3: day3,
            timezone: timezone,
            time1: time1,
            time2: time2,
            time3: time3,
            timeind1: convertToIndianTime(time1, timezone),
            timeind2: convertToIndianTime(time2, timezone),
            timeind3: convertToIndianTime(time3, timezone),
            payment_id: null,
            payment_status: 'pending',
            is_submit: true,
        };

        createConsultation(consultationRequestData)
            .then(async (response) => {
                console.log('Consultation API Response:', response);

                try {
                    const patientStatusId = localStorage.getItem("patientStatusId");
                    await updatePatientStatusAPI(patientStatusId, {
                        consultation_status: "form_completed",
                        payment_status: 'pending',
                    });

                    const success_url = 'https://meditravels.in/#/success-payment';
                    const customer = localStorage.getItem("customer_id");
                    console.log("printing customer id from localstorage on click pay", customer);
                    const line_items_price = 'price_1NuCBESJgRPCKe218KbTlPIP';
                    const line_items_quantity = '1';
                    const mode = 'payment';
                    const payment_method_types = 'card';

                    const sessionResponse = await createSession({
                        success_url,
                        customer,
                        line_items_price,
                        line_items_quantity,
                        mode,
                        payment_method_types,
                    });

                    console.log("Response from Stripe:", sessionResponse);
                    window.location.href = sessionResponse.url;
                    localStorage.setItem('form_status', 'form_incomplete');
                    localStorage.setItem("patientSidebar", 'consultation-complete');
                } catch (error) {
                    console.error('API Error:', error);
                    // Handle error scenario
                    if (error.response && error.response.data) {
                        console.error("Error Details:", error.response.data);
                    }
                }
            })
            .catch((error) => {
                console.error("Error creating session or consultation:", error);
            });
    };

    const ValidationError = ({ message }) => {
        return message ? <div style={{ color: 'red', fontSize: '12px' }}>{message}</div> : null;
    };

    const handleClearForm = () => {
        setSelectedLanguage('');
        setDay1('');
        setDay2('');
        setDay3('');
        setTimezone('');
        setTime1('');
        setTime2('');
        setTime3('');
        setValidationErrors('');
    };

    const handleCloseErrorModal = () => {
        setErrorModalData({
            title: "",
            message: "",
            show: false,
        });
    };

    return (
        <Page>
            {((statusConsult === 'form_incomplete' || formStatus === 'form_incomplete') && paymentStatus === 'success') ? (
                <SectionComponent>
                    <h5 style={{ color: '#000000' }}>
                        Thank you for sending in the form. The MediTravels team will get back to you shortly.
                    </h5>
                </SectionComponent>
            ) : statusConsult === 'date_selected' || formStatus === 'date_selected' ? (
                <SectionComponent>
                    <div style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '8px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
                        <h5 style={{ color: '#000000', marginBottom: '10px' }}>Your Scheduled Consultation Details:</h5>
                        <p><strong>Date:</strong> {finalDay}</p>
                        <p><strong>Time:</strong> {finalTime}</p>
                        <p style={{ marginTop: '10px' }}>You can join the consultation using this <strong>Google Meet link:</strong> <a href={meet} target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'underline' }}>{meet}</a></p>
                    </div>
                </SectionComponent>

            ) : (
                <>
                    <FirstSection title="Consultation Form">
                        <h4 style={{ margin: '0px' }}>Schedule Your Meet</h4>
                    </FirstSection>
                    <SectionComponent>
                        <label className="sec-label">LANGUAGE</label>
                        <br></br>
                        <Dropdown
                            className="dropdown-compo"
                            label={selectedlanguage ? '' : 'Select Language'}
                            value={selectedlanguage}
                            options={language}
                            onChange={handlePlatformSelection}
                        />
                        <ValidationError message={validationErrors.selectedlanguage} />
                    </SectionComponent>
                    <SectionComponent>
                        <label className="sec-label">SELECT TIMEZONE</label>
                        <br></br>

                        <Dropdown
                            className="dropdown-compo"
                            label={timezone ? '' : 'Select Timezone'}
                            value={timezone}
                            options={timezones.map((timezone) => ({
                                value: timezone.value,
                                label: timezone.value,
                            }))}
                            onChange={handleSelectTimeZone}
                        />
                        <ValidationError message={validationErrors.timezone} />
                    </SectionComponent>
                    <SectionComponent>
                        <label className="sec-label">SLOT 1 :</label>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='consultation-date'>
                                <label className="sec-label">Select date :</label>
                                <FutureDatePickerDialog
                                    value={day1 !== null ? day1 : ''}
                                    onChange={handleDateChange}
                                />
                                <ValidationError message={validationErrors.day1} />
                                <br></br>
                            </div>
                            <div>
                                <label className="sec-label">Select time :</label>
                                <TimePickerComponent
                                    value={time1}
                                    onChange={(value) => setTime1(value)}
                                    onTimeSelect={(time) => handleTimeChange(time)}
                                />
                                <ValidationError message={validationErrors.time1} />
                            </div>
                        </div>
                    </SectionComponent>
                    <SectionComponent>
                        <label className="sec-label">SLOT 2 :</label>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='consultation-date'>
                                <label className="sec-label">Select date :</label>
                                <FutureDatePickerDialog value={day2 !== null ? day2 : ''} onChange={handleDateChange2} />
                                <ValidationError message={validationErrors.day2} />
                                <br></br>
                            </div>
                            <div>
                                <label className="sec-label">Select time :</label>
                                <TimePickerComponent value={time2} onChange={(value) => setTime2(value)} onTimeSelect={(time) => handleTimeChange2(time)} />
                                <ValidationError message={validationErrors.time2} />
                            </div>
                        </div>
                    </SectionComponent>
                    <SectionComponent>
                        <label className="sec-label">SLOT 3 :</label>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='consultation-date'>
                                <label className="sec-label">Select date :</label>
                                <FutureDatePickerDialog value={day3 !== null ? day3 : ''} onChange={handleDateChange3} />
                                <ValidationError message={validationErrors.day3} />
                                <br></br>
                            </div>
                            <div>
                                <label className="sec-label">Select time :</label>
                                <TimePickerComponent value={time3} onChange={(value) => setTime3(value)} onTimeSelect={(time) => handleTimeChange3(time)} />
                                <ValidationError message={validationErrors.time3} />
                            </div>
                        </div>
                    </SectionComponent>
                    <SectionComponent>
                        <CustomButton variant="contained" color="primary" onClick={handleClickPay}>
                            Pay Now
                        </CustomButton>
                    </SectionComponent>
                    {/* <div className='consultation-action-btn' style={{ width: '50%', display: 'flex', justifyContent: 'space-between' }}>
                        <CustomButton variant="contained" color="primary" onClick={handleSubmitForm}>
                            Submit
                        </CustomButton>
                        <CustomButton variant="contained" color="primary" onClick={handleClearForm}>
                            Clear Form
                        </CustomButton>
                    </div> */}
                    <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
                        <Ticket variant="contained" color="secondary" section="Patient: Consultation Form " />
                    </div>
                </>
            )}
            <CustomModal
                show={errorModalData.show}
                onHide={handleCloseErrorModal}
                titleStyle={{ fontWeight: "bold" }}
                title={errorModalData.title}
                message={errorModalData.message}
            />
        </Page>
    )
};

export default section;