import React, { useState, useEffect } from 'react';
import Sidebar from '../PatientDashboard/sidebar';

const Layout = ({ children }) => {
  const [selectedPage, setSelectedPage] = useState('');
  const [isSidebarVisible, setIsSidebarVisible] = useState(true); // Initially set to true for desktop view

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    // Add an effect to hide the sidebar by default on smaller screens
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      setIsSidebarVisible(false);
    }
  }, []);

  return (
    <div className={`main-prodashboard ${isSidebarVisible ? 'sidebar-visible' : ''}`}>
      <div className={`pro-sidebar ${isSidebarVisible ? '' : 'sidebar-hidden'}`}>
        <Sidebar setSelectedPage={setSelectedPage} />
      </div>
      <div className={`main-compo ${isSidebarVisible ? 'main-compo-sidebar-visible' : ''}`}>
        {/* Toggle between hamburger and cross icon based on sidebar visibility */}
        <div className="hamburger-icon" onClick={toggleSidebar}>
          {isSidebarVisible ? (
            <i className="fa fa-times"></i>
          ) : (
            <i className="fa fa-bars"></i>
          )}
        </div>
        <div className="main-compo-content">{children}</div>
      </div>
    </div>
  );
};

export default Layout;