import React from "react";
import { Modal, Button } from "react-bootstrap";

const CustomModal = ({ show, onHide, title, titleStyle, message }) => {
  return (
    <Modal show={show} onHide={onHide} className="newsletter-popup" backdrop="static">
      <Modal.Header>
        <Modal.Title style={titleStyle}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{textAlign: 'center'}}>{message}</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-yellow" onClick={onHide}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;