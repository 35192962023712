import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { fetchTestimonialData } from '../../api';

function Client() {
  const [testimonials, setTestimonial] = useState([]);
  const testimonialsRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  useEffect(() => {
    const loadTestimonials = async () => {
      try {
        const testimonialData = await fetchTestimonialData();
        setTestimonial(testimonialData.testimonials);
        // localStorage.setItem('testimonialsimage', testimonialData.data.image_url);
      } catch (error) {
        console.error("Error loading testimonials:", error);
      }
    };

    loadTestimonials();
  }, []);

  useEffect(() => {
    if (testimonialsRef.current) {
      testimonialsRef.current.scrollTo({
        left: testimonialsRef.current.offsetWidth * activeSlide,
        behavior: 'smooth',
      });
    }
  }, [activeSlide]);

  const goToNextSlide = () => {
    if (activeSlide < testimonials.length - 1) {
      setActiveSlide(activeSlide + 1);
      scrollSlider(activeSlide + 1);
    } else {
      setActiveSlide(0);
      scrollSlider(0);
    }
  };
	// const goToNextSlide = () => {
	// 	console.log('Next Slide Clicked');
	// 	if (activeSlide < testimonials.length - 1) {
	// 		setActiveSlide(activeSlide + 1);
	// 		scrollSlider(activeSlide + 1);
	// 	} else {
	// 		setActiveSlide(0);
	// 		scrollSlider(0);
	// 	}
	// };

	// const goToPrevSlide = () => {
	// 	console.log('Previous Slide Clicked');
	// 	if (activeSlide > 0) {
	// 		setActiveSlide(activeSlide - 1);
	// 		scrollSlider(activeSlide - 1);
	// 	} else {
	// 		setActiveSlide(testimonials.length - 1);
	// 		scrollSlider(testimonials.length - 1);
	// 	}
	// };

  const goToPrevSlide = () => {
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
      scrollSlider(activeSlide - 1);
    } else {
      setActiveSlide(testimonials.length - 1);
      scrollSlider(testimonials.length - 1);
    }
  };

  const scrollSlider = (slideIndex) => {
    if (testimonialsRef.current) {
      const testimonialsWrapper = testimonialsRef.current;
      const slideWidth = testimonialsWrapper.offsetWidth;
      testimonialsWrapper.scrollLeft = slideIndex * slideWidth;
    }
  };

  return (
    <div className="client-area pd-top-108 pd-bottom-120 jarallax" style={{ backgroundImage: 'url(' + publicUrl + 'assets/img/bg/10.png)' }}>
      <div className="container" >
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title text-center style-two">
              <h2 className="title" style={{ color: '#000000' }}>What Our Clients Say</h2>
			  <p style={{color:'#000000'}}>"Discover what our satisfied patients have to say about their transformative medical journeys with our expert healthcare professionals."</p>
              {/* Check if there's only one testimonial and apply the center-align class */}
              <div className="swiper-container client-slider-two">
                <div className="swiper-wrapper" ref={testimonialsRef}>
                  {testimonials.map((testimonial, index) => (
                    <div className={`swiper-slide ${testimonials.length === 1 ? 'center-align' : ''}`} key={testimonial._id}>
                      <div className="client-slider-item" style={{background: "#ffffff00", marginTop: '-120px'}}>
                        <div className="details" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                          <h4>{testimonial.title}</h4>
                          <p>{testimonial.description}</p>
                          <img src={testimonial.media_link} alt="testimonials image" style={{ width: '90%' }} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Add Pagination */}
                {/* <div className="tp-control-nav text-center">
                  <div className="slick-arrow swiper-buttons-prev" onClick={goToPrevSlide}><i className="la la-long-arrow-left" /></div>
                  <div className="slick-arrow swiper-buttons-next" onClick={goToNextSlide}><i className="la la-long-arrow-right" /></div>
                </div> */}
                {/* /.end carousel */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Client;