import React, { useState, useEffect } from 'react';
import Page from '../pro-components/Page';
import CustomTextField from '../pro-components/CTextField';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import Action from '../pro-components/Action';
import { useHistory } from 'react-router-dom';
import Dropdown from '../pro-components/CDropDown';
import CustomButton from '../pro-components/CButton';
import Ticket from '../pro-components/ticket'
import CustomModal from "../../global-components/modal";
import { updatePro2 } from '../../../api';

const AdditionalInformation = () => {
  const [joinMotive, setJoinMotive] = useState('');
  const [joinMotiveError, setJoinMotiveError] = useState('');
  const [showJoinMotiveError, setShowJoinMotiveError] = useState(false);
  const [socialLink, setSocialLink] = useState('');
  const [showSocialLinkError, setShowSocialLinkError] = useState(false);
  const [sectionData, setSectionData] = useState({});
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [socialMediaEntries, setSocialMediaEntries] = useState([]);
  const [showSocialmediaError, setShowSocialmediaError] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [userplatformInput, setUserPlatformInput] = useState('');
  const [socialmediaError, setSocialmediaError] = useState(false);
  const [socialmediaplatformError, setSocialmediaPlatformError] = useState(false);
  const [showSocialmediaplatformError, setShowSocialmediaplatformError] = useState(false);
  const [customPlatform, setCustomPlatform] = useState('');
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const history = useHistory();
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const previousPageURL = '/pro/section5-2';
  const nextPageURL = '/terms-and-conditions';
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const platforms = [
    { value: 'Twitter', label: 'Twitter' },
    { value: 'Telegram', label: 'Telegram' },
    { value: 'Linkdin', label: 'Linkdin' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Snapchat', label: 'Snapchat' },
    { value: 'Discord', label: 'Discord' },
    { value: 'Other', label: 'Other' },
  ];

  const updateSectionData = async (sectionData) => {
    console.log("user id from local storage", userData.id);
    const userId = userData.id;
    console.log("-------userId from local storage", userId);

    if (!userId) {
      console.log("User ID not found in local storage");
      return;
    }

    try {
      const existingSectionData = JSON.parse(localStorage.getItem("firstPageData2"));
      const section_data = [
        {
          0: existingSectionData, // Include the first page data at index 0
        },
        {
          1: JSON.parse(localStorage.getItem('secondPageData2')), // Include the second page data at index 1
        },
        {
          2: JSON.parse(localStorage.getItem('thirdPageData2')), // Include the second page data at index 1
        },
        {
          3: JSON.parse(localStorage.getItem('fourthPageData2')), // Include the second page data at index 1
        },
        {
          4: JSON.parse(localStorage.getItem('fifthPageData2')),
        },
        {
          5: sectionData,
        },
      ];

      console.log("Updated data of pro2");
      console.log(section_data);

      const updatedSectionData = await updatePro2(userId, section_data);

      if (updatedSectionData) {
        console.log("------Updated data of pro");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updatePro2.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validateSocialMedia = (entries) => {
    let error = '';

    if (entries.length === 0) {
      error = 'Social media link is required';
    } else {
      for (const entry of entries) {
        if (!entry.userId || !entry.platform) {
          error = 'Please fill all social media entries';
          break;
        }
      }
    }

    setSocialmediaError(error);

    return !error; // Return true if there is no error
  };

  const validateJoinMotive = (value) => {
    let error = '';
    if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }
    setJoinMotiveError(error);
    return !error; // Return true if there is no error
  };

  const handleJoinMotiveChange = (event) => {
    const joinMotive = event.target.value;
    setJoinMotive(joinMotive);
    validateJoinMotive(joinMotive);
    setSectionData((prevData) => ({
      ...prevData,
      join_motive: joinMotive,
    }));
  };

  const handleJoinMotiveBlur = () => {
    setShowJoinMotiveError(true);
  };

  const handlePlatformSelection = (platform) => {
    setSelectedPlatform(platform);
    setUserInput('');
    setUserPlatformInput('');
    setShowSocialmediaError(false);
    setShowSocialmediaplatformError(false);
    setSocialmediaError('');
    if (platform.value === 'Other') {
      setCustomPlatform('');
    }

    // Store the selected platform and its custom platform name in the section data
    setSectionData((prevData) => ({
      ...prevData,
      selectedPlatform: platform.value,
      customPlatform: platform.value === 'Other' ? customPlatform : '',
    }));
  };

  const handleAddSocialMedia = () => {
    const trimmedInput = userInput.trim();
    const trimmedInput2 = userplatformInput.trim();

    if (!trimmedInput) {
      setShowSocialmediaError(true);
      setSocialmediaError('Please enter a value.');
      return;
    }

    let error = '';
    let platformError = '';

    if (selectedPlatform && !socialmediaError && !socialmediaplatformError) {
      const newEntry = {
        platform: trimmedInput2 || selectedPlatform,
        userId: trimmedInput.length > 60 ? trimmedInput.slice(0, 60) + '...' : trimmedInput,
      };

      // Update the social media entries state
      const updatedSocialMediaEntries = [...socialMediaEntries, newEntry];
      setSocialMediaEntries(updatedSocialMediaEntries);

      // Update the section data with the new social media entries
      setSectionData((prevData) => ({
        ...prevData,
        list_platform: updatedSocialMediaEntries,
      }));

      setSelectedPlatform('');
      setUserInput('');
      setUserPlatformInput('');
      setShowSocialmediaError(false);
      setShowSocialmediaplatformError(false);
      setSocialmediaError('');
      setSocialmediaPlatformError('');
    } else {
      if (!trimmedInput2) {
        platformError = 'Platform is required';
      } else if (/\s/.test(trimmedInput2)) {
        platformError = 'Spaces are not allowed';
      } else if (!/[a-zA-Z]{2,}/.test(trimmedInput2)) {
        platformError = 'At least two letters are required';
      }
      error = validateSocialMedia(trimmedInput);
      setShowSocialmediaError(true);
      setShowSocialmediaplatformError(true);
    }

    setSocialmediaError(error);
    setSocialmediaPlatformError(platformError);
  };

  const handleRemoveSocialMedia = (index) => {
    const updatedEntries = [...socialMediaEntries];
    updatedEntries.splice(index, 1);
    setSocialMediaEntries(updatedEntries);
  };

  const handleUserInputChange = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, ''); // Remove spaces from the input value
    setUserInput(sanitizedValue);

    if (showSocialmediaError && sanitizedValue) {
      setShowSocialmediaError(false);
      setSocialmediaError("");
    }

    // Update the section data with the user input
    setSectionData((prevData) => ({
      ...prevData,
      userInput: sanitizedValue,
    }));

    // Store the updated section data in local storage
    localStorage.setItem('secondPageData', JSON.stringify(sectionData));
  };

  const handleUserInputChange2 = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, ''); // Remove spaces from the input value
    setUserPlatformInput(sanitizedValue);

    if (showSocialmediaError && sanitizedValue) {
      setShowSocialmediaplatformError(false);
      setSocialmediaPlatformError("");
    }

    // Update the section data with the custom platform name
    if (selectedPlatform === 'Other') {
      setSectionData((prevData) => ({
        ...prevData,
        customPlatform: sanitizedValue,
      }));
    };
  }

  // const handleSocialLinksBlur = () => {
  //   setShowSocialLinkError(true);
  // };

  const handleNextClick = async (event) => {
    event.preventDefault();

    // Store all the data in local storage
    const sixthPageData = {
      join_motive: joinMotive,
      social_link: socialMediaEntries,
    };
    localStorage.setItem('sixthPageData2', JSON.stringify(sixthPageData));

    // Update the section data in the backend
    await updateSectionData(sixthPageData);

    history.push('/pro/section7-2');
  };

  useEffect(() => {
    const storedSectionData = JSON.parse(localStorage.getItem('sixthPageData2'));

    if (storedSectionData) {
      setJoinMotive(storedSectionData.join_motive || '');
      setSocialLink(storedSectionData.social_link || '');
      setSocialMediaEntries(storedSectionData.socialLink || []);
      setSocialMediaEntries(storedSectionData.socialMediaEntries || []);
      setSocialLink(storedSectionData.socialMediaEntries || []);
      setSocialMediaEntries(storedSectionData.social_link || []);
    }
  }, []);

  useEffect(() => {
    console.log("socialMediaEntries during useEffect:", socialMediaEntries);
  }, []);

  console.log("socialMediaEntries before rendering:", socialMediaEntries);

  const handleClearForm = () => {
    setJoinMotive('');
    setJoinMotiveError('');
    setShowJoinMotiveError(false);
    setSocialMediaEntries([]);
    setShowSocialLinkError(false);
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <Page>
      <FirstSection>
        <h4>Additional Information</h4>
      </FirstSection>
      <SectionComponent>
        <label className="sec-label">WHAT MOTIVATES YOU TO JOIN OUR COMPANY AS A PRO?</label>
        <CustomTextField
          id="outlined-basic"
          placeholder="What motivates you to join our company as a PRO?"
          variant="outlined"
          value={joinMotive}
          onChange={handleJoinMotiveChange}
          onBlur={handleJoinMotiveBlur}
        />
        {joinMotiveError && showJoinMotiveError && <p className="error-msg">{joinMotiveError}</p>}
      </SectionComponent>
      <SectionComponent>
        <label className='sec-label'>LINK TO SOCIAL MEDIA PROFILES OR WEBSITE</label>
        <br />
        <Dropdown
        className="dropdown-compo"
          label={selectedPlatform ? '' : 'Select Platform'}
          value={selectedPlatform}
          options={platforms}
          onChange={handlePlatformSelection}
        />
        <br></br>
        {selectedPlatform === 'Other' && (
          <div className='social-list social-link' style={{ display: 'flex', flexDirection: 'column', width: '50%', paddingTop: '15px' }}>
            <div style={{ width: '100%' }}>
              <>
                <div className='social-list social-link'>
                  <CustomTextField
                    id="custom-platform"
                    label="Social Media Platform"
                    variant="outlined"
                    value={userplatformInput}
                    onChange={handleUserInputChange2}
                    error={!!socialmediaplatformError}
                    helperText={showSocialmediaplatformError && socialmediaplatformError}
                  />
                </div>
              </>
            </div>
          </div>
        )}
        {selectedPlatform && (
          <>
            <div className='social-list social-link' style={{ display: 'flex', flexDirection: 'column', width: '50%', paddingTop: '15px' }}>
              <div style={{ width: '100%' }}>
                <CustomTextField
                  id={`social-media-${selectedPlatform}`}
                  label={`Social Media Link (${selectedPlatform})`}
                  variant="outlined"
                  value={userInput}
                  onChange={handleUserInputChange}
                  error={!!socialmediaError}
                  helperText={showSocialmediaError && socialmediaError}
                />
              </div>

              <div className='social-list-add-btn' style={{ paddingTop: '25px' }}>
                <CustomButton type="button" onClick={handleAddSocialMedia}>
                  Add
                </CustomButton>
              </div>
            </div>
          </>
        )}
        <div className='social-list-heading' style={{ paddingTop: '20px' }}>
          <h7>SOCIAL MEDIA ENTRIES</h7>
          <ul style={{ marginTop: '25px', marginBottom: '0px' }}>
            {socialMediaEntries.map((entry, index) => (
              <li key={index} style={{ paddingBottom: '15px', display: 'flex' }}>
                <div style={{ paddingRight: '10px', borderRadius: '50%' }} className='close-btn'>
                  <CustomButton type="button" onClick={() => handleRemoveSocialMedia(index)}>
                    X
                  </CustomButton>
                </div>
                <div className='social-name'>
                  <strong style={{ paddingRight: '10px' }}>{entry.platform}: </strong>
                  {entry.userId}
                </div>

              </li>
            ))}
          </ul>
        </div>
      </SectionComponent>
      <Action backLink={previousPageURL} onClickNext={handleNextClick} onClearForm={handleClearForm} />
      <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
        <Ticket variant="contained" color="secondary" section="PRO: Verification Form - Additional Information" />
      </div>
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
};

export default AdditionalInformation;