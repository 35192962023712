import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { useNavigate }from 'react-router-dom';
// import parse from "html-react-parser";
// import Popup from "reactjs-popup";
// import styled from "styled-components";
// import Treatments from "./treatments1";
// import Modal from "./Modal";
// import Modal from 'react-modal';
import ReactModal from "react-modal";

const Offer = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  /* global $ */

  useEffect(() => {
    var $d_list_slider = $('.destinations-list-slider');
    $d_list_slider.slick({
      slidesToShow: 3,
      dots: false,
      slidesToScroll: 1,
      speed: 400,
      loop: true,
      autoplay: false,
      prevArrow: '<span class="slick-prev"><i class="la la-long-arrow-left"></i></span>',
      nextArrow: '<span class="slick-next"><i class="la la-long-arrow-right"></i></span>',
      appendArrows: $('.destinations-slider-controls .slider-nav'),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "10px"
          }
        }
      ]
    });
    //active progress
    var $progressBar = $('.d-list-progress');
    var $progressBarLabel = $('.slider__label');
    $d_list_slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      var calc = ((nextSlide) / (slick.slideCount - 1)) * 100;
      $progressBar
        .css('background-size', calc + '% 100%')
        .attr('aria-valuenow', calc);
      $progressBarLabel.text(calc + '% completed');
    });
    //active count list
    $(".destinations-list-slider").on("beforeChange", function (event, slick, currentSlide, nextSlide) {
      var firstNumber = check_number(++nextSlide);
      $(".destinations-slider-controls .slider-extra .text .first").text(firstNumber);
    });
    var smSlider = $(".destinations-list-slider").slick("getSlick");
    var smSliderCount = smSlider.slideCount;
    $(".destinations-slider-controls .slider-extra .text .last").text(check_number(smSliderCount));
    function check_number(num) {
      var IsInteger = /^[0-9]+$/.test(num);
      return IsInteger ? "0" + num : null;
    }
  }, [])

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  const handleConsultUsClick = (role) => {
    localStorage.setItem("selectedRole", role);
  };

  const onClickButton = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onAfterOpenModal = () => {
    // Logic to be executed after the modal is opened
  };

  const onAfterCloseModal = () => {
    // Logic to be executed after the modal is closed
  };

  let publicUrl = process.env.PUBLIC_URL + "/";

  //   onAfterOpen= () => document.body.style.overflow = 'hidden';
  // onAfterClose= () => document.body.style.overflow = 'unset';

  // render() {
  //   let publicUrl = process.env.PUBLIC_URL + "/";
  // const[isLocked,toogle]= useBodyScrollLock()

  // function popupClick  ()  {
  // 	return(
  // 		<Link to={"/treatments"}/>
  // 	)
  // }
  // function open () {
  // 			modal.className = "Modal is-visuallyHidden";
  // 		setTimeout(function() {
  // 		container.className = "MainContainer is-blurred";
  // 		modal.className = "Modal";
  // 		}, 100);
  // 		container.parentElement.className = "ModalOpen";
  // 	}

  // const [modal, setModal] = useState(false);

  // const toggleModal = () => {
  //   setModal(!modal);
  // };

  // if(modal) {
  // 	document.body.classList.add('active-modal')
  // } else {
  // 	document.body.classList.remove('active-modal')
  // }

  // const setHidden = () => {
  //   console.log(document.body.style.overflow);
  //   if (document.body.style.overflow !== "hidden") {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "scroll";
  //   }
  // };

  // const [modalOpen, setModalOpen] = useState(false);


  function closePopup() {
    // close();
  }

  // const [open,setOpen] = useState(false);

  // const toogle = () => setOpen(false);


  return (
    <div className="offer-area pd-top-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title text-center">
              <h2 className="title">
                Special Medical Care With Refreshing Cultural Experience Of
                Incredible India!{" "}
              </h2>
              <p>
                Get unique medical care and cultural experience in India. Enjoy the incredible beauty
                and diversity of the country.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="destinations-list-slider-bg">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-10 offset-xl-1 order-lg-12">
              <div className="destinations-list-slider">
                <div className="d-list-slider-item">
                  <div className="single-destinations-list text-center">
                    <div className="thumb">
                      <img
                        src={publicUrl + "assets/img/destination-list/Allopathy.gif"}
                        alt="list"
                      />
                      <div className="d-list-btn-wrap" >
                        <div className="d-list-btn viaje-go-top">
                          {/* <button className="btn btn-yellow" onClick={<Modal/>}>Consult US</button> */}
                          {/* <Modal open={<Modal/>} > */}
                          <div className="d-list-btn viaje-go-top">
                            <Link className="btn btn-yellow" to="/signin" onClick={() => handleConsultUsClick("Patient")}>
                              Consult US <i className="fa fa-paper-plane" />
                            </Link>
                          </div>
                          {/* <Link className="btn btn-yellow" onClick={ this.onClickButton}>Consult US <i className="fa fa-paper-plane" /></Link> */}
                          {/* </Modal> */}
                          <ReactModal
                            className="ReactModal"
                            overlayClassName=""
                            isOpen={openModal}
                            onRequestClose={onCloseModal}
                            onAfterOpen={onAfterOpenModal}
                            onAfterClose={onAfterCloseModal}
                          >
                            {/* <div className="overlay p-3">
                                <div className="Modal p-3" > */}
                            <div className="col-xl col-lg-6 modal-content">
                              <div className="btn-float-right">
                                <button
                                  className="btn btn-yellow"
                                  // onClick={toggleModal}
                                  // onClick={close}
                                  onClick={onCloseModal}
                                // onClick={() => setShowPopup(!showPopup)}
                                >
                                  X
                                </button></div>
                              <form className="tp-form-wrap">
                                <div className="row">
                                  <div className="col-md-6">
                                    <label className="single-input-wrap style-two">
                                      <span className="single-input-title">
                                        Name
                                      </span>
                                      <input type="text" name="name" />
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <label className="single-input-wrap style-two">
                                      <span className="single-input-title">
                                        Number
                                      </span>
                                      <input
                                        type="text"
                                        name="number"
                                      />
                                    </label>
                                  </div>
                                  <div className="col-lg-12">
                                    <label className="single-input-wrap style-two">
                                      <span className="single-input-title">
                                        Email
                                      </span>
                                      <input type="text" name="email" />
                                    </label>
                                  </div>
                                  <div className="col-md-6 mt-2">
                                    {/* <button className="btn btn-yellow" value="Submit" onClick={{routeChange}}>Submit</button>  */}
                                    <Link
                                      className="btn btn-yellow"
                                      to="/treatment-details"
                                    >
                                      Submit
                                    </Link>
                                  </div>
                                  <div className="col-md-6 mt-2">
                                  </div>
                                </div>
                              </form>
                            </div>
                            {/* </div>
                              </div> */}
                          </ReactModal>
                        </div>
                      </div>
                    </div>
                    <div className="details">
                      <h4 className="title">
                        An Evidence-Based System Where Doctors Treat Symptoms
                        Using Modern Medications
                      </h4>
                      <p className="content"></p>
                      {/* <ul className="tp-list-meta border-bt-dot">
                          <li>
                            <i className="fa fa-calendar-o" />
                          </li>
                          <li>
                            <i className="fa fa-clock-o" />
                          </li>
                          <li>
                            <i className="fa fa-star" />
                          </li>
                        </ul> */}
                    </div>
                  </div>
                </div>
                <div className="d-list-slider-item">
                  <div className="single-destinations-list text-center">
                    <div className="thumb">
                      <img
                        src={publicUrl + "assets/img/destination-list/2.gif"}
                        alt="list"
                      />
                      <div className="d-list-btn-wrap">
                        <div className="d-list-btn viaje-go-top">
                          <Link className="btn btn-yellow" to="/signin" onClick={() => handleConsultUsClick("Patient")}>
                            Consult US <i className="fa fa-paper-plane" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="details">
                      <h4 className="title">
                        The Basic Principles Of Homoeopathic Medicine Is
                        "SIMILIA SIMILIBUS CURENTUR"
                      </h4>
                      <p className="content"></p>
                      {/* <ul className="tp-list-meta border-bt-dot">
                          <li>
                            <i className="fa fa-calendar-o" />
                          </li>
                          <li>
                            <i className="fa fa-clock-o" />
                          </li>
                          <li>
                            <i className="fa fa-star" />
                          </li>
                        </ul> */}
                    </div>
                  </div>
                </div>
                <div className="d-list-slider-item">
                  <div className="single-destinations-list text-center">
                    <div className="thumb">
                      <img
                        src={publicUrl + "assets/img/destination-list/3.gif"}
                        alt="list"
                      />
                      <div className="d-list-btn-wrap">
                        <div className="d-list-btn viaje-go-top">
                          <Link className="btn btn-yellow" to="/signin" onClick={() => handleConsultUsClick("Patient")}>
                            Consult US <i className="fa fa-paper-plane" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="details">
                      <h4 className="title">
                        The Basic Principle Of Aayurveda Is <br></br>
                        "Vata, Pitta, Kapha"<br></br>
                      </h4>
                      <p className="content"></p>
                      {/* <ul className="tp-list-meta border-bt-dot">
                          <li>
                            <i className="fa fa-calendar-o" />
                          </li>
                          <li>
                            <i className="fa fa-clock-o" />
                          </li>
                          <li>
                            <i className="fa fa-star" />
                          </li>
                        </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 align-self-center order-lg-11">
              <div className="container">
                <div className="destinations-slider-controls">
                  <div className="slider-nav tp-control-nav" />
                  {/*slider-nav*/}
                  <div className="tp-slider-extra slider-extra">
                    <div className="text">
                      <span className="first">01 </span>
                      <span className="last" />
                    </div>
                    {/*text*/}
                    <div
                      className="d-list-progress"
                      role="progressbar"
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <span className="slider__label sr-only" />
                    </div>
                  </div>
                  {/*slider-extra*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default Offer;
