import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const CheckboxComponent = ({ label, checked, onChange, style }) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label={label}
      style={style}
    />
  );
};

export default CheckboxComponent;

{/* <CheckboxComponent label="Check me" checked={isChecked} onChange={handleCheckboxChange} /> */}