import React, { useEffect, useState } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faFileAlt, faStethoscope, faBars, faTimes, faFile  } from '@fortawesome/free-solid-svg-icons';
import CustomModal from "../../global-components/modal";
import { auth } from '../../login-signup/screens/firebase.config';
import { findOneDoc1Status, findOneDoc2Status, checkTokenValidity, logoutUser } from '../../../api';

const Sidebar = () => {
  let anchor = '#'
  let imagealt = 'image'
  let publicUrl = process.env.PUBLIC_URL + '/'
  let history = useHistory();
  const [doc1Status, setDoc1Status] = useState('');
  const [doc2Status, setDoc2Status] = useState('');
  const [isSubmit, setIsSubmit] = useState('');
  const [isSubmit2, setIsSubmit2] = useState('');
  const userDataJSON = localStorage.getItem("user");
  console.log("__++printing user data in ticket page of doc")
  console.log(userDataJSON)
  const userData = userDataJSON ? JSON.parse(userDataJSON) : {};
  const minscript = document.createElement("script");
  minscript.async = true;
  minscript.src = publicUrl + "assets/js/main.js";
  document.body.appendChild(minscript);

  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const userId = userData.id;
      try {
        const data = await findOneDoc1Status(userId); 
      
        console.log('API response:', data);
        setDoc1Status(data.is_accepted);
        setIsSubmit(data.is_submit);
        console.log("sidebar doc isSubmit", data.is_submit)
      } catch (error) {
        console.error('API Error:', error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const userId = userData.id;
      try {
        const data = await findOneDoc2Status(userId); 
      
        console.log('API response:', data);
        setDoc2Status(data.is_accepted);
        setIsSubmit2(data.is_submit);
        console.log("sidebar doc isSubmit2", data.is_submit);
      } catch (error) {
        console.error('API Error:', error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleLogout = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwt.decode(token);
        const userId = decodedToken && decodedToken.user_id;

        // Check the token validity before logging out
        const isValidToken = await checkTokenValidity(token);

        if (!isValidToken) {
          console.log('Token is invalid. Logging out without API call.');
          localStorage.removeItem('token');
          // Remove all items from local storage
          localStorage.clear();
          console.log('Logout successful');
          // Redirect to the login page or any other desired page
          history.push('/');
          return;
        }

        console.log('Token is valid. Proceeding with logout API call...');

        // Call the logout API
        const logoutSuccess = await logoutUser(userId);

        if (logoutSuccess) {
          localStorage.removeItem('token');
          localStorage.clear();
          console.log('Logout successful');
          history.push('/');
        } else {
          console.error('Logout failed');
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('Token not found in local storage');
      history.push('/');
    }
  };

  const handleDocFormClick = async () => {
    const isEmailVerifiedPro = await auth.currentUser.reload().then(() => {
      return auth.currentUser.emailVerified;
    });

    if (!isEmailVerifiedPro) {
      // Show the custom modal for email verification
      showErrorModal("Email Verification Required", "Please verify your email to continue.");
    }else if (isSubmit === 'true' && doc1Status === 'pending') {
      // showErrorModal("Alert", "Thank you for submitting your basic details. Your application is under processing.");
    } else if (isSubmit === 'true' && doc1Status === 'false') {
      // showErrorModal("Alert", "Your application form has been declined. The dashboard displays the disapproved fields. Kindly edit these areas and resubmit the form.");
      history.push('/doc/section1');
    } else if (isSubmit === 'true' && doc1Status === 'true') {
      // showErrorModal("Alert", "Your primary application has been successfully processed. Please proceed to complete the form on the dashboard for advanced verification.");
      // history.push('/doc/section1-2');
    } else {
      history.push('/doc/section1');
    }
  };

  const handleDoc2FormClick = () => {
    if (isSubmit2 === 'true' && doc2Status === 'pending') {
      // showErrorModal("Alert", "Thank you for submitting your verification details. Your application is under processing.");
    } else if (isSubmit2 === 'true' && doc2Status === 'false') {
      // showErrorModal("Alert", "Your verification form has been declined. The dashboard displays the disapproved fields. Kindly edit these areas and resubmit the form.");
      history.push('/doc/section1-2');
    } else {
      history.push('/doc/section1-2');
    }
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <div className='doc-main-sidebar'>
      <div className="widget_ads">
        <a href="/" style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
          <img src={publicUrl + "assets/img/sticky-logo.png"} alt={imagealt} style={{ width: '55%' }} />
        </a>
      </div>
      <aside className="sidebar-area sidebar-area-4">
        <div className="widget widget_search bg-none pd-none">
        </div>
        <div className="widget_categories">
          <h2 className="widget-title">Doctor</h2>
          <ul>
            <li>
              <Link to="/doc/docdashboard">
              <FontAwesomeIcon icon={faHome} /> Dashboard
              </Link>
            </li>
            {isSubmit !== 'true' && (
              <li>
                <Link onClick={handleDocFormClick}>
                <FontAwesomeIcon icon={faFileAlt} />  Application Form
                </Link>
              </li>
            )}
            {doc1Status === 'false' && isSubmit === 'true' && (
              <li>
                <Link onClick={handleDocFormClick}>
                <FontAwesomeIcon icon={faFileAlt} />  Application Form
                </Link>
              </li>
            )}
            {doc1Status === 'true' && isSubmit2 !== 'true' && (
              <li>
                <Link onClick={handleDoc2FormClick}>
                <FontAwesomeIcon icon={faFileAlt} />  Verification Form
                </Link>
              </li>
            )}
            {doc2Status === 'false' && isSubmit2 === 'true' && (
              <li>
                <Link onClick={handleDoc2FormClick}>
                <FontAwesomeIcon icon={faFileAlt} />  Verification Form
                </Link>
              </li>
            )}
            <li>
              <Link to="/doc/docticket">
              <FontAwesomeIcon icon={faFile} /> Ticket</Link>
            </li>
          </ul>
        </div>
        <div className='doc-logout pro-logout' style={{ position: 'absolute', bottom: '30px', left: '100px' }}>
          <button className="btn btn-yellow" type="button" onClick={handleLogout}>Logout</button>
        </div>
      </aside>
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </div>
  );
};

export default withRouter(Sidebar);