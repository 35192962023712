import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../pro-components/Page';
import SubmitAction from '../pro-components/submitaction';
import axios from 'axios';
import FirstSection from '../pro-components/FirstSection'
import CustomModal from "../../global-components/modal";
import { updateDoctor1, sendEmail, createDoc1Status } from '../../../api';

const SubmitPage = () => {
  const history = useHistory();
  const [sectionData, setSectionData] = useState('');
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const docId = localStorage.getItem('docId');
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  const updateSectionData = async (sectionData) => {
    const docId = localStorage.getItem("docId");
    console.log("doc id from local storage", docId);
    if (!docId) {
      console.log("doc ID not found in local storage");
      return;
    }
    try {
      const existingSectionData = JSON.parse(localStorage.getItem("docfirstPageData"));
      const sectionDataObject = [
        {
          0: existingSectionData, // Include the first page data at index 0
        },
        {
          1: JSON.parse(localStorage.getItem('docsecondPageData')), // Include the second page data at index 1
        },
        {
          2: JSON.parse(localStorage.getItem('docthirdPageData')), // Include the second page data at index 1
        },
        {
          3: JSON.parse(localStorage.getItem('docfourthPageData')), // Include the second page data at index 1
        },
        {
          4: JSON.parse(localStorage.getItem('docfifthPageData')), // Include the second page data at index 1
        },
        {
          5: sectionData, // Include the third page data at index 2
        },
      ]
      const updatedSectionData = await updateDoctor1(docId, sectionDataObject);
  
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitData = {
      is_submit: true,
    };
    await updateSectionData(submitData);
    console.log("docId and userid from submit", docId, userData.id);
  
    if (docId && userData.id) {
      try {
        const doc1StatusData = {
          user_id: userData.id,
          doc_id: docId,
          is_submit: true,
          is_accepted: "pending",
        };
  
        const response = await createDoc1Status(doc1StatusData); // Use the createDoc1Status function
      } catch (error) {
        console.error('API error:', error);
      }
    } else {
      console.error('proId or userId not found in local storage');
    }
    localStorage.setItem('isSubmitDoc', true);
    showErrorModal("Submitted successfully");
    history.push('/doc/docdashboard');
  
    const sendMailResponse = await sendEmail(userData.email, 'Confirmation of Doctor Application Submission', emailLayout);
  
    console.log('Email sent successfully:', sendMailResponse.data);
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  const emailLayout = `
  <div style="max-width: 100%; margin: 0 auto; font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6;">
    <div style="background-color: #f8f8f8; padding: 20px; border-radius: 8px; text-align: center;">
      <img src="https://meditravels.s3.amazonaws.com/Logo/952d4079-478e-44ce-92e8-f8e03e84852d-sticky-logo.png" alt="MediTravels Logo" style="max-width: 20%; height: auto;" />
      <p style="text-align: left; margin: 5px 0;">Dear ${userData.name},</p>
        <p style="text-align: left;">Thank you for submitting your application for Doctor membership with MediTravels. We have received your application and will now begin processing it.
        Our team will review your application and verify the information provided. We'll notify you as soon as your application is approved or if we need any additional information.</p>
        <p style="text-align: left; margin: 5px 0 2px;">Best regards,</p>
        <p style="text-align: left; margin: 0px;">MeditravelS Team</p>
      </div>
    </div>`;

  return (
    <Page>
      <FirstSection title="Submit Your Application In Doctor">
        After collecting this information, you can review the submitted applications to ensure that you hire qualified individuals who meet your company's requirements for providing accurate and helpful information to local patients seeking medical treatment in India.
      </FirstSection>
      <SubmitAction backLink="/doc/section5" onSubmit={handleSubmit} />
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
};

export default SubmitPage;