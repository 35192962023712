import React, { useState, useRef, useEffect } from 'react';
import Page from '../pro-components/Page';
import CustomTextField from '../pro-components/CTextField';
import SectionComponent from '../pro-components/Section';
import FirstSection from '../pro-components/FirstSection';
import FileUpload from '../pro-components/CFileUpload';
import FirstAction from '../pro-components/FirstPageAction';
import CustomButton from '../pro-components/CButton';
import { useHistory } from "react-router-dom";
import Ticket from '../pro-components/ticket';
import YearPickerComponent from '../pro-components/YearPicker';
import CustomModal from "../../global-components/modal";
import dayjs from 'dayjs';
import { updatePro2, uploadMedia } from '../../../api';

const EducationalBackground = () => {
  const [mainFile, setMainFile] = useState(null);
  const [mainPreviewUrl, setMainPreviewUrl] = useState(null);
  const [mainFileError, setMainFileError] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [educationalBackgroundEntries, setEducationalBackgroundEntries] = useState([]);
  const [otherDegree, setOtherDegree] = useState(null);
  const [otherDegreePreview, setOtherDegreePreview] = useState(null);
  const [degreeInput, setDegreeInput] = useState('');
  const [otherDegreeinput, setOtherDegreeInput] = useState('');
  const [study_field, setStudy_field] = useState('');
  const [uni_name, setUni_name] = useState('');
  const [graduation_year, setGraduation_year] = useState('');
  const [degreeInputError, setDegreeInputError] = useState('');
  const [otherDegreeInputError, setOtherDegreeInputError] = useState('');
  const [studyFieldError, setStudyFieldError] = useState('');
  const [uniNameError, setUniNameError] = useState('');
  const [gradYearError, setGradYearError] = useState('');
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [imageUrl2, setImageUrl2] = useState('');
  const [otherPreviewUrl, setOtherPreviewUrl] = useState(null);
  const [otherFileError, setOtherFileError] = useState('');
  const [sectionData, setSectionData] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [imagePreviewUrl1, setImagePreviewUrl1] = useState(null);
  const [pdfPreviewUrl1, setPdfPreviewUrl1] = useState(null);
  const pdfOpenParams = 'toolbar=0&navpanes=0&scrollbar=0';
  const userDataJSON = localStorage.getItem("user");
  const userData = userDataJSON !== undefined ? JSON.parse(userDataJSON) : {};
  const previousPageURL = '/pro/section3';
  let history = useHistory();
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);

  const [selectedYear, setSelectedYear] = useState('');

  const handleYearChange = (year) => {
    const yearObject = dayjs().year(year);
    setSelectedYear(yearObject.year()); // Store only the year as a number
    console.log("Printing selected year:", yearObject.year());
  };

  useEffect(() => {
    if (selectedYear !== '') {
      console.log("Selected Year:", selectedYear);
    }
  }, [selectedYear]);

  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };

  useEffect(() => {
    if (selectedYear !== '') {
      console.log("Selected Year:", selectedYear);
    }
  }, [selectedYear]);

  useEffect(() => {
    const storedData = localStorage.getItem('firstPageData2');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      console.log("data from ls, sec1 p-2 img", parsedData.otherDegree)
      setSectionData(parsedData);
      setMainFile(parsedData.mainFile);
      setEducationalBackgroundEntries(parsedData.list_degree || []);
      setOtherDegree(parsedData.otherDegree || null);
      setOtherDegreePreview(parsedData.otherDegree || null);
      setDegreeInput(parsedData.degreeInput || null);
      setOtherDegreeInput(parsedData.otherDegreeinput || null);
      setStudy_field(parsedData.study_field || null);
      setUni_name(parsedData.uni_name || null);
      setGraduation_year(parsedData.graduation_year || null);

      setSelectedYear(parsedData.selectedYear || null);

    }
  }, []);

  const updateSectionData = async (sectionData) => {
    console.log("user id from local storage", userData.id);
    const userId = userData.id;
    console.log("-------userId from local storage", userId);

    if (!userId) {
      console.log("User ID not found in local storage");
      return;
    }

    try {
      const section_data = [
        {
          0: sectionData,
        },
      ];

      console.log("Updated data of doc2");
      console.log(section_data);

      const updatedSectionData = await updatePro2(userId, section_data);

      if (updatedSectionData) {
        console.log("------Updated data of doc");
        console.log(updatedSectionData);
      } else {
        console.error("No data returned from updateDoctor2.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  //   const handleMainFileChange = (file) => {
  //     setMainFile(file);
  //     setMainPreviewUrl(URL.createObjectURL(file));
  //   };

  const handleAddEntry = () => {

    console.log("mamadnd", educationalBackgroundEntries)

    if (otherDegreeinput && imageUrl2) {
      const newEntry = {
        degree: otherDegreeinput,
        image: imageUrl2,
      };
      const updatedEducationalBackgroundEntries = [...educationalBackgroundEntries, newEntry];
      setEducationalBackgroundEntries(updatedEducationalBackgroundEntries);
      setOtherDegreeInput('');
      setImageUrl2('');
      setOtherDegree(null);
      setImageUrl2(null);
      setImagePreviewUrl1(null);
      setPdfPreviewUrl1(null);
      setSectionData((prevData) => ({
        ...prevData, list_degree: updatedEducationalBackgroundEntries,
      }));
    }
  };

  const handleRemoveEntry = (index) => {
    const updatedEntries = [...educationalBackgroundEntries];
    updatedEntries.splice(index, 1);
    setEducationalBackgroundEntries(updatedEntries);

    setSectionData((prevData) => ({
      ...prevData,
      list_degree: updatedEntries,
    }));
  };

  const handleDegreeChange = (event) => {
    const degreeInput = event.target.value;
    setDegreeInput(degreeInput);
    setDegreeInputError(validateDegree(degreeInput));

    setSectionData((prevData) => ({
      ...prevData,
      degreeInput: degreeInput,
    }));
  };

  const handleStudyFieldChange = (event) => {
    const study_field = event.target.value;
    setStudy_field(study_field);
    setStudyFieldError(validateStudyField(study_field));

    setSectionData((prevData) => ({
      ...prevData,
      study_field: study_field,
    }));
  };

  const handleUniNameChange = (event) => {
    const uni_name = event.target.value;
    setUni_name(uni_name);
    setUniNameError(validateUniName(uni_name));

    setSectionData((prevData) => ({
      ...prevData,
      uni_name: uni_name,
    }));
  };

  const handleGradYearChange = (event) => {
    const graduation_year = event.target.value;
    setGraduation_year(graduation_year);
    setGradYearError(validateGraduationYear(graduation_year));

    setSectionData((prevData) => ({
      ...prevData,
      graduation_year: graduation_year,
    }));
  };

  const handleOtherDegreeChange = (event) => {
    const otherDegreeinput = event.target.value;
    setOtherDegreeInput(otherDegreeinput);
    setOtherDegreeInputError(validateOtherDegree(otherDegreeinput));

    setSectionData((prevData) => ({
      ...prevData,
      otherDegreeinput: otherDegreeinput,
    }));
  };

  const validateDegree = (value) => {
    let error = "";

    if (!value) {
      error = "Please enter the highest level of education completed.";
    } else if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }

    return error;
  };

  const validateStudyField = (value) => {
    let error = "";

    if (!value) {
      error = "Please enter the field of study.";
    } else if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }

    return error;
  };

  const validateUniName = (value) => {
    let error = "";

    if (!value) {
      error = "Please enter the institution name / university name.";
    } else if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }

    return error;
  };

  const validateGraduationYear = (value) => {
    let error = "";

    if (!value) {
      error = "Please enter the graduation year.";
    } else if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }

    return error;
  };

  const validateOtherDegree = (value) => {
    let error = "";

    if (!value) {
      error = "Please enter the other Degree input.";
    } else if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }

    return error;
  };

  const validateCertificate = (file) => {
    let error = '';

    if (!file) {
      error = 'Please select a certificate file.';
    }

    return error;
  };

  const handleFileUpload = async (file) => {
    try {
      const mediaType = "PRO2";

      const uploadedImagePath = await uploadMedia(file, mediaType);

      console.log('Image URL:', uploadedImagePath);

      // Determine the file type and set the corresponding preview URL
      if (file.type.startsWith('image/')) {
        setImagePreviewUrl(URL.createObjectURL(file));
        setPdfPreviewUrl(null);
        setMainFile(uploadedImagePath);
      }
      else if (file.type === 'application/pdf') {
        setPdfPreviewUrl(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
        setImagePreviewUrl(null);
        setMainFile(uploadedImagePath);
      }
      await saveImageUrlToDatabase(uploadedImagePath)
    } catch (error) {
      console.error(error);
    }
  };

  const saveImageUrlToDatabase = async (imageUrl) => {
    const userId = userData.id;
    const sectionData = { mainFile: imageUrl };
  
    try {
      const response = await updatePro2(userId, sectionData);  

      console.log('Image URL saved in the database:', response.data);
    } catch (error) {
      console.error(error);
    }

    setSectionData((prevData) => ({
      ...prevData,
      mainFile: imageUrl,
    }));
  };

  const handleFileUpload2 = async (file) => {

    try {
      const mediaType = "PRO2";

      const uploadedImagePath = await uploadMedia(file, mediaType);

      console.log('Image URL:', uploadedImagePath);

      // Determine the file type and set the corresponding preview URL
      if (file.type.startsWith('image/')) {
        setImagePreviewUrl1(URL.createObjectURL(file));
        setPdfPreviewUrl1(null);
        setOtherDegree(uploadedImagePath);
        setImageUrl2(uploadedImagePath);

      }
      else if (file.type === 'application/pdf') {
        setPdfPreviewUrl1(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
        setImagePreviewUrl1(null);
        setOtherDegree(uploadedImagePath);
        setImageUrl2(uploadedImagePath);
      }

      await saveImageUrlToDatabase2(uploadedImagePath)

    } catch (error) {
      console.error(error);
    }
  };

  const saveImageUrlToDatabase2 = async (imageUrl) => {

    const userId = userData.id;
    const sectionData = { otherDegreePreview: imageUrl };
  
    try {
      const response = await updatePro2(userId, sectionData);  

      console.log('Image URL saved in the database:', response.data);
    } catch (error) {
      console.error(error);
    }
    setSectionData((prevData) => ({
      ...prevData,
      otherDegreePreview: imageUrl,
    }));
  };

  const handleNextClick = async (event) => {
    event.preventDefault();
    let hasError = false;

    const degreeError = validateDegree(degreeInput);
    const studyFieldError = validateStudyField(study_field);
    const uniNameError = validateUniName(uni_name);
    const gradYearError = validateGraduationYear(graduation_year);
    // const otherDegreeError = validateOtherDegree(otherDegreeinput);
    const otherFileError = validateCertificate(otherDegree);

    if (degreeError) {
      showErrorModal("Alert", "Please enter the highest level of education completed.");
      setDegreeInputError(degreeError);
      hasError = true;
      return;
    }

    if (!mainFile) {
      showErrorModal("Alert", "Please select a certificate file of highest level of education.");
      hasError = true;
      return;
    }

    if (studyFieldError) {
      showErrorModal("Alert", "Please enter the field of study.");
      setStudyFieldError(studyFieldError);
      hasError = true;
      return;
    }

    if (uniNameError) {
      showErrorModal("Alert", "Please enter the institution name / university name.");
      setUniNameError(uniNameError);
      hasError = true;
      return;
    }

    if (!selectedYear) {
      showErrorModal("Alert", "Please enter the graduation year.");
      setGradYearError(gradYearError);
      hasError = true;
      return;
    }

    // if (otherDegreeError) {
    //   alert('Please enter other degrees with institution');
    //   setOtherDegreeInputError(otherDegreeError);
    //   hasError = true;
    //   return;
    // }

    if (otherDegree && !otherDegreePreview) {
      // showErrorModal("Alert", "Please Add the selected file and the other degree.");
      // Additional check for the "other file" preview, if the "other degree" is entered
      setOtherFileError('Please Add the selected file and the other degree.');
      hasError = true;
      return;
    }

    if (!otherDegree && otherDegreePreview) {
      // showErrorModal("Alert", "Please enter other degrees with the institution.");
      // Additional check for the "other degree" field, if the "other file" is selected
      setOtherDegreeInputError('Please enter other degrees with the institution.');
      hasError = true;
      return;
    }
    if (hasError) {
      showErrorModal("Alert", "Please fill in all the required fields.");
      alert('Please fill in all the required fields.');
      return;
    }
    const firstPageData = {
      degreeInput: degreeInput,
      mainFile: mainFile,
      study_field: study_field,
      uni_name: uni_name,
      graduation_year: graduation_year,
      selectedYear: selectedYear,
      list_degree: educationalBackgroundEntries,
    };
    localStorage.setItem("firstPageData2", JSON.stringify(firstPageData));
    // Update the section data with the new values
    await updateSectionData(firstPageData);
    history.push('/pro/section2-2');

  };

  useEffect(() => {
    const storedData = localStorage.getItem('firstPageData2');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      console.log("data from ls, sec1 p-2 img", parsedData.otherDegree)
      setSectionData(parsedData);
      setMainFile(parsedData.mainFile);

      if (parsedData.mainFile && parsedData.mainFile.endsWith('.pdf')) {
        setPdfPreviewUrl(`https://docs.google.com/viewer?url=${encodeURIComponent(parsedData.mainFile)}&embedded=true`);
      } else {
        setImagePreviewUrl(parsedData.mainFile);
      }

      setEducationalBackgroundEntries(parsedData.list_degree || []);

      setOtherDegree(parsedData.otherDegree || null);
      setOtherDegreePreview(parsedData.otherDegree || null);
      setDegreeInput(parsedData.degreeInput || null);
      setOtherDegreeInput(parsedData.otherDegreeinput || null);
      setStudy_field(parsedData.study_field || null);
      setUni_name(parsedData.uni_name || null);
      setGraduation_year(parsedData.graduation_year || null);
    }
  }, []);

  const handleClearForm = () => {
    setMainFile('');
    setMainPreviewUrl('');
    setMainFileError('');
    setEducationalBackgroundEntries([]);
    setOtherDegree('');
    setOtherDegreePreview('');
    setDegreeInput('');
    setOtherDegreeInput('');
    setStudy_field('');
    setUni_name('');
    setGraduation_year('');
    setPdfPreviewUrl("");
    setImagePreviewUrl("");
    setSelectedYear("");


  };

  const EducationalBackgroundEntry = ({ degree, file, onRemoveEntry }) => {
    let previewUrl = null;

    const [pdfpreview, setpdfpreview] = useState(null)
    const [imagepreview, setimagepreviewurl] = useState(null)

    console.log("filetype", file)
    if (file && typeof file === 'object') {
      previewUrl = URL.createObjectURL(file);
    } else if (typeof file === 'string') {
      previewUrl = file;
    }

    useEffect(() => {
      if (previewUrl && previewUrl.endsWith('.pdf')) {
        setpdfpreview(`https://docs.google.com/viewer?url=${encodeURIComponent(previewUrl)}&embedded=true`);
      } else {
        setimagepreviewurl(previewUrl)
      }

    }, [previewUrl])

    return (
      <SectionComponent>
        <div style={{ paddingBottom: '20px' }}>
          Degree:
          <strong>{degree}</strong>
        </div>
        <div>
          Certificate:
          <br />
          <br />
          {imagepreview && (
            <>
              <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn"></div>
              <div style={{ border: '1px solid black' }}>
                <img src={previewUrl} alt="Selected" style={{ width: '100%' }} />
              </div>
            </>
          )}

          {pdfpreview && (
            <>
              <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                <CustomButton type="button" // Reset the PDF preview URL
                >X</CustomButton>
              </div>
              <div style={{ border: '1px solid black' }}>
                <iframe
                  src={pdfpreview}
                  title="PDF Preview"
                  style={{ width: '100%', height: '500px' }}
                />
              </div>
            </>
          )}

        </div>
        <div>
          <br />
          <CustomButton type="button" onClick={onRemoveEntry}>
            Remove
          </CustomButton>
        </div>
      </SectionComponent>
    );
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  return (
    <Page>
      <FirstSection>
        <h4>Educational Background </h4>
      </FirstSection>
      <SectionComponent>

        <label className="sec-label">HIGHEST LEVEL OF EDUCATION COMPLETED</label>

        <CustomTextField
          id="outlined-basic"
          placeholder="Highest Level of Education Completed"
          variant="outlined"
          value={degreeInput}
          onChange={handleDegreeChange}
          error={degreeInputError !== ''}
          helperText={degreeInputError}
        />
        <FileUpload onChange={handleFileUpload} />
        {mainFileError && <span style={{ color: 'red' }}>{mainFileError}</span>}
        <div>
          {imagePreviewUrl && (
            <> <div style={{ paddingTop: '15px', paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
              <CustomButton type="button" onClick={() => setImagePreviewUrl(null)} // Reset the image preview URL
              >X</CustomButton>
            </div>
              <div style={{ border: '1px solid black' }}>
                <img src={imagePreviewUrl} alt="Selected" style={{ width: '100%' }} />
              </div>
            </>
          )}
          {pdfPreviewUrl && (
            <>
              <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                <CustomButton type="button" onClick={() => setPdfPreviewUrl(null)} // Reset the PDF preview URL
                >X</CustomButton>
              </div>
              <div style={{ border: '1px solid black' }}>
                <iframe
                  src={pdfPreviewUrl}
                  title="PDF Preview"
                  style={{ width: '100%', height: '500px' }}
                />
              </div>
            </>
          )}
        </div>
      </SectionComponent>
      <SectionComponent>

        <label className="sec-label">FIELD OF STUDY</label>

        <CustomTextField
          id="field-of-study"
          placeholder="Field of Study"
          variant="outlined"
          value={study_field}
          onChange={handleStudyFieldChange}
          error={studyFieldError !== ''}
          helperText={studyFieldError}
        />
      </SectionComponent>
      <SectionComponent>

        <label className="sec-label">INSTITUTION NAME / UNIVERSITY NAME*</label>

        <CustomTextField
          id="institution-name"
          placeholder="Institution Name / University Name"
          variant="outlined"
          value={uni_name}
          onChange={handleUniNameChange}
          error={uniNameError !== ''}
          helperText={uniNameError}
        />
      </SectionComponent>
      <SectionComponent>

        <label className="sec-label">GRADUATION YEAR*</label>
        <br></br>
        <YearPickerComponent style={{ paddingTop: '20px' }} value={selectedYear} onChange={handleYearChange} />

        {/* <CustomTextField
          id="graduation-year"
          placeholder="Graduation Year"
          variant="outlined"
          value={graduation_year}
          onChange={handleGradYearChange}
          error={gradYearError !== ''}
          helperText={gradYearError}
        /> */}

      </SectionComponent>
      <SectionComponent>
        <label className="sec-label">OTHER DEGREES WITH INSTITUTION / UNIVERSITY NAME AND PASSING YEAR</label>
        <CustomTextField
          id="outlined-basic"
          placeholder="Highest Level of Education Completed"
          variant="outlined"
          value={otherDegreeinput}
          onChange={handleOtherDegreeChange}
          error={otherDegreeInputError !== ''}
          helperText={otherDegreeInputError}
        />
        <FileUpload onChange={handleFileUpload2} />
        {otherFileError && <span style={{ color: 'red' }}>{otherFileError}</span>}
        {imagePreviewUrl1 && (
          <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
            <CustomButton type="button" onClick={() => setImagePreviewUrl1(null)} // Reset the image preview URL
            >X</CustomButton>
          </div>
            <div style={{ border: '1px solid black' }}>
              <img src={imagePreviewUrl1} alt="Selected" style={{ width: '100%' }} />
            </div>
          </>
        )}
        {pdfPreviewUrl1 && (
          <>
            <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
              <CustomButton type="button" onClick={() => setPdfPreviewUrl1(null)} // Reset the PDF preview URL
              >X</CustomButton>
            </div>
            <div style={{ border: '1px solid black' }}>
              <iframe
                src={pdfPreviewUrl1 + '#' + pdfOpenParams}
                title="PDF Preview"
                style={{ width: '100%', height: '500px' }}
              />
            </div>
          </>
        )}
        <div style={{ paddingTop: '15px' }}>
          <CustomButton type="button" onClick={handleAddEntry}>
            Add
          </CustomButton>
        </div>
      </SectionComponent>
      {educationalBackgroundEntries.map((entry, index) => (
        <EducationalBackgroundEntry
          key={index}
          degree={entry.degree}
          file={entry.image}
          onRemoveEntry={() => handleRemoveEntry(index)}
        />
      ))}
      <FirstAction
        onClickNext={handleNextClick}
        onClearForm={handleClearForm}
      />
      <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
        <Ticket variant="contained" color="secondary" section="PRO: Verification Form - Educational Background" />
      </div>
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  );
}

export default EducationalBackground;