import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

const CustomTextArea = ({ id, label, variant, value, onChange, placeholder, rows, rowsMax }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        id={id}
        variant={variant}
        value={value}
        onChange={onChange}
        multiline
        rows={rows}
        rowsMax={rowsMax}
        onFocus={handleFocus}
        onBlur={handleBlur}
        fullWidth
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomTextArea;

{/* <CustomTextArea
  id="outlined-basic"
  variant="outlined"
  value={description}
  onChange={setDescription}
  placeholder="Enter your description"
  rows={4}
  rowsMax={8}
/> */}