import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

const FirstSection = ({ title, children }) => {
  const sectionStyle = {
    // backgroundColor: '#fff',
    // borderRadius: '8px',
    boxShadow: 'unset'
  };

  const headingStyle = {
    backgroundColor: '#f7a560',
    color: '#291211',
    padding: '16px',
    borderRadius: '8px 8px 0 0',
    fontWeight: '600'
  };

  return (
    <Card className='card-component' style={{width: '50%', borderRadius: '8px', marginBottom: '12px', overflow: 'unset' }}>
      <Typography className='first-sec-main-heading' variant="h5" component="div" style={headingStyle}>
        {title}
      </Typography>
        {/* <Card style={sectionStyle}> */}
          <CardContent className='first-sec-heading' style={{sectionStyle}}>{children}</CardContent>
        {/* </Card> */}
    </Card>
  );
};

export default FirstSection;

{/* <FirstSection title="My First Section"> */}
  {/* Your content here */}
{/* </FirstSection> */}