import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { fetchBlogs, fetchBlogById } from '../../api';

const BlogSection = () => {
  const [blogs, setBlogs] = useState([]);
  const location = useLocation();
  const { blog_id } = location.state || {};
  const history = useHistory();

  let publicUrl = process.env.PUBLIC_URL + "/"

  useEffect(() => {
    const page = 1;
    const category = 'Medical'; 

    fetchBlogs(page, category)
      .then((responseData) => {
        // Process and set your blog list data here
        setBlogs(responseData.data.list);
      })
      .catch((error) => {
        console.error('Error in BlogListPage:', error);
      });
  }, []);

  useEffect(() => {
    if (blog_id) {
      fetchBlogById(blog_id)
        .then((blogData) => {
          // Process and set your blog data here
          setBlogs(blogData);
        })
        .catch((error) => {
          console.error('Error in BlogPage:', error);
        });
    }
  }, [blog_id]);

  const handleCLick = (blog_id, image) => {
    localStorage.setItem("blog_id", blog_id);
    localStorage.setItem("image", image);
  };

  const blogDetailsPage = (blogId) => {
    history.push(`/blog-details/${blogId}`);
  };

  return (
    <div className="blog-area pd-top-120 viaje-go-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 blog-home center-div">
            <React.Fragment>
              {blogs.length > 0 &&
                blogs.slice(0, 1).map((blog) => (
                  <div
                    key={blog._id}
                    className="single-blog single-blog-wrap style-two"
                  >
                    <div className="thumb single-blog-left-wrap">
                      <img src={blog.blogimage} alt="blog" />
                    </div>
                    <div className="single-blog-details single-blog-right-wrap">
                      {/* <Link className="tag" to="/blog-details">
											{blog.category}
										</Link> */}
                      <p className="date">{blog.date}</p>
                      <h4 className="title">{blog.blogname}</h4>
                      <p className="content">
                        {blog.blogdetails.substring(0, 100)}
                        {blog.blogdetails.length > 100 ? "..." : ""}
                      </p>
                      <Link
                        className="btn-read-more"
                        to={{
                          pathname: `/blog-details/${blog._id}`,
                          state: { blog_id: blog._id, image: blog.image },
                        }}
                        onClick={() => handleCLick(blog._id, blog.image)}
                      >
                        <span>
                          Read More
                          <i className="la la-arrow-right" />
                        </span>
                      </Link>

                      {/* <Link
											className="btn-read-more"
											to={{
												pathname: `/blog-details/${blog._id}`,
												state: { blog_id: blog._id },
											}}
										>
											<span>
												Read More <i className="la la-arrow-right" />
											</span>
										</Link> */}
                    </div>
                  </div>
                ))}
            </React.Fragment>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <React.Fragment>
                {blogs.length > 0 &&
                  blogs.slice(1, 5).map((blog) => (
                    <>
                      <div className="col-sm-6 blog-home" key={blog._id}>
                        <div className="single-blog style-three">
                          <div className="thumb">
                            <img src={blog.blogimage} alt="blog" />
                          </div>
                          <div className="single-blog-details-wrap">
                            <div className="single-blog-details text-center">
                              {/* <Link className="tag" to="/blog-details">
															{blog.category}
														</Link> */}
                              <p className="date">{blog.date}</p>
                              <h4 className="title">{blog.blogname}</h4>
                              <Link
                                className="btn-read-more"
                                to={{
                                  pathname: `/blog-details/${blog._id}`,
                                  state: {
                                    blog_id: blog._id,
                                    image: blog.image,
                                  },
                                }}
                                onClick={() =>
                                  handleCLick(blog._id, blog.image)
                                }
                              >
                                <span>
                                  Read More
                                  <i className="la la-arrow-right" />
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
