import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Page_header(props) {
  const location = useLocation();
  const [refresh, setRefresh] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state

  let HeaderTitle = props.headertitle;
  let publicUrl = process.env.PUBLIC_URL + '/';
  let Subheader = props.subheader ? props.subheader : HeaderTitle;
  let bimage = props.BImage;

  const backgroundImageStyle = {
    backgroundImage: loading
      ? `url(${publicUrl}assets/img/banner/10.png)` // Fallback image
      : `url(${props.image})`, // Actual image
    opacity: loading ? 0 : 1, // Set opacity to 0 when loading, and 1 when loaded
  };

  const handleImageLoad = () => {
    setLoading(false); // Set loading state to false when the image is loaded
  };

  return (
    <div className={`breadcrumb-area jarallax ${loading ? 'loading' : ''}`} style={backgroundImageStyle}>
      {loading && (
        <div className="loader">
          <div className="loader-circle"></div>
        </div>
      )}
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner">
              <ul className="page-list">
                {/* <li><Link to="/">Home</Link></li>
                  <li>{Subheader}</li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <img
        src={props.image}
        alt="Header Image"
        style={{ display: 'none' }}
        onLoad={handleImageLoad}
      />
    </div>
  );
}

export default Page_header;