import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
// import BlogDetails from './blog-components/blog-details';//
// import BlogDetails from './blog-components/blog-details';
import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';
import TourList from './section-components/tour-list';

import Treatments from './section-components/treatments1';
const TreatmentDetails = () => {
    const headerImage = localStorage.getItem("subsubtreatmentimage");
    return <div>
        <Navbar />
        <PageHeader image={headerImage} headertitle={"KNEE REPLACEMENT - FULL / PARTIAL" }   />
        <Treatments/>
        <Subscribe />
        <Footer />
    </div>
}

// BlogDetails.propTypes = {
//      title: PropTypes.string
//   };

export default TreatmentDetails

