import React, { useRef } from 'react';
import CustomButton from './CButton';

const FileUpload = ({ onChange, previewUrl }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    onChange(file);
  };

  return (
    <div className='choose-btn' style={{ display: 'flex', flexDirection: 'column', marginTop: '18px' }}>
      <input
        type="file"
        accept=".jpg, .jpeg, .png, .pdf"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
        id="file-upload-input"
      />
      <label htmlFor="file-upload-input">
        <CustomButton variant="contained" color="primary" onClick={handleButtonClick}>
          Choose File
        </CustomButton>
      </label>

      {previewUrl && (
        <div style={{ paddingTop: '5px' }}>
          {previewUrl.endsWith('.pdf') ? (
            <iframe
              src={previewUrl}
              title="PDF Preview"
              style={{ width: '100%', height: '500px' }}
            />
          ) : (
            <img src={previewUrl} alt="Selected" style={{ width: '100%' }} />
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;