import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { fetchBlogs, fetchBlogById } from '../../api';

const HolidayFun = (props) => {
	const [blogs, setBlog] = useState([]);
	const location = useLocation();
	const { blog_id } = location.state || {};
	const history = useHistory();

	let publicUrl = process.env.PUBLIC_URL + '/';

	useEffect(() => {
		const page = page;
		const category = "Travel";
	
		const fetchData = async () => {
		  try {
			const response = await fetchBlogs(page, category);
	
			if (response) {
			  // Handle the response data here
			  setBlog(response.data.list);
			  localStorage.setItem("blog_id", response.blog_id);
			  localStorage.setItem("image", response.data.image_url);
			}
		  } catch (error) {
			console.error('Error fetching blogs:', error);
		  }
		};
	
		fetchData();
	  }, []);

	useEffect(() => {
		const fetchData = async () => {
		  if (blog_id) {
			try {
			  const response = await fetchBlogById(blog_id);
	
			  if (response) {
				// Handle the response data here
				console.log('blog data:', response);
				setBlog(response);
			  }
			} catch (error) {
			  console.error('Error fetching blog by ID:', error);
			}
		  }
		};
	
		fetchData();
	  }, [blog_id]);

	const blogDetailsPage = (blogId) => {
		history.push(`/blog-details/${blogId}`);
	};

	return (
		<div className="holiday-plan-area tp-holiday-plan-area mg-top-96" style={{ backgroundImage: 'url(' + publicUrl + 'assets/img/bg/8.png)' }}>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-6 col-lg-9">
						<div className="section-title text-center">
							<h2 className="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">Perfect Refreshing Plan</h2>
							<p className="wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">A perfect tourism refreshing plan should prioritize self-care, staying active, getting enough rest, and incorporating relaxation activities to promote physical, mental, and emotional well-being, offering unique experiences that showcase local culture and natural beauty.</p>
						</div>
					</div>
				</div>
				<div className="row">
					<React.Fragment>
						<div className="row">
							{blogs.length > 0 &&
								blogs.map((blog, index) => {
									if (index >= 12 && index <= 15) {
										return (
											<div className="col-lg-3 col-md-6" key={blog._id}>
												<div className="single-blog">
													<div className="thumb">
														<img src={blog.blogimage} alt="blog" />
													</div>
													<div className="single-blog-details">
														<h4 className="title"><Link to="/blog-details">{blog.blogname}</Link></h4>
														<p className="content">{blog.blogdetails.substring(0, 100)}{blog.blogdetails.length > 100 ? "..." : ""}</p>
														<Link
															className="btn-read-more"
															to={{ pathname: `/blog-details/${blog._id}`, state: { blog_id: blog._id } }}
														>
															<span>Read More <i className="la la-arrow-right" /></span>
														</Link>
													</div>
												</div>
											</div>
										);
									}
									return null;
								})}
						</div>
					</React.Fragment>
				</div>
			</div>
		</div>
	);
};

export default HolidayFun;