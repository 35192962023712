import React, { useState, useEffect, useRef } from 'react';
import Page from '../pro-components/Page'
import SectionComponent from '../pro-components/Section'
import FirstSection from '../pro-components/FirstSection'
import CustomTextField from '../pro-components/CTextField';
import FileUpload from '../pro-components/CFileUpload';
import CustomButton from '../pro-components/CButton';
import Action from '../pro-components/Action'
import { useHistory } from "react-router-dom";
import Ticket from '../pro-components/ticket';
import Webcam from 'react-webcam';
import { Modal, Button } from 'react-bootstrap';
import { BsPersonFill, BsCheckCircle } from 'react-icons/bs';
import CheckboxComponent from '../pro-components/CCheckBox';
import CustomModal from "../../global-components/modal";
import { updateDoctor1, uploadMedia } from '../../../api';

const RelevantExperience = () => {
  const [expiInput, setExpiInput] = useState('');
  const [expiImg, setExpiImg] = useState(null);
  const [expiPreviewUrl, setExpiPreviewUrl] = useState(null);
  const [expiInputError, setExpiInputError] = useState('');
  const [showExpiInputError, setshowExpiInputError] = useState(false);
  const [sectionData, setSectionData] = useState('');
  const webcamRef = useRef(null);
  const bodyRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showWebcamModal, setShowWebcamModal] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [imagePreviewUrl1, setImagePreviewUrl1] = useState(null);
  const [pdfPreviewUrl1, setPdfPreviewUrl1] = useState(null);
  const [photoProof, setPhotProof] = useState(null);
  const pdfOpenParams = 'toolbar=0&navpanes=0&scrollbar=0';
  const [mainFileError, setMainFileError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isModalScrolled, setIsModalScrolled] = useState(false);
  const [isAgreeEnabled, setIsAgreeEnabled] = useState(false);
  const [isScrollbarAtEnd, setIsScrollbarAtEnd] = useState(false);
  const [checkboxError1, setCheckboxError1] = useState("");
  const [showCheckboxError1, setShowCheckboxError1] = useState(false);
  const [isCheckedTC, setIsCheckedTC] = useState(false);
  const [isAgreeButtonClicked, setIsAgreeButtonClicked] = useState(false);
  let history = useHistory();
  const minscript = document.createElement("script");
  minscript.async = true;
  document.body.appendChild(minscript);
  const previousPageURL = '/doc/section4';
  const [errorModalData, setErrorModalData] = useState({
    title: "",
    message: "",
    show: false,
  });

  useEffect(() => {
    const handleScroll = () => {
      const target = bodyRef.current;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      const scrollTop = target.scrollTop;
      const maxScrollTop = scrollHeight - clientHeight;
      setIsModalScrolled(scrollTop === maxScrollTop);

      if (scrollTop === maxScrollTop) {
        setIsAgreeEnabled(true);
      } else {
        setIsAgreeEnabled(false);
      }
    };

    if (bodyRef.current) {
      bodyRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (bodyRef.current) {
        bodyRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isScrollbarAtEnd) {
      setIsAgreeEnabled(true);
    } else {
      setIsAgreeEnabled(false);
    }
  }, [isScrollbarAtEnd]);

  const showErrorModal = (title, message) => {
    setErrorModalData({
      title: title,
      message: message,
      show: true,
    });
  };
  const [capctureImgPhotoerror, setcapctureImgPhotoerror] = useState("")

  useEffect(() => {
    const storedSectionData = JSON.parse(localStorage.getItem('docfifthPageData'));
    console.log("printing data from localstorage of doc1 sec5", storedSectionData);
    if (storedSectionData) {
      setExpiInput(storedSectionData.expiInput || '');
      setExpiImg(storedSectionData.expiImg);
      setUploadedImageUrl(storedSectionData.uploadedImageUrl);
      setPhotProof(storedSectionData.photoproof);

      // Determine the file type and set the corresponding preview URLs
      if (storedSectionData.photoproof && storedSectionData.photoproof.startsWith('data:image/')) {
        setImagePreviewUrl(storedSectionData.photoproof);
        setPdfPreviewUrl(null);
      } else if (storedSectionData.photoproof && storedSectionData.photoproof.startsWith('data:application/pdf')) {
        setPdfPreviewUrl(storedSectionData.photoproof);
        setImagePreviewUrl(null);
      }

      setUploadSuccess(storedSectionData.capturedImage);
      setCapturedImage(storedSectionData.capturedImage);
    }
  }, []);

  useEffect(() => {
    if (modalImage) {
      setCapturedImage(modalImage);
      setModalImage(null);
      setShowWebcamModal(false);
      setUploadSuccess(false);
      setShowWebcam(false);
    }
  }, [modalImage]);

  const handleCaptureButtonClick = () => {
    setShowWebcamModal(true);
    setShowWebcam(true); // Show webcam feed when modal is opened
  };

  const updateSectionData = async (sectionData) => {
    const docId = localStorage.getItem("docId");
    console.log("doc id from local storage", docId);
    if (!docId) {
      console.log("doc ID not found in local storage");
      return;
    }
    try {
      const existingSectionData = JSON.parse(localStorage.getItem("docfirstPageData"));
      const sectionDataObject = [
        {
          0: existingSectionData, // Include the first page data at index 0
        },
        {
          1: JSON.parse(localStorage.getItem("docsecondPageData")), // Include the second page data at index 1
        },
        {
          2: JSON.parse(localStorage.getItem("docthirdPageData")), // Include the second page data at index 1
        },
        {
          3: JSON.parse(localStorage.getItem("docfourthPageData")), // Include the second page data at index 1
        },
        {
          4: sectionData, // Include the third page data at index 2
        },
      ]
      const updatedSectionData = await updateDoctor1(docId, sectionDataObject);
      console.log("updated data of doc");
      console.log(updatedSectionData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextClick = async (event) => {
    event.preventDefault();

    let hasError = false;

    if (!validateMasterDegreeInput(expiInput)) {
      setExpiPreviewUrl(true);
      hasError = true;
    }

    if (!isCheckedTC) {
      showErrorModal("Alert", "Company\'s terms & conditions field is required.");
      setCheckboxError1(true);
      return;
    }

    if (!photoProof) {
      showErrorModal("Alert", "Please provide your current photo ID Proof.");
      setMainFileError("Please upload a file.");
      return;
    }

    if (!capturedImage) {
      showErrorModal("Alert", "Please Provide Your Capcture Image Photo.");
      setcapctureImgPhotoerror("please Capcture a Image and Upload");
      return;
    }

    if (hasError) {
      return;
    }

    const docfifthPageData = {
      expiInput: expiInput,
      expiImg: expiImg,
      capturedImage: capturedImage,
      photoproof: photoProof,
    };
    localStorage.setItem("docfifthPageData", JSON.stringify(docfifthPageData));
    // Update the section data in the backend
    await updateSectionData(docfifthPageData);

    history.push("/doc/submit-phase1");

  };

  // useEffect(() => {
  //   if (capturedImage && capturedImage !== 'data:,') {
  //     setUploadSuccess(false); // Reset the upload success state
  //     setcapctureImgPhotoerror(""); // Clear any previous error message
  //   } else {
  //     setUploadSuccess(false); // Reset the upload success state
  //     setcapctureImgPhotoerror("Please capture a valid image."); // Display an error message
  //   }
  // }, [capturedImage]);

  const validateMasterDegreeInput = (value) => {
    setExpiInput(value);
    let error = '';
    if (/\s{2,}/.test(value)) {
      error = 'Multiple consecutive spaces are not allowed';
    }
    setExpiInputError(error);

    return !error; // Return true if there is no error
  };

  const handleMasterDegreeChange = (event) => {
    const expiInput = event.target.value;
    setExpiInput(expiInput);
    validateMasterDegreeInput(expiInput);
  };

  const handleMasterDegreeBlur = () => {
    setshowExpiInputError(true);
  };

  const handleFileUploadMaster = async (file) => {
    try {
      const mediaType = "DOC";
      
      const uploadedImagePath = await uploadMedia(file, mediaType);
      console.log('Image URL:', uploadedImagePath);

      if (file.type.startsWith('image/')) {
        setImagePreviewUrl(URL.createObjectURL(file));
        setPdfPreviewUrl(null);
        setExpiImg(uploadedImagePath);

      }
      else if (file.type === 'application/pdf') {
        setPdfPreviewUrl(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
        setImagePreviewUrl(null);
        setExpiImg(uploadedImagePath);
      }
      await saveMasterDegreeImageUrlToDatabase(uploadedImagePath)
    } catch (error) {
      console.error(error);
    }
  };

  const saveMasterDegreeImageUrlToDatabase = async (imageUrl) => {
    const docId = localStorage.getItem('docId');
    const sectionData = { expiImg: imageUrl };
    try {
      const response = await updateDoctor1(docId, sectionData);  
  
      console.log('Image URL saved in the database:', response.data);
    } catch (error) {
      console.error(error);
    }
    setSectionData((prevData) => ({
      ...prevData,
      expiImg: imageUrl,
    }));
  };

  const handleFileUpload = async (file) => {
    try {
      const mediaType = "DOC";

      const uploadedImagePath = await uploadMedia(file, mediaType);
      console.log('Image URL:', uploadedImagePath);
      // Determine the file type and set the corresponding preview URL
      if (file.type.startsWith('image/')) {
        setImagePreviewUrl1(URL.createObjectURL(file));
        setPdfPreviewUrl1(null);
        setPhotProof(uploadedImagePath);
      }
      else if (file.type === 'application/pdf') {
        setPdfPreviewUrl1(URL.createObjectURL(file)); // Use the uploaded file directly as the preview URL
        setImagePreviewUrl1(null);
        setPhotProof(uploadedImagePath);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    const storedSectionData = JSON.parse(localStorage.getItem('docfifthPageData'));
    if (storedSectionData) {
      setExpiInput(storedSectionData.expiInput || '');
      setExpiImg(storedSectionData.expiImg);
      if (storedSectionData.expiImg && storedSectionData.expiImg.endsWith('.pdf')) {
        setPdfPreviewUrl(`https://docs.google.com/viewer?url=${encodeURIComponent(storedSectionData.expiImg)}&embedded=true`);
      } else {
        setImagePreviewUrl(storedSectionData.expiImg);
      }

      setPhotProof(storedSectionData.photoproof || null);
      if (storedSectionData.photoproof && storedSectionData.photoproof.endsWith('.pdf')) {
        setPdfPreviewUrl1(`https://docs.google.com/viewer?url=${encodeURIComponent(storedSectionData.photoproof)}&embedded=true`);
      } else {
        setImagePreviewUrl1(storedSectionData.photoproof);
      }

      setUploadedImageUrl(storedSectionData.capturedImage);
      setCapturedImage(storedSectionData.capturedImage)

    }

  }, []);

  useEffect(() => {

    let webcamLoadingTimeout;
    if (showWebcam) {
      // Simulate webcam loading with a delay
      webcamLoadingTimeout = setTimeout(() => {
        setLoading(false); // Set loading state to false after delay
      }, 2000);
    }

    return () => {
      clearTimeout(webcamLoadingTimeout);
    };
  }, [showWebcam]);

  useEffect(() => {
    const isLoading = setTimeout(() => {
      setLoading(false);
    }, 2300);

    return () => {
      clearTimeout(isLoading);
    };
  }, []);

  const handleClearForm = () => {
    setExpiInput('');
    setExpiImg('');
    setExpiPreviewUrl('');
    setExpiInputError('');
    setshowExpiInputError('');
    setPdfPreviewUrl("")
    setPdfPreviewUrl1("")
    setImagePreviewUrl1("")
    setImagePreviewUrl("")
    setIsCheckedTC(false);
    setCapturedImage("")
    setUploadedImageUrl("")
    setPhotProof("")
  };

  const handleCapturePhoto = () => {
    const video = webcamRef.current.video;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const photo = canvas.toDataURL('image/jpeg');
    setCapturedImage(photo);
    setShowWebcam(false);
  };

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user',
  };

  const handleUploadImage = async () => {
    if (capturedImage) {
      try {
        setLoading(true); 
        const blob = await fetch(capturedImage).then((res) => res.blob());
        const uploadedImagePath = await uploadMedia(blob, 'Webcam');
        console.log('Image URL:', uploadedImagePath);

        // Store the uploaded image URL
        setUploadedImageUrl(uploadedImagePath);
        setCapturedImage(uploadedImagePath);
        // Further actions with the image URL if needed
        setcapctureImgPhotoerror("");
        setUploadSuccess(true);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading state to false
        setShowWebcamModal(false); // Close the modal after uploading
      }
    }
  };

  const handleRecaptureButtonClick = () => {
    setCapturedImage(null);
    setShowWebcam(true);
    setUploadSuccess(false); // Reset upload success state
  };

  const handleWebcamModalClose = () => {
    setShowWebcamModal(false);
    setShowWebcam(null); // Hide the webcam feed
    setLoading(false); // Reset the loading state
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({
      title: "",
      message: "",
      show: false,
    });
  };

  const handleCheckboxChangeTC = (checked) => {
    let error = '';
    if (!isAgreeButtonClicked) {
      error = 'Please click on the "View company\'s terms & conditions" button and then click "Agree" before checking the checkbox.';
      setCheckboxError1(error);
      return;
    }
    setIsCheckedTC(checked);
    setCheckboxError1(checked ? "" : "Company's terms & conditions field is required");

    setSectionData((prevData) => ({
      ...prevData,
      isCheckedTC: checked,
    }));
  };

  const handleCheckboxChangeTCBlur = () => {
    setShowCheckboxError1(true);
  };

  const handleViewClick = () => {
    setShowModal(true);
  };

  const handleAgreeClick = () => {
    setIsCheckedTC(true);
    setIsAgreeEnabled(false);
    setIsScrollbarAtEnd(false);
    setShowModal(false);
    const target = bodyRef.current;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

  };

  const handleModalClose = () => {
    setShowModal(false);
  };


  return (
    <Page>
      <FirstSection>
        <h4>Relevant Experience</h4>
      </FirstSection>
      <SectionComponent>
        <label className="sec-label">Total Years Of Experience Relative Field</label>
        <CustomTextField
          id="outlined-basic"
          placeholder="Total Years Of Experience Relative Field"
          variant="outlined"
          value={expiInput}
          onChange={handleMasterDegreeChange}
          onBlur={handleMasterDegreeBlur}
        />
        <span className="error-msg">
          {showExpiInputError && expiInputError && (
            <p className="error-msg">{expiInputError}</p>
          )}
        </span>
        <FileUpload onChange={handleFileUploadMaster} value={expiImg} />
        {imagePreviewUrl && (
          <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
            <CustomButton type="button" onClick={() => setImagePreviewUrl(null)} // Reset the image preview URL
            >X</CustomButton>
          </div>
            <div style={{ border: '1px solid black' }}>
              <img src={imagePreviewUrl} alt="Selected" style={{ width: '100%' }} />
            </div>
          </>
        )}
        {pdfPreviewUrl && (
          <>
            <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
              <CustomButton type="button" onClick={() => setPdfPreviewUrl(null)} // Reset the PDF preview URL
              >X</CustomButton>
            </div>
            <div style={{ border: '1px solid black' }}>
              <iframe
                src={pdfPreviewUrl + '#' + pdfOpenParams}
                title="PDF Preview"
                style={{ width: '100%', height: '500px' }}
              />
            </div>
          </>
        )}

      </SectionComponent>
      <SectionComponent>
        I AGREE TO TERMS AND CONDITIONS*
        <br />
        <CheckboxComponent label="Yes" checked={isCheckedTC}
          // onChange={handleCheckboxChangeTC} 
          onChange={() => handleViewClick()}
          onBlur={handleCheckboxChangeTCBlur} error={!!checkboxError1}
          helperText={checkboxError1} />
        {showCheckboxError1 && checkboxError1 && (
          <div className="error-msg">{checkboxError1}</div>
        )}
        <div style={{ paddingTop: '10px' }}>
          <CustomButton type="button" onClick={handleViewClick}>
            View
          </CustomButton>
        </div>
      </SectionComponent>
      <SectionComponent>
        PLEASE PROVIDE YOUR PHOTO ID PROOF*
        <div>
          <FileUpload onChange={handleFileUpload} />
          {mainFileError && <div className="error-msg">{mainFileError}</div>}
          <div style={{ paddingTop: '15px' }}>

            {imagePreviewUrl1 && (
              <> <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                <CustomButton type="button" onClick={() => setImagePreviewUrl1(null)} // Reset the image preview URL
                >X</CustomButton>
              </div>
                <div style={{ border: '1px solid black' }}>
                  <img src={imagePreviewUrl1} alt="Selected" style={{ width: '100%' }} />
                </div>
              </>
            )}
            {pdfPreviewUrl1 && (
              <>
                <div style={{ paddingRight: '10px', borderRadius: '50%' }} className="close-btn">
                  <CustomButton type="button" onClick={() => setPdfPreviewUrl1(null)} // Reset the PDF preview URL
                  >X</CustomButton>
                </div>
                <div style={{ border: '1px solid black' }}>
                  <iframe
                    src={pdfPreviewUrl1 + '#' + pdfOpenParams}
                    title="PDF Preview"
                    style={{ width: '100%', height: '500px' }}
                  />
                </div>
              </>
            )}

          </div>
        </div>
      </SectionComponent>
      <SectionComponent>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          PLEASE PROVIDE YOUR CURRENT PHOTO FOR THE PURPOSE OF IDENTIFICATION*
        </div>
        <div style={{ paddingTop: '15px' }}>
          {!showWebcam && !capturedImage && !uploadSuccess && !uploadedImageUrl && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BsPersonFill size={25} color="gray" style={{ marginRight: '10px' }} />
              <CustomButton onClick={handleCaptureButtonClick}>Capture Your Image</CustomButton>
            </div>
          )}
          {(uploadSuccess || uploadedImageUrl) && (
            <div>
              <BsPersonFill size={25} color="green" style={{ marginRight: '10px' }} />
              <BsCheckCircle size={20} color="green" style={{ marginRight: '10px' }} />
            </div>
          )}
        </div>
        {capctureImgPhotoerror && <div className="error-msg">{capctureImgPhotoerror}</div>}
      </SectionComponent>

      <Modal show={showWebcamModal} className="newsletter-popup" backdrop="static" >
        <Modal.Header>
          <Modal.Title>Capture Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showWebcam && (
            <div className='capture-modal' style={{ position: 'relative', width: '500px' }}>
              {loading && (
                <div className="loading" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <div></div>
                  <div></div>
                </div>
              )}
              {/* {!loading && ( */}
              <Webcam
                audio={false}
                height={300}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={500}
                videoConstraints={videoConstraints}
                imageSmoothing={true}
                mirrored={true}
                style={{ display: 'block' }}
              />

              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <CustomButton onClick={handleCapturePhoto} disabled={loading}>
                  Take a Shot
                </CustomButton>
                <CustomButton variant="secondary" onClick={handleWebcamModalClose}>
                  Close
                </CustomButton>
              </div>
            </div>
          )}
          {capturedImage && !uploadSuccess && !loading && (
            <div>
              <img src={capturedImage} alt="Captured" style={{ transform: 'scaleX(-1)' }} />
              <div style={{ padding: '20px 0px 0px', display: 'flex', justifyContent: 'space-between' }}>
                <CustomButton onClick={handleUploadImage}>Upload</CustomButton>
                <CustomButton onClick={handleRecaptureButtonClick}>Try Again</CustomButton>
              </div>
            </div>
          )}
          {capturedImage && !uploadSuccess && loading && (
            <div className="loading">
              <div></div>
              <div></div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal show={showModal} className="newsletter-popup view-popup" backdrop="static">
        <Modal.Header className="modal-close-button" style={{ width: '100%' }}>
          <Modal.Title >DOCTOR Terms and Conditions for MedITravelS</Modal.Title>
        </Modal.Header>
        <Modal.Body
          ref={bodyRef}
          style={{
            maxHeight: '400px', 
            overflowY: 'scroll',
          }}
          onScroll={(e) => {
            const target = e.target;
            const scrollHeight = target.scrollHeight;
            const clientHeight = target.clientHeight;
            const scrollTop = target.scrollTop;

            // Calculate a threshold for determining if the modal is scrolled to the end
            const scrollThreshold = 5; // Adjust this value as needed

            const isAtEnd = scrollHeight - scrollTop - clientHeight <= scrollThreshold;

            setIsModalScrolled(isAtEnd);

            if (isAtEnd) {
              setIsAgreeEnabled(true);
            } else {
              setIsAgreeEnabled(false);
            }
          }}
        >
           <span className="list-tc" style={{ display: 'inline-block' }}>1. Agreement: By accessing and using the facilities provided by MedITravelS, you agree to be bound by the following terms and conditions. These terms govern the relationship between MedITravelS and the doctors or consultants who provide treatment to patients visiting India for medical purposes.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>2. Medical Professionals: MedITravelS acts as an intermediary connecting patients with qualified doctors or consultants who specialize in the relevant medical field. The doctors or consultants are independent professionals, and MedITravelS does not have direct control over their practice.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>3. Verification of Medical Professionals: MedITravelS undertakes a thorough verification process to ensure that the doctors or consultants in its network are qualified and possess the necessary licenses and certifications. However, it is the responsibility of the patients to conduct their own due diligence before engaging in any medical facilities.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>4. Patient Referral: MedITravelS refers patients to doctors or consultants based on their specific medical requirements. The final decision to accept or reject a patient lies with the individual doctor or consultant. MedITravelS does not guarantee the acceptance of any patient by a particular medical professional.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>5. Medical Treatment: The doctors or consultants in MedITravelS's network provide medical treatment and facilities to patients visiting from outside of India. The treatment plans and procedures are determined by the medical professionals based on their expertise and evaluation of the patient's condition.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>6. Confidentiality: All patient information and medical records shared with MedITravelS or the doctors or consultants are treated with strict confidentiality. The medical professionals are bound by their own professional standards and applicable laws regarding patient confidentiality.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>7. Liability: MedITravelS is not liable for any medical treatment provided by the doctors or consultants in its network. The doctors or consultants are responsible for the quality and outcomes of the medical facilities they offer. MedITravelS acts as an intermediary and does not assume any liability for the medical procedures, complications, or outcomes, as we Meditravels are only the Facilitator which provide the platform to avail the medical facilities from the medical practitioners.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>8. Disputes: Any disputes arising from the facilities provided by MedITravelS shall be resolved through negotiations and discussions between the involved parties. If a resolution cannot be reached, the dispute may be subject to arbitration or legal proceedings in accordance with the applicable laws of the jurisdiction of the Rajkot.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>9. Amendments: MedITravelS reserves the right to modify or amend these terms and conditions at any time. Updated terms will be posted on the MedITravelS website or communicated to the doctors or consultants through email or other means. Continued use of MedITravelS's facilities constitutes acceptance of the modified terms.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>10. Governing Law: These terms and conditions shall be governed by and construed in accordance with the laws of the jurisdiction where MedITravelS is registered.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>11. Patient Consent: Doctors or consultants must obtain proper informed consent from the patients before initiating any medical treatment or procedures. It is the responsibility of the doctors or consultants to explain the risks, benefits, and alternatives of the proposed treatments to the patients.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>12. Medical Records and Documentation: Doctors or consultants are required to maintain accurate and complete medical records for all patients they treat through MedITravelS. These records should include details of the patient's medical history, diagnosis, treatment plans, medications, and any other relevant information.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>13. Communication and Language: Doctors or consultants should ensure effective communication with patients and provide information in a language that the patient understands. If necessary, the doctors or consultants should arrange for qualified interpreters or translators to facilitate communication.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>14. Professional Conduct: Doctors or consultants are expected to adhere to high professional and ethical standards while providing medical facilities through MedITravelS. They should treat patients with respect, dignity, and without discrimination based on race, ethnicity, religion, gender, or any other characteristic protected by law.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>15. Medical Licensing and Insurance: Doctors or consultants must maintain valid medical licenses and appropriate professional liability insurance coverage as required by the relevant regulatory bodies in their jurisdiction. It is their responsibility to ensure compliance with all applicable laws and regulations.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>16. Cancellations and Rescheduling: Doctors or consultants should communicate their cancellation and rescheduling policies to the MedITravelS clearly. Any changes to scheduled appointments should be handled promptly and with reasonable notice to minimize inconvenience to the patients.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>17. Feedback and Reviews: MedITravelS may request patients to provide feedback and reviews about the medical professionals and the facilities they received. Doctors or consultants should be open to constructive feedback and strive to continuously improve the quality of their facilities.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>18. Termination of Partnership: MedITravelS reserves the right to terminate the partnership with any doctor or consultant who fails to comply with these terms and conditions or engages in unethical or unprofessional conduct. Similarly, doctors or consultants have the right to terminate their association with MedITravelS by providing reasonable notice.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>19. Intellectual Property: MedITravelS retains all rights to its intellectual property, including its website, content, logos, and trademarks. Doctors or consultants shall not use MedITravelS's intellectual property without prior written consent.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>20. Severability: If any provision of these terms and conditions is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue to be valid and enforceable to the fullest extent permitted by law.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>21. Entire Agreement: These terms and conditions constitute the entire agreement between MedITravelS and the doctors or consultants and supersede any prior agreements or understandings, whether written or oral, relating to the subject matter herein.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>22. Emergency Situations: In the event of a medical emergency, doctors or consultants should provide immediate and necessary medical care to the best of their abilities. They should follow established protocols and guidelines to ensure patient safety and well-being.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>23. Continuity of Care: Doctors or consultants should strive to ensure continuity of care for patients who require follow-up treatment or additional medical services. They should coordinate with the patient's primary healthcare provider, if applicable, to provide comprehensive and integrated healthcare. when patient will move to their country doctor will give 2 Complimentary consultation as a Follow – up consultation.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>24. Medico-Legal Requirements: Doctors or consultants should comply with all medico-legal requirements and obligations in their respective jurisdictions, including but not limited to reporting of notifiable diseases, maintaining patient privacy and confidentiality, and complying with applicable medical ethics codes.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>25. Medical Advice and Second Opinions: Doctors or consultants should provide medical advice and second opinions in a professional and unbiased manner. They should consider the best interests of the patient and provide recommendations based on sound medical judgment and expertise.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>26. Professional Development: Doctors or consultants should actively engage in professional development activities to enhance their knowledge and skills in their respective medical specialties. They should stay updated with the latest advancements in medical science and technology to provide optimal care to patients.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>27. Data Protection and Privacy: MedITravelS and doctors or consultants should adhere to relevant data protection and privacy laws. Patient information should be securely stored, and appropriate measures should be taken to safeguard patient data from unauthorized access, use, or disclosure.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>28. Termination of Patient-Doctor Relationship: Doctors or consultants reserve the right to terminate the patient-doctor relationship if there are valid reasons, such as non-compliance with treatment plans or unethical behaviour. Termination should be communicated to the patient in a professional and respectful manner, before termination of relationship Doctor Compulsory inform to MediTravels’s associate person.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>29. Indemnification: Doctors or consultants agree to indemnify and hold harmless MedITravelS, its officers, employees, and agents from any claims, damages, or liabilities arising out of their provision of medical services through MedITravelS, except in cases of gross negligence or intentional misconduct.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>30. Force Majeure: MedITravelS shall not be held liable for any failure or delay in the performance of its obligations under these terms and conditions due to events beyond its reasonable control, including but not limited to natural disasters, acts of terrorism, or governmental regulations.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>31. Language of Agreement: The English language version of these terms and conditions shall prevail and be binding, regardless of any translations or versions provided in other languages.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>32. Waiver: The failure of MedITravelS to enforce any provision of these terms and conditions shall not be considered as a waiver of its right to enforce such provision in the future.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>33. Provision of Facilities: The Healthcare Provider shall provide medical facilities to the patients referred by the Company. Such facilities shall be consistent with the Healthcare Provider's existing standards, policies, and procedures, as well as ethical norms and medical guidelines laid out by regulatory authorities. The Healthcare Provider shall exercise professional judgment in the provision of such facilities.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>34. Appointment and Consultation: The Healthcare Provider agrees to prioritize patients referred by the Company for consultation and treatment, subject to the Healthcare Provider's availability and the medical urgency of each case.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>35. Fees and Payments: The Healthcare Provider will levy charges for the medical facilities provided to the referred patients as per the fee structure agreed upon with the Company. The Company and the Healthcare Provider will periodically review this fee structure and make amendments if necessary, subject to mutual agreement.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>36. Non-Exclusivity Clause: This Agreement is non-exclusive. The Company reserves the right to enter into similar agreements with other healthcare providers, doctors, surgeons, or hospitals. Similarly, the Healthcare Provider is free to enter into agreements with other entities requiring their facilities.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>37. Confidentiality: Both parties shall uphold the strict confidentiality of all patient-related information, ensuring compliance with the Indian Medical Council (Professional Conduct, Etiquette and Ethics) Regulations and all other relevant laws and regulations. Any breach of this clause will be regarded as a serious offense, resulting in immediate termination of this Agreement and potential legal action.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>38. Quality Assurance: The Healthcare Provider shall strive to provide high-quality care and facilities to the patients referred by the Company. This includes adhering to accepted standards and protocols set by local and national healthcare authorities in India. In case of any complaints regarding the quality of care, the Healthcare Provider will cooperate fully with the Company and any investigating authorities to resolve the issue.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>39. Licensing and Accreditation: The Healthcare Provider guarantees they hold valid licenses and accreditation as required under relevant state and national laws in India. The Healthcare Provider will promptly notify the Company of any changes to this status. Hospitals must obtain and maintain appropriate accreditation from recognized accrediting bodies. This includes accreditation related to medical care, quality standards, and patient safety.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>40. Compliance with Laws and Regulations: The Healthcare Provider agrees to comply with all applicable laws, rules, and regulations pertaining to the provision of healthcare facilities. This includes adherence to guidelines set forth by the Indian Medical Association, the Medical Council of India, and any other relevant regulatory bodies.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>41. Payment Terms: This is to ensure that the financial aspect of the partnership runs smoothly and both parties are clear on the payment terms. for IPD - 40% payment at the time of Admission of The Patient, 40% payment at the time of Discharge, 20% when patient reached at their Country.
            For OPD – all consultation charges of Previous Month will be credited to Doctor at Next Month till 10th of Every Month</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>42. Liability and Indemnity: The Healthcare Provider shall indemnify the Company against any claim, loss, damage, or liability arising out of the Healthcare Provider's breach of this Agreement, negligence, or non-compliance with any relevant laws, rules, and regulations. The Healthcare Provider agrees that this clause will survive the termination of this Agreement.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>43. Dispute Resolution: Any dispute, controversy, or claim arising out of or in relation to this Agreement, including the validity, invalidity, breach, or termination thereof, shall be resolved by arbitration in accordance with the Arbitration and Conciliation Act, 1996. The place of arbitration shall be [city, state], and the proceedings shall be conducted in the English language.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>44. Governing Law: This Agreement shall be governed by and construed in accordance with the laws of India.</span>
          <span className="list-tc" style={{ display: 'inline-block' }}>By accepting these terms and conditions, doctors or consultants agree to abide by the principles outlined herein and provide medical treatment to patients referred by MedITravelS in accordance with their professional standards and obligations.</span>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <Button
              className={`agree-btn ${isModalScrolled ? 'scrolled' : ''} ${isAgreeEnabled ? '' : 'disabled-btn'}`}
              onClick={isAgreeEnabled ? handleAgreeClick : undefined}
              style={{
                backgroundColor: isModalScrolled ? '#291211' : '#f4a460',
                pointerEvents: isModalScrolled && isAgreeEnabled ? 'auto' : 'none',
                filter: isModalScrolled ? 'none' : 'grayscale(100%)',
              }}
            >
              Agree
            </Button>
            <Button className="btn btn-yellow" variant="secondary" onClick={() => setShowModal(false)} label='Close'>
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Action
        backLink={previousPageURL}
        onClickNext={handleNextClick}
        onClearForm={handleClearForm} />
      <div className='ticket-component' style={{ width: '50%', display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
        <Ticket variant="contained" color="secondary" section="Doctor: Application Form - Relevant Experience" />
      </div>
      <CustomModal
        show={errorModalData.show}
        onHide={handleCloseErrorModal}
        titleStyle={{ fontWeight: "bold" }}
        title={errorModalData.title}
        message={errorModalData.message}
      />
    </Page>
  )
}

export default RelevantExperience